import {Component, OnDestroy, OnInit} from '@angular/core';
import {UserDataService} from '../../services/user-data.service';
import { Router } from '@angular/router';
import { HttpService } from 'src/app/services/http.service';
import { TranslateService } from '@ngx-translate/core';
import { SocketService } from "src/app/services/socket.service";
import { RouteGuardsService } from 'src/app/services/route-guards.service';
import {environment} from 'src/environments/environment'
import { Subscription } from 'rxjs';
import { AmplitudeService } from 'src/app/services/amplitude.service';

interface Page {
  name: string;
  link: string;
  icon: string;
}

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {

  constructor(
    private router: Router,
    public user: UserDataService,
    private http: HttpService,
    public translate: TranslateService,
    private socketCentral: SocketService,
    public routeGuards: RouteGuardsService,
    private amplitude: AmplitudeService,
  ) {}

  public logo:string = '';
  public version = environment.version
  public clientMenu: {[key:string]: any } = {};
  private routeSubscription: Subscription;


  async ngOnInit() {

    this.routeSubscription = this.routeGuards.authorized.subscribe(async (authorized) => {
      if (authorized){
        this.amplitude.setUserProperties();
        if (this.user.isUgm()){
          this.user.plantas = await this.http.maestroGet('get_plantas');
    
          new Set(this.user.plantas.map(p=>p.nome_cliente)).forEach(c=>{
            this.clientMenu[`${c}`]= this.user.plantas.filter(p=>p.nome_cliente == c).sort((p1, p2) => p1.nome_planta.localeCompare(p2.nome_planta))
          })
        };
      };
    });
  };

  changePlanta(newPlanta){
    this.user.overwritePlanta(newPlanta);
    if(this.user.hasSatellite()){
      this.socketCentral.disconnectFromSockCent();
    };
    this.reload();
  }

  reload(){
    let activeUrl = this.router.url
    this.router.navigateByUrl('/home', {skipLocationChange: true}).then(()=>
      this.router.navigateByUrl(activeUrl)
    )
  }

  ngOnDestroy(){
    this.routeSubscription.unsubscribe();
  }

  handleImageError(event: any) {
    const imagemPadrao = `${this.http.getServerUrl}/get_logo/999`;
    event.target.src = imagemPadrao;
  }

  redirectIfAdmin(): void {
    if (!this.user.isAdmin()) return
    
    const currentRoute = this.router.url;
    if (currentRoute.includes('map')) {
      this.router.navigate(['/admin']);
    } else {
      this.router.navigate(['/map']);
    }
  }
}
