<style>
    .form-wrapper {
        display: flex;
        flex-direction: column;
        margin-top: 1rem;
    }

    .row {
        display: flex;
    }

</style>

<mat-dialog-content>
    <h3>Gerador automático de presets</h3>

    <div class="form-wrapper">
        <div class="row">
            <mat-form-field appearance="outline" style="margin-right: .5rem;">
                <mat-label>Ângulo de início (°)</mat-label>
                <input [(ngModel)]="formOutput.initialAngle" required matInput type="number" min="0" max="359.99" placeholder="Ex: 0"/>
            </mat-form-field>
    
            <mat-form-field appearance="outline" style="margin-left: .5rem;">
                <mat-label>Nível de zoom (x)</mat-label>
                <input [(ngModel)]="formOutput.zoomLevel" required matInput type="number" min="1" max="45" placeholder="Ex: 4"/>
            </mat-form-field>
        </div>

        <div class="row" style="margin-top: .5rem;">
            <mat-form-field appearance="outline" style="margin-right: .5rem;">
                <mat-label>Tilt (°)</mat-label>
                <input [(ngModel)]="formOutput.tiltAngle" required matInput type="number" min="0" max="120" placeholder="Ex: 85"/>
            </mat-form-field>
    
            <mat-form-field appearance="outline" style="margin-left: .5rem;">
                <mat-label>Estabilização (s)</mat-label>
                <input [(ngModel)]="formOutput.stabilizationTime" required matInput type="number" min="0" max="10" placeholder="Ex: 5"/>
            </mat-form-field>
        </div>
    </div>
</mat-dialog-content>

<mat-dialog-actions align="center">
    <button mat-raised-button [mat-dialog-close]="false">Cancelar</button>
    <button mat-raised-button [mat-dialog-close]="formOutput">Criar presets</button>
</mat-dialog-actions>