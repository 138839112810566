import { Component, OnInit } from '@angular/core';
import { formatDate } from '@angular/common';
import { UntypedFormControl } from '@angular/forms';
import { Sort } from '@angular/material/sort';

import { MeteoService } from '../../services/meteo.service';
import { GeoService } from '../../services/geo.service';
import { UserDataService } from '../../services/user-data.service';
import { TranslateService } from '@ngx-translate/core';
import { AmplitudeService } from 'src/app/services/amplitude.service';

interface MeteoData {
  nome_local: string;
  risk: number;
  temperature: number;
  rain: number;
  wind_speed: number;
  humidity: number;
  weatherIcon: string;
}

@Component({  
  selector: 'app-meteo',
  templateUrl: './meteo.component.html',
  styleUrls: ['./meteo.component.scss']
})
export class MeteoComponent implements OnInit {
  
  public search_date : string;
  public date = new UntypedFormControl((new Date()));

  constructor(
    public meteo: MeteoService,
    public geo: GeoService,
    public user: UserDataService,
    private translate: TranslateService,
    private amplitude: AmplitudeService,
    ) { }
  
  public displayedColumns: string[] = [
    'nome_local',
    'icon',
    'risk',
    'day',
    'rain',
    'wind_speed',
    'humidity',
  ];
  
  public riskColorMap = [
    {legend: this.translate.instant("MAP.VERY_LOW_FIRE_RISK") , color: '#2b83ba'},
    {legend: this.translate.instant("MAP.LOW_FIRE_RISK") , color: '#abdda4'},
    {legend: this.translate.instant("MAP.MODERATE_FIRE_RISK") , color: '#ffffbf'},
    {legend: this.translate.instant("MAP.HIGH_MODERATE_FIRE_RISK") , color: '#fdae61'},
    {legend: this.translate.instant("MAP.HIGH_FIRE_RISK") , color: '#d7191c'},
  ]


  async ngOnInit(){
    this.meteo.meteoPlacesCount = await this.meteo.getMeteoPlacesCount();
    await this.meteo.getValidMeteo();
    
    console.log('onInit meteo', this.meteo.meteoData);

  }



  async refreshData() {
    this.search_date = this.date.value.toISOString().slice(0,10)
    await this.meteo.getValidMeteo(this.search_date);
    this.amplitude.sendDatePickerEvent('risk', 'MAP.WEATHER', `${formatDate(this.search_date, 'dd-MMM-yyyy', 'en-US')}`);
  }

  getRiskColor(risk: number){
    if (!risk) return '#bebebe'
    if (risk < 0.2) return '#2b83ba'
    if (risk < 0.4) return '#abdda4'
    if (risk < 0.6) return '#ffffbf'
    if (risk < 0.8) return '#fdae61'
    else return '#d7191c'
  }

  getTempColor(temp: number){
    if (temp > 30) return '#d7191c'
    if (temp > 28) return '#fdae61'
    else {
      if (!this.user.darkMode())
        return 'black'
      else return 'white';
    }
  }

  getHumidityColor(humidity: number){
    if (humidity < 30) return '#d7191c'
    if (humidity < 35) return '#fdae61'
    else {
      if (!this.user.darkMode())
        return 'black'
      else return 'white';
    }
  }

  getWindColor(wind_speed_km_h: number){
    if (wind_speed_km_h > 30) return '#d7191c'
    if (wind_speed_km_h > 25) return '#fdae61'
    else {
      if (!this.user.darkMode())
        return 'black'
      else return 'white';
    }
  }

  sortData(sort: Sort) {
    const data = this.meteo.tableData.slice();

    if (!sort.active || sort.direction === '') {
      this.meteo.tableData = data;
    } else {
      this.meteo.tableData = data.sort((a, b) => {
        const aValue = (a as any)[sort.active];
        const bValue = (b as any)[sort.active];
        return (aValue < bValue ? -1 : 1) * (sort.direction === 'asc' ? 1 : -1);
      });
    }
  }

}