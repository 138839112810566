import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'full-screen-dash-dialog',
    templateUrl: 'full-screen-dash-dialog.html',
})
export class FullScreenDashDialog {

    constructor(@Inject(MAT_DIALOG_DATA) public data: any) {
    }
}
