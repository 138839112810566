<mat-dialog-content>
    <main>
        <mat-icon color="warn" style="font-size: 48px;">error</mat-icon>
        <p>
            {{ 'SAT.SOCKET_DISCONNECTED_MSG_PT_1' | translate }}
        </p>
        <p>
            {{ 'SAT.SOCKET_DISCONNECTED_MSG_PT_2' | translate }}
        </p>
        <p>
            {{ 'SAT.SOCKET_DISCONNECTED_MSG_PT_3' | translate }}
        </p>
    </main>
</mat-dialog-content>
<mat-dialog-actions align="center">
    <button mat-raised-button [mat-dialog-close]="true" color="primary">
        {{ 'MAP.CLOSE' | translate }}
    </button>

    <button mat-raised-button color='primary'
        (click)='reloadPage()'>
        {{ 'MAP.RELOAD_PAGE' | translate }}
    </button>
</mat-dialog-actions>