import { Component, OnInit, signal } from '@angular/core';
import { Router } from '@angular/router';
import { LoginService } from '../../services/login.service';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { SUPPORT_P2, OPERADOR_PANTERA_2, UserDataService } from 'src/app/services/user-data.service';
import { ForgotPassDialog } from '../../shared/dialogs/forgot-pass-dialog/forgot-pass-dialog';
import { environment } from 'src/environments/environment'
import { HttpService } from 'src/app/services/http.service'; 
import { DeactivatedAccountComponent } from 'src/app/shared/dialogs/deactivate-dialog/deactivate-dialog.component';
import { AmplitudeService } from 'src/app/services/amplitude.service';
import { ActivatedRoute } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {

  public envDev = !environment.production;

  public username: string;
  public userpass: string;
  public rememberMe: boolean = true;

  public showErrorMessage: boolean = false;
  public showPass: boolean = false;


  public version = `${environment.version}`

  public isMfaRequired: boolean = false;
  public mfaCode: string;
  public hiddenEmail: string;
  public countdown = signal<string | undefined>(undefined);
  public countDownCheck = signal<boolean | undefined>(undefined)

  constructor(
    private router: Router,
    private dialog: MatDialog,
    public translate: TranslateService,
    public loginService: LoginService,
    public user: UserDataService,
    private http: HttpService,
    private amplitudeService: AmplitudeService,
    private route: ActivatedRoute,
    private snackBar: MatSnackBar,
  ) {}

  ngOnInit(): void {
    this.loginService.signOut();
    this.translate.use(this.user.getLang());
    this.openResetParam()
  }

  openResetParam() {
    this.route.queryParams.subscribe(async params => {
      const resetSuccess = params['reset_success'];

      if (resetSuccess === 'true') {
        setTimeout(() => {
          this.snackBar.open(this.translate.instant('MAP.PASSWORD_RESETED'), this.translate.instant('MAP.CLOSE'), {duration:5000, panelClass:'snackbar-true'});
        }, 200);
      } else if (resetSuccess === 'false') {
        setTimeout(() => {
          this.snackBar.open(this.translate.instant('MAP.INVALID_EXPIRED_LINK'), this.translate.instant('MAP.CLOSE'), {duration:5000, panelClass:'snackbar-false'});
        }, 200);
      }
    });
  }
  async sign_in() {
    this.userpass = this.userpass.replace(/\s/g, '');
    this.username = this.username.replace(/\s/g, '');
    let response = await this.loginService.credentialsLogin(this.username, this.userpass, this.rememberMe);
 
    if (!response) {
      this.showErrorMessage = true;
      console.log('-- Login Failed: wrong credentials');      
      return
    }

    if (response["mfa"] !== true) {
      let auth_user_data = await this.http.maestroGet(`pweb_auth`, this.user.getToken())

      const isOperatorOrSupportP2 = this.isAllowedToOpenCamerasInterface(auth_user_data.id_role)
      this.loginService.setIsOperatorOrSupportP2(isOperatorOrSupportP2)

      this.amplitudeService.sendEvent("Fez Login", { "Username": this.username, "ID Planta": auth_user_data.id_planta})
      if (!auth_user_data.status_planta && auth_user_data.id_cliente === 2) {
        return this.showBlockedAccountModal();
      }
      return
    }
    
    this.clearMFAStatesAfterAuthSuccess(response["hidden_email"])      
    return
  }
 
  clearMFAStatesAfterAuthSuccess(hiddenEmail: any) {
    this.showErrorMessage = false;
    this.isMfaRequired = true;
    this.hiddenEmail = hiddenEmail;
    this.loginService.setMfaExpired(false);
    this.mfaCode=''
    this.startCountdown(90);
    this.countDownCheck.set(true)
  }

  isAllowedToOpenCamerasInterface(idRole: number) {
    return [OPERADOR_PANTERA_2, SUPPORT_P2].includes(idRole)
  }

  async submitMfaCode() {
    let success = await this.loginService.mfaLogin(this.username, this.userpass, this.rememberMe, this.mfaCode);
    if (success) {
      let auth_user_data = await this.http.maestroGet(`pweb_auth`, this.user.getToken());
      
      const isOperatorOrSupportP2 = this.isAllowedToOpenCamerasInterface(auth_user_data.id_role)
      this.loginService.setIsOperatorOrSupportP2(isOperatorOrSupportP2)

      if (!auth_user_data.status_planta && auth_user_data.id_cliente === 2) {
        return this.showBlockedAccountModal();
      } 
      this.loginService.setWrongMfa(false);
      this.amplitudeService.sendEvent("Fez Login", { "Username": this.username, "ID Planta": auth_user_data.id_planta})
      return
    } else {
      console.log('-- MFA Failed: wrong code');
    }
  }

  async resendMfaCode() {
    const mfaData = {
      username: this.username.trim(),
      userpass: this.userpass.trim(),
      extended: this.rememberMe,
      request_new_code: true
    };
  
    const response = await this.http.maestroUnauthPost('v2/pweb_login', mfaData);
  
    if (response['mfa'] === true) {
      this.hiddenEmail = response['hidden_email'];
      this.startCountdown(90);
      this.countDownCheck.set(true)
      this.loginService.setMfaExpired(false);
      this.loginService.setWrongMfa(false);
      this.mfaCode='';
    } 
  }

  startCountdown(duration: number) {
    let timer = duration, minutes: number, seconds: number;

    const countdownInterval = setInterval(() => {
      minutes = Math.floor(timer / 60);
      seconds = timer % 60;

      const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
      const formattedSeconds = seconds < 10 ? `0${seconds}` : seconds;

      const timeRemaining = `${formattedMinutes}:${formattedSeconds}`;
      this.countdown.set(timeRemaining);

      if (--timer < 0) {
        clearInterval(countdownInterval);
        this.countdown.set('');
        this.countDownCheck.set(false);
      }
    }, 1000);
  }
  
  async login_sso() {
    this.router.navigateByUrl('/login-sso');
  }

  forgot_pass() {
    this.dialog.open(ForgotPassDialog, {
      data: {},
    });
  }

  switchLang(lang: string) {
    console.log(lang);
    this.translate.use(lang);
  }

  showBlockedAccountModal() {
    console.log('Mensagem Popup 🪟');
    this.dialog.open(DeactivatedAccountComponent, {
      width: '500px',
      
    });
  }

}
