import { Component, OnInit } from '@angular/core';
import { AmplitudeService } from '../../services/amplitude.service';
import { UserDataService } from 'src/app/services/user-data.service';

@Component({
  selector: 'app-weather',
  templateUrl: './weather.component.html',
  styleUrls: ['./weather.component.scss']
})
export class WeatherComponent implements OnInit {
  
  private tabs = {
    0: 'Meteorologia',
    1: 'Estações',
    2: 'Informativos'
  }

  constructor(
    private amplitude: AmplitudeService,
    public user: UserDataService,
  ) { }

  ngOnInit(){}

  amplitudeTabEvent(selectedTab){
    this.amplitude.sendTabViewEvent('weather', this.tabs, selectedTab);
  }

}
