@if(isSavingOffsetValueChange()) {
  <app-loading message="Salvando alterações..."></app-loading>
}
@else {
  <div class="offset-calibration">
      <h2>Calibração de offset</h2>
      
      <div class="top-row">
        <mat-form-field class="configForm" appearance="outline">
          <mat-label>{{ 'MAP.SELECT_TOWER' | translate }}</mat-label>
          <mat-select [(ngModel)]="selectedCamera" (selectionChange)="changeCam()">
            @for (camera of camerasList; track camera.id) {
              <mat-option [value]="camera">{{ camera.nome_local }}</mat-option>
            }
          </mat-select>
        </mat-form-field>
  
        <mat-form-field appearance="outline" class="offsetForm" appearance="outline">
          <mat-label>Offset (°)</mat-label>
          <input type="number" matInput placeholder="Offset (°)" [(ngModel)]="selectedCameraOffset"/>
        </mat-form-field>
        
        <button mat-mini-fab color="primary" (click)="updateOffsetValue(selectedCameraOffset)"><mat-icon>save</mat-icon></button>
      </div>
  </div>
}