<style>

</style>

<mat-dialog-content>
<main>
  <h3>{{ 'MAP.UPLOAD_IMAGE' | translate }}</h3>
  
  <input type="file" id="file" (change)="handleFileInput($event)" accept="image/*" multiple>
  
  <!-- [(value)]="imgTypeId"
    (selectionChange)="changeCamera()" -->
  <!-- <div>
    <mat-form-field appearance="outline" style="margin-top: 15px;">
      <mat-label>Tipo de imagem</mat-label>
      <mat-select
      >
        <mat-option *ngFor='let imageType of imageTypes' [value]="imageType.id">
          {{imageType.type}}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </div> -->

  <div class="fx-row-space-around-center">
    <button mat-raised-button color='primary' style="margin-top: 15px;"
    [mat-dialog-close]="false">
      {{ 'MAP.CANCEL' | translate }}
    </button>

    <button mat-raised-button color='primary' style="margin-top: 15px;"
    [disabled] = 'filesToUpload.length === 0'
    [mat-dialog-close]="true"
    (click)='postImgData()'>
    {{ 'MAP.SEND' | translate }}
    </button>
  </div>
</main>
</mat-dialog-content>