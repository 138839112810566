<style>
  main {
    width: 40vw;
  }
</style>

<mat-dialog-content>
  <main>
    <h3>{{ 'TERMS_OF_USE.TITLE' | translate }}</h3>
      
      <p>{{ 'TERMS_OF_USE.PT_1' | translate }}</p>
      <p>
        {{ 'TERMS_OF_USE.PT_2' | translate }} <strong>PANTERA</strong>{{ 'TERMS_OF_USE.PT_3' | translate }}
      </p>
      <p>
        {{ 'TERMS_OF_USE.PT_4' | translate }} <a [href]="userTermsUrl" target="_blank">{{ 'TERMS_OF_USE.PT_5' | translate }}</a>.
      </p>

      <button mat-raised-button color='primary' style="margin-top: 15px;"
      [mat-dialog-close]="true">
      {{ 'TERMS_OF_USE.CONTINUE' | translate }}
      </button>

  </main>
</mat-dialog-content>
