<!-- custom-toolbar -->
<div class="container">
<div class="header">
  <header class="select-report-header">
  <div class="interval-createbtn">
      @if (user.hasReportCreation()) {
        <div class="reportCreation">
          <button mat-mini-fab
          matTooltip="{{ 'REP.CREATE_REPORT' | translate }}"
          color="warn" (click)="reports.openReportDialog()">
          <mat-icon id="create-report-btn-icon">local_fire_department</mat-icon>
        </button>
      </div>
    }
  </div>
  
  <div class="report-date-select">
      <mat-form-field class="interval-form" appearance="outline">
        <mat-date-range-input [rangePicker]="picker">
          <input matStartDate placeholder="{{ 'MAP.STARTS_AT' | translate }}" [(ngModel)]='reports.startDate'>
          <input matEndDate #endDateInput placeholder="{{ 'MAP.ENDS_AT' | translate }}" [(ngModel)]='reports.endDate' (dateChange)='onEndDateChange(endDateInput.value)'>
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-date-range-picker #picker color="warn"></mat-date-range-picker>
      </mat-form-field>
    </div>
    
    <div class = "report-filters">
      <button mat-icon-button [matMenuTriggerFor]="filtersMenu" [matBadge]="(filterService.filterActived()|| searchText) ? '!' : ''"
      matBadgePosition="after"
      matBadgeColor="warn"
      [matBadgeHidden]="!(filterService.filterActived()|| searchText)"
      matTooltip="{{ 'MAP.FILTERS' | translate }}"
      matBadgeSize="small">
        <mat-icon>tune</mat-icon>
      </button>

      <mat-menu #filtersMenu="matMenu">
        <div class="search" (click)="$event.stopPropagation()">
          <mat-form-field class="report-search" appearance="fill">
            <input matInput type="text" placeholder="{{  'MAP.SEARCH_BY_NUMBER' | translate }}" [(ngModel)]="searchText">
          </mat-form-field>
        </div>
       
        <ng-container>
          @for(filter of filters; track filter.prop){
          <div mat-menu-item (click)="$event.stopPropagation()">
            <mat-slide-toggle [checked]="filterService.filterStates[filter.prop]()" 
            (change)="filterService.updateFilterState(filter.prop, $event.checked);  activateFilter()">{{ filter.label }}</mat-slide-toggle>
          </div>
        }
        </ng-container>
      
      </mat-menu>
      </div>
   </header>
  </div>


  <!-- Talvez um componente que cuide desse picker? -->

  <div class="table">
    <table mat-table [dataSource]="reports.reports_list | searchReport: searchText : filterService.filterStates.filterHasRisk() : filterService.filterStates.filterInProgress() : filterService.filterStates.filterHasTriggerings()" class="mat-elevation-z8">

      <ng-container matColumnDef="n_relatorio_planta">
        <th mat-header-cell *matHeaderCellDef>{{ 'MAP.NUMBER' | translate }}</th>
        <td mat-cell *matCellDef="let element">
          <mat-icon [inline]="true" [ngStyle]="{ color: element.icon.color === 'amber' ? '#FFD740' : element.icon.color }">
            local_fire_department
          </mat-icon>
          <br>
            {{element.n_relatorio_planta}}
          </td>
        </ng-container>

        <ng-container matColumnDef="data_deteccao">
          <th mat-header-cell *matHeaderCellDef>{{ 'MAP.DETECTION' | translate }}</th>
          <td mat-cell *matCellDef="let element" class="td-date">
            {{ element.datetime_deteccao | date: 'dd/MMM HH:mm' : undefined : currentLocale }}
          </td>
        </ng-container>

        <ng-container matColumnDef="nome_local">
          <th mat-header-cell *matHeaderCellDef>{{ 'MAP.LOCATION' | translate }}</th>
          <td mat-cell *matCellDef="let element" style="text-align: left;">
            {{ element.dados_localizacao[0].nome_geom}}
          </td>
        </ng-container>

        <ng-container matColumnDef="resultado_modelo">
          <th mat-header-cell *matHeaderCellDef>{{ 'REP.ORIGIN' | translate }}</th>
          <td mat-cell *matCellDef="let element">
            @switch (element.id_origem_deteccao) {
              @case (1) {
                <mat-icon>psychology</mat-icon>
              }
              @case (3) {
                <mat-icon>satellite_alt</mat-icon>
              }
              @default {
                <mat-icon>person</mat-icon>
              }
            }
          </td>
        </ng-container>

        <ng-container matColumnDef="dist_local">
          <th mat-header-cell *matHeaderCellDef>{{ 'MAP.DISTANCE_KM' | translate }}</th>
          <td mat-cell *matCellDef="let element">
            {{ element.dados_localizacao[0]?.distancia_area | number:'1.1-1' }}
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="reportTableColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: reportTableColumns;"
          class='report-row'
        (click)="selectReport(row.id_report)"></tr>
      </table>
    </div>
  </div>
