import { Component, ViewChild, ElementRef, OnInit, Inject, signal } from '@angular/core';

import html2canvas from 'html2canvas';
import { PDFDocument } from 'pdf-lib';

import { ReportsService } from '../../services/reports.service';
import { UserDataService } from '../../services/user-data.service';
import { AmplitudeService } from 'src/app/services/amplitude.service';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HttpService } from '../../services/http.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'download-report-dialog',
  templateUrl: 'download-report-dialog.html',
  styleUrls: ['download-report-dialog.scss']
})
export class DownloadReportDialog implements OnInit{
  
  @ViewChild('content', {static:false}) el:ElementRef;
  
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public reports: ReportsService,
    public user: UserDataService,
    private amplitude: AmplitudeService,
    private http: HttpService,
    public translate: TranslateService,
  ) {}

  public b64_logo:string;

  public loaded = false;
  public reportMapImgB64: string
  public get64ImgsLoaded = signal<boolean | undefined>(undefined); 
  public getReportMapImageLoaded = signal<boolean | undefined>(undefined); 
  public canDownload = signal<boolean>(false); 

  async ngOnInit(){
    await this.reports.set64imgs();
    this.b64_logo = await this.user.getB64Logo();
    this.checkDownloadStatus(); 
    await this.loadImages();
  }

  downloadReportPNG() {
    const contentWrapper = document.getElementById("content-wrapper");
    const elementToHide = document.getElementById("to-hide");

    contentWrapper.style.height = "auto";
    elementToHide.className = "hide";

    html2canvas(contentWrapper, {
      scrollY: -window.scrollY,
      scale: 5,
      backgroundColor: '#262626'
    }).then(canvas => {
      const imgData = canvas.toDataURL("image/png");      
      const link = document.createElement('a');
      link.download = `${this.translate.instant("REP.REPORT")}_pantera_${this.reports.selectedR.n_relatorio_planta}_png.png`;
      link.href = imgData;
      link.click();      
      elementToHide.className = "actions-row";
    });
    
    this.amplitude.sendEvent("Baixou Relatório", { "ID Relatório": this.reports.selectedR.id_report, "Número Relatório": this.reports.selectedR.n_relatorio_planta, "Formato": "PNG" })
  }

  private async generatePDF(): Promise<Uint8Array> {
    const contentWrapper = document.getElementById("content-wrapper");
    const elementToHide = document.getElementById("to-hide");
  
    contentWrapper.style.height = "auto";
    elementToHide.className = "hide";
  
    const canvas = await html2canvas(contentWrapper, {
      scrollY: -window.scrollY,
      scale: 5,
      backgroundColor: '#262626',
    });
  
    const imgData = canvas.toDataURL("image/png");
    const pdfDoc = await PDFDocument.create();
    const page = pdfDoc.addPage([canvas.width, canvas.height]);
    const pngImage = await pdfDoc.embedPng(imgData);
    const { width: imgWidth, height: imgHeight } = pngImage.scale(1);
  
    page.drawImage(pngImage, {
      x: 0,
      y: page.getHeight() - imgHeight,
      width: imgWidth,
      height: imgHeight,
    });
  
    const pdfBytes = await pdfDoc.save();
    elementToHide.className = "actions-row";
  
    return pdfBytes;
  }
  
  async downloadReportPDF() {
    const pdfBytes = await this.generatePDF();
    this.download(pdfBytes, `${this.translate.instant("REP.REPORT")}_pantera_${this.reports.selectedR.n_relatorio_planta}_pdf.pdf`, "application/pdf");
    this.amplitude.sendEvent("Baixou Relatório", { "ID Relatório": this.reports.selectedR.id_report, "Número Relatório": this.reports.selectedR.n_relatorio_planta, "Formato": "PDF" })

  }

  download(data: Uint8Array, fileName: string, mimeType: string) {
    const blob = new Blob([data], { type: mimeType });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
  }

  async sendReportViaEmail(){
    try {
      const pdfBytes = await this.generatePDF();

      const pdfBlob = new Blob([pdfBytes], { type: 'application/pdf' });

      let formData = new FormData();
      formData.append('file', pdfBlob, `relatorio_${this.reports.selectedR.n_relatorio_planta}.pdf`);
      let data = {
        'id_planta': this.user.getIdPlanta(),
        'n_relatorio_planta': this.reports.selectedR.n_relatorio_planta
      };
  
      formData.append('reportData', JSON.stringify(data));

      const response = await this.http.maestroPost('send_pdf_email', formData, true);
      console.log('PDF sent successfully:', response);
    } catch (error) {
      console.error('Error generating or sending PDF:', error);
    }
  }

  async getReportMapImage() {
    try {
      const response = await this.reports.getReportLocationImage(
        this.user.getIdPlanta(),
        this.reports.selectedR.id_report
      );
      if (response?.base64) {
        this.reportMapImgB64 = response.base64;
        return this.reportMapImgB64;
      }
      return null;
    } catch (error) {
      return null;
    }
  }

  async loadImages() {
    try {
      const imgs = await this.get64imgs();
      this.get64ImgsLoaded.set(!!imgs.length);
    } catch {
      this.get64ImgsLoaded.set(false);
    }

    try {
      const mapImage = await this.getReportMapImage();
      this.getReportMapImageLoaded.set(!!mapImage);
    } catch {
      this.getReportMapImageLoaded.set(false);
    }

    this.checkDownloadStatus(); 
  }

  checkDownloadStatus() {
    const get64Status = this.get64ImgsLoaded();
    const mapStatus = this.getReportMapImageLoaded();

    if (get64Status === undefined || mapStatus === undefined) {
      this.canDownload.set(false); 
      return;
    }

    if (get64Status || mapStatus || (!get64Status && !mapStatus)) {
      this.canDownload.set(true);
    } else {
      this.canDownload.set(false);
    }
  }

  async get64imgs() {
    try {
      const imgs = await this.reports.get64imgs();
      return imgs;
    } catch (error) {
      console.error('Error loading get64imgs:', error);
      return [];
    }
  }


}