import { Injectable, signal } from '@angular/core';
import { HttpService } from './http.service'
import { UserDataService } from './user-data.service'
import { toObservable } from '@angular/core/rxjs-interop';
import { Httpv2Service } from './httpv2.service';
import { InfoDialog } from '../shared/dialogs/info-dialog/info-dialog';
import { MatDialog } from '@angular/material/dialog';
@Injectable({
  providedIn: 'root'
})
export class PresetpickerService {

	constructor(
		private http: HttpService,
		private user: UserDataService,
		private httpv2: Httpv2Service,
		private dialog: MatDialog,
	) { }

	public presetSignal = signal({ index: null, pan: null, tilt: null, zoom: null, sleep: null, uuid: null });
	public presetSignal$ = toObservable(this.presetSignal);

	public isSavingPresetImage = signal<boolean>(false);
	public isSavingMultiplePresetsImages = signal<boolean>(false);
	public timeBetweenPositionsOnPresetsSimulator = signal<number>(3);

	updateTimeBetweenPositionsOnPresetsSimulator(value: number) {
		this.timeBetweenPositionsOnPresetsSimulator.set(value)
	}

	async updatePresetsImgCam(idCam, presetList) {
		return new Promise<void>(async (resolve) => {
			for (var preset of presetList) {
				if(this.isSavingMultiplePresetsImages() || this.isSavingPresetImage()) {
					await this.camPositionsAsync(idCam, preset);
					if(presetList.indexOf(preset)===presetList.length-1) {
						this.setIsSavingPresetImage(false);
						this.setIsSavingMultiplePresetsImages(false);
						resolve()
					}
				}
				else {
					this.setIsSavingMultiplePresetsImages(false);
					this.setIsSavingPresetImage(false);
					resolve();
				}
			}
		});
	}

	setIsSavingPresetImage(value: boolean) {
		this.isSavingPresetImage.set(value)
	}

	setIsSavingMultiplePresetsImages(value: boolean) {
		this.isSavingMultiplePresetsImages.set(value);
	}

	camPositionsAsync(idCam, preset) {
		return new Promise<void>((resolve) => {
			this.presetSignal.set(preset);
			this.requestPTZPosition(preset,idCam)
			.then(() => {
				setTimeout(() => {
					this.requestSnapshotRetry(idCam)
					.then((snapshot) => {
						this.sendSnapshotToBackend(idCam, snapshot, preset)
						.then(() => resolve())
					})
					.catch((error) => {
						console.error('Error no preset:', preset, error);
						resolve();
					});
				}, 5*1000);
			});
		});
	}

	requestPTZPosition(preset, idCam) {
		return new Promise<void>(async (resolve) => {
			this.httpv2.getFromBackendP2Promise(`/cameras/${idCam}/absolute_ptz/${preset.pan}/${preset.tilt}/${preset.zoom}`)
			.then(()=>resolve())
		});
	}

	requestSnapshotRetry(idCam, maxRetries = 3, delayBetweenRetries = 1000) {
		return new Promise(async (resolve, reject) => {
			let retries = 0;

			while (retries < maxRetries) {
				try {
					const imgBlob = await this.httpv2.getBlobFromBackendP2(`/cameras/${idCam}/get_snap`);
					resolve(imgBlob);
					return; // Saia da função se a solicitação for bem-sucedida
				}
				catch (error) {
			  		console.error('Error:', error);
					retries++;
			  		if (retries < maxRetries) {
						console.log(`Retrying in ${delayBetweenRetries / 1000} seconds...`);
						await new Promise(resolve => setTimeout(resolve, delayBetweenRetries));
			  		}
					else {
						console.log('Max retries reached. Giving up.');
						reject(false);
					}
				}
		  	}
		});
	}
	
	async sendSnapshotToBackend(idCam, snapshot, preset) {
		return new Promise<boolean>(async (resolve, reject) => {
			const currentDate = new Date();
			const currentDateTimeString = currentDate.toISOString();
			let post_data = {
				'uuid': preset.uuid,
				'id_planta': this.user.getIdPlanta(),
				'id_cam': idCam,
				'datetime': currentDateTimeString,
			};
			let formData: FormData = new FormData();
			formData.append('imgData', JSON.stringify(post_data));
			formData.append('file', snapshot, `${preset.uuid}`);
			this.http.centralUpload('add_preset_image', formData)
				.then(() => {
					resolve(true);
				})
				.catch((error) => {
					console.error('Error:', error);
					reject(false);
				});
		});
	}
}