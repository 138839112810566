import { Component, OnInit, OnDestroy, signal, WritableSignal } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { HttpService } from '../../services/http.service';
import { UserDataService } from '../../services/user-data.service';
import { PresetpickerService } from '../../services/presetpicker.service';
import { GeoService } from '../../services/geo.service';
import { Httpv2Service } from 'src/app/services/httpv2.service';
import { getCamAngularViewDeg } from 'src/app/services/cam.store';
import { AutoPresetGeneratorDialog } from 'src/app/shared/dialogs/auto-preset-generator/auto-preset-generator-dialog';
import { InfoDialog } from 'src/app/shared/dialogs/info-dialog/info-dialog';
import { SimulatePresetsDialog } from 'src/app/shared/dialogs/simulate-presets-dialog/simulate-presets-dialog';
import { IAutoPresetGeneratorDTO } from 'src/app/shared/interfaces/IAutoPresetGeneratorDTO';
import { DEFAULT_MIN_FOCAL_DISTANCE_MM, DEFAULT_SENSOR_WIDTH_MM } from 'src/app/interface/services/cameras.service';
import { PresetStatusOptions } from 'src/app/shared/enums/PresetStatusOptions';
import { PresetConfigInputTypeOptions } from 'src/app/shared/enums/PresetConfigInputTypeOptions';
import deepCopy from 'src/app/shared/utils/deepCopy';

const DEFAULT_NEW_PRESET_OBJECT = {
  index: 1,
  pan: 0,
  tilt: 85,
  zoom: 1,
  status: PresetStatusOptions.NEW
}

interface BackendResponse {
  status: boolean;
  // Add other properties if needed
}

@Component({
  selector: 'app-presets',
  templateUrl: './presets.component.html',
  styleUrls: ['./presets.component.scss']
})
export class PresetsComponent implements OnInit, OnDestroy {
  public cameras: any[];
  public displayedColumns: string[] = ['index', 'pan', 'tilt', 'zoom', 'fov', 'masks', 'actions'];
  public selectedPresetSource: string = 'presets_p2';
  public selectedCam: any = null;
  public presetActiveIndex: number;
  public canvas_el: any;
  public canvas_context: any;
  public hasChangesPresetsConfigs = signal<boolean>(false);
  public userUpdatesOnPresetsConfigs = signal<any[]>(null);
  public presetsList: WritableSignal<any[]> = signal([]);
  public deletedPresets = signal<any[]>([]);
  public isUpdatingPresetsConfigsState = signal<boolean>(false);
  public isSavingPresetsConfigsChangesState = signal<boolean>(false);
  public shouldPointCameraOnPresetSelection = signal<boolean>(false);
  public isSimulatingPresets = signal<boolean>(false);

  constructor(
    private http: HttpService,
    protected user: UserDataService,
    private geo: GeoService,
    private dialog: MatDialog,
    protected pp: PresetpickerService,
    private httpv2: Httpv2Service
  ) {
    this.pp.presetSignal$.subscribe((signal) => this.selectRow(signal, false));
  }

  async ngOnInit() {
    this.getCams();
    this.initCams();
    await this.getPresetImages();
  }

  ngOnDestroy() {
    this.clearPresets();
  }

  getCams() {
    this.cameras = this.user.getDadosPantera('cameras');
    this.cameras.sort((c1, c2) => c1.id - c2.id);
  }

  initCams() {
    this.selectedCam = this.cameras[0];
    this.createIndexForPresets();
    this.undoCurrentChangesPresetsList();
  }

  setIsSavingPresetsConfigsChangesState(value: boolean) {
    this.isSavingPresetsConfigsChangesState.set(value);
  }

  undoCurrentChangesPresetsList() {
    this.resetPresetsDeleteList();
    this.presetsList.set(deepCopy(this.selectedCam[this.selectedPresetSource]));
  }

  async getPresetImages() {
    try {
      const presetsImageData = await this.http.maestroGet(`v3/get_presets_images_url/${this.selectedCam.id}`);
      const updatedPresetsList = deepCopy(this.presetsList());

      updatedPresetsList.forEach((preset) => {
        const matchingImageData = presetsImageData.find((imageData) => imageData.uuid === preset.uuid);
        preset.img_url = matchingImageData ? matchingImageData.img_url : null;
      });

      this.presetsList.set(updatedPresetsList);
    } catch (error) {
      console.error('Error fetching preset images:', error);
    }
  }

  savePresetImages() {
    if (this.hasChangesPresetsConfigs()) {
      return this.openInfoDialog('Salve as alterações na tabela antes de tirar fotos dos presets');
    }
    try {
      this.pp.setIsSavingMultiplePresetsImages(true);
      this.pp
        .updatePresetsImgCam(this.selectedCam.id, this.selectedCam[this.selectedPresetSource])
        .then(() => {
          this.requestSyncDataWithMaestro();
          this.getPresetImages();
          return this.openInfoDialog('Salvamento de imagens de presets finalizado');
        });
    }
    catch (err) {
      this.pp.setIsSavingMultiplePresetsImages(false);
      console.log("Erro salvando imagens de presets", err)
      return this.openInfoDialog('Erro no salvamento de imagens de presets');
    }
  }

  addOrUpdatePresetImage(ev: MouseEvent, preset: any) {
    if (preset.status) {
      return this.openInfoDialog('Salve as alterações na tabela antes de tirar fotos dos presets');
    }

    this.pp.setIsSavingPresetImage(true);
    ev.stopPropagation();
    this.pp
      .updatePresetsImgCam(this.selectedCam.id, [this.selectedCam[this.selectedPresetSource][preset.index - 1]])
      .then(() => {
        this.requestSyncDataWithMaestro();
        this.getPresetImages();
        return this.openInfoDialog('Salvamento de imagens de presets finalizado');
      });
  }

  async changeCam() {
    this.unselectRow();
    this.undoCurrentChangesPresetsList();

    if (this.hasChangesPresetsConfigs()) {
      this.setHasChangesPresetsConfigsState(false);
    }

    this.geo.centerOnObject({ lat: this.selectedCam.lat, lng: this.selectedCam.lon });
    await this.getPresetImages();
  }

  drawAllPresets() {
    const presetList = this.getSelectedPreset();
    this.geo.drawAllPresets(this.selectedCam, presetList);
  }

  clearPresets() {
    this.geo.clearDrawings();
  }

  selectRow(preset: any, click: boolean = true) {
    const { result: shouldStop, warning } = this.checkIfHasCameraProcessOnGoing(click)
    if (shouldStop) {
      return this.dialog.open(InfoDialog, {
        data: { text: warning }
      })
    }

    if (this.presetActiveIndex !== preset.index) {
      this.presetActiveIndex = preset.index;
      if (this.shouldPointCameraOnPresetSelection()) {
        this.pp.requestPTZPosition(preset, this.selectedCam.id);
      }
      this.geo.drawPreset(this.selectedCam, preset);
      return
    } 

    this.presetActiveIndex = null;
    this.clearPresets();
  }

  checkInitActiveIndex() {
    if (!this.presetActiveIndex) {
      this.presetActiveIndex = 0;
    }
  }

  nextPosition() {
    const { result: shouldStop, warning } = this.checkIfHasCameraProcessOnGoing()
    if (shouldStop) {
      return this.dialog.open(InfoDialog, {
        data: { text: warning }
      })
    }

    this.checkInitActiveIndex();
    this.presetActiveIndex = (this.presetActiveIndex % this.getSelectedPreset().length) + 1;
    const preset_reference = this.getSelectedPreset()[this.presetActiveIndex - 1];
    this.geo.drawPreset(this.selectedCam, preset_reference);
  }

  previousPosition() {
    const { result: shouldStop, warning } = this.checkIfHasCameraProcessOnGoing()
    if (shouldStop) {
      return this.dialog.open(InfoDialog, {
        data: { text: warning }
      })
    }

    this.checkInitActiveIndex();
    this.presetActiveIndex = this.presetActiveIndex === 1 ? this.getSelectedPreset().length : this.presetActiveIndex - 1;
    const preset_reference = this.getSelectedPreset()[this.presetActiveIndex - 1];
    this.geo.drawPreset(this.selectedCam, preset_reference);
  }

  getFov(zoom: number) {
    if (zoom === 0) zoom = 1;
    return getCamAngularViewDeg(zoom, this.selectedCam.min_focal_distance_mm, this.selectedCam.sensor_width_mm);
  }

  getCountMasks(labels: any) {
    return labels?.filter((label) => label.class === 'preset_mask').length || 0;
  }

  drawRect() {
    try {
      this.canvas_el = document.getElementById('presetCanvas');
      this.canvas_el.width = this.canvas_el.getBoundingClientRect().width;
      this.canvas_el.height = this.canvas_el.getBoundingClientRect().height;
      this.canvas_context = this.canvas_el.getContext('2d');

      this.canvas_context.clearRect(0, 0, this.canvas_el.width, this.canvas_el.height);
      this.canvas_context.beginPath();
      this.canvas_context.lineWidth = '1';
      this.canvas_context.strokeStyle = 'black';

      const preset = this.selectedCam[this.selectedPresetSource][this.presetActiveIndex - 1];
      if (preset?.labels) {
        const masks = preset.labels.filter((label) => label.class === 'preset_mask');
        masks.forEach((mask) => {
          const [x1, y1, width, height] = mask.bbox.map((coord, index) =>
            coord * (index % 2 === 0 ? this.canvas_el.width : this.canvas_el.height)
          );
          this.canvas_context.rect(x1, y1, width - x1, height - y1);
        });
        this.canvas_context.stroke();
        this.canvas_context.closePath();
      }
    } catch (error) {
      console.error(`drawRect ${error}`, error);
    }
  }

  getSelectedPreset() {
    return this.selectedCam[this.selectedPresetSource];
  }

  unselectRow() {
    this.presetActiveIndex = null;
  }

  rowSelected() {
    return this.presetActiveIndex != null;
  }

  setHasChangesPresetsConfigsState(value: boolean) {
    this.hasChangesPresetsConfigs.set(value);
  }

  isArrayLastElement(element: any, arrayToVerify: any[]) {
    return element === arrayToVerify[arrayToVerify.length - 1];
  }

  deletePreset(ev: MouseEvent, presetToDelete: any) {
    ev.stopPropagation();
    if (presetToDelete.index === this.presetActiveIndex) this.unselectRow();
    if (!this.hasChangesPresetsConfigs()) this.setHasChangesPresetsConfigsState(true);
    const presetIndexToDelete = this.presetsList().findIndex((preset) => preset === presetToDelete);
    if (presetIndexToDelete !== -1) {
      this.clearPresets();
      const presetsListCopy = deepCopy(this.presetsList());
      const preset = presetsListCopy[presetIndexToDelete];
      preset.status = PresetStatusOptions.DELETED;
      this.addPresetToDeleteList(preset);
      presetsListCopy.splice(presetIndexToDelete, 1);
      presetsListCopy.forEach((p, index) => (p.index = index + 1));
      this.presetsList.set(deepCopy(presetsListCopy));
      this.setHasChangesPresetsConfigsState(true);
    } else {
      console.warn('Preset not found for deletion');
    }
  }

  createIndexForPresets() {
    this.cameras.forEach((cam) => {
      cam[this.selectedPresetSource].forEach((preset, index) => {
        preset.index = index + 1;
      });
    });
  }

  addPreset() {
    if (this.presetActiveIndex) this.unselectRow();
    const newPresetObject = deepCopy(DEFAULT_NEW_PRESET_OBJECT);
    const presets = deepCopy(this.presetsList());
    presets.push({ ...newPresetObject, flag: true});
    const sortedList = this.sortPresets(presets);
    const newPresetIndex = sortedList.findIndex(
      (preset: any) => preset.flag === true
    );
    sortedList.forEach((preset: any) => {
      if(preset.flag) delete preset.flag
    })
    this.presetsList.set(deepCopy(sortedList));
    this.selectRow(this.presetsList()[newPresetIndex]);
    this.setHasChangesPresetsConfigsState(true);
  }

  sortPresets(presetsList: any[]) {
    presetsList.sort((a, b) => (a.zoom === b.zoom ? a.pan - b.pan : a.zoom - b.zoom));
    presetsList.forEach((preset, index) => (preset.index = index + 1));
    return presetsList;
  }

  toggleUpdatePresetConfigs() {
    this.isUpdatingPresetsConfigsState.update((state) => !state);
  }

  toggleShouldPointCameraOnPresetSelection() {
    this.shouldPointCameraOnPresetSelection.update((state) => !state);
  }

  runAutoPresetGenerator() {
    if (this.presetActiveIndex) this.unselectRow();
    const dialogRef = this.dialog.open(AutoPresetGeneratorDialog);
    dialogRef.afterClosed().subscribe((confirmation: IAutoPresetGeneratorDTO) => {
      if (confirmation) {
        const fov = getCamAngularViewDeg(
          confirmation.zoomLevel,
          this.selectedCam.min_focal_distance_mm || DEFAULT_MIN_FOCAL_DISTANCE_MM,
          this.selectedCam.sensor_width_mm || DEFAULT_SENSOR_WIDTH_MM
        );
        const numberOfPresets = Math.ceil(360 / fov);
        const deltaPresets = Math.round(360 / numberOfPresets);
        const autoPresets = [...Array(numberOfPresets).keys()].map((n) => ({
          pan: (confirmation.initialAngle + n * deltaPresets) % 360,
          tilt: confirmation.tiltAngle,
          zoom: confirmation.zoomLevel,
          status: PresetStatusOptions.NEW,
        }));

        let updatedPresetsList = this.sortPresets([...this.presetsList(), ...autoPresets]);
        updatedPresetsList.forEach((preset: any, index: number) =>  preset.index = index + 1);
        this.presetsList.set(updatedPresetsList);
        this.setHasChangesPresetsConfigsState(true);
      }
    });
  }

  stopSimulatingPresets() {
    this.updateIsSimulatingPresets(false);
  }

  async simulatePresets() {
    if (!this.presetsList().length) {
      return this.openInfoDialog('Não há presets salvos');
    }

    const dialogRef = this.dialog.open(SimulatePresetsDialog);
    dialogRef.afterClosed().subscribe(async (response) => {
      if (response) {
        if (this.pp.timeBetweenPositionsOnPresetsSimulator() < 3 || this.pp.timeBetweenPositionsOnPresetsSimulator() > 10) {
          this.openInfoDialog('Escolha valores entre 3 e 10');
          return;
        }

        this.updateIsSimulatingPresets(true);

        let auxPresetsList = deepCopy(this.presetsList());
        if (this.presetActiveIndex) {
          const [elementsBeforeSelectedPreset, elementsAfterSelectedPreset] = [
            this.presetsList().slice(0, this.presetActiveIndex - 1),
            this.presetsList().slice(this.presetActiveIndex - 1),
          ];
          auxPresetsList = [...elementsAfterSelectedPreset, ...elementsBeforeSelectedPreset];
        }

        const delay = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

        for (const preset of auxPresetsList) {
          if (this.isSimulatingPresets()) {
            this.selectRow(preset, false);
            await this.pp.requestPTZPosition(preset, this.selectedCam.id);
            await delay(1000 * this.pp.timeBetweenPositionsOnPresetsSimulator());
          }
        }

        this.updateIsSimulatingPresets(false);
      }
    });
  }

  async saveChangesOnPresets() {
    this.setIsSavingPresetsConfigsChangesState(true);
    let hasActualChanges = false;

    const initialPresetsListWithChanges = this.presetsList().filter((preset) => preset.status);
    initialPresetsListWithChanges.forEach((preset) => {
      const originalPreset = this.selectedCam[this.selectedPresetSource].find(
        (presetFromOriginalArray) => presetFromOriginalArray.uuid === preset.uuid
      );

      if (preset.status === PresetStatusOptions.NEW) {
        hasActualChanges = true;
      } else if (preset.status === PresetStatusOptions.CHANGED && !this.areThesePresetsEquals(preset, originalPreset)) {
        hasActualChanges = true;
      } else {
        delete preset.status;
      }
    });

    if (hasActualChanges) {
      try {
        const finalPresetsListWithChanges = [
          ...initialPresetsListWithChanges,
          ...this.deletedPresets(),
        ].filter((preset) => preset.status);

        const backendResponse = await this.httpv2.postToBackendP2Promise<BackendResponse>('/maestro/update_cam_presets', {
          id: this.selectedCam.id,
          presets: finalPresetsListWithChanges,
        });

        if (backendResponse.status) {
          await this.updateAfterSaveSuccess();
          return this.openInfoDialog('Sucesso ao salvar alterações no banco de dados');
        }

        throw new Error('Erro no backend ao salvar os presets.');
      } catch (error) {
        console.error('Erro ao salvar alterações:', error);
        this.setIsSavingPresetsConfigsChangesState(false);
        this.resetPresetsDeleteList();
        return this.openInfoDialog('Erro ao salvar alterações no banco de dados');
      }
    } else {
      this.setIsSavingPresetsConfigsChangesState(false);
      this.openInfoDialog('Nenhuma alteração a ser salva.');
    }
  }

  async updateAfterSaveSuccess() {
    const selectedCamId = deepCopy(this.selectedCam.id);
    await this.user.updatePanteraData();
    await this.user.atualizarDadosCliente();
    this.getCams();
    this.createIndexForPresets();
    this.selectedCam = this.cameras.find((cam) => cam.id === selectedCamId);
    this.presetsList().forEach((preset) => delete preset.status);
    this.presetsList.set(deepCopy(this.selectedCam[this.selectedPresetSource]));
    this.resetPresetsDeleteList();
    this.setHasChangesPresetsConfigsState(false);
    this.setIsSavingPresetsConfigsChangesState(false);
  }

  areThesePresetsEquals(preset1: any, preset2: any) {
    return preset1.pan === preset2.pan && preset1.tilt === preset2.tilt && preset1.zoom === preset2.zoom;
  }

  requestSyncDataWithMaestro() {
    return this.httpv2.getFromBackendP2Promise('/maestro/atualizar_dados_cliente')
  }

  openInfoDialog(content: string) {
    return this.dialog.open(InfoDialog, {
      data: { text: content },
    });
  }

  addPresetToDeleteList(preset: any) {
    preset.status = PresetStatusOptions.DELETED;
    this.deletedPresets.update((state) => [...state, preset]);
  }

  resetPresetsDeleteList() {
    this.deletedPresets.set([]);
  }

  updateInput(index: number, inputType: string) {
    if (!this.presetInputGenericValidation(index, inputType)) {
      this.presetsList()[index - 1][inputType] = this.selectedCam[this.selectedPresetSource][index - 1][inputType];
      return;
    }

    this.setHasChangesPresetsConfigsState(true);
    if (this.presetsList()[index - 1].status !== PresetStatusOptions.NEW) {
      this.presetsList()[index - 1].status = PresetStatusOptions.CHANGED;
    }
  }

  presetInputGenericValidation(index: number, inputType: string) {
    const validators = {
      [PresetConfigInputTypeOptions.PAN]: this.presetInputPanValidator,
      [PresetConfigInputTypeOptions.TILT]: this.presetInputTiltValidator,
      [PresetConfigInputTypeOptions.ZOOM]: this.presetInputZoomValidator,
    };

    return validators[inputType]?.(this.presetsList()[index - 1][inputType]);
  }

  presetInputPanValidator(value: number) {
    if (Number.isNaN(value)) {
      this.openInfoDialog('Não deixe o campo em branco');
      return false;
    }

    if (value < 0 || value >= 360) {
      this.openInfoDialog('Digite apenas valores entre 0 e 359,99');
      return false;
    }

    return true;
  }

  presetInputTiltValidator(value: number) {
    if (Number.isNaN(value)) {
      this.openInfoDialog('Não deixe o campo em branco');
      return false;
    }

    if (value < 0 || value > 120) {
      this.openInfoDialog('Digite apenas valores entre 0 e 120');
      return false;
    }

    return true;
  }

  presetInputZoomValidator(value: number) {
    if (Number.isNaN(value)) {
      this.openInfoDialog('Não deixe o campo em branco');
      return false;
    }

    if (value < 1 || value > 45) {
      this.openInfoDialog('Digite apenas valores entre 1 e 45');
      return false;
    }

    return true;
  }

  updateIsSimulatingPresets(value: boolean) {
    this.isSimulatingPresets.set(value);
  }

  checkIfHasCameraProcessOnGoing(click: boolean = true) {
    if (this.isSimulatingPresets() && click) return {
      result: true, warning: "Simulação de presets em andamento. Pause o processo e tente novamente."
    }

    if (this.pp.isSavingMultiplePresetsImages() && click) return {
      result: true, warning: "Salvamento de imagens de presets em andamento. Pause o processo e tente novamente."
    }

    return { result: false }
  }
}