<main>

  <mat-tab-group [backgroundColor]="'primary'">

    <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon class="tab-icon">pets</mat-icon>
        {{ 'MAP.BIODIVERSITY' | translate }}
      </ng-template>
      <div class="tab-content-container">
        <div class="fx-row-center">
          <mat-form-field appearance="outline" class="cam-select">
            <mat-label>{{ 'MAP.SELECT_CAMERA' | translate }}</mat-label>
            <mat-select
              [(value)]="selectedCamId"
              (selectionChange)="changeCamera()">
              @for (cam of camerasTrap; track cam.id_cam_trap) {
                <mat-option [value]="cam.id_cam_trap">
                  {{cam.nome_cam_trap}}
                </mat-option>
              }
            </mat-select>
          </mat-form-field>
        </div>
        <div class="img-container">
          @if (imgLoaded) {
            <img class='bio-img' [src]="selectedCamImgUrlList[imgIndex]">
          }
          <button
            id='previousImage'
            mat-mini-fab color='primary'
            (click)="previousImage()">
            <mat-icon>navigate_before</mat-icon>
          </button>
          <button
            id='nextImage'
            mat-mini-fab color='primary'
            (click)="nextImage()">
            <mat-icon>navigate_next</mat-icon>
          </button>
        </div>
      </div>
    </mat-tab>

    <mat-tab [disabled]='true'>
      <ng-template mat-tab-label>
        <mat-icon class="tab-icon">camera</mat-icon>
        {{ 'MAP.TRACKING' | translate }}
      </ng-template>
    </mat-tab>

  </mat-tab-group>

</main>
