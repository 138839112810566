import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { environment } from '../../environments/environment'
import {catchError, timeout} from 'rxjs/operators';
import { lastValueFrom, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HttpService {

  private MAESTRO_URL =  environment.api;
  private CAMPS_API_ADDRESS = environment.camsCloudApiUrl;
  private PROJECTS_API_ADDRESS = environment.projectsApiUrl;
  private OPEN_ROUTE_API_BASE_URL = 'https://api.openrouteservice.org/v2/';
  private OPEN_ROUTE_API_KEY = '5b3ce3597851110001cf6248380d9489b47c4445a5e76a953acd5bb4';

  private requestTimeout= 100000;
  private reducedRequestTimeout= 7000;
  
  // Jeito mais eficiente?
  public get maestroBearer(): string {
    return localStorage.getItem('MAESTRO')
  }


  private get headers(): HttpHeaders {
    return new HttpHeaders({
      'content-type': 'application/json',
      'Authorization': 'Bearer ' + this.maestroBearer
    });
  }

  private get authHeader(): HttpHeaders {
    return new HttpHeaders({
      'Authorization': 'Bearer ' + this.maestroBearer
    });
  }
  constructor(private http: HttpClient) { }
  
  async get(route:string, ordered_args=[]){
    if(ordered_args.length > 0){
      route += '/';
      route += ordered_args.join('/');
    }
    return await this.http.get(route).toPromise().then(data => data, err => null);
  }

  async post(route:string, ordered_args=[], data){
    if(ordered_args.length > 0){
      route += '/';
      route += ordered_args.join('/');
    }
    return await this.http.post(route, data).toPromise().then(data => data, err => null);
  }

  async maestroPost(route:string, object, useAuthHeader=false){
    const headers = useAuthHeader ? this.authHeader : this.headers
    return await this.http.post(`${this.MAESTRO_URL}/${route}`, object,  {headers: headers, withCredentials: true}).toPromise().then(data => data, err => null);
  }

  async maestroUnauthPost(route:string, object){
    return await this.http.post(`${this.MAESTRO_URL}/${route}`, object).toPromise().then(data => data, err => null);
  }

  async maestroPatch(route: string, body: any, ordered_args: string[] = []){
    if(ordered_args.length > 0){
      route += '/';
      route += ordered_args.join('/');
    }
    return await lastValueFrom(this.http.patch(`${this.MAESTRO_URL}/${route}`, body, { headers: this.headers })).then(data => data, err => null);
  }

  async centralGetBinary(route:string, ordered_args:string[] = []){
    if(ordered_args.length > 0){
      route += '/';
      route += ordered_args.join('/');
    }
    let options = { headers: this.authHeader, responseType: 'arraybuffer' as 'json' };
    return await lastValueFrom(this.http.get(`${this.MAESTRO_URL}/${route}`, options)).then(data => data, err => null);
  }

  async camsGet(route:string, ordered_args:string[] = []){
    if(ordered_args.length > 0){
      route += '/';
      route += ordered_args.join('/');
    }
    return await this.http.get(`${this.CAMPS_API_ADDRESS}/${route}`, {headers: this.headers}).toPromise().then(data => data, err => null);
  }

  async camsPost(route:string, object){
    return await this.http.post(`${this.CAMPS_API_ADDRESS}/${route}`, object,  {headers: this.headers, withCredentials: true}).toPromise().then(data => data, err => null);
  }

  async projectsGet(route:string, ordered_args:string[] = []){
    if(ordered_args.length > 0){
      route += '/';
      route += ordered_args.join('/');
    }
    return await lastValueFrom(this.http.get(`${this.PROJECTS_API_ADDRESS}/${route}`, {headers: this.headers})).then(data => data, err => null);
  }

  async projectsPost(route:string, object){
    return await lastValueFrom(this.http.post(`${this.PROJECTS_API_ADDRESS}/${route}`, object,  {headers: this.headers, withCredentials: true})).then(data => data, err => null);
  }

  async projectsPut(route:string, object){
    return await lastValueFrom(this.http.put(`${this.PROJECTS_API_ADDRESS}/${route}`, object,  {headers: this.headers, withCredentials: true})).then(data => data, err => null);
  }

  async projectsDelete(route:string, ordered_args:string[] = []){
    if(ordered_args.length > 0){
      route += '/';
      route += ordered_args.join('/');
    }
    return await lastValueFrom(this.http.delete(`${this.PROJECTS_API_ADDRESS}/${route}`, {headers: this.headers})).then(data => data, err => null);
  }

  async openRoutePost(route:string, object){
    const headers = new HttpHeaders({
      'content-type': 'application/json',
      'Authorization': `${this.OPEN_ROUTE_API_KEY}`,
    });
    return await lastValueFrom(this.http.post(`${this.OPEN_ROUTE_API_BASE_URL}/${route}`, object,  {headers: headers})).then(data => data, err => null);
  }

  async centralUpload(route:string, formData: any){
    return await this.http.post(
        `${this.MAESTRO_URL}/${route}`,
        formData,
        {headers: this.authHeader, withCredentials: true}
      ).toPromise().then(data => data, err => null);
  }

  centralUploadUsingObservable(route:string, formData: any) {
    return this.http.post(`${this.MAESTRO_URL}/${route}`, formData, { headers: this.authHeader, withCredentials: true })
  }

  getServerUrl(){
    return this.MAESTRO_URL;
  }

  async maestroGet(route: string, token: string | null = null, reduced = false): Promise<any> {
    const header = token
      ? new HttpHeaders({ 'Authorization': `Bearer ${token}` })
      : this.authHeader;
  
    const timeoutValue = reduced ? this.reducedRequestTimeout : this.requestTimeout;
  
    try {
      const response = await lastValueFrom(
        this.http.get(`${this.MAESTRO_URL}/${route}`, { headers: header }).pipe(
          timeout(timeoutValue),
          catchError((error) => {
            console.error("Erro em maestroGet", error.message);
            return of(null);
          })
        )
      );
      return response;
    } catch (err) {
      return null;
    }
  }

}
