import { Injectable, EventEmitter, signal } from '@angular/core';
import {formatDate} from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { Injector } from '@angular/core';

import {UserDataService} from './user-data.service';
import {HttpService} from './http.service';
import {GeoService} from './geo.service';
import { createReportDialog } from '../reports-components/reports-dialog/create-report-dialog'
import {environment} from '../../environments/environment'
import { AmplitudeService } from '../services/amplitude.service';
import { InfoDialog } from '../shared/dialogs/info-dialog/info-dialog';
import { ConfirmationDialog } from '../shared/dialogs/confirmation-dialog/confirmation-dialog';
import { TelegramGroupsDialog } from '../shared/dialogs/telegram-groups-dialog/telegram-groups-dialog';
import { LoggingService } from './logging.service';
import { BehaviorSubject } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { BroadcastService } from 'src/app/interface/services/broadcast.service';


const initDeltaDays = 1

@Injectable({
  providedIn: 'root'
})
export class ReportsService {

  constructor(
    private http: HttpService,
    private user: UserDataService,
    public geo: GeoService,
    private dialog: MatDialog,
    private amplitude: AmplitudeService,
    private injector: Injector,
    public logging: LoggingService,
    private translate: TranslateService,
    private broadcastService: BroadcastService,
    ) {
      this.selectReportEmmitter = new EventEmitter<boolean>();
    }

  public selectReportEmmitter: EventEmitter<boolean>;

  public reports_list = []
  public selectedR = null;

  public selectedGroup: any;
  
  public startDate = new Date(new Date().getTime() - initDeltaDays *  24 * 60 * 60 * 1000)
  public endDate = new Date();
  public finished:boolean;

  public imagesLoaded = signal<boolean>(false)
  public imgs;

  public filterReportsList = new BehaviorSubject<any[]>([]);
  public reportsUpdated = new BehaviorSubject<boolean>(false);

  public selectedReportImageIndex = signal(0)

  public recentReportsAmount = signal<undefined | number>(undefined)
  public hasDamageLocation = signal(false);

  setRecentReports(recentReport: number){
    return this.recentReportsAmount.set(recentReport)
}

  selectReport(id_report) {
    this.imagesLoaded.set(false);
    this.setSelectedReportImageIndex(0);
    
    if (this.selectedR) {
      this.selectedR.selected = false;

      if (this.selectedR.id_report == id_report) {
        this.selectedR = null;
        this.selectReportEmmitter.emit(false)
        return
      }
    }

    this.selectedR = this.reports_list.filter(r => r.id_report == id_report)[0];
    this.selectedR.acionamentos.sort((a, b) => a.dt_acionamento.localeCompare(b.dt_acionamento));
    this.selectedR.selected = true;
    this.selectedR.recent = false;
    this.selectReportEmmitter.emit(true)

    this.setFinished();
    this.loadImages()
    this.selectedR.hasAcionamentoLayer = this.selectedR.acionamentos.some((acionamento) => acionamento?.geojson_acionamento?.features[0]?.geometry) ? true : false
    this.setDamageLocation();
    console.log(this.selectedR)
  }

  unselectReport(){
    this.selectedR = null;
  }
  setDamageLocation() {
    this.hasDamageLocation.set(this.selectedR.danos?.some(damage => damage.nome_local));
  }

  async sendReportViaTelegram(){
    let telegram_groups = this.user.getTelegramGroups()
    telegram_groups = telegram_groups.filter(g=>g.id_tipo_destinatario == 1);

    if (telegram_groups.length == 0) {
        const dialogRef = this.dialog.open(InfoDialog , {
            data: {text: this.translate.instant("MAP.NO_TELEGRAM_GROUP")}
        });

        dialogRef.afterClosed().subscribe(async(confirmation) => {
            if (confirmation){
                console.log("Sem grupos")
            }
        });
    }
    
    else {
        const lang = this.translate.currentLang
        const id_report = this.selectedR.id_report
        const datetime_det = this.selectedR.datetime_deteccao
        const id_planta = this.selectedR.id_planta
        const n_relatorio_planta = this.selectedR.n_relatorio_planta

        let dados_loc = this.selectedR.dados_localizacao[0]?.dados_geo.atributos?.Local
        if (!dados_loc) {
          dados_loc = ""
        }
        let telegram_data = {
          "img_id_report": id_report,
          "datetime_det" : datetime_det,
          "id_planta": id_planta,
          "dados_loc": dados_loc,
          "n_relatorio_planta": n_relatorio_planta,
          "weather": this.selectedR.dados_meteo[0],
          "geoloc_type_id": this.selectedR.geoloc_type_id,
          "lang": lang,
          "lat":this.selectedR.dados_localizacao[0].lat,
          "lon":this.selectedR.dados_localizacao[0].lon
        }

        if (telegram_groups.length == 1) {
            const dialogRef = this.dialog.open(ConfirmationDialog , {
                data: { text: this.translate.instant("MAP.SEND_TELEGRAM_ALERT") }
            });

            dialogRef.afterClosed().subscribe(async (confirmation) => {
                if (confirmation){
                    this.amplitude.sendEvent("Enviou Relatorio Telegram", {
                        "id_report": id_report
                    })
                    telegram_data['id_telegram'] = telegram_groups[0].id_telegram;
                    let response = await this.http.maestroPost('v2/send_telegram_report', telegram_data);
                    this.amplitude.sendEvent("Enviou Relatório Telegram", { "ID Relatório": id_report, "Número Relatório": n_relatorio_planta, "Grupo": telegram_groups[0].nome_grupo })
                    console.log('telegram', response, telegram_data)
                }
            })
        }
        
        else {
            const dialogRef2 = this.dialog.open(TelegramGroupsDialog , {
                data: {group_list: telegram_groups}
            });

            dialogRef2.afterClosed().subscribe(async (confirmation) => {
                if (confirmation) {
                    this.amplitude.sendEvent("Enviou Relatório Telegram", {
                        "id_report": id_report
                    })
                    telegram_data['id_telegram'] = this.selectedGroup[0].id_telegram;
                    let response = await this.http.maestroPost('v2/send_telegram_report', telegram_data);
                    this.amplitude.sendEvent("Enviou Relatório Telegram", { "ID Relatório": id_report, "Número Relatório": n_relatorio_planta, "Grupo": this.selectedGroup[0].nome_grupo })
                    console.log('telegram', response, telegram_data)
                }
            });
        }
    }
}



async sendReportViaWhatsApp() {
  let dados_loc = "";
  if (this.selectedR.dados_localizacao && this.selectedR.dados_localizacao[0]) {
    const loc = this.selectedR.dados_localizacao[0];
    dados_loc = loc.nome_geom;
  }

  let dados_meteo = "";
  if (this.selectedR.dados_meteo && this.selectedR.dados_meteo[0]) {
    const weather = this.selectedR.dados_meteo[0];
    const windEmojiOrigin = this.getWindOriginIcon(weather.wind_deg)
    dados_meteo = `\n🌤️${this.translate.instant("MAP.WEATHER")}:\n 🌡️${this.translate.instant("MAP.TEMPERATURE")}: ${weather.temp}°C\n 💧${this.translate.instant("MAP.HUMIDITY")}: ${weather.humidity}%\n ☔${this.translate.instant("WHATSAPP.RAIN")}: ${weather.rain}mm\n 💨${this.translate.instant("MAP.WIND_SPEED")}: ${(weather.wind_speed*3.6).toFixed(1)}km/h\n 🧭${this.translate.instant("MAP.WIND_ORIGIN")}: ${windEmojiOrigin.origin} ${windEmojiOrigin.emoji}`;
  }
  
  const id_report = this.selectedR.id_report;
  const datetime_det = formatDate(this.selectedR.datetime_deteccao,'dd-MM-yyyy HH:mm:ss', 'en-US');
  const mapsLink = `http://www.google.com/maps/place/${this.selectedR.dados_localizacao[0].lat},${this.selectedR.dados_localizacao[0].lon}`;
  const id_planta = this.selectedR.id_planta;
  const accessLink = `https://pantera.umgrauemeio.com/#/map?idPlanta=${id_planta}&idReport=${id_report}`;

  // Get report image URL
  let reportImageUrl = '';
  try {
    const response = await this.http.maestroGet(`get_whatsapp_image_url/${id_planta}/${id_report}`);
    reportImageUrl = response.imageUrl;
    console.log("reportImageUrl", reportImageUrl)
  } catch (error) {
    
  }

  const reportDetailsUrl = `🔥${this.translate.instant("WHATSAPP.REPORT_DETAILS")}: n°${this.selectedR.n_relatorio_planta} - ${datetime_det}\n\n📍${this.translate.instant("MAP.LOCATION")}:\n${dados_loc}\n${dados_meteo}\n\n🚒 ${this.translate.instant("WHATSAPP.ROUTE")}: ${mapsLink}\n\n ${this.translate.instant("WHATSAPP.MORE_DETAILS")} ${accessLink}\n\n🖼️ ${this.translate.instant("WHATSAPP.REPORT_IMAGE")}: ${reportImageUrl}`;
  const reportDetails = `🔥${this.translate.instant("WHATSAPP.REPORT_DETAILS")}: n°${this.selectedR.n_relatorio_planta} - ${datetime_det}\n\n📍${this.translate.instant("MAP.LOCATION")}:\n${dados_loc}\n${dados_meteo}\n\n🚒 ${this.translate.instant("WHATSAPP.ROUTE")}: ${mapsLink}\n\n ${this.translate.instant("WHATSAPP.MORE_DETAILS")} ${accessLink}`;

  if(reportImageUrl){
    const encodedMessage = encodeURIComponent(reportDetailsUrl);
    window.open(`https://api.whatsapp.com/send?&text=${encodedMessage}`, "WhatsApp", "height=400,width=600");
  }else{
  const encodedMessage = encodeURIComponent(reportDetails);
  window.open(`https://api.whatsapp.com/send?&text=${encodedMessage}`, "WhatsApp", "height=400,width=600");
}
}

  async loadImages(){
    try {
      this.imgs = await this.fetchReportImages(this.selectedR.id_report) 
      this.imagesLoaded.set(true)
      console.log('loadImages', this.imgs)
    }
    catch (error){
      this.logging.logERROR(`loadImages ${error}`,error);
      this.imagesLoaded.set(false)
      console.log(error.name, error.message);
    }
  }

  setFinished(){
    if (this.selectedR.datetime_finalizado){
      this.finished = true;
    } else {
      this.finished = false;
    }
  }

  goToReport(deltaIndex = 1){
    if (!this.selectedR) return;
    this.imagesLoaded.set(false);
    let currentIndex = this.reports_list.indexOf(this.selectedR);
    let newIndex = (currentIndex + deltaIndex) % this.reports_list.length;
    this.selectedR = this.reports_list[newIndex];
    this.loadImages();
    this.setFinished();
    this.setSelectedReportImageIndex(0)
    this.selectedR.hasAcionamentoLayer = this.selectedR.acionamentos.some((acionamento) => acionamento?.geojson_acionamento?.features[0]?.geometry) ? true : false
  }

  getSelectedId(){
    return this.selectedR?.id_report
  }

  // Usados em outros componentes
  
  async updateReports() {
    if (!this.startDate || !this.endDate) return;

    let endDate = formatDate(this.endDate, 'yyyy-MM-dd', 'en-US')
    let startDate = formatDate(this.startDate, 'yyyy-MM-dd', 'en-US')

    this.reports_list = await this.http.maestroGet(`get_reports_planta/${this.user.getIdPlanta()}/${startDate}/${endDate}`) || [];
    
    for (let r of this.reports_list) {
      let minutesToRecent = 30;
      r.recent = +this.user.last_access - +new Date(r.datetime_confirmacao) < minutesToRecent * 60 * 1000;

      let acionamentos = r.acionamentos || [];

      if (acionamentos.some(a => a.dt_inicio_combate)) {
          r.icon = {'color': 'amber'}; 
      } 
      else if (acionamentos.some(a => a.dt_acionamento)) {
          r.icon = {'color': 'orange'}; 
      } 
      else if (!acionamentos.length || acionamentos.map(a => a.dt_inicio_combate).every((dt) => dt === null)){
          r.icon = {'color': 'red'}; 
      }
      if (r.sem_risco) {
          r.icon = {'color': 'blue'};
      }
      if (!!r.datetime_finalizado) {
          r.icon = {'color': 'grey'};
      }
      if (r.has_sat_image[1]) {
          r.icon = {'color': 'black'};
      }
    }

    this.reports_list.sort((r1, r2) => r2.n_relatorio_planta - r1.n_relatorio_planta);
    this.filterReportsList.next(this.reports_list)

    if (this.user.isOperatorP2() || this.user.isSupportP2()){
      const mergedReportsAndImages = await this.mergeReportAndImagesData()
      this.broadcastService.publishMessage({ event: "UpdatedReports", reports: mergedReportsAndImages })
    }

    return true;
  }

  async mergeReportAndImagesData() {
    try {
      const reportsImages = await this.fetchLastReportsImages()
      
      const now = new Date();
      const thirtyDaysAgo = new Date();
      thirtyDaysAgo.setDate(now.getDate() - 30);
      
      const dateFilteredReportsList = this.reports_list.filter(report => {
        const detectionDate = new Date(report.datetime_deteccao);
        return !isNaN(detectionDate.getTime()) && detectionDate >= thirtyDaysAgo && detectionDate <= now;
      });

      const reportObjects = [...dateFilteredReportsList]

      const mergedReportsAndImages = await this.addImagesToReports(reportObjects, reportsImages)
      return mergedReportsAndImages
    }
    catch(error) {
      console.log("An error occurred at mergeReportAndImagesData", error)
      return []
    }
  }

  async addImagesToReports(reportsList: any[], reportsImages: any[]) {
    const result = reportsList.map(reportObject => {
      let images = []
      reportsImages.forEach(elm => {
        if(elm.report == reportObject.id_report) {
          images = elm.images
        }
      })
      return {
          ...reportObject,
          images: images
      };
    });
    return result
  }
 
  async getCurrentReports(){
    if (!this.startDate || !this.endDate) return;
    let startDate = formatDate(this.startDate, 'yyyy-MM-dd', 'en-US')
    let endDate = formatDate(this.endDate, 'yyyy-MM-dd', 'en-US')
    return await this.http.maestroGet(`get_reports_planta/${this.user.getIdPlanta()}/${startDate}/${endDate}`) || [];
  }


  async checkReports(startDate, endDate){
    startDate = formatDate(startDate, 'yyyy-MM-dd', 'en-US'),
    endDate = formatDate(endDate, 'yyyy-MM-dd', 'en-US')
    return await this.http.maestroGet(`check_relatorios/${this.user.getIdPlanta()}/${startDate}/${endDate}`);
  }

  async downloadReportsXls(startDate, endDate){
    startDate = formatDate(startDate, 'yyyy-MM-dd', 'en-US'),
    endDate = formatDate(endDate, 'yyyy-MM-dd', 'en-US')
    let downloadReportXls = await this.http.maestroGetBinary(`download_reports_xls/${this.user.getIdPlanta()}/${startDate}/${endDate}`);
    return downloadReportXls
  }

  async getReportsDashData(startDate, endDate){
    startDate = formatDate(startDate, 'yyyy-MM-dd', 'en-US'),
    endDate = formatDate(endDate, 'yyyy-MM-dd', 'en-US')
    console.log('getReportsDashData', startDate, endDate)
    let reportsDashData = await this.http.maestroGet(`get_reports_dash_data/${this.user.getIdPlanta()}/${startDate}/${endDate}`);
    return reportsDashData
  }

  async getTrpEvolution(){
    return await this.http.maestroGet(`evolucao_trp/${this.user.getIdPlanta()}`);
  }

  async getReportsDashDataCliente(startDate, endDate){
    startDate = formatDate(startDate, 'yyyy-MM-dd', 'en-US'),
    endDate = formatDate(endDate, 'yyyy-MM-dd', 'en-US')
    console.log('getReportsDashDataCliente', startDate, endDate)
    return await this.http.maestroGet(`get_reports_dash_data_cliente/${this.user.getIdCliente()}/${startDate}/${endDate}`);
  }

  async getDadosLoc(){
    // rota pra pegar o local mais proximo
  }

  async updateReportKey(reportKey:string){
    let data = {
      'id_report':this.selectedR.id_report,
      'columns':[reportKey],
      'values': [this.selectedR[reportKey]],
    }
    await this.http.maestroPost('update_report', data).then((response) => {console.log(`update ${reportKey}`, response); });
  }
  
  async createReport(lat, lon, datetime_deteccao: Date, rData){

    let report = {
      "id_planta": this.user.getIdPlanta(),
      "id_report": formatDate(new Date(datetime_deteccao).getTime(), 'dd-MM-yy_HH-mm-ss', 'en-US') + `_0${this.user.getIdPlanta()}`,
      "comentarios": rData.comentarios,
      "dados_meteo": null,
      "id_causa_incendio": rData.causa_incendio,
      "id_origem_deteccao": rData.origem_deteccao,
      "dados_localizacao": [{
        "lat": lat,
        "lon": lon,
      }],
      "datetime_deteccao": formatDate(new Date(datetime_deteccao).getTime(), 'yyyy-MM-dd HH:mm:ss', 'en-US'),
      "datetime_finalizado": "",
      "datetime_confirmacao": formatDate(new Date().getTime(), 'yyyy-MM-dd HH:mm:ss', 'en-US'),
      "software_id": 2,
      "operador": this.user.username(),
      "version": environment.version,
      "geoloc_type_id": rData.geoloc_type_id,
    }
    let response = await this.http.maestroPost('create_report', report)
    console.log(response)
    //Amplitude
    let origem_deteccao_nome = this.user.getDadosPantera('report_params').origens_deteccao.find(item => item.id_origem_deteccao === rData.origem_deteccao)?.origem_deteccao;
    let geoloc_type_nome = this.user.getDadosPantera('report_params').geoloc_type.find(item => item.id_tipo_geolocalizacao === rData.geoloc_type_id)?.tipo_geolocalizacao;

    this.amplitude.sendEvent('Criou Relatório', {"Origem": origem_deteccao_nome,
                                                 "Geolocalização": geoloc_type_nome,
                                                 "Número Relatório": response["n_relatorio_planta"],
                                                 "Local": response["dados_loc_detailed"][0].nome_geom,
                                                 "Distância_km": response["dados_loc_detailed"][0].distancia_area
      }
    );
    
    this.reportsUpdated.next(true);
    return report.id_report
  }

  async get64imgs(){
    try{
      let imgs = await this.http.maestroGet(`get_reports_images_64/${this.selectedR.id_report}`)
      this.imagesLoaded.set(!!this.imgs.length);
      return imgs
    } catch (error){
      this.logging.logERROR(`get64imgs ${error}`,error);
      console.log('Error in get64imgs' + error);
    }
  }

  async set64imgs() {
    this.imgs = await this.get64imgs()
  }

  openReportDialog(){
    this.dialog.open(createReportDialog)
  }

  async deleteImage(data) {
    try {
      let response = await this.http.maestroPost('/delete_img_from_report', data)
      console.log("...delete_img_from_report", response)
      if (response) {
        response = JSON.stringify(response);
      }
      return response
    }
    catch (err) {
      this.logging.logERROR(`deleteImage ${err}`,err);
      console.log("Erro ao deletar a imagem: ", err);
    }
  }

  setSelectedReportImageIndex(value: number) {
    this.selectedReportImageIndex.set(value)
  }

  changeCursor(cursorStyle: string) {
    const element = document.getElementById('map');
    if (element) {
      element.style.cursor = cursorStyle;
    }
  }

  restoreCursor() {
    const element = document.getElementById('map');
    if (element) {
      element.style.cursor = 'grab';
    }
  }

  async fetchLastReportsImages() {
    const reportsList = this.reports_list
    
    const reportImagesResultObject = await Promise.all(reportsList.map(async (rep) => {
      const images = await this.fetchReportImages(rep.id_report);
      return { report: rep.id_report, images: images.length > 0 ? images : [] };
    }));
    return reportImagesResultObject;
  }

  async fetchReportImages(idReport: string) {
    try{
      const reportImages =  await this.http.maestroGet(`get_reports_images_obj/${idReport}`)
      if (reportImages[0].img_path === null){
        throw new Error("Relatorio sem imagens")
      }
      return reportImages;
    }
    catch (error){
      this.logging.logERROR(`fetchReportImages ${error}`,error);
      console.log(error.name, error.message);
      return []
    }
  }

  async getReportLocationImage(idPlanta: number, idReport: string) {
    try {
      const reportLocationImage = await this.http.maestroGet(`get_report_map_image/${idPlanta}/${idReport}`);
      if (!reportLocationImage.img_b64) {
        throw new Error("Relatorio sem imagem mapa");
      }
  
      return {  base64: reportLocationImage.img_b64 };
    } catch (error) {
      this.logging.logERROR(`getReportLocationImage ${error}`, error);
      console.log(error.name, error.message);
      throw error; 
    }
  }
  

getWindOriginIcon(windOriginonValue: number): { icon: string, origin: string, emoji: string } {
  if (windOriginonValue > 337.5 || windOriginonValue < 22.5) {
      return {icon: "south", origin: this.translate.instant("WIND.NORTH"), emoji:"⬇️" };  // North
  }
  if (22.5 <= windOriginonValue && windOriginonValue < 67.5) {
      return {icon: "south_west", origin:this.translate.instant("WIND.NORTHEST"), emoji:"↙️" };  // Northeast
  }
  if (67.5 <= windOriginonValue && windOriginonValue < 112.5) {
      return {icon:"west", origin: this.translate.instant("WIND.EAST"), emoji:"⬅️" };  // East
  }
  if (112.5 <= windOriginonValue && windOriginonValue < 157.5) {
      return {icon:"north_west", origin: this.translate.instant("WIND.SOUTHEAST"), emoji:"↖️"}; // Southeast
  }
  if (157.5 <= windOriginonValue && windOriginonValue < 202.5) {
      return {icon:"north", origin: this.translate.instant("WIND.SOUTH"), emoji:"⬆️" }; // South
  }
  if (202.5 <= windOriginonValue && windOriginonValue < 247.5) {
      return { icon: "north_east", origin: this.translate.instant("WIND.SOUTHWEST"), emoji:"↗️"  };  // Southwest
  }
  if (247.5 <= windOriginonValue && windOriginonValue < 292.5) {
      return { icon: "east", origin: this.translate.instant("WIND.WEST"), emoji:"➡️"  }; // West
  }
  if (292.5 <= windOriginonValue && windOriginonValue < 337.5) {
      return { icon: "south_east", origin: this.translate.instant("WIND.NORTHWEST"), emoji:"↘️"  }; // Northwest
  }
  return { icon: "explore", origin: "?", emoji:"🧭" };  // Default icon
}
          
  
}