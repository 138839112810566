import { Component } from '@angular/core';
import { IAutoPresetGeneratorDTO } from '../../interfaces/IAutoPresetGeneratorDTO';

@Component({
  selector: 'auto-preset-generator-dialog',
  templateUrl: 'auto-preset-generator-dialog.html',
})
export class AutoPresetGeneratorDialog{
  
  public formOutput: IAutoPresetGeneratorDTO = {
    initialAngle: 0,
    zoomLevel: 1,
    tiltAngle: 85,
    stabilizationTime: 5
  }

  constructor(
  ) {}
}