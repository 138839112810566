@if (loaded) {
    <div class="tab-content-container">
      <div class="option-select">
        <mat-button-toggle-group [(ngModel)]="selectedStationViewOption" name="option" [formControl]="stationsViewFormControl" (change)="amplitudeSubTabEvent($event.value)">
          @for (option of stationsViewOptions; track option.id) {
            <mat-button-toggle [value]="option.id" [disabled]="option.id == 2 && !user.isManager()">
              <mat-icon>{{option.icon}}</mat-icon> {{ option.option | translate }}
            </mat-button-toggle>
          }
        </mat-button-toggle-group>
        @if(stations?.length && selectedStationViewOption == 1){
          <div class = "filter">
            <button mat-icon-button [matMenuTriggerFor]="filtersMenu" [matBadge]="searchField ? '!' : ''"
            matBadgePosition="above after"
            matBadgeColor="warn"
            [matBadgeHidden]="!searchField"
            matTooltip="{{ 'MAP.FILTERS' | translate }}"
            matBadgeSize="small">
              <mat-icon>tune</mat-icon>
            </button>
      
            <mat-menu #filtersMenu="matMenu">
              <div class="search" (click)="$event.stopPropagation()">
                <mat-form-field class="brigade-search" appearance="fill">
                  <input matInput type="text" placeholder="{{  'MAP.SEARCH' | translate }}" [(ngModel)]="searchField">
                </mat-form-field>
              </div>
            </mat-menu>
          </div>
        }
      </div>
      @if (!stations?.length && selectedStationViewOption == 1) {
        <div class="sem-dados">
          {{'MAP.NO_STATIONS_REGISTERED' | translate }}
        </div>
      }
      @if (stations?.length && selectedStationViewOption == 1) {
        <div>
          <div class="table-container">
            <table mat-table [dataSource]="stations | searchStations: searchField" matSort (matSortChange)="sortData($event)" class="mat-elevation-z8" matSortDisableClear = "true">
              @for (col of stationColumnsSchema; track col.key) {
                <ng-container [matColumnDef]="col.key">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header="{{col.key}}">
                    {{ col.label }}
                  </th>
                  @if (col.key != 'acao') {
                    <td mat-cell *matCellDef="let element"> {{element[col.key]}} </td>
                  }
                  @if (col.key == 'acao') {
                    <td mat-cell *matCellDef="let element">
                      @if ((user.isManager()) && element.station_id) {
                        <button mat-icon-button color="primary" (click)='removeMeteoStation(element)'>
                          <mat-icon class="cancel-button">close</mat-icon>
                        </button>
                      }
                    </td>
                  }
                </ng-container>
              }
              <tr mat-header-row *matHeaderRowDef="stationColumns; sticky: true" ></tr>
              <tr mat-row *matRowDef="let row; columns: stationColumns;" class='table-row' [ngClass]="{'table-row-color': row.station_id == this.layer.selectedMeteoStation}" (click)="selectMeteoStation(row.station_id)"></tr>
            </table>
          </div>
        </div>
      }
      @if (selectedStationViewOption == 2) {
        <div>
          <app-create-stations (notifyParent)="onChildEvent($event)"></app-create-stations>
        </div>
      }
    </div>
  }
  