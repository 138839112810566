<mat-dialog-content>
    <main>
        <mat-icon color="warn" style="font-size: 48px;">error</mat-icon>
        <br><br>
        {{ 'MAP.DEPLOY_1' | translate }}
        <br><br>
        {{ 'MAP.DEPLOY_2' | translate }}
        <br><br>
        {{ 'MAP.DEPLOY_3' | translate }}
    </main>
</mat-dialog-content>
<mat-dialog-actions align="center">

    <button mat-raised-button color='primary'
        (click)='reloadPage()'>
        {{ 'MAP.DEPLOY_4' | translate }}
    </button>
</mat-dialog-actions>