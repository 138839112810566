import {Component, Inject, ViewChildren, QueryList, AfterViewInit} from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import {MatFormField} from "@angular/material/form-field";
import {HttpService} from '../../../services/http.service';
import { AmplitudeService } from 'src/app/services/amplitude.service';

@Component({
  selector: 'forgot-pass-dialog',
  templateUrl: 'forgot-pass-dialog.html',
})
export class ForgotPassDialog implements AfterViewInit {
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private http: HttpService, private amplitudeService: AmplitudeService) {
  }

  @ViewChildren(MatFormField) formFields: QueryList<MatFormField>;

  ngAfterViewInit(): void {
  }

  public username: string;
  public message: string;

  public sucess: boolean = false;
  public loading: boolean = false;
  
  async reset_pass() {
    this.loading = true;
    let response = await this.http.maestroPost('request_reset_password', {'username': this.username})
    if (response.status) {
      this.sucess = true;
      this.amplitudeService.sendEvent("Alterou Senha", { "Username": this.username})
    } else {
      this.sucess = true;
      this.message = response.msg
    }
    this.loading = false;
  }

}