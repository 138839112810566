<mat-dialog-content>
    <h3>Simulador de presets</h3>
    <mat-form-field appearance="outline">
        <mat-label>Tempo entre posições (s)</mat-label>
        <input matInput (change)="onChangeInput($event)" type="number" min=3 max=10 required matInput placeholder="Ex: 5" />
    </mat-form-field>
</mat-dialog-content>

<mat-dialog-actions align="center">
    <button mat-raised-button [mat-dialog-close]="false">Cancelar</button>
    <button mat-raised-button [mat-dialog-close]="true">Simular</button>
</mat-dialog-actions>