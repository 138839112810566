
<main>

  <div class="fx-row-space-around-center">

    <div class="report-date-select">
      <mat-form-field appearance='outline'>
        <mat-label>{{ 'MAP.SELECT_DATE' | translate }}</mat-label>
        <input matInput [matDatepicker]="picker"
          (dateChange)="refreshData()" [formControl]="date">
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker disabled="false"></mat-datepicker>
        </mat-form-field>
      </div>
    </div>

    @if (!meteo.meteoData && meteo.meteoPlacesCount > 0) {
      <div class="no-data">
        {{ 'MAP.NO_WEATHER_DATA' | translate }}
      </div>
    }

    @if (meteo.meteoPlacesCount == 0) {
      <div class="no-data">
        {{ 'MAP.NO_TOWERS' | translate }}
      </div>
    }

    @if (meteo.meteoData) {
      <div class="table-wrapper">
        @if (meteo.triplo30) {
          <div class="triplo-30 fx-row-center">
            <mat-icon color='accent'>warning</mat-icon> {{ 'MAP.TRIPLE_30' | translate }}
          </div>
        }
        <div class="table">
          <table mat-table [dataSource]="meteo.tableData" matSort (matSortChange)="sortData($event)" class="mat-elevation-z8" matSortDisableClear = "true">
            <ng-container matColumnDef="nome_local">
              <th mat-header-cell *matHeaderCellDef mat-sort-header="nome_local"> Local </th>
              <td mat-cell *matCellDef="let element">
                {{element.nome_local}}
              </td>
            </ng-container>
            <ng-container matColumnDef="day">
              <th mat-header-cell *matHeaderCellDef mat-sort-header="temperature"> {{ 'MAP.AVERAGE_TEMPERATURE' | translate }} </th>
              <td mat-cell *matCellDef="let element">
                <span [ngStyle]="{'color': getTempColor(element.temperature)}">
                  {{element.temperature|number:'.0-0'}}
                </span>
              </td>
            </ng-container>
            <ng-container matColumnDef="icon">
              <th mat-header-cell *matHeaderCellDef></th>
              <td mat-cell *matCellDef="let element">
                <img src='https://openweathermap.org/img/w/{{element.weatherIcon}}.png'>
              </td>
            </ng-container>
            <ng-container matColumnDef="risk">
              <th mat-header-cell *matHeaderCellDef mat-sort-header="risk">{{ 'MAP.FIRE_RISK' | translate }}</th>
              <td mat-cell *matCellDef="let element">
                {{element.risk * 100 | number:'.0-0'}}
                <span class="dot" [ngStyle]="{'background-color': getRiskColor(element.risk)}"></span>
              </td>
            </ng-container>
            <ng-container matColumnDef="rain">
              <th mat-header-cell *matHeaderCellDef mat-sort-header="rain"> {{ 'MAP.RAIN' | translate }}</th>
              <td mat-cell *matCellDef="let element"> {{element.rain}} </td>
            </ng-container>
            <ng-container matColumnDef="wind_speed">
              <th mat-header-cell *matHeaderCellDef mat-sort-header="wind_speed"> {{ 'MAP.WIND_SPEED' | translate }} (km/h)</th>
              <td mat-cell *matCellDef="let element">
                <span [ngStyle]="{'color': getWindColor(element.wind_speed)}">
                  {{element.wind_speed |number:'.0-0'}}
                </span>
              </td>
            </ng-container>
            <ng-container matColumnDef="wind_deg">
              <th mat-header-cell *matHeaderCellDef> {{ 'MAP.WIND_DIRECTION' | translate }} </th>
              <td mat-cell *matCellDef="let element"> {{element.daily[0].wind_deg}} </td>
            </ng-container>
            <ng-container matColumnDef="humidity">
              <th mat-header-cell *matHeaderCellDef mat-sort-header="humidity"> {{ 'MAP.HUMIDITY' | translate }} (%)</th>
              <td mat-cell *matCellDef="let element">
                <span [ngStyle]="{'color': getHumidityColor(element.humidity)}">
                  {{element.humidity}}
                </span>
              </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>
        </div>
        <p style="align-self: flex-start; margin: 0 1rem;">
          {{ 'MAP.SOURCE' | translate }} <a [ngClass]="user.darkMode() ? 'link-dark' : ''" href='https://openweathermap.org/' target="_blank">openweathermap.org</a>
        </p>
        <mat-card appearance="outlined" class='risk-legend'>
          <h3>{{ 'MAP.FIRE_RISK' | translate }}</h3>
          @for (obj of riskColorMap; track obj.legend) {
            <div class="fx-row-start-center">
              <i [ngStyle]="{'background': obj.color}"></i> {{obj.legend}}
            </div>
          }
        </mat-card>
      </div>
    }


  </main>