import { Component } from '@angular/core';

@Component({
  selector: 'app-socket-disconnected',
  templateUrl: './socket-disconnected.component.html',
  styleUrls: ['./socket-disconnected.component.scss']
})
export class SocketDisconnectedDialog {

    constructor(
  ) {}

  reloadPage(){
    window.location.reload(); // Reload the modified URL, bypassing the cache
  }
}
