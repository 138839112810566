import { Component, OnInit, HostListener } from '@angular/core';
import { GeoService } from '../../services/geo.service';
import { UserDataService } from '../../services/user-data.service';
import { AmplitudeService } from '../../services/amplitude.service';
import { TranslateService  } from '@ngx-translate/core';
import { SatelliteService } from 'src/app/services/satellite.service';


interface LegendObj {
  legend: string;
  color: string;
}

@Component({
  selector: 'app-map-ctrl',
  templateUrl: './map-ctrl.component.html',
  styleUrls: ['./map-ctrl.component.scss']
})
export class MapCtrlComponent implements OnInit {

  constructor(
    public amplitude: AmplitudeService,
    public geo: GeoService,
    public user: UserDataService,
    private translate: TranslateService,
    public sat: SatelliteService, 
  ) { }

  public talhaoColorMap: LegendObj[] = [];

  public riskColorMap: LegendObj[] = [
    { legend: 'MAP.VERY_LOW_FIRE_RISK', color: '#2b83ba' },
    { legend: 'MAP.LOW_FIRE_RISK', color: '#abdda4' },
    { legend: 'MAP.MODERATE_FIRE_RISK', color: '#ffffbf' },
    { legend: 'MAP.HIGH_MODERATE_FIRE_RISK', color: '#fdae61' },
    { legend: 'MAP.HIGH_FIRE_RISK', color: '#d7191c' },
  ];

  public satColorMap:LegendObj[] = [
    {legend:'\< 30min' , color: 'red'},
    {legend:'30min - 1h' , color: 'orange'},
    {legend:'1h - 3h' , color: 'yellow'},
    {legend:'3h - 12h' , color: 'gray'},
  ]

  public reportColorMap:LegendObj[] = [
    {legend:"MAP.BRIGADE_NOT_ACTIVATED" , color: 'red'},
    {legend:"MAP.BRIGADE_TRIGGERED" , color: 'orange'},
    {legend:"MAP.COMBAT_STARTED" , color: 'amber'},
    {legend:"MAP.NO_RISK"  , color: 'blue'},
    {legend:"MAP.CLOSED" , color: 'grey'},
    {legend:"SAT.REPORT_WITH_AFTER_IMAGE" , color: 'black'},
  ]

  private filterList;
  public show: string = null;

  async ngOnInit(){
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
    this.populateTalhaoColorMap();
  }

  toggleBase(){
    this.setShow('base')
    this.filterList = [
      1,
      6,
      22,
      23
    ]
    this.updateLayerGroupList()
  }

  toggleReport(){
    this.setShow('reports')
    this.filterList = [
      3,
      24
    ]
    this.updateLayerGroupList()
  }

  toggleVisibility(){
    this.setShow('visibility')
    this.filterList = [
      2,
      7,
    ]
    this.updateLayerGroupList()
  }

  toggleSat(){
    this.setShow('sat')
    this.filterList = [
      4,
      10,
      14,
      18,
    ]
    this.updateLayerGroupList()
  }

  toggleFm(){
    this.setShow('fm')
    this.filterList = [
      11,
      8,
    ]
    this.updateLayerGroupList()
  }

  toggleRisk(){
    this.setShow('risk')
    this.filterList = [
      5,
      80,
      82,
    ]
    this.updateLayerGroupList();
    this.geo.updateLegendLanguage(this.translate.currentLang);
  }

  toggleHistoricalData(){
    this.setShow('historicalData')
    this.filterList = [
      15,
      9,
    ]
    this.updateLayerGroupList()
  }

  toggleDeforestation(){
    this.setShow('deforestation')
    this.filterList = [
      16,
      37,
    ]
    this.updateLayerGroupList()
  }

  toggleBio(){
    this.setShow('bio')
    this.filterList = [
      13,
      83
    ]
    this.updateLayerGroupList()
  }  

  toggleUgm(){
    this.setShow('ugm')
    this.filterList = [
      0,
      19,
      20,
      81,
      21,
    ]
    this.updateLayerGroupList()
  }

  setShow(legendGroup: string){
    if (this.show === legendGroup) {
      this.show = null
      return
    }
    this.show = legendGroup;
    this.amplitude.sendEvent('Viu Camadas', {'Grupo': `${this.amplitude.groupName[legendGroup]}`});
  }

  updateLayerGroupList(){
    this.geo.triggerPageReflow();
    return this.geo.layerGroupList.filter(g=>this.filterList.includes(g.groupId)).sort((b1, b2) => b1.groupId - b2.groupId);
  }

  selectBaseLayer(event){
    this.geo.setBaseMap(event.value.layer)
  }

  toogleLayer(event, layer){
    this.geo.setLayer(layer, event.checked)
  }

  toggleInfo(index: number){
    let filteredLayerGroupList = [];
    filteredLayerGroupList = this.updateLayerGroupList();
    filteredLayerGroupList[index].showInfo = !filteredLayerGroupList[index].showInfo;
  }

  hasNotLayer(overlayGroup, overlayGroupId): boolean {
    if (overlayGroup['groupId'] === overlayGroupId) {
      return overlayGroup.layersList.length == 0;
    }
    return false;
  }

  hasNotHotspotRoiLayer(overlayGroup): boolean {
    if (overlayGroup['groupId'] === 18 && overlayGroup.layersList) {
      return !overlayGroup.layersList.some(layerObj => layerObj.legend === "SAT.HOTSPOT_ROI_LAYER");
    }
    return false;
  }

  populateTalhaoColorMap() {
    const talhaoLegendData = this.user.getTalhaoLegend();
    if (talhaoLegendData) {
      this.talhaoColorMap = talhaoLegendData.map(item => {
        return {
          legend: item.nome_talhao,
          color: item.fill_color
        };
      });
      this.talhaoColorMap.sort((a, b) => a.legend.localeCompare(b.legend));  
    } else {
      this.talhaoColorMap = [];
    }
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }

}
