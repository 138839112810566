<main>
  
  <mat-accordion>
    <mat-expansion-panel expanded>
      <mat-expansion-panel-header>
        <mat-panel-title> Execução de scripts </mat-panel-title>
        <!-- <mat-panel-description> This is a summary of the content </mat-panel-description> -->
      </mat-expansion-panel-header>
      
      <!-- <button mat-mini-fab (click)='update()'>
        <mat-icon>updated</mat-icon>
      </button>
     -->
      <table mat-table [dataSource]="scriptLog" class="mat-elevation-z8">
        
        <ng-container matColumnDef="script_name">
          <th mat-header-cell *matHeaderCellDef> Script </th>
          <td mat-cell *matCellDef="let element"> {{element.script_name}}</td>
        </ng-container>
    
        <ng-container matColumnDef="script_version">
          <th mat-header-cell *matHeaderCellDef> Versão </th>
          <td mat-cell *matCellDef="let element"> {{element.script_version}}</td>
        </ng-container>
    
        <ng-container matColumnDef="update_freq">
          <th mat-header-cell *matHeaderCellDef> Frequencia desejada </th>
          <td mat-cell *matCellDef="let element"> {{element.update_freq}}</td>
        </ng-container>
        
        <ng-container matColumnDef="updated_day">
          <th mat-header-cell *matHeaderCellDef> Data </th>
          <!-- [ngClass]="checkDate(element.updated_dt)"  -->
          <td mat-cell *matCellDef="let element"  [ngClass]="checkDate(element.updated_dt)"
          >
           {{element.updated_dt | date : "dd/MM/YY"}} </td>
        </ng-container>
    
        <ng-container matColumnDef="updated_time">
          <th mat-header-cell *matHeaderCellDef> Hora atualizado </th>
          <td mat-cell *matCellDef="let element"> {{element.updated_dt | date : "HH:mm:ss"}} </td>
        </ng-container>
    
        <ng-container matColumnDef="script_location">
          <th mat-header-cell *matHeaderCellDef> Local </th>
          <td mat-cell *matCellDef="let element"> {{element.script_location}}</td>
        </ng-container>
        
        <tr mat-header-row *matHeaderRowDef="scriptsLogTableColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: scriptsLogTableColumns;"></tr>
        
      </table>

    </mat-expansion-panel>
  
    <mat-expansion-panel>
      <mat-expansion-panel-header>
        <mat-panel-title> Camadas de Risco </mat-panel-title>
      </mat-expansion-panel-header>

      <table mat-table [dataSource]="risk_layers" class="mat-elevation-z8">

        <ng-container matColumnDef="id_planta">
          <th mat-header-cell *matHeaderCellDef> id_planta </th>
          <td mat-cell *matCellDef="let element"> {{element.id_planta}}</td>
        </ng-container>
        
        <ng-container matColumnDef="layer">
          <th mat-header-cell *matHeaderCellDef> layer </th>
          <td mat-cell *matCellDef="let element"> {{element.layer}}</td>
        </ng-container>
    
        <ng-container matColumnDef="s3_updated">
          <th mat-header-cell *matHeaderCellDef> s3_updated </th>
          <td mat-cell *matCellDef="let element"> {{element.s3_updated | date : "dd/MM/YY HH:mm:ss"}}</td>
        </ng-container>
        
        <ng-container matColumnDef="geoserver2_updated">
          <th mat-header-cell *matHeaderCellDef> geoserver2_updated </th>
          <td mat-cell *matCellDef="let element"> {{element.geoserver2_updated | date : "dd/MM/YY HH:mm:ss"}}</td>
        </ng-container>
        
        <tr mat-header-row *matHeaderRowDef="riskUpdatedTableColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: riskUpdatedTableColumns;"></tr>
        
      </table>

    </mat-expansion-panel>
  
  </mat-accordion>

</main>