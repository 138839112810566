    <mat-dialog-content>
      <div>
        <h3>Não foi possível realizar {{ data.action }} após 3 tentativas! Tente novamente ou entre em contato com o time de tecnologia.</h3>
      </div>
    </mat-dialog-content>
    <mat-dialog-actions align="center">
      <button mat-raised-button [mat-dialog-close]="true" color="primary">
        Ok
      </button>
    </mat-dialog-actions>
  