import { Component, OnInit } from '@angular/core';
import { HttpService } from 'src/app/services/http.service';
import { UserDataService } from 'src/app/services/user-data.service';
import { UntypedFormControl } from '@angular/forms';
import { Sort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
import { InfoDialog } from "../../shared/dialogs/info-dialog/info-dialog";
import { ConfirmationDialog } from 'src/app/shared/dialogs/confirmation-dialog/confirmation-dialog';
import { TranslateService } from '@ngx-translate/core';
import { GeoService } from '../../services/geo.service';
import { AmplitudeService } from '../../services/amplitude.service';
import { GeoLayersService } from '../../services/geo.layers';

const icon_dir = './assets/icons/'

interface ViewOption {
  id: number;
  option: string;
  icon: string;
}

@Component({
  selector: 'app-poi',
  templateUrl: './poi.component.html',
  styleUrls: ['./poi.component.scss']
})
export class PoiComponent implements OnInit {

  public poiViewFormControl = new UntypedFormControl('');
  public poiViewOptions: ViewOption[] = [
    { id: 1, option: "MAP.LIST_POINTS_OF_INTEREST", icon: 'list' },
    { id: 2, option: "MAP.REGISTER_POINT_OF_INTEREST", icon: 'add_location_alt' },
  ]
  public selectedPoiViewOption: number = this.poiViewOptions[0].id;

  constructor(
    private http: HttpService,
    public user: UserDataService,
    private dialog: MatDialog,
    private translate: TranslateService,
    public geo:GeoService,
    private amplitude: AmplitudeService,
    public layer: GeoLayersService,
  ) { }

  public POI_COLUMNS_SCHEMA = [
    { key: "tipo_poi", label:`${this.translate.instant("MAP.TYPE")}` },
    { key: "nome_poi", label: `${this.translate.instant("MAP.NAME")}` },
    { key: "lat_poi", label:"Latitude" },
    { key: "lon_poi", label:"Longitude" },
    { key: "acao", label:"" }
  ]

  public loaded:boolean = false;
  private types;
  public pois;
  public poiColumns: string[] = this.POI_COLUMNS_SCHEMA.map((col) => col.key);
  poiColumnsSchema = this.POI_COLUMNS_SCHEMA;
  public searchField: string;

  ngOnInit(){
    this.getPois();
    this.loaded = true;
  }

  async getPois(){
    this.pois = []
    this.types = await this.http.maestroGet('get_tipo_poi');
    let pois_raw = await this.user.getDadosPantera('pontos_interesse');
    if (!!pois_raw.features){
      for (let poi of pois_raw.features){
        let tipo_poi: string;
        for (let type of this.types){
          if (type.id_tipo_poi === poi.properties.id_tipo_poi){
            tipo_poi = icon_dir + this.types.find(poi_type => poi_type.id_tipo_poi === type.id_tipo_poi)?.icon_path
          }
        }
        this.pois.push({
          lat_poi: poi.geometry.coordinates[1],
          lon_poi: poi.geometry.coordinates[0],
          nome_poi: poi.properties.nome_poi,
          tipo_poi: tipo_poi,
          uuid_poi: poi.properties.uuid_poi,
          nome_tipo_poi: poi.properties.nome_tipo_poi // nome tipo POI do banco (p/ Amplitude)
        })
      }
    }
    if (this.pois.length){
      for (let poi of this.pois){
        poi['lat_poi'] = (poi['lat_poi']*100/100).toFixed(4);
        poi['lon_poi'] = (poi['lon_poi']*100/100).toFixed(4);
      }
      this.pois.sort((a,b) => this.sortAlph(a['tipo_poi'], b['tipo_poi']));
    }
  }

  async removePoi(poi){
    const dialogRef = this.dialog.open(ConfirmationDialog, {
      data: {text:`${this.translate.instant("MAP.DELETE_POINT_OF_INTEREST_DIALOG")}`}
    })
    
    dialogRef.afterClosed().subscribe(async (confirmation) => {
      if (!confirmation) return
      this.pois = this.pois.filter(p => p != poi)
      if (poi.uuid_poi){
        let response = await this.http.maestroPost('remove_poi', poi)
        this.openResponseDialog(response, poi)
        await this.user.updatePanteraData();
        await this.geo.updatePoiGroup();
      }
    })
  }

  openResponseDialog(response, poi){
    if (response?.status){
      this.dialog.open(InfoDialog, {
        data: {text:`${this.translate.instant("MAP.POINTS_OF_INTEREST_UPDATED_DIALOG")}`}
      });
      this.amplitude.sendEvent('Removeu Recurso', {"Recurso": this.amplitude.resourceName['poi'], "Tipo": poi['nome_tipo_poi'], "Nome": poi['nome_poi'], "ID": poi.uuid_poi});
    }
    else{
      this.dialog.open(InfoDialog, {
        data: {text:`${this.translate.instant("MAP.ERROR_ON_UPDATING_POINTS_OF_INTEREST_DIALOG")}`}
      });
      console.warn(`${this.translate.instant("MAP.ERROR")}: ${response?.msg}`)
    }
  }

  async onChildEvent(poi){
    this.pois.push({
      lat_poi: poi.lat,
      lon_poi: poi.lon,
      nome_poi: poi.nome_poi,
      tipo_poi: icon_dir + this.types.find(poi_type => poi_type.id_tipo_poi === poi.id_tipo_poi)?.icon_path
    })
    await this.user.updatePanteraData()
    this.getPois()
  }

  sortAlph(a:string,b:string){
    return (a < b) ? -1 : (a > b) ? 1 : 0;
  }

  sortData(sort: Sort) {
    const data = this.pois.slice();

    if (!sort.active || sort.direction === '') {
      this.pois = data;
    } else {
      this.pois = data.sort((a, b) => {
        const aValue = (a as any)[sort.active];
        const bValue = (b as any)[sort.active];
        return (aValue < bValue ? -1 : 1) * (sort.direction === 'asc' ? 1 : -1);
      });
    }
  }

  selectPoi(id_poi: string){
    let groupIndex = this.geo.layerGroupList.findIndex(l => l.groupId === 11);
    let idTipoPoi = this.layer.poiMarkers.find(m => m.feature.properties.uuid_poi == id_poi)?.feature.properties.id_tipo_poi;
    let layerIndex = this.geo.layerGroupList[groupIndex].layersList.findIndex(l => l.legend === `POI.${idTipoPoi}`);
    if (!this.geo.layerGroupList[groupIndex].layersList[layerIndex].active){
      this.geo.activateLayer(groupIndex, layerIndex);
    }
    this.geo.openPoiPopup(id_poi);
    this.layer.selectedPoi = id_poi;

    let nomeTipoPoi = this.layer.poiMarkers.find(m => m.feature.properties.uuid_poi == id_poi)?.feature.properties.nome_tipo_poi;
    let nomePoi = this.layer.poiMarkers.find(m => m.feature.properties.uuid_poi == id_poi)?.feature.properties.nome_poi;
    this.amplitude.sendEvent("Clicou Recurso", {"Recurso": this.amplitude.resourceName['poi'], "Tipo": nomeTipoPoi, "Nome": nomePoi, "ID": id_poi, "Origem": "Tabela"});
  }

  amplitudeSubTabEvent(selectedSubTab){
    this.amplitude.sendSubTabViewEvent('brigades', 'MAP.POINTS_OF_INTEREST', this.poiViewOptions, selectedSubTab, 'option')
  }

}
