@if (loaded) {
  <div class="tab-content-container">
    <div class="option-select">
      <mat-button-toggle-group [(ngModel)]="selectedPoiViewOption" name="option" [formControl]="poiViewFormControl" (change)="amplitudeSubTabEvent($event.value)">
        @for (option of poiViewOptions; track option.id) {
          <mat-button-toggle [value]="option.id" [disabled]="option.id == 2 && !user.isManager()">
            <mat-icon>{{option.icon}}</mat-icon> {{ option.option | translate }}
          </mat-button-toggle>
        }
      </mat-button-toggle-group>
      @if(pois?.length && selectedPoiViewOption == 1){
        <div class = "filter">
          <button mat-icon-button [matMenuTriggerFor]="filtersMenu" [matBadge]="searchField ? '!' : ''"
          matBadgePosition="above after"
          matBadgeColor="warn"
          [matBadgeHidden]="!searchField"
          matTooltip="{{ 'MAP.FILTERS' | translate }}"
          matBadgeSize="small">
            <mat-icon>tune</mat-icon>
          </button>
    
          <mat-menu #filtersMenu="matMenu">
            <div class="search" (click)="$event.stopPropagation()">
              <mat-form-field class="brigade-search" appearance="fill">
                <input matInput type="text" placeholder="{{  'MAP.SEARCH' | translate }}" [(ngModel)]="searchField">
              </mat-form-field>
            </div>
          </mat-menu>
        </div>
      }
    </div>
    @if (!pois?.length && selectedPoiViewOption == 1) {
      <div class="sem-dados">
        {{'MAP.NO_POI_REGISTERED' | translate }}
      </div>
    }
    @if (pois?.length && selectedPoiViewOption == 1) {
      <div>
        <div class="table-container">
          <table mat-table [dataSource]="pois | searchPoi: searchField" matSort (matSortChange)="sortData($event)" class="mat-elevation-z8" matSortDisableClear = "true">
            @for (col of poiColumnsSchema; track col.key) {
              <ng-container [matColumnDef]="col.key">
                <th mat-header-cell *matHeaderCellDef mat-sort-header="{{col.key}}">
                  {{ col.label }}
                </th>
                @if (col.key != 'tipo_poi' && col.key != 'acao') {
                  <td mat-cell *matCellDef="let element"> {{element[col.key]}} </td>
                }
                @if (col.key == 'tipo_poi') {
                  <td mat-cell *matCellDef="let element"> <img [src]="element[col.key]" alt="Ponto de interesse"> </td>
                }
                @if (col.key == 'acao') {
                  <td mat-cell *matCellDef="let element">
                    @if ((user.isManager()) && element.uuid_poi) {
                      <button mat-icon-button color="primary" (click)='removePoi(element)'>
                        <mat-icon class="cancel-button">close</mat-icon>
                      </button>
                    }
                  </td>
                }
              </ng-container>
            }
            <tr mat-header-row *matHeaderRowDef="poiColumns; sticky: true" ></tr>
            <tr mat-row *matRowDef="let row; columns: poiColumns;" class='table-row' [ngClass]="{'table-row-color': row.uuid_poi == this.layer.selectedPoi}" (click)="selectPoi(row.uuid_poi)"></tr>
          </table>
        </div>
      </div>
    }
    @if (selectedPoiViewOption == 2) {
      <div>
        <app-create-poi (notifyParent)="onChildEvent($event)"></app-create-poi>
      </div>
    }
  </div>
}
