<main>
  <mat-tab-group id="group" (selectedTabChange)="tabChangeEvent($event['index'])">
    <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon class="tab-icon">local_fire_department</mat-icon>
        {{ 'REP.REPORTS' | translate }}
      </ng-template>
      <div class="tab-content-container">
        <app-reports></app-reports>
      </div>
    </mat-tab>
    
    <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon class="tab-icon">analytics</mat-icon>
        Dashboard
      </ng-template>
      <div class="tab-content-container">
        <app-dashboard></app-dashboard>
      </div>
    </mat-tab>

    <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon class="tab-icon">email</mat-icon>
        {{ 'MAP.INFORMATIVE' | translate }}
      </ng-template>
      <div class="tab-content-container">
        <app-manager></app-manager>
      </div>
    </mat-tab>
  </mat-tab-group>
</main>