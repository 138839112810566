<div class="cameras-controls-wrapper">
    <table mat-table [dataSource]="cameras">
      <ng-container matColumnDef="Câmeras">
        <th mat-header-cell *matHeaderCellDef>
            <div id="cameras-header">
              <mat-icon>videocam</mat-icon>
              {{ 'MAP.CAMERAS' | translate }}
            </div>
        </th>
        <td mat-cell *matCellDef="let element">{{element.nome_local}}</td>
      </ng-container>

      <ng-container matColumnDef="Centralizar">
        <th mat-header-cell *matHeaderCellDef>{{ 'MAP.CENTER' | translate }}</th>
        <td mat-cell *matCellDef="let element">
            <button mat-icon-button (click)="centerOnCam(element)"><mat-icon>filter_center_focus</mat-icon></button>
        </td>
      </ng-container>

      <ng-container matColumnDef="Direcionar">
        <th mat-header-cell *matHeaderCellDef>{{ 'MAP.DIRECT' | translate }}</th>
        <td mat-cell *matCellDef="let element">
          <button mat-icon-button
            (click)="toggleControlCameraDirection(element)"
            [color]="geoFactoryService.selectedCamerasIdsToControlFromMap().includes(element.id) ? 'warn' : 'primary'">
              <mat-icon>control_camera</mat-icon>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="camerasControlsOptionsColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: camerasControlsOptionsColumns;"></tr>
    </table>
  </div>