import { Component, OnInit } from '@angular/core';
import { UserDataService } from 'src/app/services/user-data.service';
import { AmplitudeService } from '../../services/amplitude.service';

@Component({
  selector: 'app-alert-groups',
  templateUrl: './alert-groups.component.html',
  styleUrls: ['./alert-groups.component.scss']
})
export class AlertGroupsComponent implements OnInit {

  constructor(
    protected user: UserDataService,
    public amplitude: AmplitudeService,
  ) { }

  public groupsTableColumns: string[] = [
    'nome',
    'link',
  ]

  public groups: any;

  ngOnInit(): void {
    if(!this.groups){
      this.getTelegramGroups();
    }
  }

  async getTelegramGroups(){
    if(!this.groups){
      this.groups = this.user.getDadosPantera('telegram_groups')
    }
  }
}
