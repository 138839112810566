<main>

  <div class="fx-row-space-between-center">
    <mat-form-field appearance="outline" style="margin-top: 15px;" class="torre-select">
      <mat-label>{{ 'MAP.SELECT_TOWER' | translate }}</mat-label>
      <mat-select
        [(value)]="selectedId"
        (selectionChange)="selectCamera()">
        @for (cam of cameras; track cam.id) {
          <mat-option [value]="cam.id">
            {{cam.nome_local}}
          </mat-option>
        }
      </mat-select>
    </mat-form-field>
  </div>

  <div class="img-container">
    @if (changingCamera) {
      <div class="fx-col-center img-wrapper" >
        <mat-spinner diameter="20" class="center"></mat-spinner>
      </div>
    }
    @if (!changingCamera && !hasOnlineCamera) {
      <div><span style="width: 100%;">{{ 'MAP.NO_ONLINE_CAM' | translate }}</span></div>
    }
    @if (!changingCamera && hasOnlineCamera) {
      <div class='img-wrapper'>
        @if (image64 && hasOnlineCamera) {
          <img class='cam-img' [src]="image64  | safe: 'resourceUrl'">
        }
        @if(image64){
        <span class="log">
          {{imgTime | date: "dd/MM HH:mm:ss"}}
        </span>
      }
        <span class="center">
          @if (loadingImage) {
            <mat-spinner diameter=20></mat-spinner>
          }
        </span>
        <div class="cam-ctrl-btns-container">
          @if (!expanded && image64) {
            <button mat-mini-fab
              matTooltip="{{ 'MAP.FULL_SCREEN' | translate }}"
              (click)="openFullScreen()">
              <mat-icon >open_in_full</mat-icon>
            </button>
          }
        </div>
      </div>
    }
  </div>
</main>