import { Component, AfterViewInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
import { UserDataService } from 'src/app/services/user-data.service';
import { GeoService } from 'src/app/services/geo.service';
import { SatelliteService } from 'src/app/services/satellite.service';
import { ReportsService } from '../../services/reports.service';
import { LoggingService } from 'src/app/services/logging.service';
import { AmplitudeService } from '../../services/amplitude.service';

import { createReportClusterDialog } from 'src/app/components/sat-cluster/create-report-cluster';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-sat-cluster',
  templateUrl: './sat-cluster.component.html',
  styleUrls: ['./sat-cluster.component.scss']
})
export class SatClusterComponent implements AfterViewInit {

  constructor(
    public user: UserDataService,
    public geo: GeoService,
    public sat: SatelliteService,
    public rep: ReportsService,
    public logging: LoggingService,
    private dialog: MatDialog,
    private amplitude: AmplitudeService,
    public translate: TranslateService
  ) {}

  @ViewChild('clusterHotspotTableSort') clusterHotspotTableSort = new MatSort();

  public clusterHotspotTableColumns: string[] = [
    'satellite',
    'acquisition_utc_offset',
    'detection_utc_offset',
    'confidence',
    'frp_normalized_mw_km2'
  ]

  public selectedRepId: string = null;
  public creatingReport: boolean = false;

  public rData = {
    'origem_deteccao':3, //satélite
    'causa_incendio':null,
    'comentarios': null,
    'geoloc_type_id':7 //click no cluster de satélite
    }
  
  ngAfterViewInit() {
    this.sat.clusterHotspotTableData.sortingDataAccessor = (data, sortHeaderId) => {
      if (sortHeaderId === 'acquisition_utc_offset' || sortHeaderId === 'detection_utc_offset') {
        return new Date(data[sortHeaderId]);
      }
      return data[sortHeaderId];
    };
    this.sat.clusterHotspotTableData.sort = this.clusterHotspotTableSort;
    this.sat.updateSelectedCluster$.subscribe(() => {this.sat.clusterHotspotTableData.sort = this.clusterHotspotTableSort});
  }

  selectHotspot(id){
    let groupIndex = this.geo.layerGroupList.findIndex(l => l.groupId === 4);
    let layerIndex = this.geo.layerGroupList[groupIndex].layersList.findIndex(l => l.legend === 'SAT.HOTSPOT_ACTIVE_FIRE_LAYER');
    if (!this.geo.layerGroupList[groupIndex].layersList[layerIndex].active){
      this.geo.activateLayer(groupIndex, layerIndex);
    }
    this.geo.openHotspotMarker(id);
    this.amplitude.sendEvent("Clicou Foco De Calor", {"Origem": "Tabela", "ID Foco Calor": id});
  }
  
  openReportClusterDialog(){
    this.dialog.open(createReportClusterDialog, {})    
  }

  sendEvent(event: any) {
    this.amplitude.sendEvent('Expandiu Tabela Focos De Calor Do Agrupamento', {'unique_id': this.sat.selectedCluster["unique_id"]});
  }

  get currentLocale(): string {
    return this.translate.currentLang === 'pt-br' ? 'pt-BR' : 'en-US';
  }
}
