<style>
    mat-card{
        display: flex;
        flex-direction: column;
        margin: 1rem;
    }

    mat-card-title {
        font-size: 18px;
        text-align: center;
        margin-bottom: 12px;
    }

    mat-form-field {
    width: 80%;
    }

    @media(max-width:600px){
    mat-card{
        margin: 0;
    }  
    }

</style>

<div class="report-dialog-wrapper">
    <mat-dialog-content>
        <div class="tab-content-container">
            <mat-card appearance="outlined">
                <mat-card-title>Simule uma propagação de incêndio</mat-card-title>
          
              <div class="fx-column-space-around-center">
        
                <mat-form-field appearance="outline" color='primary'>
                    <mat-label>Velocidade do vento (km/h)</mat-label>
                    <input required matInput [(ngModel)]="wind_speed" type="number" min="0" max=150 placeholder="Ex: 23"/>
                </mat-form-field>
        
                <mat-form-field appearance="outline" color='primary' matTooltip="0 corresponde à direção Norte">
                    <mat-label>Direção do vento (graus)</mat-label>
                    <input required matInput [(ngModel)]="wind_deg" type="number" min="0" max=359 placeholder="Ex: 138"/>
                </mat-form-field>
        
                <mat-form-field appearance="outline" color='primary'>
                    <mat-label>Tempo de simulação (minutos)</mat-label>
                    <input required matInput [(ngModel)]="t_sim_min" type="number" min="5" max=180 placeholder="Ex: 60"/>
                </mat-form-field>
        
              </div>
          
              <div class="fx-row-space-around-center">
                <!-- OBS sobre o disabled abaixo: nao deu pra botar !windspeed e !wind_deg pq senao ele nao tava deixando passar o valor zero -->
                <!-- parece ter a ver com isso https://stackoverflow.com/questions/2910495/why-null-0-null-0-but-not-null-0 -->
                <button mat-raised-button color='primary'
                  [disabled]= "wind_speed == null || wind_speed == undefined || wind_deg == null || wind_deg == undefined || (wind_speed < 0) || (wind_speed > 150) || (wind_deg < 0) || (wind_deg >= 360)
                  || !t_sim_min || (t_sim_min < 5) || (t_sim_min > 180)"
                  [mat-dialog-close]="{wind_speed: wind_speed, wind_deg: wind_deg, t_sim_min: t_sim_min}">
                  Simular
                </button>
          
              </div>
            </mat-card>
        </div>
    </mat-dialog-content>
</div>