import { Component, OnInit } from '@angular/core';
import { UserDataService } from 'src/app/services/user-data.service';
import { HttpService } from 'src/app/services/http.service';
import { Chart, ChartType, ChartConfiguration, ChartOptions } from 'chart.js';
import { LoggingService } from '../../services/logging.service';
import { TranslateService } from '@ngx-translate/core';

const DEFAULT_START_YEAR = 2015;
const DEFAULT_END_YEAR = 2023;
const PALETA_SCARS = [
  'rgba(253, 98, 94, 1)',
  'rgb(242, 200, 15, 1)',
  'rgb(138, 212, 235, 1)',
  'rgb(95, 107, 109, 1)',
  'rgb(40, 56, 60, 1)',
  'rgba(1, 184, 170, 1)',
  // add more colors as needed
]

const PALETA_EMISSIONS = PALETA_SCARS.slice().reverse();

const ID_LULC_OUTROS = 999;

interface Lulc {
  id_lulc: number,
  nome_lulc: string,
  id_lulc_group: number,
}

interface ScarsEmissionsData {
  ano: number,
  area_ha: number,
  emissoes_tCO2: number,
  id_lulc: number,
}

interface ChartDataset {
  label: any,
  backgroundColor: string,
  borderColor: string,
  borderWidth: number,
  data: number[]
}

interface HotspotsData {
  ano: number,
  num_hotspots: number,
}

interface LabelsValues {
  labels: number[],
  values: number[],
}

function getTooltipWithUnit(tooltipItem, unit: string) {
  const parsedValue = typeof tooltipItem.parsed === 'number' ? tooltipItem.parsed : tooltipItem.parsed.y;
  let value = Number(parsedValue).toFixed(0);
  return `${value} ${unit}`;
}

@Component({
  selector: 'app-carbon',
  templateUrl: './carbon.component.html',
  styleUrls: ['./carbon.component.scss']
})
export class CarbonComponent implements OnInit {

  constructor(
    public user: UserDataService,
    private http: HttpService,
    public logging: LoggingService,
    private translate: TranslateService
  ) { }

  public yearlyScarsChart: Chart;
  public yearlyEmissionsChart: Chart;
  public totalScarsByLulcChart: Chart;
  public totalEmissionsByLulcChart: Chart;
  public yearlyHotspotsChart: Chart;
  public data: ScarsEmissionsData[];
  public lulc: Lulc[];
  private lulcIds: number[];
  private hotspotsData: HotspotsData[];

  // TO DO botar types direitinho em tudo, aqui e nas funçoes


  async ngOnInit() {
    await this.getScarsEmissionsData();
    await this.getLulc();
    this.createTotalScarsChart();
    this.createTotalEmissionsChart();
    this.createTotalScarsByLulcChart();
    this.createTotalEmissionsByLulcChart();
    await this.getHotspotsData();
    this.createTotalHotspotsChart();

  }

  async getScarsEmissionsData(){
    this.data = await this.http.maestroGet(`get_cicatrizes_emissoes_planta/${this.user.getIdPlanta()}/${DEFAULT_START_YEAR}/${DEFAULT_END_YEAR}`);
  }

  async getLulc(){
    this.lulc = await this.http.maestroGet(`get_lulc`);
  }

  async getHotspotsData(){
    this.hotspotsData = await this.http.maestroGet(`get_hotspots_historicos_stats_planta/${this.user.getIdPlanta()}`);
    try {
      this.hotspotsData.sort((a, b) => a.ano - b.ano);
    } catch(e){
      this.logging.logERROR(`getHotspotsData ${e}`,e);
    }
  }

  getNomesLulcsByIds(ids: number[], lulcs: Lulc[]): string[] {
    return ids.map(id => {
      const item = lulcs.find(item => item.id_lulc === id);
      return item ? item.nome_lulc : 'Outros';
    });
  }

  getChartDataIdLulc(field: string, dataArray: ScarsEmissionsData[], idLulc:number): LabelsValues {
    let dataMap = new Map();
    for (let year = 2015; year <= 2022; year++){
      dataMap.set(year, 0);
    };

    const ids = this.lulcIds.filter((value) => value !== ID_LULC_OUTROS);

    dataArray.forEach(record => {
      const year = record.ano;
      const value = record[field];
      const recordIdLulc = record.id_lulc;
    
      const shouldProcessRecord = ids.includes(idLulc) ? recordIdLulc === idLulc : !ids.includes(recordIdLulc);
    
      if (shouldProcessRecord) {
        const existingData = dataMap.get(year) || 0;
        dataMap.set(year, existingData + value);
      }
    });

    let labels: number[] = [];
    let values: number[] = [];
    dataMap.forEach((value, key) => {
      labels.push(key);
      values.push(value);
    });
    return {
      labels: labels,
      values: values
    }
  }

  getSimplifiedDataByLulc(data: Record<number, number> | any){
    const dataByLulc = this.simplifyLulcs(data);
    const dataValues = Object.values(dataByLulc)
    this.lulcIds = Object.keys(dataByLulc).map((strId) => Number(strId));
    const dataLabels = this.getNomesLulcsByIds(this.lulcIds, this.lulc);
    return {
      dataValues: dataValues,
      dataLabels: dataLabels
    }
  }

  getYearLabels(){
    let years = [];
    for (let year = DEFAULT_START_YEAR; year <= DEFAULT_END_YEAR; year++){
      years.push(year)
    }
    return years;
  }

  createTotalScarsChart() {
    let areaByLulc = this.getAreaByLulc(this.data);
    const { dataValues, dataLabels } = this.getSimplifiedDataByLulc(areaByLulc);
    const dataLabels2 = this.lulcIds
    let chartLabels = this.getYearLabels();

    let datasets: ChartDataset[] = []
    for (let i = 0; i < dataValues.length; i++){
      let translatedLabel = this.translate.instant(`LULC.${dataLabels2[i]}`);

      datasets.push({
        label: translatedLabel,
        backgroundColor: PALETA_SCARS[i],
        borderColor: PALETA_SCARS[i],
        borderWidth: 1,
        data: this.getChartDataIdLulc('area_ha', this.data, this.lulcIds[i]).values,
      });
    }
    console.log(this.lulcIds)
    this.yearlyScarsChart = new Chart('yearlyScarsChart', this.getBarChartConfig(chartLabels, datasets, 'ha', true, this.translate));
  }

  createTotalEmissionsChart() {

    let emissionsByLulc = this.getAreaByLulc(this.data);
    const { dataValues, dataLabels } = this.getSimplifiedDataByLulc(emissionsByLulc);
    const dataLabels2 = this.lulcIds

    let chartLabels = this.getYearLabels();

    let datasets: ChartDataset[] = []
    for (let i = 0; i < dataValues.length; i++){
      let translatedLabel = this.translate.instant(`LULC.${dataLabels2[i]}`);
      datasets.push({
        label: translatedLabel,
        backgroundColor: PALETA_EMISSIONS[i],
        borderColor: PALETA_EMISSIONS[i],
        borderWidth: 1,
        data: this.getChartDataIdLulc('emissoes_tCO2', this.data, this.lulcIds[i]).values
      });
    }
    this.yearlyEmissionsChart = new Chart('yearlyEmissionsChart', this.getBarChartConfig(chartLabels, datasets, 'tCO2', true, this.translate));
  }

  getBarChartConfig(labels, datasets: ChartDataset[], chartUnit: string, stacked: boolean,translate:TranslateService, yTickCallbackFunc?){
    
    const chartTitleKey = chartUnit == 'ha' ? 'LULC.BURNED_AREA' : 'LULC.EMISSIONS';
    const chartTitle = this.translate.instant(chartTitleKey);
    const chartType: ChartType = 'bar';
    let chartOptions = {
      type: chartType,
      data: {
        labels: labels,
        datasets: datasets,
      },
      options: {
        maintainAspectRatio: false,
        scales: {
          y: {
            beginAtZero: true,
            stacked: stacked,
          },
          x: {
            stacked: stacked,
          }
        },
        responsive: true,
        plugins: {
          title: {
            display: true,
            text: chartTitle,
            font: {
              size: 14,
            }
          },
          tooltip: {
            callbacks: {
              label: (tooltipItem) => {
                return getTooltipWithUnit(tooltipItem, chartUnit);
              }
            }
          }
        }
      }
    };
    return chartOptions;
  }

  getAreaByLulc(data){
    const areaByLulc = data.reduce((accum, record) => {
      const idLulc = record.id_lulc;
      if (accum[idLulc]) {
        accum[idLulc] += record.area_ha;
      } else {
        accum[idLulc] = record.area_ha;
      }
      return accum;
    }, {});
    return areaByLulc;
  }

  getPieChartConfig(data, backgroundColor: string[], labels, chartUnit: string, translate: TranslateService){
    const chartTitleKey = chartUnit == 'ha' ? 'LULC.BURNED_AREA_COVER' : 'LULC.EMISSIONS_COVER';
    const chartTitle = this.translate.instant(chartTitleKey);
    const translatedLabel = this.lulcIds.map(id  => translate.instant(`LULC.${id }`))
    const dataConfig = {
      labels: translatedLabel,
      datasets: [{
        data: data,
        backgroundColor: backgroundColor,
      }]
    };
    const plugins = {
      title: {
        display: true,
        text: chartTitle,
        font: {
          size: 14
        }
      },
      tooltip: {
        callbacks: {
          label: (tooltipItem) => {
            return getTooltipWithUnit(tooltipItem, chartUnit);
          }
        }
      }
    }
    const options: ChartOptions = {
      responsive: true,
      maintainAspectRatio: false,
      plugins: plugins,
    }
    const config: ChartConfiguration = {
      type: 'pie',
      data: dataConfig,
      options: options,
    };
    return config;
  }

  createTotalScarsByLulcChart(){
    let areaByLulc = this.getAreaByLulc(this.data);
    const { dataValues, dataLabels } = this.getSimplifiedDataByLulc(areaByLulc);
    this.totalScarsByLulcChart = new Chart('totalScarsByLulcChart', this.getPieChartConfig(dataValues, PALETA_SCARS, dataLabels, 'ha', this.translate));
    }

  simplifyLulcs(dataByLulc: Record<number, number>): Record<number,number> {
    const sortedData = Object.entries(dataByLulc).sort((a:any, b:any) => b[1] - a[1]);
    const newData = {}; // manter só top 3 e somar o resto numa categoria "outros" 999

    let sum = 0;
    for (let i = 0; i < sortedData.length; i++) {
      if (i < 3) {
        newData[sortedData[i][0]] = sortedData[i][1];
      } else {
        sum += sortedData[i][1];
      }
    }
    if (sum > 0) newData[ID_LULC_OUTROS] = sum;
    return newData;
  }

  getEmissionsByLulc(data: ScarsEmissionsData[]){
    const emissionsByLulc = data.reduce((accum, record) => {
      const idLulc = record.id_lulc;
      if (accum[idLulc]) {
        accum[idLulc] += record.emissoes_tCO2;
      } else {
        accum[idLulc] = record.emissoes_tCO2;
      }
      return accum;
    }, {});
    return emissionsByLulc;
  }

  createTotalEmissionsByLulcChart(){
    let emissionsByLulc = this.getEmissionsByLulc(this.data);
    const { dataValues, dataLabels } = this.getSimplifiedDataByLulc(emissionsByLulc);
    this.totalEmissionsByLulcChart = new Chart('totalEmissionsByLulcChart', this.getPieChartConfig(dataValues, PALETA_EMISSIONS, dataLabels, 'tCO2', this.translate));
  }

  createTotalHotspotsChart() {
    const chartTitleKey = 'SAT.HOTSPOT';
    const chartTitle = this.translate.instant(chartTitleKey);
    const chartLabel = 'LULC.SATELLITE_AQUA';
    const chartLabelTranslate = this.translate.instant(chartLabel);
      if (!this.hotspotsData) return;
      let chartLabels = [];
      let datasets = [];
      let data = [];
      this.hotspotsData.forEach((element) => {
        chartLabels.push(element.ano);
        data.push(element.num_hotspots)
      });
      datasets.push({
        label: chartLabelTranslate,
        backgroundColor: PALETA_SCARS[0].replace('1)', '0.7)'),
        borderColor: PALETA_SCARS[0],
        borderWidth: 1,
        data: data,
      });

      this.yearlyHotspotsChart = new Chart('yearlyHotspotsChart', {
        type: 'bar',
        data: {
          labels: chartLabels,
          datasets: datasets,
        },
        options: {
          maintainAspectRatio: false,
          scales: {
            y: {
              beginAtZero: true,
            },
          },
          responsive: true,
          plugins: {
            title: {
              display: true,
              text: chartTitle,
              font: {
                size: 14,
              },
            }
          }
        }
      });
  }

}
