import { Component, OnInit } from '@angular/core';
import { UserDataService } from 'src/app/services/user-data.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { PropagationService, NUMBER_SIMULATION_STEPS, PROPAGATION_COLORS } from 'src/app/services/propagation.service';
import { LoggingService } from '../../services/logging.service';
import { GeoService } from 'src/app/services/geo.service';
import { ReportsService } from 'src/app/services/reports.service';

interface propLayerLegend {
  color: string,
  legend: string,
}
@Component({
  selector: 'app-propagation-widget',
  templateUrl: './propagation-widget.component.html',
  styleUrls: ['./propagation-widget.component.scss']
})
export class PropagationWidgetComponent implements OnInit {

  constructor(
    public user: UserDataService,
    private fb: FormBuilder,
    public prop: PropagationService,
    public logging: LoggingService,
    public geo: GeoService,
    public reports: ReportsService
  ) {}

  public propSimulationForm: FormGroup;

  public propLayersLegend: propLayerLegend[];

  ngOnInit(){
    this.propSimulationForm = this.fb.group({
      windSpeedKmh: [, [Validators.required, Validators.min(0), Validators.max(50)]],
      windDeg: [, [Validators.required, Validators.min(0), Validators.max(359)]],
      tSimulationMin:[, [Validators.required, Validators.min(5), Validators.max(1440)]],
      lat: [, [Validators.required, Validators.min(-90), Validators.max(90)]], // to do ajustar aos bounds planta
      lon: [, [Validators.required, Validators.min(-180), Validators.max(180)]], // idem
    });

    this.user.currentPropagationParams.subscribe((params) => {
      this.propSimulationForm.patchValue({
        windSpeedKmh: params.windSpeedKmh,
        windDeg: params.windDeg,
        tSimulationMin: params.tSimulationMin,
        lat: params.lat,
        lon: params.lon,
      })
    });
  }

  onSubmit(){
    this.prop.simulationFormSubmitted = true;
    const fv = this.propSimulationForm.value;
    this.propLayersLegend = [];
    for (let i = 0; i < NUMBER_SIMULATION_STEPS; i++){
      this.propLayersLegend.push({
        color: PROPAGATION_COLORS[i], legend: `${(fv.tSimulationMin * (i+1) / NUMBER_SIMULATION_STEPS).toFixed(0)} min`
      });
    }
    try {
      this.prop.foreFire(this.reports.selectedR.n_relatorio_planta, this.reports.selectedR.id_report, fv.windSpeedKmh, fv.windDeg, fv.tSimulationMin * 60, fv.lat, fv.lon);
    } catch (error) {
      console.warn(`Erro ao simular propagação: ${error}`);
      this.logging.logERROR(`onSubmit ${error}`,error);
    }
  }

  closeWidget(){
    this.user.showPropagationWidget = false;
    this.prop.simulationFormSubmitted = false;
    clearTimeout(this.prop.drawPropTimeoutId);
    this.geo.clearFirefronts();
  }

  newSimulation(){
    this.prop.simulationFormSubmitted = false;
  }

}
