import { Component } from '@angular/core';

@Component({
  selector: 'app-reloaddeployparams',
  templateUrl: './reloaddeployparams.component.html',
  styleUrls: ['./reloaddeployparams.component.scss']
})
export class ReloaddeployparamsComponent {

  constructor(
    ) {}
  
    reloadPage(){
      window.location.reload(); // Reload the modified URL, bypassing the cache
    }
  }
  