import { Injectable} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { HttpService } from './http.service';
import { UserDataService } from './user-data.service';
import { LoggingService } from './logging.service';
import { AmplitudeService } from '../services/amplitude.service';

import * as L from 'leaflet'
import * as gf from "./geo.format";
import { addActivatedPopup }  from "./geo.format";

import { LayerObj, LayerGroupObj } from './geo.store';
import { GeoFactoryService } from './geoFactory.service'
import { formatFeaturePopUp, formatScarRecurrenceBPBioenergy } from './geo.format';
import { brigadeIconMap } from './brigades.service';

import { environment } from '../../environments/environment'

const geoServerBaseUrl = environment.geoserverUrl;

const icon_dir = './assets/icons/'

export interface ScarStats {
  ano: number,
  area_ha: number,
  emissao_tco2: number,
  id_lulc: number,
}

@Injectable({
  providedIn: 'root'
})

export class GeoLayersService {

  constructor(
    private http: HttpService,
    private user: UserDataService,
    private translate: TranslateService,
    public logging: LoggingService,
    public geoFactoryService: GeoFactoryService,
    private amplitude: AmplitudeService,
  ) {
    this.pt = this.translate.currentLang == 'pt-br'
  }

  private pt:boolean

  public riskLayers;

  public brigadeMarkers:any[] = [];
  public selectedBrigadeId: string | null = null;

  public poiMarkers:any[] = [];
  public selectedPoi: string | null = null;

  public stationMarkers:any[] = [];
  public selectedMeteoStation: string | null = null;

  public scarStats: ScarStats[] = null;

  async createProtectedAreaLayerGroup():Promise<LayerGroupObj>{    
    let limitsOverlays = []

    if (this.user.hasTerraIndigena()){
      let terra_indigena = this.createWmsLayer('ugm','terra_indigena', 0.7)
      limitsOverlays.push({
        'legend': "MAP.TI",
        'layer': terra_indigena,
        'active': false
      });
    }

    if (this.user.hasUnidadeConservacao()){
      let unidade_conservacao = this.createWmsLayer('ugm','unidade_conservacao', 0.7)
      limitsOverlays.push({
        'legend': "MAP.UC",
        'layer': unidade_conservacao,
        'active': false
        });
    }

    return {
      'groupId': 22,
      'groupTitle': "MAP.PROTECTED_AREAS",
      'icon': 'forest',
      'layersList': limitsOverlays
    }
  }

  async createOtherLayerGroup():Promise<LayerGroupObj>{
    let limitsOverlays = []

    let divisas_layer = L.tileLayer('https://services.arcgisonline.com/arcgis/rest/services/Reference/World_Boundaries_and_Places/MapServer/tile/{z}/{y}/{x}');
    limitsOverlays.push({
      'legend': "MAP.POLITICAL_BOUNDARIES",
      'layer': divisas_layer,
      'active': true
    })

    //Plantas do Pantanal
    if([30,32,36].includes(this.user.getIdPlanta())) {
      let pantanal_subregiao = this.createClientWmsLayer(this.user.getIdPlanta(), 'ugm', 'pantanal_subregiao', 0.8);
      limitsOverlays.push({
        'legend': 'Pantanal sub-regiões',
        'layer': pantanal_subregiao,
        'active': false
      })
    }

    return {
      'groupId': 23,
      'groupTitle': "MAP.OTHER_LAYERS",
      'icon': 'more_horiz',
      'layersList': limitsOverlays
    }
  }

  async createInfraLayerGroup(locais_planta, all_instalacoes, stations, isUgm, isOperatorP2):Promise<LayerGroupObj>{
    if(!locais_planta.features) return null

    let limitsOverlays = []
    
    let centralGeoJson = this.geoFactoryService.createPlantaLocaisGeoJSON(locais_planta, all_instalacoes, [1], isUgm, isOperatorP2, this.translate);
    if (centralGeoJson.getLayers().length > 0) {
      limitsOverlays.push(
        {
          'legend': "MAP.CENTRAL",
          'layer': centralGeoJson,
          'active': false
        },
      )
    }

    let torresMonitoramentoGeoJson = this.geoFactoryService.createPlantaLocaisGeoJSON(locais_planta, all_instalacoes, [2], isUgm, isOperatorP2, this.translate);
    limitsOverlays.push(
      {
        'legend': "MAP.MONITORING",
        'layer': torresMonitoramentoGeoJson,
        'active': !this.user.is999()
      },
    )

    // layer simplificada torres IHP pro prevfogo
    if (this.user.getIdPlanta() === 32){
      let locaisIHPGeojson = await this.http.maestroGet('get_geojson_locais_planta/36')
      let torresMonitoramentoIHPGeoJson = this.geoFactoryService.createPlantaLocaisGeoJSON(locaisIHPGeojson, all_instalacoes, [2], isUgm, isOperatorP2, this.translate, false);
      limitsOverlays.push(
        {
          'legend': "MAP.MONITORING_IHP",
          'layer': torresMonitoramentoIHPGeoJson,
          'active': !this.user.is999()
        },
      )
    }

    let torresRepetidoraGeoJson = this.geoFactoryService.createPlantaLocaisGeoJSON(locais_planta, all_instalacoes, [3], isUgm, isOperatorP2, this.translate);
    if (torresRepetidoraGeoJson.getLayers().length > 0) {
      limitsOverlays.push(
        {
          'legend': "MAP.REPEATER",
          'layer': torresRepetidoraGeoJson,
          'active': isUgm
        },
      )
    }

    let patrimonialGeoJson = this.geoFactoryService.createPlantaLocaisGeoJSON(locais_planta, all_instalacoes, [5], isUgm, isOperatorP2, this.translate);
    if (patrimonialGeoJson.getLayers().length > 0) {
      limitsOverlays.push(
        {
          'legend': "MAP.SECURITY",
          'layer': patrimonialGeoJson,
          'active': isUgm
        },
      )
    }

    let linksLayerObj = await this.createLinksLayerObj(isUgm);
    if(linksLayerObj){
      limitsOverlays.push(linksLayerObj)
    }

    return {
      'groupId': 2,
      'groupTitle': "MAP.INFRASTRUCTURE" ,
      'icon': 'cell_tower',
      'layersList': limitsOverlays
    }
  }

  createPlantasLayer(plantas){
    let features = [];
    let plantaProperties = Object.keys(plantas[0]);
    let color: string;
    for (let planta of plantas){
      let properties = {}
      for (let property of plantaProperties){
        properties[property] = planta[property];
      }
      features.push({
        "type": "Feature",
        "geometry": {
          "type": "Point",
          "coordinates": [planta.lon, planta.lat]
        },
        "properties": properties
      })
    };
    let plantasGeojson = L.geoJSON(features, {
      pointToLayer: (feature, latlng) => {
        color = 'green'
        if (feature.properties.nome_cliente === 'TRIAL'){
          color ='red'
        }
        if (feature.properties.nome_cliente === 'POC'){
          color = 'orange'
        }
        if (feature.properties.nome_cliente === 'Desativados'){
          color = 'black'
        }
        var geojsonMarkerOptions = {
          radius: 8,
          fillColor: color,
          color: "#000",
          weight: 1,
          opacity: 1,
          fillOpacity: 0.8
        };

        let marker = L.circleMarker(latlng, geojsonMarkerOptions);
        marker.bindTooltip(feature.properties.nome_cliente);
        marker.bindPopup(gf.formatPlantaInfo(feature.properties));
        return marker
      }
    })
    return plantasGeojson;
  }

  createWmsLayer(workspaceName: string, layerName: string, opacity: number){
    return L.tileLayer.wms(`${geoServerBaseUrl}/geoserver/${workspaceName}/wms`,
      {
        layers: `${workspaceName}:${layerName}`,
        format: 'image/png',
        version: '1.1.0',
        transparent: true,
        opacity: `${opacity}`,
      }
    )
  }

  createClientWmsLayer(idPlanta: number, workspaceName: string, layerName: string, opacity: number){
    return L.tileLayer.wms(`${geoServerBaseUrl}/geoserver/${workspaceName}/wms`,
      {
        layers: `${workspaceName}:${layerName}`,
        format: 'image/png',
        version: '1.1.0',
        transparent: true,
        opacity: `${opacity}`,
        viewparams: `id_planta:${idPlanta}`,
      }
    )
  }

  createClientYearWmsLayer(idPlanta: number, ano: number, workspaceName: string, layerName: string, opacity: number){
    return L.tileLayer.wms(`${geoServerBaseUrl}/geoserver/${workspaceName}/wms`,
      {
        layers: `${workspaceName}:${layerName}`,
        format: 'image/png',
        version: '1.1.0',
        transparent: true,
        opacity: `${opacity}`,
        viewparams: `id_planta:${idPlanta};ano:${ano}`,
      }
    )
  }

  createInpeTerraBrasilisWmsLayer(workspace: string, layer: string){
    return L.tileLayer.wms(`https://terrabrasilis.dpi.inpe.br/geoserver/${workspace}/ows`,
      {
        layers: `${workspace}:${layer}`,
        format: 'image/png',
        version: '1.1.1',
        transparent: true,
      }
    )
  }

  createClientWfsLayer(idPlanta: number, workspaceName: string, layerName: string, wfsGeojson, ano?: number){
    let wfsUrl = `${geoServerBaseUrl}/geoserver/${workspaceName}/wfs`;
    let viewParams = ano ? `id_planta:${idPlanta};ano:${ano}` : `id_planta:${idPlanta}`;
    let wfsParams = {
      service: 'WFS',
      request: 'GetFeature',
      typeName: `${workspaceName}:${layerName}`,
      outputFormat: 'application/json',
      viewparams: viewParams,
    };
    fetch(wfsUrl + L.Util.getParamString(wfsParams)).then(response => response.json()).then(data => {
      wfsGeojson.addData(data);
    });
    return wfsGeojson;
  }

  createBioWfsLayer(idOnca: number, nomeOnca: string){
    const oncaGeojson = L.geoJSON(null, {
      pointToLayer: (feature, latlng) => {
        let icon = L.icon({iconUrl: "./assets/icons/panther.png", iconSize: [32,32]});
        let marker = L.marker(latlng, {icon: icon});
        marker.bindPopup(formatFeaturePopUp(
            feature.properties,
            nomeOnca,
            ['acquisition_time', 'latitude', 'longitude']
            ));
          return marker
        },
      pane: 'cicatrizes',
    });
    let wfsUrl = `${geoServerBaseUrl}/geoserver/ugm/wfs`;
    let wfsParams = {
      service: 'WFS',
      request: 'GetFeature',
      typeName: 'ugm:bio_oncas',
      outputFormat: 'application/json',
      viewparams: `id:${idOnca}`,
    };
    fetch(wfsUrl + L.Util.getParamString(wfsParams)).then(response => response.json()).then(data => {
        oncaGeojson.addData(data);
      });
    return oncaGeojson;
  }

  async createMeteoStationLayerGroup(): Promise<LayerGroupObj> {
    if (!this.user.hasMeteoStations()) {
      return 
    }
  
    let wfsUrl = `${geoServerBaseUrl}/geoserver/ugm/wfs`;
    let wfsParams = {
      service: 'WFS',
      request: 'GetFeature',
      typeName: 'ugm:meteo_station',
      outputFormat: 'application/json',
      viewparams: `id_planta:${this.user.getIdPlanta()}`
    };
  
    const response = await fetch(wfsUrl + L.Util.getParamString(wfsParams));
    const data = await response.json();
    if (!data.features) {
      return 
    }
  
    this.stationMarkers = []; 

    const layer = L.geoJSON(data, {
      pointToLayer: (feature, latlng) => {
        const icon = L.icon({iconUrl: "./assets/icons/meteo_station.png", iconSize: [32,32]});
        const marker = L.marker(latlng, {icon: icon});
        marker.bindPopup(gf.formatMeteoStationPopup(feature.properties, this.translate));
        this.stationMarkers.push(marker);
        marker.getPopup().on('remove', e => {
          this.selectedMeteoStation = null;
        });
  
        return marker;
      },
      onEachFeature: (feature, layer) => {
        layer.on('click', e => {
          this.selectedMeteoStation = feature.properties.station_id;
          this.amplitude.sendEvent("Clicou Estação", {"Estação": this.amplitude.resourceName['meteo_station'],"Nome": feature.properties.station_name,"ID": feature.properties.station_id,"Origem": "Mapa"});
        });
      }
    });
  
    return {
      'groupId': 82,
      'groupTitle': 'MAP.WEATHER_STATIONS',
      'icon': 'dew_point',
      'layersList': [{
        'legend': "MAP.VIRTUAL_STATIONS",
        'layer': layer,
        'active': false
      }]
    };
  }
  
///////////////////////////////////////////////////////

  createCameraTrapWfsLayer(idPlanta: number){
    const camTrapjson = L.geoJSON(null, {
      pointToLayer: (feature, latlng) => {
        let icon = L.icon({iconUrl: "./assets/icons/camera_trap.png", iconSize: [32,32]});
        let marker = L.marker(latlng, {icon: icon});
        marker.bindPopup(gf.formatCameraTrapPopup(feature.properties));
          return marker
        },

    });

    let wfsUrl = `${geoServerBaseUrl}/geoserver/ugm/wfs`;
    let wfsParams = {
      service: 'WFS',
      request: 'GetFeature',
      typeName: 'ugm:camera_trap',
      outputFormat: 'application/json',
      viewparams: `id_planta:${idPlanta}`,

    };
    fetch(wfsUrl + L.Util.getParamString(wfsParams)).then(response => response.json()).then(data => {
      camTrapjson.addData(data);
      });
    return camTrapjson;
  }

  async createCamTrapLayerGroup():Promise<LayerGroupObj>{
    if (!this.user.hasBio() || !this.user.hasCameraTrap()) return
    let meteoLayers:LayerObj[] = [];
     {
      meteoLayers.push(
        {
          'legend':"MAP.TRAP_CAMERA",
          'layer': this.createCameraTrapWfsLayer(this.user.getIdPlanta()),
          'active':false
        }
      )
    }
    return {
      'groupId': 83,
      'groupTitle': 'MAP.TRAP_CAMERA',
      'icon': 'camera',
      'layersList': meteoLayers,
    }

  }

  createDeforestationAlertWmsLayer(idPlanta: number, alertCode: number, workspaceName: string, layerName: string, opacity: number){
    return L.tileLayer.wms(`${geoServerBaseUrl}/geoserver/${workspaceName}/wms`,
      {
        layers: `${workspaceName}:${layerName}`,
        format: 'image/png',
        version: '1.1.0',
        transparent: true,
        opacity: `${opacity}`,
        viewparams: `id_planta:${idPlanta};id_geom:${alertCode}`,
      }
    )
  }

  async createUgmLayerGroup():Promise<LayerGroupObj>{
    let ugmLayersList:LayerObj[] = []

    let rawPlantas = this.user.plantas;

    try {
      ugmLayersList.push(
        {
          'legend': 'Plantas - por contrato',
          'layer': this.createPlantasLayer(rawPlantas),
          'active': false
        }
      )
    } catch(e){
      console.warn(`Error in createPlantasLayer : ${e}`)
    }

    let rawLocais = await this.http.maestroGet('get_locais_ugm')
    let allLocais = this.geoFactoryService.createAllTowersLayer(rawLocais);
    ugmLayersList.push(
      {
        'legend': 'Torres de monitoramento',
        'layer': allLocais,
        'active': false
      }
    )

    return {
      'groupId': 0,
      'groupTitle': 'umgrauemeio',
      'icon': 'public',
      'layersList': ugmLayersList
    }
  }

  createViewshedLayerGroup(locaisMonitoramento):LayerGroupObj{

    let viewshedOverlays:LayerObj[] = [];

    let deteccao_automatica = this.createClientWmsLayer(this.user.getIdPlanta(), 'ugm', 'deteccao_automatica', 1);
    viewshedOverlays.push({
      'legend':'MAP.LIMIT_20KM',
      'layer': deteccao_automatica,
      'active': false
    });

    //Apenas plantas com 2 ou mais torres de monitoramento. Plantas que tem 1 torre o viewhshed da planta = viewshed do local.
    if (locaisMonitoramento.length >= 2){
      let viewshed_planta = this.createClientWmsLayer(this.user.getIdPlanta(), 'ugm', 'viewshed_planta', 1);
      viewshedOverlays.push({
        'legend':'MAP.ALL_CAMERAS',
        'layer': viewshed_planta,
        'active': false
      })
    }

    for (var local of locaisMonitoramento){
      let viewshed_local = L.tileLayer.wms(`${geoServerBaseUrl}/geoserver/ugm/wms`,
        {
          layers: 'ugm:viewshed_local',
          format: 'image/png',
          version: '1.1.0',
          transparent: true,
          opacity: 1,
          viewparams: `id_local:${local.properties.id_local}`
        }
      );
      viewshedOverlays.push({
        'layer': viewshed_local,
        'legend': local.properties.nome_local,
        'active': false,
      });
    }

    return {
      'groupId': 7,
      'groupTitle': "MAP.VISIBILITY_MAP",
      'icon': 'visibility',
      'layersList': viewshedOverlays
    }
  }

  createRasterLayer(layerName, workspace='ugm'){
    return L.tileLayer.wms(`${geoServerBaseUrl}/geoserver/${workspace}/wms`,
      {
        format: 'image/png',
        version: '1.1.0',
        layers: `${workspace}:${layerName}`,
        transparent: true,
      }
    )
  }

  async createRiskLayerGroup():Promise<LayerGroupObj>{

    let layersList:LayerObj[] = []

    let customRiskSource = await this.http.maestroGet(`get_risk_layers/${this.user.getIdPlanta()}`);
    this.riskLayers = customRiskSource

    for (const r of customRiskSource) {
      layersList.push(
        {
          'legend': `LAYERS.${r.layer_type}`,
          'layer': this.createRasterLayer(r.layer, 'risk'),
          'active': false
        },
      )
    }


    return {
      'groupId': 5,
      'groupTitle': "MAP.FIRE_RISK",
      'icon': 'warning',
      'layersList': layersList
    }
  }


  createGwisRiskLayerGroup():LayerGroupObj{

    let layersList:LayerObj[] = []

    let fwi = L.tileLayer.wms('https://ies-ows.jrc.ec.europa.eu/gwis',
      {
        version: '1.3.0',
        format: 'image/png',
        transparent: true,
        layers: `ecmwf.fwi`,
      })
    let mark5 = L.tileLayer.wms('https://ies-ows.jrc.ec.europa.eu/gwis',
      {
        version: '1.3.0',
        format: 'image/png',
        transparent: true,
        layers: `ecmwf.mark5.fdi`,
      })

      let fuel_map = L.tileLayer.wms('https://ies-ows.jrc.ec.europa.eu/gwis',
      {
        version: '1.3.0',
        format: 'image/png',
        transparent: true,
        layers: `fuel_map`,
      })

    layersList.push(
      {
        'legend':'FWI',
        'layer': fwi,
        'active': false
      },
      {
        'legend':'Mark5',
        'layer': mark5,
        'active': false
      },
      {
        'legend':'Material combustivel',
        'layer': fuel_map,
        'active': false
      }
    )

    return {
      'groupId': 81,
      'groupTitle': this.pt?'Risco de incêndio - outros':'Fire Danger',
      'icon': 'warning',
      'layersList': layersList
    }
  }

  createInfraHumanaLayerGroup():LayerGroupObj{

    let infraLayersList:LayerObj[] = []

    let ferrovia_osm = L.tileLayer.wms(`${geoServerBaseUrl}/geoserver/ugm/wms`,
      {
        layers: 'ugm:ferrovia_osm',
        format: 'image/png',
        version: '1.1.0',
        transparent: true
      }
    );
    infraLayersList.push(
      {
        'legend': 'Ferrovias',
        'layer': ferrovia_osm,
        'active': false
      }
    )

    let linha_transmissao_onse = L.tileLayer.wms(`${geoServerBaseUrl}/geoserver/ugm/wms`,
      {
        layers: 'ugm:linha_transmissao_onse',
        format: 'image/png',
        version: '1.1.0',
        transparent: true
      }
    );
    infraLayersList.push(
      {
        'legend': 'Linhas de transmissão',
        'layer': linha_transmissao_onse,
        'active': false
      }
    )

    let uf_br = L.tileLayer.wms(`${geoServerBaseUrl}/geoserver/ugm/wms`,
      {
        layers: 'ugm:uf_br',
        format: 'image/png',
        version: '1.1.0',
        transparent: true
      }
    );
    infraLayersList.push(
      {
        'legend': 'Estados do Brasil',
        'layer': uf_br,
        'active': false
      }
    )

    let municipio_br = L.tileLayer.wms(`${geoServerBaseUrl}/geoserver/ugm/wms`,
    {
      layers: 'ugm:municipio_br',
      format: 'image/png',
      version: '1.1.0',
      transparent: true
    }
  );
  infraLayersList.push(
    {
      'legend': 'Municípios do Brasil',
      'layer': municipio_br,
      'active': false
    }
  )

    return {
      'groupId': 19,
      'groupTitle': this.pt?'Infraestrutura':'Infrastructure',
      'icon': 'home',
      'layersList': infraLayersList
    }
  }

  createPreservationLayerGroup():LayerGroupObj{

    let preservationLayersList:LayerObj[] = []

    let bioma_br = this.createWmsLayer('ugm','bioma_br', 1)
    preservationLayersList.push(
      {
        'legend': 'Biomas',
        'layer': bioma_br,
        'active': false
      }
    )

    return {
      'groupId': 20,
      'groupTitle': this.pt?'Preservação':'Preservation',
      'icon': 'forest',
      'layersList': preservationLayersList
    }
  }

  async createLinksLayerObj(isUgm=false){
    let links = await this.http.maestroGet(`get_links_planta/${this.user.getIdPlanta()}`) || [];
    if(!links.length) return null;
    try {
      let linksGroup = []

      links.forEach(l => {
        let pts = [
          [l.lat_origem, l.lon_origem],
          [l.lat_destino, l.lon_destino],
        ]

        let linkColor = 'white';

        let externalLine = L.polyline(pts, {color: 'black', weight: 5, opacity: 0.7});
        let internalLine = L.polyline(pts, {color: linkColor, weight: 2});

        if (isUgm){
          externalLine.bindPopup(gf.formatLinkInfo(l))
          internalLine.bindPopup(gf.formatLinkInfo(l))
        }

        linksGroup.push(externalLine, internalLine)
      });
      return {
        'legend': 'MAP.RADIO_LINKS',
        'layer': L.layerGroup(linksGroup),
        'active': isUgm? true:false
      }

    } catch (error) {
      this.logging.logERROR(`createLinksLayerObj ${error}`,error);
      console.log("❌ Erro em get_links");
      return null
    }

  }


  async createFleetTrackingGroup():Promise<LayerGroupObj>{

    let geojson_brigadas = await this.http.maestroGet(`get_brigadas_geojson/${this.user.getIdPlanta()}`)
    if(!geojson_brigadas.features)
    return {
      'groupId': 8,
      'groupTitle': 'MAP.FLEET_TRACKING',
      'icon': 'fire_truck',
      'layersList': []
    }

    this.brigadeMarkers = []

    let layer = L.geoJSON(geojson_brigadas, {
      pointToLayer: (feature, latlng) => {
        let icon = L.icon({iconUrl: brigadeIconMap[feature.properties.id_tipo_brigada], iconSize: [32,32]});

        let marker = L.marker(latlng, {icon: icon});
        marker.bindPopup(gf.formatBrigadeInfo(feature.properties, this.translate));
        gf.addActivatedPopup({
          tipo: 'Brigade',
          marker: marker,
          properties: feature.properties
        });

        this.brigadeMarkers.push(marker);

        marker.getPopup().on('remove', e => {
          this.selectedBrigadeId = null;
        });

        return marker
      },
      onEachFeature: (feature, layer) => {
        layer.on('click', e => {
          this.selectedBrigadeId = feature.properties.id_brigada;
          this.amplitude.sendEvent("Clicou Recurso", {"Recurso": this.amplitude.resourceName['brigade'], "Tipo": feature.properties.nome_tipo_brigada, "Nome": feature.properties.nome_brigada, "ID": feature.properties.id_brigada, "Origem": "Mapa"});
        });
      }
    });

    return {
      'groupId': 8,
      'groupTitle': "MAP.FLEET_TRACKING",
      'icon': 'fire_truck',
      'layersList': [{
        'legend':"MAP.BRIGADES",
        'layer': layer,
        'active': false
      }]
    }
  }

  async createBioOverlay():Promise<LayerGroupObj>{

    if (!this.user.hasBio()) return

    const oncas = [ {id: 1, nome: 'Guató'}, {id: 2, nome: 'JouJou'} ]; // só pq wfs c/ string tava dando cao
    try{
      let bioLayerList = [];
      for (let onca of oncas){
        let layer = this.createBioWfsLayer(onca.id, onca.nome);
        bioLayerList.push({
          'legend': onca.nome,
          'layer': layer,
          'active': false,
        })
      };
      return {
        'groupId': 13,
        'groupTitle': 'MAP.BIODIVERSITY',
        'icon': 'pets',
        'layersList': bioLayerList,
      }
    } catch (e) {
      this.logging.logERROR(`createBioOverlay ${e}`,e);
      console.log('❌ BioGeoJson');
      return null
    }
  }

  async createHistoricalHotspotsLayerGroup():Promise<LayerGroupObj>{
    if (!this.user.hasHistoricalData()) return

    let hotspotsLayerList = []
    for (let year = 2023; year >= 2018; year--) {
      let hotspotLayer = await this.createClientYearWmsLayer(this.user.getIdPlanta(), year, 'sat', 'hotspot_history', 1);
      hotspotsLayerList.push({
        'legend': String(year),
        'layer': hotspotLayer,
        'active': false
      })
    }

    return {
      'groupId': 9,
      'groupTitle': 'MAP.HOTSPOT_HISTORY',
      'icon': 'satellite_alt',
      'layersList': hotspotsLayerList,
      'info': true,
      'infoHTML': "MAP.HOTSPOT_HISTORY_GROUP_INFO",
      'showInfo': false,
    }
  }

  async createCicatrizesLayerGroup():Promise<LayerGroupObj>{
    if (!this.user.hasHistoricalData()) return

    await this.getScarStats();
    if (!this.scarStats) return

    let scarsLayerList = []

    let scarLayer = await this.createClientWmsLayer(this.user.getIdPlanta(), 'geo', 'cicatriz', 1)
    scarsLayerList.push({
      'legend': "MAP.SCAR_ALL_YEARS_LAYER",
      'layer': scarLayer,
      'active': false
    });

    let distinctYears = Array.from(new Set(this.scarStats.map(item => item.ano))).sort((a, b) => b - a);
    for (const year of distinctYears) {
      let scarYearLayer = await this.createClientYearWmsLayer(this.user.getIdPlanta(), year, 'geo', 'cicatriz', 1)
      scarsLayerList.push({
        'legend': String(year),
        'layer': scarYearLayer,
        'active': false
      });
    }

    // Camada customizada com dados próprios da BP Bioenergy (geo.cicatriz, id_base_sig = 296)
    if ([61, 62, 63].includes(this.user.getIdPlanta())) {

      //WFS
      const scarRecurrenceBpBioenergyGeoJSON = L.geoJSON(null, {
        onEachFeature: (feature, layer) => { 
          layer.bindPopup(formatScarRecurrenceBPBioenergy(feature.properties, this.translate));
          addActivatedPopup({
            tipo: 'Scar BP Bioenergy',
            marker: layer,
            properties: feature.properties
          });
          let style = {
            "weight": 0.5,
            "color": '#000000',
            "opacity": 1, //opaque stroke
            "fillColor": feature.properties.fill_color,
            "fillOpacity": 1  
          }
          layer.setStyle(style)
        }
      });

      let scarRecurrenceBpBioenergyLayer = await this.createClientWfsLayer(this.user.getIdPlanta(), 'geo', 'cicatriz_bp_bioenergy', scarRecurrenceBpBioenergyGeoJSON, null);
      scarsLayerList.push({
        'legend': "MAP.SCAR_RECURRENCE_LAYER",
        'layer': scarRecurrenceBpBioenergyLayer,
        'active': false
      });
    }

    return {
      'groupId': 15,
      'groupTitle': 'MAP.SCAR_GROUP',
      'icon': 'contrast',
      'layersList': scarsLayerList,
      'info': true,
      'infoHTML': "MAP.SCAR_GROUP_INFO",
      'showInfo': false,
    }
  }

  async getScarStats(){
    this.scarStats = await this.http.maestroGet(`get_cicatriz_stats/${this.user.getIdPlanta()}`);
  };

// DEFORESTATION
  async createDeforestationOverlays():Promise<LayerGroupObj>{
    if (!this.user.hasDeforestation()) return

    let deforestationDeterAmazonia = this.createInpeTerraBrasilisWmsLayer('deter-amz', 'deter_amz');
    let deforestationDeterCerrado = this.createInpeTerraBrasilisWmsLayer('deter-cerrado-nb', 'deter_cerrado')
    let deforestationDeterLayerGroup = L.layerGroup([deforestationDeterAmazonia, deforestationDeterCerrado]);

    let deforestationProdes = this.createInpeTerraBrasilisWmsLayer('prodes-brasil-nb', 'prodes_brasil');

    const deforestationAlertsGeojson = L.geoJSON(null, {
      style: {
        color: 'red',
        weight: 2
      },
      onEachFeature: function(feature, layer) {
        layer.bindPopup(gf.formatDeforestationPopUp(feature.properties));
      },
      pane: 'cicatrizes',
    });
    let deforestationAlerts = await this.createClientWfsLayer(this.user.getIdPlanta(), 'ugm', 'desmatamento_mapbiomas', deforestationAlertsGeojson);
    let layersList = [
      {
        'legend':'DEF.HISTORICAL_ALERTS',
        'layer': deforestationAlerts,
        'active': false
      },
      {
        'legend':'DETER',
        'layer': deforestationDeterLayerGroup,
        'active':false
      },
      {
        'legend':'PRODES',
        'layer':deforestationProdes,
        'active':false
      },
    ]

    // Camada corte raso, planta Westrock
    if (this.user.getIdPlanta() === 156){
      let corteRasoLayer = this.createClientWmsLayer(this.user.getIdPlanta(), 'ugm', 'westrock_corte_raso', 1);
      layersList.push({
        'legend': "Corte raso",
        'layer': corteRasoLayer,
        'active': false
      });
    }

    return {
      'groupId': 16,
      'groupTitle': "DEF.HISTORICAL_ALERTS",
      'icon': 'park',
      'layersList': layersList
    }
  }

  async createPoiLayerGroup():Promise<LayerGroupObj>{

    await this.user.updatePanteraData();
    let poiGeoJson = this.user.getDadosPantera('pontos_interesse');

    if(!poiGeoJson.features)
    return {
      'groupId': 11,
      'groupTitle': 'MAP.POINTS_OF_INTEREST',
      'icon': 'pin_drop',
      'layersList': []
    }

    let poiType = await this.http.maestroGet('get_tipo_poi');
    poiType.forEach(poi_type => {
      const poiTypeNameFront = this.translate.instant(`POI.${poi_type.id_tipo_poi}`) || '';
      poi_type['legend2'] = poiTypeNameFront;
    });
    poiType.sort((p1, p2) => p1.legend2.localeCompare(p2.legend2));

    let poiOverlay = []
    this.poiMarkers = []

    for (let poi_type of poiType) {

      let poi_features = poiGeoJson.features.filter(p => p.properties.id_tipo_poi == poi_type.id_tipo_poi) || [];
      if (!poi_features.length) continue;

      let poi_collection = {
        type:'FeatureCollection',
        features: poi_features
      }

      let layer = L.geoJSON(poi_collection, {
        pointToLayer: (feature, latlng) => {

          let icon = L.icon({
            iconSize: [28, 28],
            iconUrl: icon_dir + poi_type.icon_path
          });

          let marker = L.marker(latlng, {icon: icon});
          marker.bindPopup(gf.formatPoiInfo(feature.properties));

          this.poiMarkers.push(marker);

          marker.getPopup().on('remove', e => {
            this.selectedPoi = null;
          });

          return marker;
        },
        onEachFeature: (feature, layer) => {
          layer.on('click', e => {
            this.selectedPoi = feature.properties.uuid_poi;
            this.amplitude.sendEvent("Clicou Recurso", {"Recurso": this.amplitude.resourceName['poi'], "Tipo": feature.properties.nome_tipo_poi, "Nome": feature.properties.nome_poi, "ID": feature.properties.uuid_poi, "Origem": "Mapa"})
          });
        }
      })

      poiOverlay.push(
      {
        'legend':`POI.${poi_type.id_tipo_poi}`,
        'layer': layer,
        'active': false,
      })

    }

    return {
      'groupId': 11,
      'groupTitle': 'MAP.POINTS_OF_INTEREST',
      'icon': 'pin_drop',
      'layersList': poiOverlay
    }
  }

}
