<style>
  main{
  display: flex;
  flex-direction: column;
}

.left-forms {
margin-right: 1.5rem;
}
</style>

<div class="report-dialog-wrapper">
  <mat-dialog-content>
    <main>
      <h2>{{ 'REP.CREATE_REPORT' | translate }}</h2>
  
      <div>
  
        <mat-form-field appearance="outline" class="left-forms">
          <mat-label>{{ 'MAP.LATITUDE' | translate }}</mat-label>
          <input matInput [(ngModel)]="lat" type="number" min="-90" max="90" id="lat"/>
        </mat-form-field>
  
        <mat-form-field appearance="outline" color='primary'>
          <mat-label>{{ 'MAP.LONGITUDE' | translate }}</mat-label>
          <input matInput [(ngModel)]="lon" type="number" min="-180" max=180 id="lon"/>
        </mat-form-field>
      </div>
  
      <div>
        <mat-form-field appearance="outline" class="left-forms">
          <mat-label>{{ 'MAP.DETECTION_SOURCE' | translate }}</mat-label>
          <mat-select
            [(ngModel)]="rData.origem_deteccao">
            @for (o of origens; track o.id_origem_deteccao) {
              <mat-option [value] = "o.id_origem_deteccao">
                {{origensTranslated[o.id_origem_deteccao]}}
              </mat-option>
            }
          </mat-select>
        </mat-form-field>
  
        <mat-form-field appearance="outline">
          <mat-label>{{ 'MAP.FIRE_CAUSE' | translate }}</mat-label>
          <mat-select
            [(ngModel)]="rData.causa_incendio">
            @for (c of causas; track c.id_causa_incendio) {
              <mat-option [value] = "c.id_causa_incendio">
                {{causasTranslated[c.id_causa_incendio]}}
              </mat-option>
            }
          </mat-select>
        </mat-form-field>
      </div>
  
      <div>
  
        <mat-form-field appearance="outline" class="outlineForm left-forms">
          <mat-label>{{ 'MAP.DETECTION_TIME' | translate }}</mat-label>
          <input matInput type="datetime-local" [(ngModel)]="datetime_deteccao">
        </mat-form-field>
  
      </div>
  
      <div class="fx-row-space-around-center">
        <button mat-raised-button color='primary'
          [disabled]='!lat || !lon || !datetime_deteccao || (lat < -90) || (lat > 90) || (lon < -180) || (lon > 180)'
          [mat-dialog-close]="true"
          (click)='postReportData()'>
          {{ 'REP.CREATE_REPORT' | translate }}
        </button>
  
        <button mat-raised-button
          [mat-dialog-close]="false">
          {{ 'MAP.CANCEL' | translate }}
        </button>
  
      </div>
    </main>
  </mat-dialog-content>
</div>