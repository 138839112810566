import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'user-terms-dialog',
  templateUrl: 'user-terms-dialog.html',
})

export class UserTermsDialog{
  constructor(@Inject(MAT_DIALOG_DATA) public data: any) 
  {
    this.language = data.lang;
    this.userTermsUrl = `${environment.api}/get_user_terms/${this.language}`
  }
  
  private language: string;
  protected userTermsUrl: string; 

}

