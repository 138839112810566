import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA , MatDialogRef} from '@angular/material/dialog';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { map, Observable, startWith } from 'rxjs';


@Component({
  selector: 'add-text-dialog',
  templateUrl: 'add-text-dialog.html',
})
export class addTextDialog implements OnInit {
  commentField: UntypedFormGroup;
  characterCount: number = 0;
  maxCharacters: number = 3000;

  constructor(
    public dialogRef: MatDialogRef<addTextDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private fb: UntypedFormBuilder
  ) {
    this.commentField = this.fb.group({
      comentarios: [this.data.comentarios, [Validators.required, Validators.maxLength(this.maxCharacters)]]
    });

    this.commentField.get('comentarios').valueChanges.subscribe(value => {
      if (value.length > this.maxCharacters) {
        this.commentField.get('comentarios').setValue(value.substring(0, this.maxCharacters), { emitEvent: false });
        this.characterCount = this.maxCharacters;
      } else {
        this.characterCount = value.length;
      }
    });
  }

  ngOnInit(): void {
    this.characterCount = this.commentField.get('comentarios').value.length;
  }

  onSubmit() {
    if (this.commentField.valid) {
      const comentarios = this.commentField.value.comentarios;
      this.dialogRef.close(comentarios);
    }
  }
}


interface LocalCliente {
  id_geom: number;
  nome_geom: string;
}


@Component({
  selector: 'select-perdas-dialog',
  templateUrl: 'select-perdas-dialog.html',
})
export class SelectPerdasDialog {
  idTipoDano: number[];
  unidades: { name: string, unit: string }[];
  locais: LocalCliente[];
  filteredLocais: Observable<LocalCliente[]>;
  editarDanos: boolean
  lossForm: UntypedFormGroup;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public fb: UntypedFormBuilder) {
    this.idTipoDano = data.idTipoDano;
    this.unidades = data.unidades;
    this.locais = data.locais;
    this.editarDanos = data.editarDanos
    this.lossForm = this.fb.group({
      id_tipo_dano: [data.id_tipo_dano || null, [Validators.required]],
      unit: [data.unidade || '', [Validators.required]],
      value: [data.valor || '', [Validators.required, Validators.min(0.01)]],
      nome_local: [data.nome_local || null,[this._validLocalValidator.bind(this)]]
    });
    
  
    this.filteredLocais = this.lossForm.controls['nome_local'].valueChanges.pipe(
      startWith(''),
      map(value => this._filterLocais(value || ''))
    );
  }

  private _validLocalValidator(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) return null; 
    const isValid = this.locais.some(local => local.nome_geom === control.value);
    return isValid ? null : { invalidLocal: true };
  }
  

  private _filterLocais(value: string): LocalCliente[] {
    const filterValue = value.toLowerCase();
    return this.locais.filter(local => local.nome_geom.toLowerCase().includes(filterValue));
  }
}


@Component({
  selector: 'lat-lon-dialog',
  templateUrl: 'lat-lon-dialog.html',
})
export class LatLonDialog {

  public latlngForm : UntypedFormGroup;
  public latlng = {
    lat:null,
    lng:null
  };

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: any,
    private fb: UntypedFormBuilder,
  ) {

    this.latlngForm = this.fb.group({
      lat:['',[
        Validators.required,
        Validators.min(data.bounds.min_y_talhao),
        Validators.max(data.bounds.max_y_talhao)
      ]],
      lng:['',[
        Validators.required,
        Validators.min(data.bounds.min_x_talhao),
        Validators.max(data.bounds.max_x_talhao)
      ]],
    })
    console.log('LimitesPlanta', data.bounds);

  }
}

@Component({
  selector: 'propagation-dialog',
  templateUrl: 'propagation-dialog.html',
})
export class PropagationDialog {

  public lat:number;
  public lon:number;
  public wind_speed:number;
  public wind_deg: number;
  public t_sim_min: number;

  constructor() {}
}