import { Component, OnInit, OnDestroy, HostListener, Input} from '@angular/core';
import { HttpService } from '../../services/http.service';
import { UserDataService } from '../../services/user-data.service';
import { GeoService } from '../../services/geo.service';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { FullScreenDialog } from 'src/app/shared/dialogs/cameras-dialog/cameras-dialog';


@Component({
  selector: 'app-camera',
  templateUrl: './camera.component.html',
  styleUrls: ['./camera.component.scss']
})
export class CameraComponent implements OnInit, OnDestroy{
  
  @Input() public cam_id: number;
  @Input() public expanded: boolean=false;
  public cameras:any[];
  public loadingImage:boolean=false;
  public changingCamera:boolean=true;
  
  private timeout_obj: any;
  private streamIntervalSec:number=3000;
  public image64:string;
  public imgTime:Date;
  
  public selectedId:number=null;

  public hasOnlineCamera = true;
  private stopFetchingImg = false;
  
  public towerRadiusKm: number = 20;
  private isActive: boolean = true;
  
  constructor(
    private http:HttpService,
    private geo:GeoService,
    public user:UserDataService,
    private dialog: MatDialog,
  ) {}
  
  async ngOnInit(){
    await this.getCameras();
    this.hasOnlineCamera = await this.getFirstOnlineCamera();
  }

  async getCameras(){
    this.cameras = await this.http.maestroGet(`get_cameras_planta/${this.user.getIdPlanta()}`)
  }

  async getFirstOnlineCamera(){
    for (let camera of this.cameras){
      let b64response = await this.http.camsGet('b64_streaming', [`${camera.id}`]);
      if (b64response?.base64_image){
        if (!this.stopFetchingImg){
          this.selectedId = camera.id;
          this.changeCamera();
        }
        return true;
      }
    }
    this.changingCamera = false;
    return false;
  }

  async updateStream(){
    this.timeout_obj = setTimeout(async () => {
      if (!this.isActive) return;
      this.loadingImage= true;

      const response = await this.http.camsGet('b64_streaming', [`${this.selectedId}`])
      if (response?.base64_image){
        this.image64 = response?.base64_image
        this.imgTime = new Date()
      } else {
        this.image64 = null
        this.imgTime = null
      }
      
      this.loadingImage = false;
      this.updateStream();
    },
    this.streamIntervalSec)
  }

  async selectCamera(){
    let cam = this.cameras.filter(x=>x.id == this.selectedId)[0]
    this.geo.centerOnObject({ lat: cam.lat, lng: cam.lon })
    this.stopFetchingImg = true;
    await this.changeCamera();
  }

  async changeCamera(){
    this.changingCamera = true;
    await this.updateStream();
    this.changingCamera = false;
  }

  ngOnDestroy(){
    this.isActive = false;
    clearTimeout(this.timeout_obj);
  }

  openFullScreen(){
    // OBS ta errado chamar a varivel de cam_id e salvar o obj todo da camera
    if (!this.cam_id){
      this.cam_id = this.cameras.filter(x=>x.id == this.selectedId)[0]
    }
    console.log('cam id:', this.cam_id);
    let dialogConfig: MatDialogConfig = {
      data: { cam_id: this.cam_id },
      panelClass: 'app-full-bleed-dialog',
      height: '85vw'
    }
    const dialogRef = this.dialog.open(FullScreenDialog , dialogConfig);
    dialogRef.afterClosed().subscribe(() => this.expanded = false);
  }

}
