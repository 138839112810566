import { Component, Output, EventEmitter } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { UserDataService } from '../../services/user-data.service';
import { HttpService } from 'src/app/services/http.service';
import { AmplitudeService } from 'src/app/services/amplitude.service';
import { MatDialog } from '@angular/material/dialog';
import { InfoDialog } from "../../shared/dialogs/info-dialog/info-dialog";
import { TranslateService } from '@ngx-translate/core';
import { GeoService } from '../../services/geo.service';
import { MeteoService } from 'src/app/services/meteo.service';

const METEO_STATIONS_LAYER_GROUP_ID = 82
@Component({
  selector: 'app-create-stations',
  templateUrl: './create-stations.component.html',
  styleUrl: './create-stations.component.scss'
})
export class CreateStationsComponent {
  @Output() notifyParent: EventEmitter<any> = new EventEmitter();
  private newStation: {};

  public stationName: string;
  public stationNameFc = new UntypedFormControl('', [
    Validators.required,
    Validators.pattern(String.raw`^(?!\s*$).+`)
  ]);
  public lat: number;
  public lon: number;

  constructor(
    private user: UserDataService,
    private http: HttpService,
    private dialog: MatDialog,
    private amplitude: AmplitudeService,
    private translate: TranslateService,
    public geo:GeoService,
    private meteo: MeteoService
  ) { }

  ngOnInit(): void {
    this.setupMapClickListener();
  }

  ngOnDestroy(): void {
    window.removeEventListener('mapClick', this.mapClickListener);
  }

  setupMapClickListener() {
    window.addEventListener('mapClick', this.mapClickListener);
  }

  mapClickListener = (event: any) => {
    const customEvent = event as CustomEvent;
    this.lat = customEvent.detail.lat.toFixed(5);
    this.lon = customEvent.detail.lng.toFixed(5);
  }
  

  async postStation(){
    this.stationName = this.stationName.replace(/\s+/g, ' ').trim()

    this.newStation = {
      'id_planta': this.user.getIdPlanta(),
      'station_name': this.stationName,
      'lat': this.lat,
      'lon': this.lon,
    };

    for (let fc of [this.stationNameFc]) { fc.reset() };
    this.lat = null;
    this.lon = null;

    let response = await this.http.maestroPost('post_meteo_station', this.newStation);
    if (response?.status){
      this.dialog.open(InfoDialog, {
        data: {text:`${this.translate.instant("MAP.WEATHER_STATION_REGISTERED_DIALOG")}`}
      });
      this.amplitude.sendEvent('Cadastrou Estação Meteorológica', {"Nome": this.newStation['station_name']});
      this.notifyParent.emit(this.newStation);
      await this.user.updatePanteraData();
      
      await this.geo.updateMeteoStationGroup();
      let groupIndex = this.geo.layerGroupList.findIndex(l => l.groupId === METEO_STATIONS_LAYER_GROUP_ID);
      let layerIndex = this.geo.layerGroupList[groupIndex].layersList.findIndex(l => l.legend === `MAP.VIRTUAL_STATIONS`);
      this.geo.activateLayer(groupIndex, layerIndex);
      this.meteo.getValidMeteo();
    }
    else{
      this.dialog.open(InfoDialog, {
        data: {text:`${this.translate.instant("MAP.ERROR_ON_REGISTERING_STATION_DIALOG")}`}
      });
      console.warn(`${this.translate.instant("MAP.ERROR")}: ${response?.msg}`)
    }

  }
}
