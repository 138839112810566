<div class="fx-row-space-around-start">
  <div id="dynamic-sidebar">
    <span class="expand-all" (click)="toggleAll()">{{
      !expandAll ? 'Expand All' : 'Close All'
    }}</span>
    <div class="wrapper element-shadow">
      @for (item of deployService.result; track item; let i = $index) {
        <div class="group">
          @if (item.submenu.length) {
            <a
              (click)="ddToggle(i)"
              class="light-blue-bg"
              >{{ item.linkText }}</a
              >
            }
            @else {
              <a
                (click)="ddToggle(i)"
                class="light-blue-bg"
                >{{ item.linkText }}</a
                >
              }
              @if (item.submenu.length) {
                <div
                  class="caret"
                  [ngClass]="{ 'rotate-caret': item.menu }"
                  >
                  &#9660;
                </div>
              }
              <div class="sub-menu" [ngClass]="{ 'show-menu': item.menu }">
                @for (menu of deployService.result[i].submenu; track menu) {
                  <ul>
                    <li>
                      <a (click)="sendDataForm(menu.data, menu.childtext)"
                      >{{ menu.childtext }}</a>
                    </li>
                  </ul>
                }
              </div>
            </div>
          }
        </div>
      </div>


      <div id="parameters-panel">


        @if (deployService.tableType=='lista') {
          <table border="2">
            <thead>
              <tr>
                @for (header of deployService.getHeaders(); track header) {
                  <th>{{header}}</th>
                }
                <th>portainer_install</th>
              </tr>
            </thead>
            <tbody>
              @for (item of deployService.dataTable; track item) {
                <tr>
                  @for (key of deployService.getHeaders(); track key) {
                    <td>{{item[key]}}</td>
                  }
                  <td><button (click)="copyToClipboard(item)">Copy to clipboard</button></td>
                </tr>
              }
            </tbody>
          </table>
        }


        @if (deployService.tableType=='item') {
          <table border="2">
            <tr>
              <th>Key</th>
              <th>Value</th>
            </tr>
            @for (item of deployService.dataTable | keyvalue; track item.key) {
              <tr>
                <td>{{item.key}}</td>
                <td style="max-width:400px;">{{item.value}}</td>
              </tr>
            }
          </table>
        }

        @if (checkDataType()) {
          <div class="pcs-dash flex">
            @for (item of deployService.dataTable; track item.id_endpoint) {
              <div class="pcs-display">
                <p class="header-pcs">({{ item.id_endpoint }}) {{ item.pc_name }}
                  @if (deployService.endpointStatus[item.id_endpoint]) {
                    <i class="material-icons md-green"
                      matTooltip= "Endpoint online"
                      > &#xe3a6; </i >
                    }
                    @else {
                      <i class="material-icons md-red"
                        matTooltip= "Endpoint offline"
                        > &#xe3a6; </i >
                      }
                    </p>
                    <div>
                      <ul class="reports-container">
                        @for (stack of item.stacks; track stack) {
                          <li>
                            <input type="checkbox" class="k-checkbox"
                              [checked]="checkValueBox(stack)"
                              [(value)]="deployService.modules[stack]"
                              (change)="onChkBoxChange($event,stack)" />
                              {{stack}} <span style="font-size: x-small;">{{ deployService.getVersion(stack) }}
                              @if (deployService.modulesImages[stack]) {
                                <i class="material-icons md-green"
                                  matTooltip= "Imagem está disponível no cliente"
                                  > &#xe5ca; </i >
                                }
                                @if (!deployService.modulesImages[stack]) {
                                  <i class="material-icons md-red"
                                    matTooltip= "Necessário baixar imagem no cliente"
                                    > &#xe5cd; </i >
                                  }
                                </span>
                              </li>
                            }
                          </ul>
                        </div>
                      </div>
                    }
                  </div>
                }


                @if (checkDataType()) {
                  <div >
                    <div style="padding-bottom:25px;">
                      <button mat-icon-button
                        matTooltip='Clique para atualizar os dados cliente'
                        (click)='atualizarDadosCliente()'
                        >
                        <span class="radio-buttono"> <mat-icon>cloud_download</mat-icon> Atualizar dados do cliente remotamente  @if (deployService.runComplete) {
                        <span> &#9989;</span>
                      }</span>
                    </button>
                    </div>
                    <div class="container">
                      <div class="box">
                        <p class="info-p">Versao Pantera:{{deployService.panteraDetect['pantera_version']}} &#10145; {{deployService.panteraDetect['wanted_pantera_version']}}</p>
                        <p class="info-p">Versao Modelo: {{deployService.panteraDetect['installed_model']}} &#10145; {{deployService.panteraDetect['id_modelo']}}</p>
                        <p class="info-p">Tipo de deploy: {{deployService.panteraDetect['deploy_type']}} 	</p>
                      </div>
                      <div class="box">
                        <ul>
                          @for (item of deployService.selectParamsDeploy() | keyvalue; track item.key) {
                            <li>
                              @if (item.key == "central_token"){
                                Ͱ {{ item.key }}: *********
                              } @else {
                                Ͱ {{ item.key }}: {{ item.value }}
                              }
                            </li>
                          }
                        </ul>
                      </div>
                    </div>

                  <div style="padding-bottom:25px;">
                  <button mat-icon-button
                    matTooltip='Clique para baixar as imagens docker'
                    (click)='deployService.pullWantedVersion()'>
                    <span class="radio-buttono"> <mat-icon>download</mat-icon> 1) Baixar imagens antes da instação  @if (checkPullComplete()) {
                    <span> &#9989;</span>
                  }</span>
                </button>
                </div>
                <div>
                  <button mat-icon-button
                    matTooltip='Clique para atualizar o cliente'
                    (click)='deployService.deployWantedVersion()'
                    [disabled]="!checkPullComplete()"
                    >
                    <span class="radio-buttono"> <mat-icon>install_desktop</mat-icon> 2) Realizar instalação da versão desejada  @if (deployService.runComplete) {
                    <span> &#9989;</span>
                  }</span>
                </button>
              </div>
            </div>
          }
          <br>
            <br>
              <br>
                @if (checkDataType()) {
                  <span>Containers existentes em cada endpoint</span>
                  <div class="pcs-dash flex">
                    @for (item of deployService.endpointContainers | keyvalue; track item.key) {
                      <div class="pcs-display">
                        <p class="header-pcs"> {{ deployService.getPCName2(item.key) }} ({{ item.key }}) </p>
                        <div>
                          @if (item.value==false) {
                            <div> Endpoint OFFLINE</div>
                          }
                          @else {
                            <ul class="reports-container">
                              @for (endpoint of item.value; track endpoint) {
                                <li>
                                  {{ endpoint.Names[0] }}:{{ endpoint.Image.split('/')[1]  }}
                                </li>
                              }
                            </ul>
                          }
                        </div>
                      </div>
                    }
                  </div>
                }



              </div>

            </div>
