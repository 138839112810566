@if (!loaded) {
  <app-loader></app-loader>
}

@if (loaded) {
  <main>
    <mat-tab-group id="group" [backgroundColor]="'primary'" [color]="'accent'" (selectedTabChange)="onTabChanged($event)">
      <mat-tab>
        <ng-template mat-tab-label>
          <mat-icon class="tab-icon">terminal</mat-icon>
          Pantera
        </ng-template>
        <div class="tab-content-container">
          <mat-accordion>
            <mat-expansion-panel class="config-panel" expanded>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <mat-icon [ngStyle]="{ color: '#6895D2' }">terminal</mat-icon>
                  <span class='panel-title'>
                    Configurações Deploy
                  </span>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="table-container">
                <table mat-table [dataSource]="pantera_plantas" matSort class="mat-elevation-z8">
                  <ng-container matColumnDef="nome_cliente">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Cliente </th>
                    <td mat-cell *matCellDef="let element"> {{element.nome_cliente}}</td>
                  </ng-container>
                  <ng-container matColumnDef="planta">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Planta </th>
                    <td mat-cell *matCellDef="let element">
                      {{element.nome_planta}} - {{element.id_planta}}
                      @if (element.changed) {
                        <button mat-icon-button
                          matTooltip='Atualizar configurações planta'
                          (click)='updateConfigs(element)'>
                          <mat-icon>cloud</mat-icon>
                        </button>
                      }
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="pantera_version">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Versão instalada </th>
                    <td mat-cell *matCellDef="let element">
                      <div [ngClass]="element.pantera_version != element.wanted_pantera_version? 'red' : 'green'">
                        {{element.pantera_version}}
                      </div>
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="wanted_pantera_version">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Versão desejada </th>
                    <td mat-cell *matCellDef="let element">
                      <mat-form-field appearance="outline">
                        <mat-select
                          [(ngModel)]="element.wanted_pantera_version"
                          (selectionChange)="changedElement($event, element)"
                          >
                          @for (v of versions; track v.version) {
                            <mat-option [value] = "v.version">
                              {{v.version}}
                            </mat-option>
                          }
                        </mat-select>
                      </mat-form-field>
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="id_modelo">
                    <th mat-header-cell *matHeaderCellDef> Modelo desejado </th>
                    <td mat-cell *matCellDef="let element">
                      <mat-form-field appearance="outline">
                        <mat-select
                          [(ngModel)]="element.id_modelo"
                          (selectionChange)="changedElement($event, element)">
                          @for (model of models; track model.id_modelo) {
                            <mat-option [value] = "model.id_modelo">
                              {{model.id_modelo}}
                            </mat-option>
                          }
                        </mat-select>
                      </mat-form-field>
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="installed_model">
                    <th mat-header-cell *matHeaderCellDef> Modelo instalado </th>
                    <td mat-cell *matCellDef="let element">
                      <div [ngClass]="element.id_modelo != element.installed_model? 'red' : 'green'">
                        {{element.installed_model}}
                      </div>
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="threshold">
                    <th mat-header-cell *matHeaderCellDef> Tsh </th>
                    <td mat-cell *matCellDef="let element">
                      <mat-form-field appearance="outline" class="tsh-input">
                        <input matInput type="number" [(ngModel)]="element.model_tsh" (input)="changedElement($event, element)">
                      </mat-form-field>
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="threshold_zoom">
                    <th mat-header-cell *matHeaderCellDef> Tsh zoom </th>
                    <td mat-cell *matCellDef="let element">
                      <mat-form-field appearance="outline" class="tsh-input">
                        <input matInput type="number" [(ngModel)]="element.smoke_tsh_zoom" (input)="changedElement($event, element)">
                      </mat-form-field>
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="backend_api">
                    <th mat-header-cell *matHeaderCellDef> Ip Backend </th>
                    <td mat-cell *matCellDef="let element"> {{element.backend_api}} </td>
                  </ng-container>
                  <ng-container matColumnDef="updated_dt">
                    <th mat-header-cell *matHeaderCellDef> Dados atualizados </th>
                    <td mat-cell *matCellDef="let element"> {{element.updated_dt | date : "dd/MM HH:mm"}} </td>
                  </ng-container>
                  <ng-container matColumnDef="save_img_fp_percent">
                    <th mat-header-cell *matHeaderCellDef> % Save FP's </th>
                    <td mat-cell *matCellDef="let element">
                      {{element.save_img_fp_percent}}
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="frontend_version">
                    <th mat-header-cell *matHeaderCellDef> Frontend </th>
                    <td mat-cell *matCellDef="let element">
                      <mat-form-field appearance="outline">
                        <mat-select
                          [(ngModel)]="element.frontend_version"
                          (selectionChange)="changedElement($event, element)"
                          >
                          @for (v of frontend_versions; track v) {
                            <mat-option [value] = "v">
                              {{v}}
                            </mat-option>
                          }
                        </mat-select>
                      </mat-form-field>
                    </td>
                  </ng-container>
                  <tr mat-header-row *matHeaderRowDef="panteraTableColumns; sticky: true"></tr>
                  <tr mat-row *matRowDef="let row; columns: panteraTableColumns;"></tr>
                </table>
              </div>
            </mat-expansion-panel>
            <mat-expansion-panel class="config-panel">
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <mat-icon>settings_suggest</mat-icon>
                  <span class='panel-title'>
                    Produtos Contratados
                  </span>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <div class="table-container">
                <table mat-table [dataSource]="plantasDataSource" matSort class="mat-elevation-z8">
                  <ng-container matColumnDef="nome_cliente">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Cliente </th>
                    <td mat-cell *matCellDef="let element"> {{element.nome_cliente}}</td>
                  </ng-container>
                  <ng-container matColumnDef="planta">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header> Planta </th>
                    <td mat-cell *matCellDef="let element">
                      {{element.nome_planta}}
                      @if (changedFeatures(element)) {
                        <button mat-icon-button
                          matTooltip='Atualizar funcionalidades'
                          (click)='sendChanges(element)'>
                          <mat-icon>cloud</mat-icon>
                        </button>
                      }
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="fire_mngmt">
                    <th mat-header-cell *matHeaderCellDef class='center'> Gestão de incêndio </th>
                    <td mat-cell *matCellDef="let element" class='center'>
                      <mat-checkbox
                        [(ngModel)]="element.pantera_features.fire_mngmt"
                        [disabled]='!user.isDev()'>
                      </mat-checkbox>
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="cam_detection">
                    <th mat-header-cell *matHeaderCellDef class='center'> Deteccao por cameras </th>
                    <td mat-cell *matCellDef="let element" class='center'>
                      <mat-checkbox
                        [(ngModel)]="element.pantera_features.cam_detection"
                        [disabled]='!user.isDev()'>
                      </mat-checkbox>
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="risk_map">
                    <th mat-header-cell *matHeaderCellDef class='center'> Mapa de Risco </th>
                    <td mat-cell *matCellDef="let element" class='center'>
                      <mat-checkbox
                        [(ngModel)]="element.pantera_features.risk_map"
                        [disabled]='!user.isDev()'>
                      </mat-checkbox>
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="satellite">
                    <th mat-header-cell *matHeaderCellDef class='center'> Detecção Satelital </th>
                    <td mat-cell *matCellDef="let element" class='center'>
                      <mat-checkbox
                        [(ngModel)]="element.pantera_features.satellite"
                        [disabled]='!user.isDev()'>
                      </mat-checkbox>
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="brigades_position">
                    <th mat-header-cell *matHeaderCellDef class='center'> Rastreamento de Frotas </th>
                    <td mat-cell *matCellDef="let element" class='center'>
                      <mat-checkbox
                        [(ngModel)]="element.pantera_features.brigades_position"
                        [disabled]='!user.isDev()'>
                      </mat-checkbox>
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="scars">
                    <th mat-header-cell *matHeaderCellDef class='center'> Dados Históricos </th>
                    <td mat-cell *matCellDef="let element" class='center'>
                      <mat-checkbox
                        [(ngModel)]="element.pantera_features.scars"
                        [disabled]='!user.isDev()'>
                      </mat-checkbox>
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="propagation">
                    <th mat-header-cell *matHeaderCellDef class='center'> Propagação </th>
                    <td mat-cell *matCellDef="let element" class='center'>
                      <mat-checkbox
                        [(ngModel)]="element.pantera_features.propagation"
                        [disabled]='!user.isDev()'>
                      </mat-checkbox>
                    </td>
                  </ng-container>
                  <ng-container matColumnDef="deforestation">
                    <th mat-header-cell *matHeaderCellDef class='center'> Desmatamento </th>
                    <td mat-cell *matCellDef="let element" class='center'>
                      <mat-checkbox
                        [(ngModel)]="element.pantera_features.deforestation"
                        [disabled]='!user.isDev()'>
                      </mat-checkbox>
                    </td>
                  </ng-container>
                  <tr mat-header-row *matHeaderRowDef="plantasTableColumns; sticky: true"></tr>
                  <tr mat-row *matRowDef="let row; columns: plantasTableColumns;"></tr>
                </table>
              </div>
            </mat-expansion-panel>
          </mat-accordion>
          <div class="footer-button-container">
            <button mat-mini-fab color='primary'
              matTooltip='Voltar ao mapa'
              [routerLink]="['/map']">
              <mat-icon>arrow_back</mat-icon>
            </button>
          </div>
        </div>
      </mat-tab>
      <mat-tab>
        <ng-template mat-tab-label>
          <mat-icon class="tab-icon">person_add</mat-icon>
          Cadastro
        </ng-template>
        <div class="tab-content-container" style="padding:2rem">
          <mat-card appearance="outlined" class='settings-card'>
            <h2> Cadastro </h2>
            <form [formGroup]='newUserForm' (ngSubmit)="newUserSubmit()">
              <div class="fx-row-space-around-center">
                <mat-form-field class='input-register' appearance="outline">
                  <mat-label>Usuário</mat-label>
                  <input matInput type="text" formControlName='username'>
                  <mat-hint>Padrao 'nome.sobrenome'</mat-hint>
                </mat-form-field>
                <mat-form-field class='input-register' appearance="outline">
                  <mat-label>Email</mat-label>
                  <input matInput type="text" formControlName='email'>
                  @if (newUserForm.get('email').errors) {
                    <mat-error>Email inválido</mat-error>
                  }
                </mat-form-field>
              </div>
              <div class="fx-row-space-around-center">
                <mat-form-field class='input-register' appearance="outline">
                  <mat-label>Nome</mat-label>
                  <input matInput type="text" formControlName='nome'>
                </mat-form-field>
                <mat-form-field class='input-register' appearance="outline">
                  <mat-label>Sobrenome</mat-label>
                  <input matInput type="text" formControlName='sobrenome'>
                </mat-form-field>
              </div>
              <div class="fx-row-space-around-center">
                <mat-form-field  class='input-register' appearance="outline">
                  <mat-label>Planta</mat-label>
                  <mat-select formControlName='id_planta'>
                    @for (p of plantas; track p.id_planta) {
                      <mat-option [value] = "p.id_planta">
                        {{p.nome_cliente}} <b>[{{p.id_planta}}]</b> : <i>{{p.nome_planta}}</i>
                      </mat-option>
                    }
                  </mat-select>
                </mat-form-field>
                <mat-form-field class='input-register' appearance="outline">
                  <mat-label>Papel do usuario</mat-label>
                  <mat-select formControlName='id_role'>
                    @for (r of roles; track r.id_role) {
                      <mat-option [value] = "r.id_role">
                        <b>{{r.id_role}}</b> : <i>{{r.user_role}}</i>
                      </mat-option>
                    }
                  </mat-select>
                </mat-form-field>
              </div>
              <div style="text-align: center;">
                <button mat-raised-button color='primary' type="submit"
                  [disabled]="!newUserForm.valid || loadingNewUser" >
                  <span>{{ 'MAP.CONFIRM' | translate }}</span>
                  @if (loadingNewUser) {
                    <mat-spinner diameter=20></mat-spinner>
                  }
                </button>
              </div>
            </form>
          </mat-card>
          <div>
            <h2> Tipos de usuários </h2>
            <table mat-table [dataSource]="roles" matSort class="mat-elevation-z8">
              <ng-container matColumnDef="id_role">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> id tipo usuário </th>
                <td mat-cell *matCellDef="let element"> <b>{{element.id_role}}</b></td>
              </ng-container>
              <ng-container matColumnDef="user_role">
                <th mat-header-cell *matHeaderCellDef mat-sort-header> Tipo de usuário </th>
                <td mat-cell *matCellDef="let element"> {{element.user_role}} </td>
              </ng-container>
              <ng-container matColumnDef="role_description">
                <th mat-header-cell *matHeaderCellDef> Descrição </th>
                <td mat-cell *matCellDef="let element"> {{element.role_description}} </td>
              </ng-container>
              <tr mat-header-row *matHeaderRowDef="rolesTableColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: rolesTableColumns;"></tr>
            </table>
          </div>
          <div class="footer-button-container">
            <button mat-mini-fab color='primary'
              matTooltip='Voltar ao mapa'
              [routerLink]="['/map']">
              <mat-icon>arrow_back</mat-icon>
            </button>
          </div>
        </div>
      </mat-tab>
      <mat-tab>
        <ng-template mat-tab-label>
          <mat-icon class="tab-icon">track_changes</mat-icon>
          Planta {{user.getIdPlanta()}}
        </ng-template>
        <div class="tab-content-container">
          <mat-accordion>
            <mat-expansion-panel class="config-panel" expanded>
              <mat-expansion-panel-header>
                <mat-panel-title>
                  <mat-icon>videocam</mat-icon>
                  <span class='panel-title'>
                    Cameras
                  </span>
                </mat-panel-title>
              </mat-expansion-panel-header>
              <table mat-table [dataSource]="cameras" class="mat-elevation-z8">
                <ng-container matColumnDef="id_instalacao">
                  <th mat-header-cell *matHeaderCellDef> id </th>
                  <td mat-cell *matCellDef="let element"> {{element.id}} </td>
                </ng-container>
                <ng-container matColumnDef="nome_local">
                  <th mat-header-cell *matHeaderCellDef> Nome </th>
                  <td mat-cell *matCellDef="let element"> {{element.nome_local}} </td>
                </ng-container>
                <ng-container matColumnDef="ip_lan">
                  <th mat-header-cell *matHeaderCellDef> ip_lan </th>
                  <td mat-cell *matCellDef="let element"> {{element.ip_lan}}:{{element.port_lan}} </td>
                </ng-container>
                <ng-container matColumnDef="ip_wan">
                  <th mat-header-cell *matHeaderCellDef> ip_wan </th>
                  <td mat-cell *matCellDef="let element"> {{element.ip_wan}}:{{element.port_wan}} </td>
                </ng-container>
                <ng-container matColumnDef="rtsp_url">
                  <th mat-header-cell *matHeaderCellDef
                    matTooltip="Usado pelo de Streaming">
                    url RTSP (caso VMS)
                  </th>
                  <td mat-cell *matCellDef="let element">
                    <input [(ngModel)]="element.rtsp_url"/>
                  </td>
                </ng-container>
                <ng-container matColumnDef="model_host">
                  <th mat-header-cell *matHeaderCellDef
                    matTooltip="Usado pela deteccao">
                    Host do modelo (detecção)
                  </th>
                  <td mat-cell *matCellDef="let element">
                    <input [(ngModel)]="element.model_host"/>
                  </td>
                </ng-container>
                <ng-container matColumnDef="model_port">
                  <th mat-header-cell *matHeaderCellDef>
                    Porta do modelo
                  </th>
                  <td mat-cell *matCellDef="let element">
                    <input [(ngModel)]="element.model_port"/>
                  </td>
                </ng-container>
                <ng-container matColumnDef="use_cam_rtsp">
                  <th mat-header-cell *matHeaderCellDef> Usar RSTP da camera </th>
                  <td mat-cell *matCellDef="let element">
                    <mat-checkbox [(ngModel)]="element.use_cam_rtsp"></mat-checkbox>
                  </td>
                </ng-container>
                <ng-container matColumnDef="use_ip_wan">
                  <th mat-header-cell *matHeaderCellDef> Usar IP Wan </th>
                  <td mat-cell *matCellDef="let element">
                    <mat-checkbox [(ngModel)]="element.use_ip_wan"></mat-checkbox>
                  </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="camTableColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: camTableColumns;"></tr>
              </table>
              <button style= "margin-top: 10px" mat-raised-button color='primary' (click)="updateConfigCameras()">
                <span>Atualizar</span>
                <!-- <mat-spinner *ngIf='loadingNewUser' diameter=20></mat-spinner> -->
              </button>
            </mat-expansion-panel>
          </mat-accordion>'
          <div class="footer-button-container">
            <button mat-mini-fab color='primary'
              matTooltip='Voltar ao mapa'
              [routerLink]="['/map']">
              <mat-icon>arrow_back</mat-icon>
            </button>
          </div>
        </div>
      </mat-tab>
      @if (user.isAdmin() && envDev) {
        <mat-tab>
          <ng-template   mat-tab-label>
            <mat-icon class="tab-icon">install_desktop</mat-icon>
            Deploy
          </ng-template>
          <div class="tab-content-container">
            <app-deploy #componentDeploy
              [pantera_plantas]="pantera_plantas"
            ></app-deploy>
            <div class="footer-button-container">
              <button mat-mini-fab color='primary'
                matTooltip='Voltar ao mapa'
                [routerLink]="['/map']">
                <mat-icon>arrow_back</mat-icon>
              </button>
            </div>
          </div>
        </mat-tab>
      }
      <mat-tab>
        <ng-template mat-tab-label>
          <mat-icon class="tab-icon">monitor_heart</mat-icon>
          Scripts
        </ng-template>
        <div class="tab-content-container">
          <app-sysstatus></app-sysstatus>
          <div class="footer-button-container">
            <button mat-mini-fab color='primary'
              matTooltip='Voltar ao mapa'
              [routerLink]="['/map']">
              <mat-icon>arrow_back</mat-icon>
            </button>
          </div>
        </div>
      </mat-tab>
    </mat-tab-group>
  </main>
}
