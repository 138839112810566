import { Component } from '@angular/core';
import { PresetpickerService } from 'src/app/services/presetpicker.service';

@Component({
  selector: 'simulate-presets-dialog',
  templateUrl: 'simulate-presets-dialog.html',
})
export class SimulatePresetsDialog {
  
  constructor(
    private presetPickerService: PresetpickerService,
  ) {}

  onChangeInput(ev: any) {
    const castedValue = parseInt(ev.target.value);
    this.presetPickerService.updateTimeBetweenPositionsOnPresetsSimulator(castedValue);
  }
}