import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { UserDataService } from './user-data.service';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})
export class LoggingService {

  constructor(
    private http: HttpClient,
    private user: UserDataService
  ) { }

  routeFluent = `https://fluentd.umgrauemeio.com/${environment.logName}`

  async logINFO(msg){
    try {
      this.http
      .post(
        this.routeFluent,
        {
          log_level: 'INFO',
          id_planta: this.user.getIdPlanta(),
          user: this.user.getUsername(),
          msg: msg
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        },
      )
      .toPromise()
      .then(
        res => {return},
        err => {console.log(`Error fluentD logging: ${err}`)},
      );
    } catch (error) {
      console.log('Error fluentD logging', error);
    }
 }


  logERROR(msg, error=undefined){
    try {
      this.http
      .post(
        this.routeFluent,
        {
          log_level: 'ERROR',
          id_planta: this.user.getIdPlanta(),
          user: this.user.getUsername(),
          msg: msg,
          stack: this.parseError(error)
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        },
        )
        .toPromise()
        .then(
          res => {return},
          err => {console.log(`Error fluentD logging: ${err}`)},
      );
    } catch (error) {
      console.log('Error fluentD logging', error);
    }
  }

  parseError(error){
    try {
      if (error==undefined) {
        return "N/A"
      }else{
        const stackTrace = error.stack;
        const fileAndLine = stackTrace.split('\n')[1].trim();
        return fileAndLine
      }
    } catch (err) {
      this.logERROR(`logging:parseError ${err}`,err);
      return "N/A"
    }
  }
}



// FUTURAMENTE PODEMOS IMPLEMENTAR UM DECORADOR COMO É FEITO NO PYTHON

// // Custom Decorator
// function catchError(target: any, propertyKey: string, descriptor: PropertyDescriptor) {
//   const originalMethod = descriptor.value;

//   descriptor.value = function(...args: any[]) {
//     try {
//       return originalMethod.apply(this, args);
//     } catch (error) {
//       console.error(`Error in method ${propertyKey}:`, error);
//     }
//   };

//   return descriptor;
// }

// // Usage
// class ExampleComponent {
//   @catchError
//   someMethod() {
//     // Code that may throw an error
//     throw new Error('Example Error');
//   }
// }
