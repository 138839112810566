<style>
  main{
    padding: 2rem;
    display: flex;
    flex-direction: column;
  }

</style>

<mat-dialog-content>
  <main>

    <h2>{{ 'MAP.PASS_RECOVERY' | translate }}</h2>
    <h3>{{ 'MAP.INSERT_USERNAME' | translate }}</h3>

    <mat-form-field appearance="outline">
      <mat-label>{{ 'MAP.USERNAME' | translate }}</mat-label>
      <span matPrefix><mat-icon>account_circle</mat-icon></span>
      <input matInput [(ngModel)]="username">
    </mat-form-field>

    @if (sucess) {
      <div>
        <h3 style='color:green; display: flex;'>
          <mat-icon>check_circle_outline</mat-icon>
          {{ 'MAP.RECOVERY_SENT' | translate }}
        </h3>
      </div>
    }

    <mat-dialog-actions align="center">
      <button mat-raised-button [mat-dialog-close]='false'>{{ 'MAP.BACK' | translate }}</button>
      @if (!sucess) {
        <button mat-raised-button class="login-btn" color='primary'
          (click)='reset_pass()' [disabled]='loading'>
          {{ 'MAP.RECOVER_PASS' | translate }}
        </button>
      }
    </mat-dialog-actions>

  </main>
</mat-dialog-content>