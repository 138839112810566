<style>
  mat-error {
    font-size: 10px;
}
</style>

<div class="report-dialog-wrapper">
  <mat-dialog-content >
    <div>
      <h2 style="text-align: center;">Atualização do local do relatório</h2>
      <h3 style="text-align: center;">Insira as novas coordenadas</h3>
  
      <form [formGroup]='latlngForm' class="fx-column-space-around-center">
  
        <mat-form-field appearance="outline" [color]="'primary'">
          <mat-label [style]="{'color': '#3a3a3a'}">Latitude</mat-label>
          <input matInput [style]="{'color': '#3a3a3a'}"
            placeholder="Ex: -22.1823"
            formControlName='lat'
            type="number"
            step="0.0001"
            />
            @if (latlngForm.get('lat').errors) {
              <mat-error>Latitude fora dos limites permitidos</mat-error>
            }
          </mat-form-field>
  
          <mat-form-field appearance="outline" [color]="'primary'">
            <mat-label>Longitude</mat-label>
            <input matInput
              placeholder="Ex: -47.8911"
              formControlName='lng'
              type="number"
              step="0.0001"
              />
              @if (latlngForm.get('lng').errors) {
                <mat-error>Longitude fora dos limites permitidos</mat-error>
              }
            </mat-form-field>
  
          </form>
  
        </div>
      </mat-dialog-content>
  
      <mat-dialog-actions align="center">
        <button mat-raised-button [mat-dialog-close]='false'>{{ 'MAP.CANCEL' | translate }}</button>
        <button mat-raised-button
          [mat-dialog-close]='latlngForm.value'
          [disabled]='latlngForm.status=="INVALID"'
          [color]="'primary'">
          {{ 'MAP.CONFIRM' | translate }}
        </button>
      </mat-dialog-actions>
</div>