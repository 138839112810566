import { Component, OnInit,ViewChild  } from '@angular/core';
import { HttpService } from '../../services/http.service';
import { UserDataService } from '../../services/user-data.service';
import { MatDialog } from '@angular/material/dialog';
import { InfoDialog } from "../../shared/dialogs/info-dialog/info-dialog";
import { ColorSchemeService } from 'src/app/services/color-scheme.service';
import { TranslateService } from '@ngx-translate/core';
import { AmplitudeService } from '../../services/amplitude.service';
import { Router } from '@angular/router';
import { updatePopupLang, removeActivatedPopup } from '../../services/geo.format';
import { GeoService } from '../../services/geo.service';
import { Httpv2Service } from 'src/app/services/httpv2.service';
import { BroadcastService } from 'src/app/interface/services/broadcast.service';
import { LoggingService } from 'src/app/services/logging.service';
import { PouchDBService } from '../../interface/services/pouchdb.service';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { LoginService } from 'src/app/services/login.service';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {

  firstName:string;
  lastName:string;
    
  pass1:string;
  pass2:string;
  confirmPass2:string;
  showPass1:boolean = false;
  showPass2:boolean = false;
  showConfirmPass2: boolean = false;
  passwordHints: boolean = false;
  public usersPlanta
  protected isIndia: boolean;
  public updating:boolean = false;
  public updateMessage:string;
  public numberOfRedetectionsFormValue: number;

  passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[\W_]).{8,}$/;

  public usersTableColumns: string[] = [
    'id_role',
    'username',
    'nome',
    'sobrenome',
    'last_access',
  ];

  public usersTableColumnsManager: string[] = [
    'username',
    'nome',
    'sobrenome',
  ];
      
  constructor(
    public user: UserDataService,
    private http:HttpService,
    private dialog: MatDialog,
    private colorScheme: ColorSchemeService,
    public translate: TranslateService,
    public geo:GeoService,
    public broadcastService: BroadcastService,
    public logging: LoggingService,
    public pouchDBService: PouchDBService,
    public amplitude: AmplitudeService,
    private router: Router,
    private httpv2: Httpv2Service
  ) { 
    if (this.user.isOperatorP2() || this.user.isSupportP2()) {this.syncCouchDB();}
  }
  @ViewChild(MatSort) sort: MatSort;

  async ngOnInit() {
    this.isIndia = [64, 65].includes(this.user.getIdPlanta());
    if(this.user.isDev() || this.user.isManager()){
      this.usersPlanta = new MatTableDataSource(await this.http.maestroGet(`get_all_users/${this.user.getIdPlanta()}`));
      this.usersPlanta.sort = this.sort
    }
    if (this.user.isOperatorP2() || this.user.isSupportP2()) {
      this.user.getP2UserPrefsFromLs()
      await this.fetchUserPrefsStatusOnDB()
      this.numberOfRedetectionsFormValue = this.user.tempMinNumberRedetections() ?? this.user.userPrefsStatus().data.minNumberRedetections
    }
  }
   
  syncCouchDB() {
    try {
      this.pouchDBService.changesFromDB
      .on('change', this.onChangesUserPrefsFilter.bind(this))
      .on('error', (err) => {
        setTimeout(() => this.syncCouchDB(), 1000)}
        )
    } catch(e){
      console.error(`Error syncing couchDB ${e}`)
    }
   }

  onChangesUserPrefsFilter(change) {
    if (change.doc._id.startsWith('user_prefs')) {
      this.user.setUserPrefsStatus(change.doc)
    }
  }

  async fetchUserPrefsStatusOnDB() {
    let doc = await this.pouchDBService.fetchDocumentOnDBById('user_prefs')
    this.user.setUserPrefsStatus(doc)
    console.log('DEBUG userPrefsStatus', this.user.userPrefsStatus())
  }

  toggleTelegram(checked: boolean) {
    this.amplitude.sendEvent("Alterou Configuração", { "Configuração": "Telegram", "Valor": checked })
    this.user.updateUserPrefsStatus('telegramStatus', checked)
    this.pouchDBService.updateDocument(this.user.userPrefsStatus());
  }

  toggleIdleTurnAutoModeStatus(checked: boolean) {
    this.amplitude.sendEvent("Alterou Configuração", { "Configuração": "idleTurnAutoModeStatus", "Valor": checked })
    this.user.updateUserPrefsStatus('idleTurnAutoModeStatus', checked)
    this.pouchDBService.updateDocument(this.user.userPrefsStatus());
  }

  toggleDrawAutoGeolocEllipse(checked: boolean){
    this.user.setDrawAutoGeolocEllipse(checked)
    this.broadcastService.publishMessage({ event: "DrawAutoGeolocEllipseSettingChange", drawAutoGeolocEllipse: checked })
    if (!checked){
      this.amplitude.sendEvent("Alterou Configuração", { "Configuração": "Elipse", "Valor": checked })
      this.geo.clearAutoGeolocLayers()
      return
    }
    this.amplitude.sendEvent("Alterou Configuração", { "Configuração": "Elipse", "Valor": checked })
  }

  onNumberOfRedetectionsChange(value: number){
    this.user.updateUserPrefsStatus('minNumberRedetections', value)
    this.broadcastService.publishMessage({ event: "MinNumberOfRedetectionsSettingChange", value: value })
    this.user.setTempMinNumberRedetections(value)
  }
  
  syncNumberOfRedetectionsChange(){
    this.pouchDBService.updateDocument(this.user.userPrefsStatus());
  }

  async changePassword(){
    this.pass1 = this.pass1.replace(/\s/g,'');
    if(/\s/g.test(this.pass2)) {
      this.dialog.open(InfoDialog,{
        data:{text:this.translate.instant('MAP.SPACE_IN_PASSWORD')}

      }); 
      return;
    }

    let obj = {
      username : this.user.username(),
      oldpass : this.pass1,
      newpass : this.pass2,
    }    
    let response = await this.http.maestroPost('update_pwd_pweb', obj)
    
    if (response.status){
      this.dialog.open(InfoDialog, {
        data: {text:this.translate.instant('MAP.PASSWORD_CHANGED_SUCCESS')}
      });
      this.pass1 = ''
      this.pass2 = ''
      this.confirmPass2 = ''
    }
    else{
      this.dialog.open(InfoDialog, {
        data: {text: this.translate.instant('MAP.INCORRECT_PASSWORD')}
      });
    }
  }

  colorSchemeChange(checked: boolean){
    this.user.setDarkMode(checked);
    const scheme = checked ? 'dark' : 'light';
    this.colorScheme.update(scheme);
    this.broadcastService.publishMessage({ event: "ColorSchemeChange", scheme })
    if (checked) {
      this.amplitude.sendEvent("Alterou Configuração", { "Configuração": "Tema", "Valor": "Escuro" })
    } else {
      this.amplitude.sendEvent("Alterou Configuração", { "Configuração": "Tema", "Valor": "Claro" })
    }    
  }

  onToggleChange(language: string){
    this.translate.onLangChange.subscribe(() => {
      updatePopupLang(this.translate);
      this.geo.updateLegendLanguage(language);
    })
    this.translate.use(language);
    this.user.saveLang(language);

    removeActivatedPopup(this.translate)

    this.broadcastService.publishMessage({ event: "Translate", language })
    
    this.amplitude.sendEvent("Alterou Configuração", {"Configuração": "Idioma", "Valor": this.translate.currentLang})
  }

  passwordTooltips(show: boolean) {
    this.passwordHints = show;
  }

  validPassword(): boolean {
    return this.passwordRegex.test(this.pass2) && this.pass2 === this.confirmPass2;
  }
  
  toggleAudio() {
    this.amplitude.sendEvent("Alterou Configuração", { "Configuração": "Som", "Valor": this.user.audioState() })
    this.user.setAudioState(!this.user.audioState());
    this.broadcastService.publishMessage({ event: "AudioSettingChange", audioState: this.user.audioState() });
  }

  reloadInterface() {
    this.amplitude.sendEvent("Recarregou Interfaces");
    this.user.reloadInterface();
    this.broadcastService.publishMessage({ event: "ReloadInterface" });
  }
    
  async atualizarDados() {
    this.amplitude.sendEvent("Clicou Atualizar Dados");
    try {
      this.updateMessage = this.translate.instant('MAP.UPDATING_DATA');
      this.updating = true;
      const updatingData = await this.user.atualizarDadosCliente();
      this.updating = false;

      if (updatingData.status) {
        const dialogRef = this.dialog.open(InfoDialog, {
          data: { text: this.translate.instant('MAP.UPDATED_DATA') }
        });
        dialogRef.afterClosed().subscribe(response => {
          if (response === true) {
            this.reloadInterface();
          }
        });
      } else {
        this.dialog.open(InfoDialog, {
          data: { text: this.translate.instant('MAP.ERROR_UPDATING_DATA') }
        });
      }
    } catch (error) {
      this.logging.logERROR(`updateDataClient ${error}`, error);
      console.error('Erro ao atualizar dados do cliente:', error);
      this.dialog.open(InfoDialog, {
        data: { text: this.translate.instant('MAP.INTERNAL_ERROR') }
      });

    } finally {
      this.updating = false; 
    }
  }

  logout() {
    this.amplitude.sendEvent('Fez Logout');
    this.broadcastService.publishMessage({ event: "Logout" });
    this.router.navigateByUrl("/login");
    window.close()
  }

  async deleteAllDetections(){
    this.broadcastService.publishMessage({ event: "TogglePouchDbIsBatchDeletingDetections", value: true })
    try {
      await this.httpv2.getFromBackendP2Promise('/maestro/deletar_todas_dets');
      this.dialog.open(InfoDialog, {
        data: { text: this.translate.instant('MAP.DETECTIONS_SUCCESSFULLY_DELETED') }
      })
      this.broadcastService.publishMessage({ event: "TogglePouchDbIsBatchDeletingDetections", value: false, error: false })
    }
    catch (error) {
      console.error('Error deleting detections and images:', error)
      this.logging.logERROR(`deleteAllDetections ${error}`, error)
      this.dialog.open(InfoDialog, {
        data: { text: this.translate.instant('MAP.INTERNAL_ERROR') }
      })
      this.broadcastService.publishMessage({ event: "TogglePouchDbIsBatchDeletingDetections", value: false, error: true })
    }
  }
}
