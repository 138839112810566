<section class="client-dashboard">
  @if (relatoriosCount === 0) {
    <div class="error-msg">
      {{ errorMsg }}
    </div>
  }
  <div>
    <canvas id="resumoRiscoChartCliente"></canvas>
  </div>
  <div>
    <canvas id="areaQueimadaChartCliente"></canvas>
  </div>
  <div>
    <canvas id="acionamentosChartCliente"></canvas>
  </div>
  <div>
    <canvas id="causasChartCliente"></canvas>
  </div>
  <div>
    <canvas id="origensChartCliente"></canvas>
  </div>
</section>