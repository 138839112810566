<!-- Zona -->
@if (hasZones) {
  <div class="zone-pick-wrapper">
    <h3>{{ 'SAT.SELECT_ZONE' | translate }}</h3>
    <mat-form-field appearance='outline'>
      @if (!selectedZone) {
        <mat-label>{{ 'SAT.ALL_ZONES' | translate }}</mat-label>
      }
      <mat-select (selectionChange)="selectZones()" [(ngModel)]="selectedZone">
        @for (z of sat.alertRecipients; track z.id_zona) {
          <mat-option [value]="z.id_zona">
            {{z.nome_zona || 'SAT.ALL_ZONES' | translate}}
          </mat-option>
        }
      </mat-select>
    </mat-form-field>
  </div>
}

<!-- Email -->
<div>
  <table mat-table [dataSource]="recipients" class="mat-elevation-z8"  matSort (matSortChange)="sortData($event)" matSortDisableClear="true">
    <ng-container matColumnDef="email">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="emails">
        <mat-icon>email</mat-icon>
        Email
      </th>
      <td mat-cell *matCellDef="let element">
        <div class="fx-row-space-between-center">
          {{element}}
          @if (user.isManager()) {
            <button mat-icon-button color="primary" (click)='rmRecipient(element)'>
              <mat-icon class="cancel-button">close</mat-icon>
            </button>
          }
        </div>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="recipientsTableColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: recipientsTableColumns;"></tr>
  </table>
  
  @if (user.isManager()) {
    <mat-card appearance="outlined">
      <div class="fx-row-space-around-center email-form " style="margin-top:1rem">
        @if(emailAlreadyRegistered()){
          <p class="email-registered">{{ 'MAP.EMAIL_ALREADY_REGISTERED' | translate }}</p>
        }
        <div class="form-wrapper">
        <mat-form-field appearance="outline">
          <mat-label>{{ 'MAP.NEW_EMAIL' | translate }}</mat-label>
          <input type="email" matInput [formControl]="emailFormControl" [(ngModel)]="newRecipient">
            @if (emailFormControl.hasError('email')) {
              <mat-error>{{ 'MAP.INVALID_EMAIL' | translate }}</mat-error>
            }
        </mat-form-field>
          <button mat-mini-fab (click)="addRecipient()" color="warn" matTooltip="{{ 'MAP.REGISTER_EMAIL' | translate }}"
            [disabled]="emailFormControl.hasError('email') || emailFormControl.hasError('required') || emailAlreadyRegistered()">
            <mat-icon>add</mat-icon>
          </button>
        </div>
      </div>
    </mat-card>
  }
</div>

<!-- WhatsApp -->
@if (![5].includes(user.getIdCliente())) {
  <div>
    <table mat-table [dataSource]="recipients_whatsapp" class="mat-elevation-z8">
      <ng-container matColumnDef="whatsapp">
        <th mat-header-cell *matHeaderCellDef>
          <mat-icon>smartphone</mat-icon>
          WhatsApp
        </th>
        <td mat-cell *matCellDef="let element">
          <div class="fx-row-space-between-center">
            {{element}}
            @if (user.isManager()) {
              <button mat-icon-button color="primary" (click)='rmRecipient_whatsapp(element)'>
                <mat-icon class="cancel-button">close</mat-icon>
              </button>
            }
          </div>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="recipientsTableColumns_whatsapp"></tr>
      <tr mat-row *matRowDef="let row; columns: recipientsTableColumns_whatsapp;"></tr>
    </table>

    @if (user.isManager()) {
      <mat-card appearance="outlined">
        <div class="fx-row-space-around-center email-form" style="margin-top:1rem">
          @if(whatsappAlreadyRegistered()){
            <p class="email-registered">{{ 'MAP.NUMBER_ALREADY_REGISTERED' | translate }}</p>
          }
          <div class="form-wrapper">
          <mat-form-field appearance="outline">
            <mat-label>{{ 'MAP.NEW_WHATSAPP' | translate }}</mat-label>
            <mat-hint>{{ 'MAP.PHONE_EXAMPLE' | translate }}</mat-hint>
            <input type="tel" matInput [formControl]="whatsappFormControl" [(ngModel)]="newRecipient_whatsapp">
              @if (whatsappFormControl.hasError('pattern')) {
                <mat-error>{{ 'MAP.INVALID_TELEPHONE' | translate }}</mat-error>
              }
          </mat-form-field>
          <button mat-mini-fab (click)="addRecipient_whatsapp()" color="warn" matTooltip="{{ 'MAP.REGISTER_WHATSAPP' | translate }}"
            [disabled]="whatsappFormControl.hasError('pattern') || whatsappFormControl.hasError('required') || whatsappAlreadyRegistered()">
            <mat-icon>add</mat-icon>
          </button>
          </div>
        </div>
      </mat-card>
    }
  </div>
}

<!-- Telegram -->
<!-- não tem a opção de enviar por zona -->
<div>
  @if (!selectedZone) {
    <table mat-table [dataSource]="this.telegramDetectionGroup">
      <ng-container matColumnDef="link">
        <th mat-header-cell *matHeaderCellDef>
          <mat-icon>telegram</mat-icon>
          Telegram
        </th>
        <td mat-cell *matCellDef="let element">
          <a [ngClass]="user.darkMode() ? 'link-dark' : ''" href={{element.invite_link}} target="_blank" rel="noreferrer" 
            (click)="this.amplitude.sendEvent('Clicou Link', {'Grupo': this.amplitude.groupName['sat'], 'Destino': 'Telegram', 'Assunto': element.tipo_destinatario})">
            {{ element.invite_link }}
          </a>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="telegramTableColumns; sticky: true" ></tr>
      <tr mat-row *matRowDef="let row; columns: telegramTableColumns"></tr>
    </table>
  }

  @if (selectedZone == null && !telegramDetectionGroup.length) {
    <mat-card class="mat-card-sat" appearance="outlined" style="margin-top:1rem">
      {{ 'SAT.NO_TELEGRAM_DETECTION_GROUP' | translate }}. {{ 'MAP.CONTACT_UGM' | translate }}
    </mat-card>
  }
</div>


