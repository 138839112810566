import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { HttpService } from 'src/app/services/http.service';
import { UserDataService } from 'src/app/services/user-data.service';
import { GeoService } from 'src/app/services/geo.service';
import { Chart, ChartType } from 'chart.js';
import { FormControl } from '@angular/forms';
import 'chartjs-adapter-date-fns'; // TO DO ver se precisa mesmo
import { ptBR } from 'date-fns/locale';
import { formatDate } from '@angular/common';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';
import { AmplitudeService } from 'src/app/services/amplitude.service';
import { MatDialog } from '@angular/material/dialog';
import { InfoDialog } from "../../shared/dialogs/info-dialog/info-dialog";

interface DeforestationChartsData {
  monthly_sum: { [key: string]: number },
  yearly_sum: { [key: string]: number },
  source_counts: {[key: string]: number },
  city_area_sum: {[key: string]: number },
}
interface DeforestationTableData {
  id_geom: number,
  nome_geom: string,
  alert_code: number,
  area_ha: number,
  sources: string,
  detected_at: string,
  alert_cities: string,
  published_at: string,
  centroid: {lat: number, lon: number},
  confidence: string,
  confirmed: boolean,
  planet_tiles_urls?: any,
}

interface AlertDataResponse {
  alert_data: any, // to do tipar
  token: string,
}

const INIT_QUERY_DELTA_DAYS = 365;
const DEFAULT_START_YEAR = 2019;
const DEFAULT_END_YEAR = 2024;
const PALETA = ['rgba(253, 98, 94, 1)', 'rgba(1, 184, 170, 1)', 'rgb(95, 107, 109, 1)', 'rgb(138, 212, 235, 1)'];

@Component({
  selector: 'app-deforestation',
  templateUrl: './deforestation.component.html',
  styleUrls: ['./deforestation.component.scss']
})
export class DeforestationComponent implements OnInit {

  constructor (
    public user: UserDataService,
    private http: HttpService,
    private elementRef: ElementRef,
    private geo: GeoService,
    private translate: TranslateService,
    private amplitude: AmplitudeService,
    private dialog: MatDialog,
  ) {}

  private tabs = {
    0: 'Consulta',
    1: 'Dashboard'
  }

  public deforestationChartsData: DeforestationChartsData; 
  public monthlyDeforestationChart: Chart;
  public yearlyDeforestationChart: Chart;
  public sourcesChart: Chart;
  public citiesChart: Chart;

  public startDateControl = new FormControl(new Date(new Date().getTime() - INIT_QUERY_DELTA_DAYS*24*60*60*1000));
  public endDateControl = new FormControl(new Date());
  private deforestationTableData: DeforestationTableData[] = [];
  public sortedTableDataSource: any;
  public deforestationTableColumns = ['idGeom', 'nomeGeom', 'detectedAt', 'publishedAt', 'confidence', 'sources', 'areaHa', 'confirmed'];
  @ViewChild(MatSort) sort: MatSort;

  public selectedDeforestationAlert: AlertDataResponse = null;
  public imgUrlNum = 0;
  public planetUrlsIndex = 0;

  private confidenceMap = {
    'low': `${this.translate.instant("MAP.LOW")}`, 'high': `${this.translate.instant("MAP.HIGH")}`, 'highest': `${this.translate.instant("MAP.VERY_HIGH")}`
  };

  async ngOnInit() {
    if (!this.user.hasDeforestation()) return;
    await this.getFilteredDeforestationPlanta();
  }
  
  async createDeforestationDashboard(){
    await this.getDeforestationData();
    this.createMonthlyDeforestationChart();
    this.createYearlyDeforestationChart();
    this.createSourcesChart();
    this.createCitiesChart();
  };

  async onEndDateChange(endDate: Date) {
    if (endDate) {
      await this.getFilteredDeforestationPlanta();
      this.amplitude.sendDatePickerEvent('deforestation', 'MAP.SEARCH', `[${formatDate(this.startDateControl.value, 'dd-MMM-yyyy', 'en-US')}, ${formatDate(this.endDateControl.value, 'dd-MMM-yyyy', 'en-US')}]`);
    }
  }

  mapConfidenceDisplayValues(){
    this.deforestationTableData.forEach((alert) => {
      alert.confidence = this.confidenceMap[`${alert.confidence}`];
      return alert;
    })
  }

  async getFilteredDeforestationPlanta(){
    if (!this.startDateControl.value || !this.endDateControl.value) return;
    this.deforestationTableData = await this.http.maestroGet(`get_filtered_desmatamento_planta/${this.user.getIdPlanta()}/${formatDate(this.startDateControl.value, 'yyyy-MM-dd', 'en-US')}/${formatDate(this.endDateControl.value, 'yyyy-MM-dd', 'en-US')}`);
    if (!this.deforestationTableData?.length){
      this.deforestationTableData = [];
      this.sortedTableDataSource = [];
      return;
    }
    this.mapConfidenceDisplayValues()
    this.deforestationTableData.sort(
      (alertA, alertB) => alertB.published_at.localeCompare(alertA.published_at)
    );
    this.sortedTableDataSource = new MatTableDataSource(this.deforestationTableData);
    this.sortedTableDataSource.sort = this.sort;
  }

  tabChangeEvent(selectedTab: number){
    switch (selectedTab){
      case 0:
        this.destroyCharts();
        break;
      case 1:
        this.createDeforestationDashboard();
        break;
    }
    this.amplitude.sendTabViewEvent('deforestation', this.tabs, selectedTab);
  }

  async getDeforestationData(){
    this.deforestationChartsData = await this.http.maestroGet(`get_desmatamento_planta/${this.user.getIdPlanta()}/${DEFAULT_START_YEAR}/${DEFAULT_END_YEAR}`);
  }

  async selectDeforestationAlert(idGeom:number){
    const alert = this.deforestationTableData.find((alert) => alert.id_geom === idGeom);
    const alertCode = alert?.alert_code;
    if (alertCode){ //mapbiomas
      // futuramente nao ter essa request adicional por alert, pegar tudo na inicial da tabela
      this.selectedDeforestationAlert = await this.http.maestroGet(`get_deforestation_alert_data/${alertCode}/${this.user.getMapbiomasToken()}`);
      this.selectedDeforestationAlert.alert_data['nomeGeom'] = alert.nome_geom;
      this.selectedDeforestationAlert.alert_data['confirmed'] = alert.confirmed;
      this.user.saveMapbiomasToken(this.selectedDeforestationAlert.token);
    } else { // GFW
      this.selectedDeforestationAlert = {
        alert_data: {
          idGeom: alert.id_geom,
          nomeGeom: alert.nome_geom,
          areaHa: alert.area_ha,
          detectedAt: alert.detected_at,
          publishedAt: alert.published_at,
          crossedCities: alert.alert_cities,
          sources: alert.sources,
          confirmed: alert.confirmed,
          planetTilesUrls: alert?.planet_tiles_urls,
        },
        token: this.user.getMapbiomasToken(),
      };
    };
    this.selectedDeforestationAlert.alert_data.confidence = alert.confidence;
    const zoom = this.selectedDeforestationAlert.alert_data.areaHa < 1 ? 17 : 15; 
    this.geo.createDeforestationAlertWmsLayer(this.user.getIdPlanta(), idGeom, alert.centroid.lat, alert.centroid.lon + 0.002, zoom);
  }

  goToImage(deltaIndex: number){
    if (!this.selectedDeforestationAlert) return;
    if (!this.selectedDeforestationAlert.alert_data.planetTilesUrls) return;
    const planetUrls = this.selectedDeforestationAlert.alert_data.planetTilesUrls;
    const currentIndex = (this.planetUrlsIndex + deltaIndex) % planetUrls.length;
    this.planetUrlsIndex = currentIndex < 0 ? planetUrls.length - 1 : currentIndex;
  }

  unselectAlert(){
    this.selectedDeforestationAlert = null;
    this.imgUrlNum = 0;
    this.planetUrlsIndex = 0;
  }

  changeImage(){
    this.imgUrlNum = this.imgUrlNum === 0 ? 1 : 0;
  }

  async confirmAlert(idGeom: number, confirmed: boolean = true){
    const response = await this.http.maestroPatch(`deforestation_alert_confirmation/${idGeom}/${confirmed}`, null)
    if (response?.status){
      this.selectedDeforestationAlert.alert_data.confirmed = confirmed
      let newConfidence: string;
      if (confirmed){
        newConfidence = this.confidenceMap['highest']
      } else {
        newConfidence = this.confidenceMap['low']
      }
      this.selectedDeforestationAlert.alert_data.confidence = newConfidence
      const selectedAlert = this.deforestationTableData.find(defAlert => defAlert.id_geom === idGeom)
      selectedAlert.confirmed = this.selectedDeforestationAlert.alert_data.confirmed
      selectedAlert.confidence =  newConfidence
    } else {
      this.dialog.open(InfoDialog, {
        data: {text:`${this.translate.instant("MAP.INTERNAL_ERROR")}`}
      })
      console.warn(`Erro ao atualizar alerta: ${response?.msg}`)
    }
  }

  getMonthlyDeforestationLabelsData(){
    const monthlySum = this.deforestationChartsData.monthly_sum;
    const months = Object.keys(monthlySum).map(monthStr => { return Date.parse(`${monthStr}-15`) });
    const areaValues: number[] = Object.values(monthlySum);
    return {
      labels: months,
      data: areaValues,
    }
  }

  destroyCharts(){
    for (let chart of [this.monthlyDeforestationChart, this.yearlyDeforestationChart, this.sourcesChart, this.citiesChart]){
      if (chart){
        chart.destroy();
      }
    }
  }

  createMonthlyDeforestationChart(){
    const labelsData = this.getMonthlyDeforestationLabelsData();
    const data = {
      labels: labelsData.labels,
      datasets: [{
        data: labelsData.data,
        backgroundColor: PALETA[0],
        borderColor: 'rgba(253, 98, 94, 0.3)',
      }]
    };
    const htmlChartRef = this.elementRef.nativeElement.querySelector('#monthlyDeforestation');
    const chartType: ChartType = 'line';
    this.monthlyDeforestationChart = new Chart(htmlChartRef, {
    type: chartType,
    data: data,
    options: {
      scales: {
          x: {
              type: 'time',
              time: {
                displayFormats: {
                  month: 'MMM yyyy'
                },
                tooltipFormat: 'MMM yyyy',
                unit: 'month'
              },
              adapters: {
                date: {
                  locale: ptBR // depois fazer em funçao da lingua do usuario
                }
              }
          },
      },
      responsive: true,
      plugins: {
        title: {
          display: true,
          text: `${this.translate.instant("MAP.MONTLY_DEFORESTED")}`,
          font: {
            size: 14,
          }
        },
        legend: {
          display: false,
        },
        tooltip: {
          callbacks: {
            label: (tooltipItem) => {
              return this.getTooltip(tooltipItem, 'ha');
            }
          }
        }
      }
    }
  });
  };

  configBarChart(deforestationDataKey: string, datasetLabel: string, chartId: string, title: string, chartUnit: string, backgroundColor: string = PALETA[0]){
    const maestroData = this.deforestationChartsData[`${deforestationDataKey}`];
    const dataValues: number[] = Object.values(maestroData); // tipar senao o chartjs reclama
    const data = {
      labels: Object.keys(maestroData),
      datasets: [{
        label: datasetLabel,
        data: dataValues,
        backgroundColor: backgroundColor,
        maxBarThickness: 50,
      }]
    };
    const chartType: ChartType = 'bar';
    const htmlChartRef = this.elementRef.nativeElement.querySelector(chartId)
    return new Chart(htmlChartRef, {
      type: chartType,
      data: data,
      options: {
        scales: {
          x: {
            ticks: {
              maxRotation: 0,
              minRotation: 0,
            }
          }
        },
        responsive: true,
        plugins: {
          title: {
            display: true,
            text: title,
            font: {
              size: 14,
            }
          },
          legend: {
            display: false,
          },
          tooltip: {
            callbacks: {
              label: (tooltipItem) => {
                return this.getTooltip(tooltipItem, chartUnit);
              }
            }
          }
        }
      }
  });
  }

  createYearlyDeforestationChart(){
    this.yearlyDeforestationChart = this.configBarChart('yearly_sum', 'Mapbiomas Alerta', '#yearlyDeforestation', `${this.translate.instant("MAP.ANNUAL_DEFORESTED")}`, 'ha');
  }

  createSourcesChart(){
    this.sourcesChart = this.configBarChart('source_counts', 'Fontes', '#sources', `${this.translate.instant("MAP.NUMBER_ALERTS")}`, `${this.translate.instant("MAP.ALERTS")}`, PALETA[1]);
  }

  createCitiesChart(){
    this.citiesChart = this.configBarChart('city_area_sum', 'Municípios', '#cities', `${this.translate.instant("MAP.DEFORESTED_CITY")}`, 'ha', PALETA[2]);
  }

  getTooltip(tooltipItem, unit){
    return `${Number(tooltipItem.raw).toFixed(0)} ${unit}`;
  }

}
