import { Component } from '@angular/core';
import { HttpService } from '../../../services/http.service';
import { ReportsService } from '../../../services/reports.service';
import { UserDataService } from '../../../services/user-data.service';
import {formatDate} from '@angular/common';
import { AmplitudeService } from 'src/app/services/amplitude.service';

@Component({
  selector: 'add-img-dialog',
  templateUrl: 'add-img-dialog.html',
})
export class AddImagesDialog{
  constructor(
    private http: HttpService,
    private reports: ReportsService,
    private user: UserDataService,
    private amplitudeService: AmplitudeService
  ) {}
  
  public filesToUpload: File [] = [];
  public imageTypes = [
    {
      'id':20,
      'type':'Terrestre'
    },
    { 
    'id':30,
    'type':'Aérea'
    }
  ]

  handleFileInput(event){
    this.filesToUpload = Array.from(event.target.files);
  }

  // TODO MELHORAR DETALHES
  async postImgData(){
    this.amplitudeService.sendEvent("Fez Upload Imagem Relatório", { "ID Relatório": this.reports.selectedR.id_report, "Número Relatório": this.reports.selectedR.n_relatorio_planta })
    for (let file of this.filesToUpload) {
      let fd = new FormData();
      let imgData = {
        'id_report': this.reports.getSelectedId(),
        'id_planta': this.user.getIdPlanta(),
        'img_type': 20,
        'img_timestamp': formatDate(new Date().getTime(), 'yyyy-MM-dd HH:mm:ss', 'en-US'),
        'img_details': {}
      }
      fd.append('file', file, file.name);
      fd.append('imgData', JSON.stringify(imgData));
      let response = await this.http.centralUpload('add_report_image', fd)
      console.log('postImgData', response)
    }
      await this.reports.loadImages();
      this.reports.setSelectedReportImageIndex(0)
  }
  
}

