<style>
  main{
    width: 350px;
  }

  .outlineForm{
    width: 100%;
  }

  textarea {
    min-height: 15vh;
  }

  .character-count, .character-count-max, .character-count-warn {
    text-align: right;
    font-size: 0.8rem;
    color: grey;
  }

  .character-count-max, .character-count-warn{
    color:red;
  }
  
  .character-count-container{
    display: flex;
    gap: 10px;
    justify-content: right;
  }
</style>

<main class="report-dialog-wrapper">
  <mat-dialog-content>
    <div>
      <h3>{{data.text}}</h3>
    </div>

    <form [formGroup]="commentField" (ngSubmit)="onSubmit()">
      <mat-form-field appearance="outline" class="outlineForm">
        <textarea matInput formControlName="comentarios"></textarea>
        <div class="character-count-container">
          @if( characterCount >= maxCharacters){
            <div class="character-count-max">
              {{ 'MAP.MAX_CHARACTERS' | translate }}
            </div>
          }
          <div [ngClass]="characterCount >= maxCharacters ? 'character-count-warn' : 'character-count'">{{ characterCount }}/{{ maxCharacters }}</div>
      </div>
      </mat-form-field>

      <mat-dialog-actions align="center">
        <button mat-raised-button [mat-dialog-close]='false'>{{ 'MAP.CANCEL' | translate }}</button>
        <button [color]="'primary'" mat-raised-button type="submit">{{ 'MAP.CONFIRM' | translate }}</button>
      </mat-dialog-actions>
    </form>
  </mat-dialog-content>
</main>
