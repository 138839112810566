<div class="tab-content-container">
  <div class="option-select">
    <mat-button-toggle-group [(ngModel)]="selectedContactViewOption" name="option" [formControl]="contactViewFormControl" (change)="amplitudeSubTabEvent($event.value)">
      @for (option of contactViewOptions; track option.id) {
        <mat-button-toggle [value]="option.id" [disabled]="option.id == 2 && !user.isManager()">
          <mat-icon>{{option.icon}}</mat-icon> {{ option.option | translate }}
        </mat-button-toggle>
      }
    </mat-button-toggle-group>
  </div>

  @if (!contatos.length && selectedContactViewOption == 1) {
    <div class="sem-dados">
      {{ 'MAP.NO_CONTACTS' | translate }}
    </div>
  }
  @if (contatos.length && selectedContactViewOption == 1) {
    <div class="table-container">
      <table mat-table [dataSource]="contatos" matSort (matSortChange)="sortData($event)" class="mat-elevation-z8" matSortDisableClear="true">
        <ng-container matColumnDef="nome">
          <th mat-header-cell *matHeaderCellDef mat-sort-header="nome"> {{ 'MAP.NAME' | translate }} </th>
          <td mat-cell *matCellDef="let element"> {{element.nome}} </td>
        </ng-container>
        <ng-container matColumnDef="telefone">
          <th mat-header-cell *matHeaderCellDef mat-sort-header="telefone"> {{ 'MAP.TELEPHONE' | translate }} </th>
          <td mat-cell *matCellDef="let element"> {{element.telefone}} </td>
        </ng-container>
        <ng-container matColumnDef="complemento">
          <th mat-header-cell *matHeaderCellDef mat-sort-header="complemento"> {{ 'MAP.COMPLEMENT' | translate }} </th>
          <td mat-cell *matCellDef="let element"> {{element.complemento}} </td>
        </ng-container>
        <ng-container matColumnDef="acao">
          <th mat-header-cell *matHeaderCellDef> </th>
          <td mat-cell *matCellDef="let element">
            @if (user.isManager()) {
              <button mat-icon-button color="primary" (click)='removeContato(element)'>
                <mat-icon class="cancel-button">close</mat-icon>
              </button>
            }
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="contatosTableColumns; sticky:true;"></tr>
        <tr mat-row *matRowDef="let row; columns: contatosTableColumns;"></tr>
      </table>
    </div>
  }
  @if (selectedContactViewOption == 2) {
    <mat-card appearance="outlined" class="cadastro">
      <mat-card-title>{{ 'MAP.CREATE_NEW_CONTACT' | translate }}</mat-card-title>
      <div class="fx-column-space-around-center" style="margin-top:1rem">
        <mat-form-field appearance="outline">
          <mat-label>{{ 'MAP.NAME' | translate }}</mat-label>
          <input type="text" matInput [formControl]="nameFormControl"
            [(ngModel)]="newContactName">
            @if (nameFormControl.hasError('pattern')) {
              <mat-error>{{ 'MAP.CHECK_WHITE' | translate }}</mat-error>
            }
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>{{ 'MAP.TELEPHONE' | translate }}</mat-label>
            <input type="text" matInput [formControl]="telefoneFormControl"
              [(ngModel)]="newContactTelefone">
              @if (telefoneFormControl.hasError('pattern')) {
                <mat-error>{{ 'MAP.ENTER_PHONE' | translate }}</mat-error>
              }
            </mat-form-field>
            <mat-form-field appearance="outline">
              <mat-label>{{ 'MAP.COMPLEMENT' | translate }}</mat-label>
              <input type="text" matInput [formControl]="complementoFormControl"
                [(ngModel)]="newContactComplemento">
                @if (complementoFormControl.hasError('pattern')) {
                  <mat-error>{{ 'MAP.CHECK_WHITE' | translate }}</mat-error>
                }
              </mat-form-field>
              <div class="button-wrapper">
                <button mat-raised-button (click)="postContato()" color="primary"
            [disabled]="nameFormControl.hasError('pattern') || nameFormControl.hasError('required') ||
            telefoneFormControl.hasError('pattern') || telefoneFormControl.hasError('required') ||
            complementoFormControl.hasError('pattern')">
                  {{ 'MAP.NEW_CONTACT' | translate }}
                </button>
              </div>
            </div>
          </mat-card>
        }
      </div>