export class SidebarModel {
    linkText: string;
    parentLink: string;
    menu: boolean;
    data: JSON[];
    submenu: { childtext: string; link: string, data: JSON[] }[];
  }
  
  
export enum DialogType {
    Deploy = 'DeployDialog',
    DeployError = 'DeployErrorDialog',
    ReloadDeploy = 'ReloadDeployDialog'
}
