<main>

  @if (groups.length) {
    <table mat-table [dataSource]="this.groups">
      <ng-container matColumnDef="nome">
        <th mat-header-cell *matHeaderCellDef>{{ 'MAP.GROUP' | translate }}</th>
        <td mat-cell *matCellDef="let element">{{element.nome_grupo}}</td>
      </ng-container>
      <ng-container matColumnDef="link">
        <th mat-header-cell *matHeaderCellDef>Link</th>
        <td mat-cell *matCellDef="let element">
          <a [ngClass]="user.darkMode() ? 'link-dark' : ''" href={{element.invite_link}} target="_blank" rel="noreferrer"
            (click)="this.amplitude.sendEvent('Clicou Link', {'Grupo': this.amplitude.groupName['cams'], 'Destino': 'Telegram', 'Assunto': element.tipo_destinatario})">
            {{element.invite_link}}</a>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="groupsTableColumns; sticky: true" ></tr>
      <tr mat-row *matRowDef="let row; columns: groupsTableColumns;"></tr>
    </table>
  }

  @else {
    <mat-card appearance="outlined" style="margin: 2rem">
      <p>{{ 'MAP.NO_GROUP' | translate }}</p>
      <p>{{ 'MAP.CONTACT_UGM' | translate }}</p>
    </mat-card>
  }

</main>