import { Component, OnInit, signal,ViewChild } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';

import { MatDialog } from '@angular/material/dialog';
import { InfoDialog } from "../../shared/dialogs/info-dialog/info-dialog";
import { ConfirmationDialog } from 'src/app/shared/dialogs/confirmation-dialog/confirmation-dialog'

import { UserDataService } from 'src/app/services/user-data.service';
import { HttpService } from 'src/app/services/http.service';
import { TranslateService } from '@ngx-translate/core';
import { AmplitudeService } from '../../services/amplitude.service';
import { SatelliteService } from 'src/app/services/satellite.service';
import { MatSort, Sort } from '@angular/material/sort';

@Component({
  selector: 'app-sat-alerts',
  templateUrl: './sat-alerts.component.html',
  styleUrls: ['./sat-alerts.component.scss']
})
export class SatAlertsComponent implements OnInit {

  constructor(
    protected user: UserDataService,
    private http: HttpService,
    private translate: TranslateService,
    private dialog: MatDialog,
    public amplitude: AmplitudeService,
    public sat: SatelliteService,
  ) { }

  public telegramDetectionGroup: any;
  public telegramTableColumns: string[] = [
    'link',
  ];

  public selectedZone: number | null;
  public hasZones = false;
  
  public recipients: string[]
  public recipientsTableColumns: string[] = [
    'email'
  ]

  public recipients_whatsapp: string[]
  public recipientsTableColumns_whatsapp: string[] = [
    'whatsapp'
  ]

  public newRecipient:string;
  public newRecipient_whatsapp:string;

  public emailFormControl = new UntypedFormControl('', [
    Validators.required,
    Validators.email,
  ]);
    public whatsappFormControl = new UntypedFormControl('', [
    Validators.required,
    Validators.pattern('^[+][+0-9]{11,13}') //começa com "+"; depois somente números (mínimo 11 --> USA; e máximo 13 --> BR)
  ]);  

  public emailAlreadyRegistered = signal<boolean | undefined>(undefined)
  public whatsappAlreadyRegistered = signal<boolean | undefined>(undefined)
  @ViewChild(MatSort) sort: MatSort;

  async ngOnInit(){
    this.selectedZone = null;
    if(!this.telegramDetectionGroup){
      this.getTelegramDetectionGroup();
    };

    await this.sat.getAlertRecipients();
    await this.selectZones();
    this.emailFormControl.valueChanges.subscribe((value: string) => {
      this.setEmailExists(value);
    });
    this.whatsappFormControl.valueChanges.subscribe((value: string) => {
      this.setWhatsappExists(value);
    });
    this.sortOnInit();
  }

  setEmailExists(email: string) {
    if (this.recipients.includes(email)) {
      this.emailAlreadyRegistered.set(true);
    } else {
      this.emailAlreadyRegistered.set(false);
    }
  }

  setWhatsappExists(number: string) {
    if (this.recipients_whatsapp.includes(number)) {
      this.whatsappAlreadyRegistered.set(true);
    } else {
      this.whatsappAlreadyRegistered.set(false);
    }
  }

  async selectZones(){
    if (!!this.sat.alertRecipients){
      if (!this.selectedZone) {
        this.recipients = this.sat.alertRecipients.filter(zone => zone.id_zona === null)[0].sat_alert_recipients_email || []
        this.recipients_whatsapp = this.sat.alertRecipients.filter(zone => zone.id_zona === null)[0].sat_alert_recipients_whatsapp || []
        for (let zone of this.sat.alertRecipients){
          if (zone.id_zona !== null){
            this.hasZones = true
            break
          }
        }
      }
      else {
        for (let zone of this.sat.alertRecipients){
          if (zone.id_zona === this.selectedZone){
            this.recipients = zone.sat_alert_recipients_email || []
            this.recipients_whatsapp = zone.sat_alert_recipients_whatsapp || []
          }
        }
      }
    }
    else {
      this.sat.alertRecipients = []
      this.recipients = []
      this.recipients_whatsapp = []
    }
  }

  addRecipient(){
    if (!this.recipients.includes(this.newRecipient) && !!this.newRecipient){
      this.recipients.push(this.newRecipient);
      this.recipients = [...this.recipients];      
      this.sendUpdateRecipientEvent('Cadastrou Destinatário', 'E-mail', this.newRecipient);      
      this.emailFormControl.reset()
    }
    this.updateRecipients();
  }

  addRecipient_whatsapp(){    
    if (!this.recipients_whatsapp.includes(this.newRecipient_whatsapp) && !!this.newRecipient_whatsapp){
      this.recipients_whatsapp.push(this.newRecipient_whatsapp);
      this.recipients_whatsapp = [...this.recipients_whatsapp];
      this.sendUpdateRecipientEvent('Cadastrou Destinatário', 'WhatsApp', this.newRecipient_whatsapp);
      this.whatsappFormControl.reset()
    }
    this.updateRecipients();
  }
  
  rmRecipient(r){
    const dialogRef = this.dialog.open(ConfirmationDialog, {
      data: {text:`${this.translate.instant("MAP.DELETE_EMAIL_DIALOG")}`}
    })
    
    dialogRef.afterClosed().subscribe((confirmation) => {
      if (!confirmation) return
      this.recipients = this.recipients.filter(d => d!=r);
      this.updateRecipients();
      this.sendUpdateRecipientEvent('Removeu Destinatário', 'E-mail', r);
    })
    this.emailFormControl.reset();
    this.emailAlreadyRegistered.set(false);
  }

  rmRecipient_whatsapp(r){
    const dialogRef = this.dialog.open(ConfirmationDialog, {
      data: {text:`${this.translate.instant("MAP.DELETE_WHATSAPP_DIALOG")}`}
    })
    
    dialogRef.afterClosed().subscribe((confirmation) => {
      if (!confirmation) return
      this.recipients_whatsapp = this.recipients_whatsapp.filter(d => d!=r);
      this.updateRecipients();
      this.sendUpdateRecipientEvent('Removeu Destinatário', 'WhatsApp', r);
    })
    this.whatsappFormControl.reset();
    this.whatsappAlreadyRegistered.set(false);
  }

  async updateRecipients(){    

    let obj = {
      id_zona: this.selectedZone,
      id_planta: this.user.getIdPlanta(),
      recipients_email: this.recipients.length === 0 ? null : this.recipients,
      recipients_whatsapp: this.recipients_whatsapp.length === 0 ? null : this.recipients_whatsapp
    }
    let response = await this.http.maestroPost('update_sat_alert_recipients', obj)
    
    if (response?.status){
      this.dialog.open(InfoDialog, {
        data: {text:`${this.translate.instant("MAP.RECIPIENTS_UPDATED_DIALOG")}`}
      });
      await this.sat.getAlertRecipients();
      await this.selectZones();
    }
    else{
      this.dialog.open(InfoDialog, {
        data: {text:`${this.translate.instant("MAP.ERROR")}: ${response?.msg}`}
      });
    }
  }

  async getTelegramDetectionGroup(){
    let telegramGroup = this.user.getDadosPantera('telegram_groups')
    this.telegramDetectionGroup = telegramGroup.filter(group => group.id_tipo_destinatario === 2);
  }

  sendUpdateRecipientEvent(eventName : string, recipientType : string, recipient : string) {
    let props: { [key: string]: any } = {"Grupo": this.amplitude.groupName['sat'], "Tipo": recipientType, "Destinatário": recipient}
    if (this.selectedZone) {
      const nome_zona = this.sat.alertRecipients.filter(zone => zone.id_zona === this.selectedZone)[0].nome_zona;
      props = { ...props, "Zona": nome_zona };
    }
    this.amplitude.sendEvent(eventName, props);
  }

  sortData(sort: Sort) {
    const data = this.recipients.slice();
    if (!sort.active || sort.direction === '') {
      this.recipients = data;
    } else {
      this.recipients = data.sort((a, b) => {
        return (a.toLowerCase() < b.toLowerCase() ? -1 : 1) * (sort.direction === 'asc' ? 1 : -1);
      });
    }
  }

  sortOnInit() {
    const defaultSort: Sort = { active: 'email', direction: 'asc' }; 
    this.sortData(defaultSort); 
  }

}
