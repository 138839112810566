import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { ReportsService } from 'src/app/services/reports.service';
import { UserDataService } from 'src/app/services/user-data.service';
import { HttpService } from 'src/app/services/http.service';
import { TranslateService } from '@ngx-translate/core';
import { Chart, ChartType, registerables} from 'chart.js';
Chart.register(...registerables); // TO DO só registrar o que realmente usar pra otimizar bundle
import { ACIONAMENTOS_LABELS } from '../dashboard/dashboard.component';
import { BehaviorSubject, Subscription } from 'rxjs';

const PALETA_DASH = [
  '#003f5c',
  '#374c80',
  '#7a5195',
  '#bc5090',
  '#ef5675',
  '#ff764a',
  '#ffa600'
];

interface ChartDataset {
  backgroundColor: string,
  label: string,
  data: number[],
}
@Component({
  selector: 'app-client-dashboard',
  templateUrl: './client-dashboard.component.html',
  styleUrls: ['./client-dashboard.component.scss']
})
export class ClientDashboardComponent implements OnInit, OnDestroy {

  @Input() startDate: Date;
  @Input() endDate: Date;
  @Input() updateData: BehaviorSubject<boolean>;
  public clientDashData: any;
  public relatoriosCount: number;
  public resumoRiscoChartCliente: Chart;
  public acionamentosChartCliente: Chart;
  public areaQueimadaChartCliente: Chart;
  public causasChartCliente: Chart;
  public origensChartCliente: Chart;
  private idPlantas: number[];
  private updateDataSubscription: Subscription;
  public errorMsg = 'Não existem relatórios para o período selecionado';

  constructor(
    public reports: ReportsService,
    private user: UserDataService,
    private http: HttpService,
    private translate: TranslateService,
  ){}

  async ngOnInit() {
    await this.updateDashboard();
    this.updateDataSubscription = this.updateData.subscribe(async (dateChanged) => {
      if (dateChanged){
        if (this.relatoriosCount > 0) this.destroyClientCharts();
        await this.updateDashboard();
      };
    })
  }

  ngOnDestroy() {
    if (this.updateDataSubscription){
      this.updateDataSubscription.unsubscribe();
    }
  }
  
  async updateDashboard(){
    this.clientDashData = await this.reports.getReportsDashDataCliente(this.startDate, this.endDate);
    this.idPlantas = Object.keys(this.clientDashData.acionamentos).map((idPlanta) => Number(idPlanta));
    this.relatoriosCount = 0;
    for (let idPlanta of this.idPlantas){
      this.relatoriosCount += this.clientDashData.acionamentos[`${String(idPlanta)}`].relatorios_count;
    };
    if (this.relatoriosCount > 0){
      this.createClientCharts();
    };
  }

  createClientCharts(){
    this.createResumoRiscoChart();
    this.createAcionamentosChart();
    this.createAreaQueimadaChart();
    this.createCausasChart();
    this.createOrigensChart();
  };

  destroyClientCharts(){
    this.resumoRiscoChartCliente.destroy();
    this.acionamentosChartCliente.destroy();
    this.areaQueimadaChartCliente.destroy();
    this.causasChartCliente.destroy();
    this.origensChartCliente.destroy();
  };

  createAcionamentosChart(){
    const acionamentosLabels = ACIONAMENTOS_LABELS.map((label) => this.translate.instant(label));
    const acionamentosDataset: ChartDataset[] = [];
    let colorIndex = 0;
    
    for (let idPlanta of this.idPlantas){
      const summary = this.clientDashData.acionamentos[idPlanta];
      let dataset: ChartDataset = {
        backgroundColor: PALETA_DASH[colorIndex],
        label: this.user.plantas_list.filter((planta) => planta.id_planta == idPlanta)[0]['nome_planta'],
        data: [summary.relatorios_count, summary.acionamentos_count, summary.acionamentos_count, summary.combates_iniciados_count, summary.rescaldos_iniciados_count],
      };
      acionamentosDataset.push(dataset);
      colorIndex++;
    };
    const chartTitleKey = 'MAP.ACTIVATION_COMBAT';
    const chartTitle = this.translate.instant(chartTitleKey);
    const config = this.configBarChart(chartTitle, acionamentosDataset, acionamentosLabels, true);
    this.acionamentosChartCliente = new Chart('acionamentosChartCliente', config);
  };

  createAreaQueimadaChart(){
    // copiando a funçao de origens e causas mas nao deu pra reaproveitar pelo agrupamento ser por planta e nao por tipo
    let tipos = [];
    for (let idPlanta of this.idPlantas){
      let tiposPlanta = Object.keys(this.clientDashData.relatorios[idPlanta].area_queimada_ha);
      for (let tipo of tiposPlanta){
        tipos.push(tipo);
      }
    }
    const uniqueTipos = [... new Set(tipos)];
    let tiposValuesObj = {};
    for (let tipo of uniqueTipos){
      let areaQueimadaPlantaList = [];
      for (let idPlanta of this.idPlantas){
        let value = this.clientDashData.relatorios[idPlanta]['area_queimada_ha'][tipo] ? this.clientDashData.relatorios[idPlanta]['area_queimada_ha'][tipo] : 0;
        areaQueimadaPlantaList.push({ idPlanta: idPlanta, value: value});
      };
      tiposValuesObj[tipo] = areaQueimadaPlantaList;
    };
    
    let datasets: ChartDataset[] = [];
    let colorIndex = 0;
    for (let tipo of uniqueTipos){
      let data: number[] = [];
      for (let idPlanta of this.idPlantas){
        data.push(tiposValuesObj[tipo].filter((planta) => planta.idPlanta === idPlanta)[0]['value']);
      };
      datasets.push({
        backgroundColor: PALETA_DASH[colorIndex],
        label: tipo,
        data: data,
      });
      colorIndex++;
    };
    let plantasLabels = [];
    for (let idPlanta of this.idPlantas){
      plantasLabels.push(this.user.plantas_list.filter((planta) => planta.id_planta == idPlanta)[0]['nome_planta']); // ta com codigo repetido
    };
    const chartTitleKey = 'LULC.BURNED_AREA';
    const chartTitle = this.translate.instant(chartTitleKey);
    const config = this.configBarChart(chartTitle, datasets, plantasLabels, true);
    this.areaQueimadaChartCliente = new Chart('areaQueimadaChartCliente', config);
  };

  createCausasChart(){
    const chartTitleKey = 'MAP.CAUSES_FIRES';
    const chartTitle = this.translate.instant(chartTitleKey);
    const [uniqueCausas, causasDatasets] = this.prepareLabelsAndDatasets('causa_incendio');
    const config = this.configBarChart(chartTitle, causasDatasets, uniqueCausas, true);
    this.causasChartCliente = new Chart('causasChartCliente', config);

  };

  createOrigensChart(){
    const chartTitleKey = 'MAP.SOURCE_DETECTIONS';
    const chartTitle = this.translate.instant(chartTitleKey);
    const [uniqueOrigens, origensDatasets] = this.prepareLabelsAndDatasets('origem_deteccao');
    const config = this.configBarChart(chartTitle, origensDatasets, uniqueOrigens, true);
    this.origensChartCliente = new Chart('origensChartCliente', config);
  };
  
  createResumoRiscoChart(){
    let plantasLabels = [];
    let riscoDatasets: ChartDataset[] = [];
    let comRiscoData = [];
    for (let idPlanta of this.idPlantas){
      plantasLabels.push(this.user.plantas_list.filter((planta) => planta.id_planta == idPlanta)[0]['nome_planta']);
      const semRiscoValue = Math.min(this.clientDashData.relatorios[idPlanta].sem_risco, this.clientDashData.acionamentos[idPlanta].relatorios_count); // pra evitar dar com risco negativo em alguns casos (que nao deveriam acontecer)
      comRiscoData.push(this.clientDashData.acionamentos[idPlanta].relatorios_count - semRiscoValue);
    };
    riscoDatasets.push({
      backgroundColor: PALETA_DASH[0],
      label: 'Com risco',
      data: comRiscoData,
    });

    let semRiscoData = [];
    for (let idPlanta of this.idPlantas){
      const semRiscoValue = Math.min(this.clientDashData.relatorios[idPlanta].sem_risco, this.clientDashData.acionamentos[idPlanta].relatorios_count);
      semRiscoData.push(semRiscoValue)
    };
    riscoDatasets.push({
      backgroundColor: PALETA_DASH[3],
      label: 'Sem risco',
      data: semRiscoData,
    });
    const chartTitleKey = 'MAP.REPORTS_PER_UNIT';
    const chartTitle = this.translate.instant(chartTitleKey);
    const config = this.configBarChart(chartTitle, riscoDatasets, plantasLabels, true);
    this.resumoRiscoChartCliente = new Chart('resumoRiscoChartCliente', config);
  }

  // para causas e origens, onde os nomes dependem dos dados
  prepareLabelsAndDatasets(key:string){
    let labels = [];
    for (let idPlanta of this.idPlantas){
      let labelsPlanta = Object.keys(this.clientDashData.relatorios[idPlanta][key]);
      for (let label of labelsPlanta){
        labels.push(label);
      }
    }
    const uniqueLabels = [... new Set(labels)];
    let itemsObj = {};
    for (let label of uniqueLabels){
      let itemsPlantaCountList = [];
      for (let idPlanta of this.idPlantas){
        let count = this.clientDashData.relatorios[idPlanta][key][label] ?  this.clientDashData.relatorios[idPlanta][key][label] : 0;
        itemsPlantaCountList.push({ idPlanta: idPlanta, count: count});
      };
      itemsObj[label] = itemsPlantaCountList;
    };

    let datasets: ChartDataset[] = [];
    let colorIndex = 0;

    for (let idPlanta of this.idPlantas){
      let data = [];
      for (let label of uniqueLabels){
        data.push(itemsObj[label].filter((plantasCounts) => plantasCounts.idPlanta == idPlanta)[0].count);
      };
      let dataset: ChartDataset = {
        backgroundColor: PALETA_DASH[colorIndex],
        label: this.user.plantas_list.filter((planta) => planta.id_planta == idPlanta)[0]['nome_planta'],
        data: data,
      };
      datasets.push(dataset);
      colorIndex++;
    };
    return [uniqueLabels, datasets];
  }


  configBarChart(title: string, datasets: ChartDataset[], labels: string[], stacked:boolean = false){
    const data = {
      labels: labels,
      datasets: datasets,
    };
    const chartType: ChartType = 'bar';
    const config = {
      type: chartType,
      data: data,
      options: {
        scales: {
          x: {
            ticks: {
              maxRotation: 45,
              minRotation: 0,
            },
            stacked: stacked,
          },
          y: {
            min: 0,
            stacked: stacked,
          }
        },
        responsive: true,
        plugins: {
          title: {
            display: true,
            text: title,
            font: {
              size: 14,
            }
          },
          legend: {
            display: true,
          }
        }
      }
    };
    return config;
  }

}
