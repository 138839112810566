import { Component, Input, OnChanges, ViewChild } from '@angular/core';
import { DeployService } from 'src/app/services/deploy.service';
import { DeploySidebarComponent } from '../deploy-sidebar/deploy-sidebar.component';

@Component({
  selector: 'app-deploy',
  templateUrl: './deploy.component.html',
  styleUrls: ['./deploy.component.scss']
})
export class DeployComponent implements OnChanges {
  @ViewChild(DeploySidebarComponent) componentDeploySide: DeploySidebarComponent;

  constructor(
    public deployService: DeployService,

  ) { }
  
  @Input() pantera_plantas;

  async ngOnChanges() {
    await this.deployService.saveInfos(this.pantera_plantas);
  }

  async changeKeepCalling(index){
    if (index !== 3) {this.deployService.keepCalling = false;}
  }
}
