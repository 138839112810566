import { Injectable } from '@angular/core';
import { CamerasService } from './cameras.service';
import * as turf from '@turf/turf';


@Injectable({
  providedIn: 'root'
})
export class MapboxService {

  public showMapbox = false;

  constructor(
    private camerasService: CamerasService
  ) { }



  public toggleMapbox(value: boolean): void {
    this.showMapbox = value;
    if (this.showMapbox) {
      this.camerasService.setSelectedAuxCameraStreaming = null;
    }
  }


  public async getElevationProfile(line: turf.Feature<turf.LineString>, length: number) {
    const steps = 100;
    const stepSize = length / steps;

    const coordinates = [];
    for (let i = 0; i <= steps; i++) {
      const point = turf.along(line, i * stepSize, {units: 'kilometers'});
      coordinates.push(point.geometry.coordinates);
    }
    return coordinates;
  }



  
}
