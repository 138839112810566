@if (!loaded) {
  <div class="loading-container">
    <mat-spinner></mat-spinner>
  </div>
}

@else {
  <main id="content-wrapper">
    <div class="pick-interval-wrapper" [ngClass]="{'fx-row-space-between-center': showMultiPlantasButton, 'fx-row-center': !showMultiPlantasButton }">
      @if (showMultiPlantasButton) {
        <mat-button-toggle-group [formControl]="managerOptionsFormControl" (change)="onToggleOptionChange($event.value)">
          @for (option of managerOptions; track option.id) {
            <mat-button-toggle [value]="option.id">
              <button mat-icon-button [matTooltip]="option.tooltip">
                <mat-icon>{{option.icon}}</mat-icon>
              </button>
            </mat-button-toggle>
          }
        </mat-button-toggle-group>
      }
      <mat-form-field appearance="outline" class="pick-interval">
        <mat-label>{{ 'MAP.PICK_INTERVAL' | translate }}</mat-label>
        <mat-date-range-input [rangePicker]="picker">
          <input matStartDate placeholder="{{ 'MAP.STARTS_AT' | translate }}" [(ngModel)]='reportsStartDate'>
          <input matEndDate #endDateInput placeholder="{{ 'MAP.STARTS_AT' | translate }}" [(ngModel)]='reportsEndDate' (dateChange)='onEndDateChange(endDateInput.value)'>
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-date-range-picker #picker color="warn"></mat-date-range-picker>
      </mat-form-field>
      <div class="actions-row" id="to-hide">
      <button mat-mini-fab 
        [matMenuTriggerFor]="downloadMenu"
        matTooltip="{{ 'MAP.EXPORT_DASH' | translate }}" style="margin-left: 1rem;">
        <mat-icon>download</mat-icon>
      </button>
      </div>
      <div>
      <mat-menu  class="download-menu" #downloadMenu="matMenu">
        <button (click)='downloadReportsXls()' mat-menu-item [disabled]='!countData'>{{ 'MAP.EXPORT_EXCEL' | translate }} </button>
        @if(maxDateLimit()){
          <button disabled mat-menu-item>{{ 'MAP.SELECT_PERIOD_DOWNLOAD_EXCEL' | translate }} </button>
        }@else {
          <button (click)='downloadAlertsXls()' mat-menu-item>{{ 'MAP.EXPORT_ALERTS_EXCEL' | translate }} </button>
        }
        <button (click)='downloadPdf()' mat-menu-item>{{ 'MAP.EXPORT_PDF' | translate }} </button>
      </mat-menu>
      </div>
      @if(!expanded){
      <div class="expanded-report actions-row" id="to-hide-2">
          <button mat-mini-fab
            [disabled]='!countData'
            matTooltip="{{ 'MAP.FULL_SCREEN' | translate }}"
            (click)="openFullScreen()">
            <mat-icon >open_in_full</mat-icon>
          </button>
      </div>
    }
    </div>
    @if (managerOptionsFormControl.value === 1) {
      <section>
        @if (!countData) {
          <h4 style="text-align: center; margin-top: 1rem;">
            {{errorMsg}}
          </h4>
        }
        @else {
          <div id='analytics-content'>
            <div class="cards-container">
              @for (c_data of countData; track c_data.text) {
                <mat-card appearance="outlined" class="overview-card">
                  <h2 style="margin:0">
                    {{c_data.text}}
                  </h2>
                  <h1 class='count-span'>
                    <mat-icon>{{c_data.icon}}</mat-icon>
                    {{c_data.value}}
                  </h1>
                  <div class="fx-row-end-center" style="width: 100%;">
                    @if (c_data.duration) {
                      <div>{{c_data.duration}}</div>
                    }
                  </div>
                </mat-card>
              }
            </div>
            <div class="charts-container">
              <div>
                <canvas id="acionamentosChart"></canvas>
              </div>
              <div>
                <canvas style=" height:259px" id="areaQueimadaChart"></canvas>
              </div>
              <div>
                <canvas id="causasChart"></canvas>
              </div>
              <div>
                <canvas id="origensChart"></canvas>
              </div>
              <div>
                <canvas id="riscoChart"></canvas>
              </div>
              @if(deletedObjectsData.length >0){
              <div>
                <canvas id="deletedChart"></canvas>
              </div>
            }
              @if(trpEvolution && trpEvolution.length > 0){
              <div>
                <canvas id="tprevolutionChart"></canvas>
                <div style="justify-content: center; display: flex;" [innerHTML]="'MAP.ASSERTIVENESS_FAQ' | translate" 
                (click)="this.amplitude.sendEvent('Clicou Link', {'Grupo': this.amplitude.groupName['reports'], 'Destino': 'Zendesk (Central de Ajuda)', 'Assunto': 'Assertividade da detecção automática'})"></div>
              </div>

            }
              @if (user.hasOcorrenciaRegiao()) {
                <div>
                  <canvas id="jariChart"></canvas>
                </div>
              }
              <div>
                <canvas id="operadoresChart"></canvas>
              </div>
            </div>
          </div>
        }
      </section>
    }
    @if (managerOptionsFormControl.value === 2) {
      <section>
        <app-client-dashboard [startDate]="reportsStartDate" [endDate]="reportsEndDate" [updateData]="dateChanged"></app-client-dashboard>
      </section>
    }
  </main>
}