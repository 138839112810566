@if (isSavingPresetsConfigsChangesState()) {
  <app-loading message="Salvando alterações..." />  
}

@else {
  <div class="presets-wrapper">
    <section class='preset-config-wrapper'>
      <div class='fx-row-center fx-space-around'>
        <div class="fx-col-center" style="margin-top: 20px;">
          <mat-form-field class="configForm" appearance="outline">
            <mat-label>{{ 'MAP.SELECT_TOWER' | translate }}</mat-label>
            <mat-select
              [(ngModel)]="selectedCam"
              (selectionChange)="changeCam()"
              [disabled]="pp.isSavingMultiplePresetsImages() || isSimulatingPresets()">
              @for (camera of cameras; track camera.id) {
                <mat-option
                  [value]="camera">
                  {{ camera.nome_local }}
                </mat-option>
              }
            </mat-select>
          </mat-form-field>
        </div>
        <div class="presets-actions-wrapper">
          <button (click)="previousPosition()"
            mat-fab color="accent"
            matTooltip="{{ 'MAP.PREVIOUS' | translate }}" matTooltipPosition="above" >
            <mat-icon>skip_previous</mat-icon>
          </button>
          <button (click)="nextPosition()" id="skip"
            mat-fab color="accent"
            matTooltip="{{ 'MAP.NEXT' | translate }}" matTooltipPosition="above">
            <mat-icon>skip_next</mat-icon>
          </button>
          <button mat-fab color="accent"
            (click)="drawAllPresets()"
            matTooltip="{{ 'MAP.DRAW_ALL_PRESETS' | translate }}">
            <mat-icon>draw</mat-icon>
          </button>
          <button mat-fab color="accent"
            (click)="clearPresets()"
            matTooltip="{{ 'MAP.CLEAR_PRESETS' | translate }}">
            <mat-icon>backspace</mat-icon>
          </button>
  
          @if(user.isSupportP2()) {
            <button mat-fab color="accent" id="last" [matMenuTriggerFor]="configMenuActions">
              <mat-icon>build</mat-icon>
            </button>
            <mat-menu #configMenuActions="matMenu">
              <button [disabled]="pp.isSavingMultiplePresetsImages() || isSimulatingPresets()" (click)="$event.stopPropagation()" mat-menu-item><mat-slide-toggle color="warn" [checked]="isUpdatingPresetsConfigsState()" (change)="toggleUpdatePresetConfigs()"></mat-slide-toggle>&nbsp;Configurar presets</button>
              <button [disabled]="pp.isSavingMultiplePresetsImages() || isSimulatingPresets()" (click)="$event.stopPropagation()" mat-menu-item><mat-slide-toggle color="warn" [checked]="shouldPointCameraOnPresetSelection()" (change)="toggleShouldPointCameraOnPresetSelection()"></mat-slide-toggle>&nbsp;Apontar câmera ao selecionar</button>
              <button [disabled]="pp.isSavingMultiplePresetsImages() || isSimulatingPresets()" mat-menu-item (click)="addPreset()"><mat-icon>add</mat-icon>Adicionar preset</button>
              <button [disabled]="hasChangesPresetsConfigs() || pp.isSavingMultiplePresetsImages() || isSimulatingPresets()" mat-menu-item (click)="savePresetImages()"><mat-icon>image</mat-icon>Salvar imagens</button>
              <button [disabled]="pp.isSavingMultiplePresetsImages() || isSimulatingPresets()" mat-menu-item (click)="runAutoPresetGenerator()"><mat-icon>queue</mat-icon>Gerador automático</button>
              @if(!this.isSimulatingPresets()) {
                <button [disabled]="pp.isSavingMultiplePresetsImages() || isSimulatingPresets()" mat-menu-item (click)="simulatePresets()"><mat-icon>radar</mat-icon>Simular presets</button>
              }
            </mat-menu>
  
            @if(hasChangesPresetsConfigs()) {
              <button [disabled]="pp.isSavingMultiplePresetsImages() || isSimulatingPresets()" matTooltip="Salvar mudanças" mat-fab color="primary" (click)="saveChangesOnPresets()">
                <mat-icon>save</mat-icon>
              </button>
            }

            @if(pp.isSavingMultiplePresetsImages()) {
              <button matTooltip="Pausar processo" mat-fab color="primary" (click)="pp.setIsSavingMultiplePresetsImages(false)">
                <mat-icon>pause</mat-icon>
              </button>
            }

            @if(isSimulatingPresets()) {
              <button matTooltip="Pausar processo" mat-fab color="primary" (click)="stopSimulatingPresets()">
                <mat-icon>pause</mat-icon>
              </button>
            }
          }
        </div>
      </div>

      @if (pp.isSavingPresetImage()) {
        <app-loading message="Salvando imagem de preset..." />  
      }
      @else {
        @if (presetActiveIndex) {
          <div class='img-wrapper fx-col-center fx-space-around'>
            <canvas id='presetCanvas'></canvas>
            <img [src]="presetsList()[presetActiveIndex - 1]?.img_url ?? 'assets/preset-fallback.png'" (load)="drawRect()"/>
          </div>
        }
    
        <div class="table-wrapper">
          <table mat-table [dataSource]="presetsList()" class="mat-elevation-z8">
            <ng-container matColumnDef="index">
              <th color="warn" mat-header-cell *matHeaderCellDef [ngClass]="{'th-top': !presetActiveIndex}">N°</th>
              <td mat-cell *matCellDef="let element">
                <button mat-mini-fab color="primary">
                  {{ element.index }}
                </button>
              </td>
            </ng-container>
            <ng-container matColumnDef="pan">
              <th mat-header-cell *matHeaderCellDef [ngClass]="{'th-top': !presetActiveIndex}">{{ 'INTERFACE.AZIMUTH' | translate }} (°)</th>
              <td mat-cell *matCellDef="let element">
                @if(isUpdatingPresetsConfigsState()) {
                  <input (click)="$event.stopPropagation()" class="presetTableInput" min=0 max=359.99 (change)="updateInput(element.index, 'pan')" type="number" [(ngModel)]="element.pan" required/>
                }
                @else {
                  {{element.pan}}
                }
              </td>
            </ng-container>
            <ng-container matColumnDef="tilt">
              <th mat-header-cell *matHeaderCellDef [ngClass]="{'th-top': !presetActiveIndex}">Tilt (°)</th>
              <td mat-cell *matCellDef="let element">
                @if(isUpdatingPresetsConfigsState()) {
                  <input (click)="$event.stopPropagation()" class="presetTableInput" min=0 max=120 (change)="updateInput(element.index, 'tilt')" type="number" [(ngModel)]="element.tilt" required/>
                }
                @else {
                  {{element.tilt}}
                }
              </td>
            </ng-container>
            <ng-container matColumnDef="zoom">
              <th mat-header-cell *matHeaderCellDef [ngClass]="{'th-top': !presetActiveIndex}">Zoom (x)</th>
              <td mat-cell *matCellDef="let element">
                @if(isUpdatingPresetsConfigsState()) {
                  <input (click)="$event.stopPropagation()" class="presetTableInput" min=1 max=45 (change)="updateInput(element.index, 'zoom')" type="number" [(ngModel)]="element.zoom" required/>
                }
                @else {
                  {{element.zoom}}
                }
              </td>
            </ng-container>
            <ng-container matColumnDef="masks">
              <th mat-header-cell *matHeaderCellDef [ngClass]="{'th-top': !presetActiveIndex}" matTooltip="{{ 'MAP.MASKS_TOOLTIP' | translate }}"> {{ 'MAP.MASKS' | translate }}</th>
              <td mat-cell *matCellDef="let element">
                {{ getCountMasks(element.labels) }}
              </td>
            </ng-container>
            <ng-container matColumnDef="fov">
              <th mat-header-cell *matHeaderCellDef [ngClass]="{'th-top': !presetActiveIndex}">{{ 'MAP.VIEWING_ANGLE' | translate }} (°)</th>
              <td mat-cell *matCellDef="let element">
                {{ getFov(element.zoom) | number:'.0-0'}}
              </td>
            </ng-container>
            
            <ng-container matColumnDef="actions">
              <th mat-header-cell *matHeaderCellDef [ngClass]="{'th-top': !presetActiveIndex}">&nbsp;</th>
              <td mat-cell *matCellDef="let element">
                @if(user.isSupportP2()) {
                  <button [disabled]="pp.isSavingMultiplePresetsImages() || isSimulatingPresets()" mat-icon-button (click)="addOrUpdatePresetImage($event, element)"><mat-icon>add_a_photo</mat-icon></button>
                  <button [disabled]="pp.isSavingMultiplePresetsImages() || isSimulatingPresets()" mat-icon-button (click)="deletePreset($event, element)"><mat-icon>delete</mat-icon></button>
                }
              </td>
            </ng-container>
    
            <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky:true"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"
              (click)="selectRow(row)"
              [ngClass]="row.index == presetActiveIndex ? 'selected-row' : row.status ? 'edit-mode' : 'regular-row'"
            ></tr>
          </table>
        </div>
      }
  
    </section>
  </div>
}