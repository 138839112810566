import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { environment } from '../../environments/environment'
import { catchError, timeout } from 'rxjs/operators';
import { firstValueFrom, lastValueFrom, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HttpService {

  private MAESTRO_URL = environment.api;
  private CAMS_API_ADDRESS = environment.camsCloudApiUrl;
  private PROJECTS_API_ADDRESS = environment.projectsApiUrl;
  private OPEN_ROUTE_API_BASE_URL = 'https://api.openrouteservice.org/v2/';
  private OPEN_ROUTE_API_KEY = '5b3ce3597851110001cf6248380d9489b47c4445a5e76a953acd5bb4';
  private requestTimeout = 100000;
  private reducedRequestTimeout = 7000;

  constructor(private http: HttpClient) { }

  // Basics
  async get(route: string): Promise<any> {
    return await firstValueFrom(this.http.get(route));
  }

  async post(route: string, data: any): Promise<any> {
    return await firstValueFrom(this.http.post(route, data));
  }


  // Aux
  private get authHeader(): HttpHeaders {
    return new HttpHeaders({
      'Authorization': 'Bearer ' + this.maestroBearer
    });
  }

  private get headers(): HttpHeaders {
    return new HttpHeaders({
      'content-type': 'application/json',
      'Authorization': 'Bearer ' + this.maestroBearer
    });
  }

  // Jeito mais eficiente?
  public get maestroBearer(): string {
    return localStorage.getItem('MAESTRO')
  }

  public get getServerUrl() {
    return this.MAESTRO_URL;
  }


  // Maestro
  async maestroGet(route: string, token: string | null = null, reduced = false): Promise<any> {
    const header = token
      ? new HttpHeaders({ 'Authorization': `Bearer ${token}` })
      : this.authHeader;

    const timeoutValue = reduced ? this.reducedRequestTimeout : this.requestTimeout;

    try {
      const response = await lastValueFrom(
        this.http.get(`${this.MAESTRO_URL}/${route}`, { headers: header }).pipe(
          timeout(timeoutValue),
          catchError((error) => {
            console.error("Erro em maestroGet", error.message);
            return of(null);
          })
        )
      );
      return response;
    } catch (err) {
      return null;
    }
  }

  async maestroPost(route: string, object: any, useAuthHeader: boolean = false): Promise<any> {
    const headers = useAuthHeader ? this.authHeader : this.headers;
    return await firstValueFrom(this.http.post(`${this.MAESTRO_URL}/${route}`, object, { headers: headers, withCredentials: true }));
  }

  async maestroUnauthPost(route: string, object: any): Promise<any> {
    return await firstValueFrom(this.http.post(`${this.MAESTRO_URL}/${route}`, object));
  }

  async maestroPatch(route: string, body: any) {
    return await firstValueFrom(this.http.patch(`${this.MAESTRO_URL}/${route}`, body, { headers: this.headers })).then(data => data, err => null);
  }

  async maestroGetBinary(route: string) {
    let options = { headers: this.authHeader, responseType: 'arraybuffer' as 'json' };
    return await firstValueFrom(this.http.get(`${this.MAESTRO_URL}/${route}`, options)).then(data => data, err => null);
  }

  // This method returns an Observable -> Must use '.subscribe()' after calling it
  maestroUpload(route: string, formData: any) {
    return this.http.post(`${this.MAESTRO_URL}/${route}`, formData, { headers: this.authHeader, withCredentials: true })
  }


  // Cams
  async camsGet(route: string): Promise<any> {
    return await firstValueFrom(this.http.get(`${this.CAMS_API_ADDRESS}/${route}`, { headers: this.headers }));
  }

  async camsPost(route: string, object: any): Promise<any> {
    return await firstValueFrom(this.http.post(`${this.CAMS_API_ADDRESS}/${route}`, object, { headers: this.headers }));
  }


  // Projects
  async projectsGet(route: string) {
    return await firstValueFrom(this.http.get(`${this.PROJECTS_API_ADDRESS}/${route}`, { headers: this.headers })).then(data => data, err => null);
  }

  async projectsPost(route: string, object) {
    return await firstValueFrom(this.http.post(`${this.PROJECTS_API_ADDRESS}/${route}`, object, { headers: this.headers, withCredentials: true })).then(data => data, err => null);
  }

  async projectsPut(route: string, object) {
    return await firstValueFrom(this.http.put(`${this.PROJECTS_API_ADDRESS}/${route}`, object, { headers: this.headers, withCredentials: true })).then(data => data, err => null);
  }

  async projectsDelete(route: string) {
    return await firstValueFrom(this.http.delete(`${this.PROJECTS_API_ADDRESS}/${route}`, { headers: this.headers })).then(data => data, err => null);
  }


  // Others
  async openRoutePost(route: string, object) {
    const headers = new HttpHeaders({
      'content-type': 'application/json',
      'Authorization': `${this.OPEN_ROUTE_API_KEY}`,
    });
    return await firstValueFrom(this.http.post(`${this.OPEN_ROUTE_API_BASE_URL}/${route}`, object, { headers: headers })).then(data => data, err => null);
  }
}
