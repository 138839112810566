interface Scripts {
    name: string;
    src: string;
}

// deprecated (nao tirei por enquanto pq scripts service ainda pode ser util)

export const ScriptStore: Scripts[] = [
    // {name: 'leaflet-ruler', src: 'https://cdn.jsdelivr.net/gh/gokertanrisever/leaflet-ruler@master/src/leaflet-ruler.js'}
    // {name: 'windy', src: 'https://unpkg.com/leaflet@1.4.0/dist/leaflet.js'},
    // {name: 'windy2', src: 'https://api.windy.com/assets/map-forecast/libBoot.js'},
];