<style>

  .outlineForm{
    margin: 0 3px;
    width: 180px;
}

  .form{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 2rem;
  }

</style>

<div class="report-dialog-wrapper">
  <mat-dialog-content>
  
    <h3>{{ 'MAP.REGISTER_DAMAGE' | translate }}</h3>
  
    <form class='form' [formGroup]='lossForm'>
  
      <mat-form-field appearance="outline" class="outlineForm">
        <mat-label>{{ 'MAP.TYPE' | translate }}</mat-label>
        <mat-select [(ngModel)]="data.tipo" formControlName='type'>
          @for (p of tiposPerdas; track p) {
            <mat-option [value] = "p">
              {{p}}
            </mat-option>
          }
        </mat-select>
      </mat-form-field>
  
      <mat-form-field appearance="outline" class="outlineForm">
        <mat-label>{{ 'MAP.UNIT' | translate }}</mat-label>
        <mat-select [(ngModel)]="data.unidade" formControlName='unit'>
          @for (u of unidades; track u.name) {
            <mat-option [value] = "u.unit">
              {{u.name}}
            </mat-option>
          }
        </mat-select>
      </mat-form-field>
  
      <mat-form-field appearance="outline" class="outlineForm">
        <mat-label>{{ 'MAP.VALUE' | translate }}</mat-label>
        <input matInput type="number" [(ngModel)]="data.valor" formControlName='value'>
        <span matSuffix>{{data.unidade}}</span>
      </mat-form-field>

      <mat-form-field appearance="outline" class="outlineForm">
        <mat-label>{{ 'MAP.LOCATION' | translate }}</mat-label>
        <input type="text" matInput [matAutocomplete]="auto" formControlName="nome_local" [(ngModel)]="data.nome_local">
        <mat-autocomplete #auto="matAutocomplete">
          <mat-option *ngFor="let local of filteredLocais | async" [value]="local.nome_geom">
            {{ local.nome_geom }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>

    </form>
  </mat-dialog-content>
  
  <mat-dialog-actions align="center">
    <button mat-raised-button [mat-dialog-close]='false'>{{ 'MAP.CANCEL' | translate }}</button>
    <button mat-raised-button [color]="'primary'"
    [disabled]='lossForm.status=="INVALID"'
    [mat-dialog-close]="data">{{ 'MAP.CONFIRM' | translate }}</button>
  </mat-dialog-actions>
</div>