<main>
  <div id="logo-container">
    <div class="full-negative-logo"></div>
  </div>
  
  <p class="version"> {{version}} </p>
  
  <div class="progress-bar-wrapper">
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  </div>
</main>