import { formatDate } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';


const alternate_color = '#ddd'
let popups = []


export function updatePopupLang(translate: TranslateService) {
  popups.forEach(popup => {
    let newContentHTML;

    switch (popup.tipo) {
      case 'Tower':
        newContentHTML = formatUgmTowerInfo(popup.feature, popup.instalacoes_object, translate);
        break;
      case 'Brigade':
        newContentHTML = formatBrigadeInfo(popup.properties, translate);
        break;
      case 'ReportLocationInfo':
        newContentHTML = formatReportLocationInfo(popup.r, translate);
        break;
      case 'LocationInfo':
        newContentHTML = formatLocationInfo(popup.dadosLoc, translate);
        break;
      case 'Cluster':
        newContentHTML = formatClusterPopUp(popup.properties, translate);
        break;
      case 'Cluster Hotspot':
        newContentHTML = formatHotspotPopUp(popup.properties, translate);
        break;
      case 'Cluster Timeline':
        newContentHTML = formatClusterTimelinePopUp(popup.properties, translate);
        break;
      case 'Hotspot Talhao':
        newContentHTML = formatHotspotTalhaoPopUp(popup.properties, translate);
        break;
      case 'Hotspot Roi':
        newContentHTML = formatHotspotRoiPopUp(popup.properties, translate);
        break;
      case 'True color Image - Before':
        newContentHTML = formatReportSatImagePopUp(popup.properties, translate);
        break;
      case 'True Color Image - After':
        newContentHTML = formatReportSatImagePopUp(popup.properties, translate);
        break;
      default:
        newContentHTML = null;
        break;
    }

    if (newContentHTML) {
      popup.marker.bindPopup(newContentHTML);
    }
  });
}


export function addActivatedPopup(popupInfo) {
  popups.push(popupInfo);
}

export function removeActivatedPopup(marker) {
  popups = popups.filter(popup => popup.marker !== marker);
}


export function formatTalhaoPopUp(properties){
  let infoHTML = '<table>'
  infoHTML += tableHeader(`${properties.nome_geom}`,`${properties.fill_color}`,'black','12px');
  properties.atributos.forEach((atributo) => {
    const key = Object.keys(atributo)[0];
    const value = atributo[key];
    infoHTML += tableRow_v2(key, value, 'white');
  });
  infoHTML+= tableRow_v2('Área', `${properties.area_ha} ha`, 'white');
  return infoHTML + '</table>'
}

export function formatTalhaoPopUpAtributosOld(properties){
  let infoHTML = '<table>'
  infoHTML += tableHeader(`${properties.nome_geom}`,`${properties.fill_color}`,'black','12px');
  properties.atributos.forEach((atributo) => {
    const key = atributo['key'];
    const value = atributo['value'];
    infoHTML += tableRow_v2(key, value, 'white');
  });
  infoHTML+= tableRow_v2('Área', `${properties.area_ha} ha`, 'white');
  return infoHTML + '</table>'
}

export function formatUgmTowerInfo(feature, instalacoes_object, translate: TranslateService){
  let infoHTML = '<table>'

  // Torre
  switch(feature.properties.id_tipo_local){
    case 1:
      infoHTML += tableHeader(feature.properties.nome_ugm, '#ee6b6e'); //light red
      break;
    case 2:
      infoHTML += tableHeader(feature.properties.nome_ugm, '#3b8132'); //dark green
      break;
    case 3:
      infoHTML += tableHeader(feature.properties.nome_ugm, '#e2fcff'); //ice
      break;
    case 5:
      infoHTML += tableHeader(feature.properties.nome_ugm, '#ee6b6e'); //light red
      break;
    default:
      infoHTML += tableHeader(feature.properties.nome_ugm, 'yellow');
  }
  infoHTML+= tableRow_v2('id Local', feature.properties.id_local)
  infoHTML+= tableRow_v2(translate.instant("MAP.TOWER_OWNER"), feature.properties.proprietario, alternate_color)
  if (feature.properties.id_tipo_local !== 1) {
    infoHTML+= tableRow_v2(translate.instant("MAP.TOWER_HEIGHT"), `${feature.properties.altura_m} m`)
  }

  // Equipamentos
  infoHTML += tableHeader(translate.instant("MAP.EQUIPMENT"), 'gray')  
  let ct = 0;

  try {
    for (const i of instalacoes_object.instalacoes) {
      let color = (ct++ % 2) === 0 ? 'white' : alternate_color;
      if (i.ip_wan){
        infoHTML+= tableRow_v2(i.equipamento, `LAN: ${i.ip_lan}:${i.port_lan} | WAN: ${i.ip_wan}:${i.port_wan}` , color)
      } else {
        infoHTML+= tableRow_v2(i.equipamento, i.ip_lan, color)
      }
    }
  } catch {
    console.log('Sem instalacoes no local')
  }
  
  // Energia
  let title_col = feature.properties.energia_concessionaria? 'white':'yellow'
  let energy_text = feature.properties.energia_concessionaria? (translate.instant("MAP.UTILITY_ENERGY")): (translate.instant("MAP.PHOTOVOLTAICS"))
  
  infoHTML += tableHeader(energy_text, 'black', title_col)
  for (const key of ['solar', 'baterias']) {
    let color = (ct++ % 2) === 0 ? 'white' : alternate_color;
    if (key === 'solar') {
      infoHTML+= tableRow_v2(translate.instant("MAP.SOLAR"), feature.properties[key], color)
    } else {
      infoHTML+= tableRow_v2(translate.instant("MAP.BATTERY"), feature.properties[key], color)
    }
  }

  // Maps
  let mapsLink = `http://www.google.com/maps/place/${feature.geometry.coordinates[1]},${feature.geometry.coordinates[0]}`
  infoHTML +=
  `<tr style="background-color:lightgray; text-align:center;">
    <td colspan="2"><a target="_blank" rel="noopener noreferrer" href="${mapsLink}" style="color:green">LinkGoogleMaps</a></td>
    </tr>
  </table>`
  
  return infoHTML;  
};


export function formatClientTowerInfo(feature, translate: TranslateService){
  let infoHTML = '<table>'

  switch(feature.properties.id_tipo_local){
    case 1:
      infoHTML += tableHeader(feature.properties.nome_ugm, '#ee6b6e'); //light red
      break;
    case 2:
      infoHTML += tableHeader(feature.properties.nome_ugm, '#3b8132'); //dark green
      break;
    case 3:
      infoHTML += tableHeader(feature.properties.nome_ugm, '#e2fcff'); //ice
      break;
    case 5:
      infoHTML += tableHeader(feature.properties.nome_ugm, '#ee6b6e'); //light red
      break;
    default:
      infoHTML += tableHeader(feature.properties.nome_ugm, 'yellow');
  }  
  
  if (feature.properties.proprietario != null) {
    infoHTML+= tableRow_v2(translate.instant("MAP.TOWER_OWNER"), feature.properties.proprietario)
  }
  if (feature.properties.altura_m != null) {
    infoHTML+= tableRow_v2(translate.instant("MAP.TOWER_HEIGHT"), `${feature.properties.altura_m} m`, alternate_color)
  }
  
  infoHTML+= tableRow_v2(translate.instant("MAP.TOWER_ENERGY_SOURCE"), feature.properties.energia_concessionaria? 'Concessionária':'Fotovoltaica')

  return infoHTML;
}


export function formatBrigadeInfo(properties, translate: TranslateService){
  let infoHTML = '<table>'
  infoHTML+= tableHeader(`${properties.nome_brigada}`, '#c4c4c4')
  infoHTML+= tableRow_v2(translate.instant("MAP.BRIGADE_LAST_UPDATE"), formatDate(properties.last_updated, 'HH:mm dd/MMM/yyyy', 'en-US'))
  let ct = 1
  if (properties.placa){
    let col = (ct++ % 2) === 0 ? 'white' : alternate_color;
    infoHTML+= tableRow_v2(translate.instant("MAP.BRIGADE_PLATE"), properties.placa, col)
  }
  if (properties.obs_brigada){
    for (const [key, value] of Object.entries(properties.obs_brigada)) {
      let col = (ct++ % 2) === 0 ? 'white' : alternate_color;
      infoHTML+= tableRow_v2(key, value, col)
    }
  }
  return infoHTML
}


export function formatLocationInfo(dadosLoc, translate: TranslateService){
  let infoHTML = '<table>'
  infoHTML += tableRow_v2('Latitude', dadosLoc?.lat.toFixed(4), 'white') 
  infoHTML += tableRow_v2('Longitude', dadosLoc?.lon.toFixed(4), alternate_color)
  if (dadosLoc?.intersect){
    infoHTML+= tableRow_v2(translate.instant("MAP.LOCATION"), dadosLoc?.properties?.nome_geom, 'white')
  } else {
    infoHTML+= tableRow_v2(translate.instant("MAP.NEAREST_PROPERTY"), dadosLoc?.properties?.nome_geom, 'white')
    infoHTML+= tableRow_v2(translate.instant("MAP.DISTANCE_FROM_PROPERTY"), `${dadosLoc?.properties?.distanceToPoint.toFixed(2)} km`, alternate_color)
  }
  return infoHTML + "</table>"
}


export function formatReportLocationInfo(r, translate: TranslateService) {
  let dados_localizacao = r.dados_localizacao[0]
  let obj = dados_localizacao.dados_geo?.atributos || {};
  let coord = {Latitude: parseFloat(dados_localizacao.lat).toFixed(4), Longitude: parseFloat(dados_localizacao.lon).toFixed(4)};
  let infoHTML = '<table>'
  
  let header_fill_color = r.icon.color;
  switch(header_fill_color){ //evitar cores brilhantes
    case 'red': 
      infoHTML+= tableHeader(`N° ${r.n_relatorio_planta}`, '#d93b3b')
      break
    case 'orange':
      infoHTML+= tableHeader(`N° ${r.n_relatorio_planta}`, '#e6b04e')
      break
    case 'amber':
      infoHTML+= tableHeader(`N° ${r.n_relatorio_planta}`, '#ede99d')
      break
    case 'blue':
      infoHTML+= tableHeader(`N° ${r.n_relatorio_planta}`, '#0072b2')
      break
    case 'black':
      infoHTML+= tableHeader(`N° ${r.n_relatorio_planta}`, '#3d3d3d', 'white')
      break
    default:
      infoHTML+= tableHeader(`N° ${r.n_relatorio_planta}`, header_fill_color)
      break
  }

  let ct = 0;
  let lat_long = ['Latitude', 'Longitude']
  for (let c of lat_long) {
    let col = (ct++ % 2) === 0 ? 'white' : alternate_color;
    infoHTML += tableRow_v2(c, coord[c], col); 
  }
  
  if (dados_localizacao.distancia_area) {
    infoHTML+= tableHeader(translate.instant("MAP.NEAREST_PROPERTY"), '#c4c4c4')
    infoHTML+= tableRow_v2(translate.instant("MAP.DISTANCE"), `${dados_localizacao.distancia_area} km`, alternate_color)
  } else {
    infoHTML+= tableHeader(translate.instant("MAP.LOCATION"), '#c4c4c4')
  }

  let polygon_attribute_keys = ['Local', 'Outros', 'Area_poligono']
  for (let k of polygon_attribute_keys) {
    if (k in obj) {
      for (let i of obj[k]) {
        let col = (ct++ % 2) === 0 ? 'white' : alternate_color;

        if (i.key === "Área do polígono (ha)") {
          const translatedKey = translate.instant("MAP.AREA_POLYGON");
          infoHTML += tableRow_v2(translatedKey, i.value, col);
        }else{
        infoHTML+= tableRow_v2(i.key, i.value, col)
        }
      }
    }
  }
  //TO DO Se usuarios não reclamarem pela falta dos dados meteo no popup, remover.
  // try{
  //   infoHTML+= tableHeader(translate.instant("MAP.METEO_DATA"), '#c4c4c4')
  //   infoHTML += tableRow_v2(translate.instant("MAP.TEMPERATURE"), `${r.dados_meteo[0]['temp']} ºC`, alternate_color)
  //   infoHTML += tableRow_v2(translate.instant("MAP.HUMIDITY"), `${r.dados_meteo[0]['humidity']} %`, 'white')
  //   infoHTML += tableRow_v2(translate.instant("MAP.WIND_SPEED"), `${(r.dados_meteo[0]['wind_speed']*3.6).toFixed(2)} km/h`, alternate_color)
  //   infoHTML += tableRow_v2(translate.instant("MAP.WIND_DIRECTION"), `${r.dados_meteo[0]['wind_deg']} º`, 'white')
  //   infoHTML += tableRow_v2(translate.instant("MAP.PRECIPITATION"), `${r.dados_meteo[0]['rain']} mm`, alternate_color)
    
  // } catch { }

  let mapsLink = `http://www.google.com/maps/place/${dados_localizacao.lat},${dados_localizacao.lon}`
  infoHTML +=
  `<tr style="background-color:lightgray; text-align:center;">
    <td colspan="2"><a target="_blank" rel="noopener noreferrer" href="${mapsLink}" style="color:green">LinkGoogleMaps</a></td>
    </tr>`

  return infoHTML+'</table>';
}

export function formatLinkInfo(link){
  let infoHTML = '<table>'
  infoHTML+= tableHeader('LINKS', 'blue', 'white')
  
  let ct = 0;
  let data = [
    'id_link',
    'ip_lan_origem', 
    'ip_lan_destino',
    'ssid',
    'distancia_km',
    'origem_ap',
    'fabricante'
  ]
  for (const [key, value] of Object.entries(link)) {
    if (data.includes(key)){
      let col = (ct++ % 2) === 0 ? 'white' : alternate_color;
      infoHTML+= tableRow(key, value, col)
    }
  }
  return infoHTML
}

export function formatPlantaInfo(planta){
  let infoHTML = '<table>'
  infoHTML+= tableHeader(planta.nome_cliente, 'black', 'white');

  let ct = 0;
  let filter_keys = [
    'pantera_version',
    'tipo_planta',
    'nome_planta'
  ]
  for (const [key, value] of Object.entries(planta)) {
    if (filter_keys.includes(key)){
      let col = (ct++ % 2) === 0 ? 'white' : '#cccccc';
      infoHTML+= tableRow(key, value, col);

    }
  }

  infoHTML += tableHeader('Produtos', "#ff1a1a");
  ct=0
  for (const [key, value] of Object.entries(planta.pantera_features)) {
    let col = (ct++ % 2) === 0 ? 'white' : '#cccccc';
    infoHTML+= tableRowLeftCenter(key, value, col);
  }
  
  infoHTML += tableHeader('Infraestrutura', '#999966');
  ct=0
  if (planta.locais_count){
    for (const l of planta.locais_count) {
      let col = (ct++ % 2) === 0 ? 'white' : '#cccccc';
      infoHTML+= tableRowLeftCenter(l.tipo_local, l.count, col);
    }
  }

  infoHTML += tableHeader('Área monitorada (mil ha)', '#999966');
  infoHTML+= tableRowLeftCenter('Área total', numberWithCommas(planta['talhao_sem_sobreposicao_ha']), 'white');
  infoHTML+= tableRowLeftCenter('Área visível por câmeras', numberWithCommas(planta['talhao_visivel_ha']), '#cccccc');
  infoHTML+= tableRowLeftCenter('Área visível média por câmera', numberWithCommas(planta['talhao_visivel_por_camera_ha']), 'white');

  return infoHTML
}

export function formatGeoInfo(feature){
  return feature.properties.nome
}

export function formatPoiInfo(properties){
  let infoHTML = '';
  infoHTML += `<div style="background-color:#d8dce3; font-size:12px; padding-top:3px, padding-bottom:3px; padding-left:5px; padding-right:17px; white-space:nowrap; text-align:center;"><strong>${properties.nome_poi}</strong></div>`;
  if (properties.obs){
    infoHTML+= `<div style="background-color:#ffffff; font-size:12px; padding-top:3px, padding-bottom:3px; padding-left:5px; padding-right:5px">${properties.obs}</div>`;
  }
  return infoHTML
}

export function formatClusterPopUp(properties, translate: TranslateService){

  let symbology_class = properties['symbology_class'];
  let cluster_id = properties['pk_mv'] ?? properties['cluster_id'];
  let distance_to_talhao = properties['distance_to_talhao_m'];

  let infoHTML = '<table>'
  switch(symbology_class){
    case 1:
      infoHTML += tableHeader (`${translate.instant("SAT.CLUSTER")} (#${cluster_id})`,'#ff7070','black','12px');
      break;
    case 2:
      infoHTML += tableHeader(`${translate.instant("SAT.CLUSTER")} (#${cluster_id})`,'#feb24c','black','12px');
      break;
    case 3:
      infoHTML += tableHeader(`${translate.instant("SAT.CLUSTER")} (#${cluster_id})`,'#ffeda0','black','12px');
      break;
    case 4:
      infoHTML += tableHeader(`${translate.instant("SAT.CLUSTER")} (#${cluster_id})`,'#bdbdbd','black','12px');
      break;            
  }
  
  if (distance_to_talhao > 0) {
    infoHTML+= tableRow_v2(`${translate.instant("MAP.PROPERTY")}`, `${properties['talhao_nome']} (${properties['distance_to_talhao_m']} m)`, 'white');
  }
  if (distance_to_talhao == 0) {
    infoHTML+= tableRow_v2(`${translate.instant("MAP.PROPERTY")}`, `${properties['talhao_nome']}`, 'white');
  }

  return infoHTML + '</table>'
}

export function formatHotspotPopUp(properties, translate: TranslateService){
  
  let symbology_class = properties['symbology_class']
  let distance_to_talhao = properties['distance_to_talhao_m'];
  
  let infoHTML = '<table>'
  switch(symbology_class){
    case 1:
      infoHTML += tableHeader(`${translate.instant("SAT.HOTSPOT")} (#${properties['id_hotspot']})`,'#ff7070','black','12px');
      break;
    case 2:
      infoHTML += tableHeader(`${translate.instant("SAT.HOTSPOT")} (#${properties['id_hotspot']})`,'#feb24c','black','12px');
      break;
    case 3:
      infoHTML += tableHeader(`${translate.instant("SAT.HOTSPOT")} (#${properties['id_hotspot']})`,'#ffeda0','black','12px');
      break;
    case 4:
      infoHTML += tableHeader(`${translate.instant("SAT.HOTSPOT")} (#${properties['id_hotspot']})`,'#bdbdbd','black','12px');
      break;
  }
  infoHTML+= tableRow_v2(`${translate.instant("SAT.SATELLITE")}`, properties['satellite'], 'white');
  infoHTML+= tableRow_v2(`${translate.instant("SAT.HOTSPOT_ACQUISITION")}`, properties['acquisition_utc_offset'], alternate_color);
  infoHTML+= tableRow_v2(`${translate.instant("SAT.HOTSPOT_DETECTION")}`, properties['detection_utc_offset'], 'white');
  infoHTML+= tableRow_v2(`${translate.instant("SAT.HOTSPOT_LOCATION")} (lat/lon)`, properties['latitude'] + ', ' + properties['longitude'], alternate_color);
  return infoHTML + '</table>'
}

export function formatClusterTimelinePopUp(properties, translate: TranslateService){
  let infoHTML = '<table>'
  let timeline_class = properties['timeline_class']
  switch(timeline_class){
    case 1:
      infoHTML += tableHeader(translate.instant("SAT.TIMELINE_LEGEND_CLASS_START"),'#efedf5','black','12px');
      break;
    case 2:
      infoHTML += tableHeader(translate.instant("SAT.TIMELINE_LEGEND_CLASS_MIDDLE"),'#756bb1','black','12px');
      break;
    case 3:
      infoHTML += tableHeader(translate.instant("SAT.TIMELINE_LEGEND_CLASS_END"),'#7a0177','black','12px');
      break;              
  }
  infoHTML+= tableRow_v2(translate.instant("SAT.TIMELINE_START"), properties['timeline_class_min_limit'], 'white');
  infoHTML+= tableRow_v2(translate.instant("SAT.TIMELINE_END"), properties['timeline_class_max_limit'], alternate_color);
  return infoHTML + '</table>'
}

export function formatHotspotTalhaoPopUp(properties, translate: TranslateService){    
  let infoHTML = '<table>'
  infoHTML += tableHeader(`${translate.instant("SAT.HOTSPOT")} (#${properties['id_hotspot']})`,'#b0c4de','black','12px');
  infoHTML += tableRow_v2(`${translate.instant("SAT.SATELLITE")}`, properties['satellite'], 'white');  
  infoHTML += tableRow_v2(`${translate.instant("SAT.HOTSPOT_ACQUISITION")}`, properties['acquisition_utc_offset'], alternate_color);
  infoHTML += tableRow_v2(`${translate.instant("SAT.HOTSPOT_DETECTION")}`, properties['detection_utc_offset'], 'white');
  infoHTML += tableRow_v2(`${translate.instant("SAT.HOTSPOT_LOCATION")} (lat/lon)`, properties['latitude'] + ', ' + properties['longitude'], alternate_color);
  return infoHTML + '</table>'
}

export function formatHotspotRoiPopUp(properties, translate: TranslateService){
  let infoHTML = '<table>'
  let satellite = properties['satellite']
  switch(satellite){
    case 'GOES-16':
      infoHTML += tableHeader(`${properties['satellite']}`,'#6a3d9a','black','12px');
      break;
    case 'GOES-18':
      infoHTML += tableHeader(`${properties['satellite']}`,'#b15928','black','12px');
    break;
    case 'NOAA-21':
      infoHTML += tableHeader(`${properties['satellite']}`,'#c9e6c8','black','12px');
      break;    
    case 'NOAA-20':
      infoHTML += tableHeader(`${properties['satellite']}`,'#6ead6c','black','12px');
      break;
    case 'SUOMI-NPP':
      infoHTML += tableHeader(`${properties['satellite']}`,'#20751d','black','12px');
    break;
    case 'AQUA':
      infoHTML += tableHeader(`${properties['satellite']}`,'#a6cee3','black','12px');
      break;
    case 'TERRA':
      infoHTML += tableHeader(`${properties['satellite']}`,'#1f78b4','black','12px');
    break;
    case 'Sentinel-3A':
      infoHTML += tableHeader(`${properties['satellite']}`,'#ff6ec7','black','12px');
      break;
    case 'Sentinel-3B':
      infoHTML += tableHeader(`${properties['satellite']}`,'#f73772','black','12px');
    break;
    case 'Landsat-8':
      infoHTML += tableHeader(`${properties['satellite']}`,'#fdbf6f','black','12px');
      break;
    case 'Landsat-9':
      infoHTML += tableHeader(`${properties['satellite']}`,'#ff5722','black','12px');
    break;    
  }
  infoHTML+= tableRow_v2(`${translate.instant('SAT.HOTSPOT_ACQUISITION')}`, properties['acquisition_utc_offset'], 'white');
  infoHTML+= tableRow_v2(`${translate.instant('SAT.HOTSPOT_DETECTION')}`, properties['detection_utc_offset'], alternate_color);
  return infoHTML + '</table>'
}

export function formatReportSatImagePopUp(properties, translate: TranslateService){
  let infoHTML = '<table>'
  switch(properties['step']) {
    case 'before':
      infoHTML += tableHeader(`${translate.instant('SAT.REPORT_IMAGE_POPUP_HEADER_BEFORE')} (N° ${properties['n_relatorio_planta']})`, '#d3f5d3', 'black', '12px');
      break;
    case 'after':
      infoHTML += tableHeader(`${translate.instant('SAT.REPORT_IMAGE_POPUP_HEADER_AFTER')} (N° ${properties['n_relatorio_planta']})`, '#3d3d3d', 'white', '12px');
      break;
  }
  infoHTML+= tableRow_v2(`${translate.instant('SAT.SATELLITE')}`, 'Sentinel-2', 'white');
  infoHTML+= tableRow_v2(`${translate.instant('SAT.REPORT_IMAGE_ACQUISITION')}`, `${properties['acquisition_interval']} (UTC 0)`, alternate_color);
  infoHTML+= tableRow_v2('Pantera', `${properties['inserted_utc_offset']}`, 'white');
  return infoHTML + '</table>'
}

export function formatFeaturePopUp(properties, title, filter_cols=[]){
  let infoHTML = '<table>'
  infoHTML+= tableHeader(title,'#ff7070')
  
  let ct = 0;

  for (const [key, value] of Object.entries(properties)) {
    if (!filter_cols.length || filter_cols.includes(key)){
      let col = (ct++ % 2) === 0 ? 'white' : alternate_color;
      infoHTML+= tableRow(key, value, col)
    } 
  } 
  return infoHTML
}

export function formatSearchedGeomPopup(feature, layer){
  feature.properties['fill_color'] = 'lightgray'
  try {
    if (feature.properties.atributos2){
      feature.properties['atributos'] = JSON.parse(feature.properties.atributos2)
      layer.bindPopup(formatTalhaoPopUp(feature.properties));
    } else {
      feature.properties['atributos'] = JSON.parse(feature.properties.atributos)['Local']
      layer.bindPopup(formatTalhaoPopUpAtributosOld(feature.properties));
    }
  } catch (e) {
    console.warn(`Error binding searched geom popup using atributos: ${e}`)
    layer.bindPopup(fallbackFormatSearchedGeomPopUp(feature.properties));
  }
}

export function fallbackFormatSearchedGeomPopUp(properties){
  let infoHTML = '<table>'
  infoHTML += tableRow_v2('Nome', properties.nome_geom, alternate_color);
  infoHTML += tableRow_v2('Área (ha)', properties.area_ha, 'white');
  return infoHTML + '</table>'
}

export function formatPropagationPopUp(areaHa: number){
  let infoHTML = '<table>'
  infoHTML += tableRow_v2('Área (ha)', `${areaHa} &nbsp;&nbsp;&nbsp;`, alternate_color);
  return infoHTML + '</table>'
}

export function formatDeforestationPopUp(properties){
  let infoHTML = '<table>'
  infoHTML += tableRow_v2('Área desmatada (ha)', properties.area_ha, alternate_color);
  infoHTML += tableRow_v2('Data detecção', properties.detected_at, 'white');
  infoHTML += tableRow_v2('Data publicação', properties.published_at, alternate_color);
  infoHTML += tableRow_v2('Fonte(s)', properties.sources, 'white');
  return infoHTML + '</table>'
}

export function formatDeforestationAlertPopUp(properties){
  const detectionDate = new Date(properties.data_deteccao)
  let infoHTML = '<table>'
  infoHTML += tableRow_v2('Imóvel', properties.nome_geom, alternate_color);
  infoHTML += tableRow_v2('Data detecção', detectionDate.toLocaleDateString('pt-BR'), 'white');
  infoHTML += tableRow_v2('Área desmatada (ha)', properties.area_ha, alternate_color);
  infoHTML += tableRow_v2('Classe', properties.classe, 'white');
  infoHTML += tableRow_v2('Satélite', properties.satellite, alternate_color);
  infoHTML += tableRow_v2('Município', properties.municipio, 'white');
  infoHTML += tableRow_v2('Unidade de Conservação', properties.unidade_conservacao ? properties.unidade_conservacao : '-', alternate_color);
  infoHTML += tableRow_v2('Área desmatada na UC (ha)', properties.uc_area_ha, 'white');
  return infoHTML + '</table>'
}

//[André - purge this function as soon as possible]
export function tableRow(key, value, color='white'){
  if (typeof value == "boolean") {
    value = value? '☑️':''
  }
  return `
    <tr style="background-color:${color}">
      <td>${key}</td>
      <td>${value}</td>
    </tr>`
}

export function tableRowLeftCenter(key, value, color='white'){
  if (typeof value == "boolean") {
    value = value? '☑️':''
  }
  return `
    <tr style="background-color:${color}">
      <td>${key}</td>
      <td style="text-align:center;">${value}</td>
    </tr>`
}

export function tableRow_v2(key, value, color='white'){
  if (typeof value == "boolean") {
    value = value? '☑️':''
  }
  return `
    <tr style="background-color:${color};line-height:16px;font-size:12px;">
      <td style="padding-top:3px;
                 padding-bottom:3px;
                 padding-left:5px;
                 padding-right:5px;">
      ${key}</td>
      <td style="padding-top:3px;
                 padding-bottom:3px;
                 padding-left:5px;
                 padding-right:5px;">
      ${value}</td>
    </tr>`
}


export function tableHeader(title, bgcolor='white', color='black', font_size='12px'){
  return `
    <tr style="background-color:${bgcolor}; 
               color:${color};
               text-align:center;
               font-size:${font_size};
               line-height:16px">
      <td style="padding-left:15px;
                 padding-right:15px;
                 padding-top:3px;
                 padding-bottom:3px"
          colspan="2";>
            <strong>${title}</strong></td>
    </tr>`;
}

function numberWithCommas(x) {
  try{
    x = (x / 1000).toFixed(0)
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  } catch {
    return
  }
}


export function formatMeteoStationPopup(properties, translate: TranslateService) {
  let infoHTML = '<table>';
  infoHTML += tableHeader(translate.instant("MAP.WEATHER_STATION"), '#414ba6');
  let ct = 1;

  let col = (ct++ % 2) === 0 ? 'white' : alternate_color;
  infoHTML += tableRow(translate.instant("MAP.NAME"), properties.station_name, col);
  infoHTML += '</table>';
  return infoHTML;
}

export function formatCameraTrapPopup(properties) {
  
  let infoHTML = '<table>';
  infoHTML += tableHeader('Câmera Trap', '#414ba6');
  let ct = 1;

  let col = (ct++ % 2) === 0 ? 'white' : alternate_color;
  infoHTML += tableRow('Nome: ', properties.nome_cam_trap, col);
  infoHTML += '</table>';
  return infoHTML;
}

