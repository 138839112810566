import { Component, OnInit } from '@angular/core';
import { UserDataService } from 'src/app/services/user-data.service';
import { AmplitudeService } from 'src/app/services/amplitude.service';
@Component({
  selector: 'app-historical-data',
  templateUrl: './historical-data.component.html',
  styleUrls: ['./historical-data.component.scss']
})
export class HistoricalDataComponent implements OnInit {

  constructor (
    public user: UserDataService,
    private amplitude: AmplitudeService,
  ){}
  
  private tabs = {
    0: 'Área queimada e emissão de CO2',
  };

  ngOnInit(){}

  amplitudeTabEvent(selectedTab){
    this.amplitude.sendTabViewEvent('historical-data', this.tabs, selectedTab);
  }

}
