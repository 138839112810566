import { Component, OnInit, HostListener, effect, Injector } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { Clipboard } from '@angular/cdk/clipboard';
import { ReportsService } from '../../services/reports.service';
import { OnboardingService } from '../../services/onboarding.service';
import { GeoService } from '../../services/geo.service';
import { UserDataService } from '../../services/user-data.service';
import { SatelliteService } from '../../services/satellite.service';
import { AmplitudeService } from '../../services/amplitude.service';
import { PropagationService } from '../../services/propagation.service';
import { ActivatedRoute } from '@angular/router';
import { FormControl } from '@angular/forms';
import { Observable, BehaviorSubject } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
import { SocketService } from "src/app/services/socket.service";
import { SocketService as SocketCIGService } from 'src/app/interface/services/socket.service';
import { SocketDisconnectedDialog } from 'src/app/shared/dialogs/socket-disconnected/socket-disconnected.component';
import { MatDialog } from '@angular/material/dialog';
import { BroadcastService } from 'src/app/interface/services/broadcast.service';
import { LoggingService } from 'src/app/services/logging.service';
import { HttpService } from 'src/app/services/http.service';

interface LocalCliente {
  id_geom: number,
  nome_geom: string,
}

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})
export class MapComponent implements OnInit  {
  
  public loaded: boolean = false;
  
  public panel:string | [
    'reports',
    'cams',
    'sat',
    'brigades',
    'weather',
    'historical-data',
    'prop',
    'settings',
    'deforestation',
    'bio',
  ];

  buscaLocalFormControl = new FormControl('');
  nomesLocais: LocalCliente[];
  filteredLocais: Observable<LocalCliente[]>;
  reportsTabSelected = true;

  private currentSearchLocationInputValue = new BehaviorSubject<string>('');
  
  constructor(
    public geo: GeoService,
    public reports: ReportsService,
    public onboarding: OnboardingService,
    public user: UserDataService,
    public sat: SatelliteService,
    private amplitude: AmplitudeService,
    private route: ActivatedRoute,
    public prop: PropagationService,
    private socketCentral: SocketService,
    private dialog: MatDialog,
    private router: Router,
    public geoService: GeoService,
    public broadcastService: BroadcastService,
    private logging: LoggingService,
    private socketCIG: SocketCIGService,
    public injector: Injector,
    private http: HttpService,
    private clipboard: Clipboard,
  ) { 
    this.reports.selectReportEmmitter.subscribe(this.selectReport.bind(this));
    this.sat.selectClusterEmmitter.subscribe(this.selectCluster.bind(this));
    
    effect(() => {
      if (!this.geo.showSearchBox()){
        this.buscaLocalFormControl.reset()
        this.currentSearchLocationInputValue.next('')
      }
    })
  }

  
  // TO DO enxugar esse OnInit que ta gigante
  ngOnInit() {
    this.user.checkTerms()
    this.geo.createMap('map');

    try{
      this.geo.closePopup();
    }
    catch(e){
      this.logging.logERROR(`unselectReport:closePopup ${e}`,e);
    }
    this.reports.unselectReport();
    this.sat.unselectCluster();
    
    this.initMapReports();
    
    this.loaded = true;
    this.onboarding.setLoaded(true);

    
    if (this.user.isOperatorP2() || this.user.isSupportP2() || this.user.hasSatellite()) {
      this.socketCentral.joinIdPlantRoom()
      this.socketCentral.onSocketCentralDown().subscribe(async () => {
        console.log('DEBUG socket down')
      });
    }
    if (this.user.isOperatorP2() || this.user.isSupportP2()) {
      this.socketCentral.onAttDadosEvent().subscribe(async () => {
        const updatingData = await this.user.atualizarDadosCliente();
        console.log('DEBUG updatingData', updatingData)
      });
    }
    
    if(this.user.hasSatellite()){
      this.socketCentral.onNewSatData().subscribe(async () => {
        await this.geo.updateSatGroups();
        if (this.sat.selectedCluster) {
          this.sat.getCluster(this.sat.selectedCluster.unique_id);
        }   
        this.socketCentral.sendAttSatConfirmation();
      });
    }

    this.openReportParam();
    this.openClusterParam();

    this.setVhVw();

    this.amplitude.sendEvent('Acessou Planta', {'ID Planta': this.user.getIdPlanta()});

    this.initSearchLocation()

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        if(this.user.hasSatellite()){
          this.socketCentral.disconnectFromSockCent();
        }
      }
    });

    this.geo.setShowSearchBox(false)

    if (this.user.isOperatorP2() || this.user.isSupportP2()) {
      this.broadcastService.publishMessage({ event: "MapOnInit" })

      this.socketCIG.onNewReportEvent().subscribe(async () => {
        this.initMapReports();
        this.selectReport(true);
      });

      this.broadcastService.broadcastChannel.onmessage = async (msg) => {
        await this.switchAsyncHandler(msg)
      }
    }
  }

  async switchAsyncHandler(broadcastChannelEventObject: any) {
    switch(broadcastChannelEventObject.data.event) {
      case 'Triangulate':
        const { lat, lon } = broadcastChannelEventObject.data.location
        this.geoService.drawFireLocationMarkerOnMap(lat, lon, 1)
        break;
  
      case 'SearchOnMap':
        if (broadcastChannelEventObject.data.detectionId) this.geo.updateDetectionIdToSendSearchOnMapAmplitudeEvent(broadcastChannelEventObject.data.detectionId)
        this.geoService.updateWaitingClickOnMapToSetFireLocationStatus(broadcastChannelEventObject.data.value)
        break;
      
      case 'ClearFireLocationMarker':
        this.geoService.removeFireLocationMarkerFromMap()
        break;
  
      case 'CamerasLogs':
        this.geoService.updateCamerasLogsReplicationState(broadcastChannelEventObject.data.logs)
        break;
  
      case 'DetectionsOnInit':
        this.geoService.updateWaitingClickOnMapToSetFireLocationStatus(false);
        this.geoService.removeFireLocationMarkerFromMap()
        break;

      case 'InterfaceOnInit':
        const mergedReportsAndImages = await this.reports.mergeReportAndImagesData()
        this.broadcastService.publishMessage({ event: "UpdatedReports", reports: mergedReportsAndImages })
        break;
      
      case 'ChangeCursor':
        this.reports.changeCursor(broadcastChannelEventObject.data.style);  
        break;
        
      case 'RestoreCursor':
        this.reports.restoreCursor();
        break; 

      case "DrawAutoGeolocEllipse":
        this.geoService.drawAutoGeolocEllipse(broadcastChannelEventObject.data.geoloc)
        break;

      case "ClearGeolocEllipse":
        this.geoService.clearAutoGeolocLayers()
        break;
      
      case "CenterMapOnCoords":
        this.geoService.centerOnObject({ lat: broadcastChannelEventObject.data.lat, lng: broadcastChannelEventObject.data.lon }, { xOffsetPctg: 0 })
        break;

      case "SimulateMapClick":
        this.handleSimulatedMapClick(broadcastChannelEventObject.data.value)
        break;
      }
  }
      
  private handleSimulatedMapClick(latlng: { lat: number, lng: number }) {

    const syntheticEvent = {
      latlng: latlng
    };
    this.geoService.mapOnClick(syntheticEvent);
  };

  openDialog() {
    this.dialog.open(SocketDisconnectedDialog, {
      width: '300px', // Ajuste de acordo com o tamanho desejado
      disableClose: true // Impede que o usuário feche o dialog clicando fora dele
    });
  }


  // ------------------------------ SEARCH LOCATION METHODS ------------------------------

  initSearchLocation(){
    this.getNomesLocais().then(() => {
      let typedValue: string = '';
      this.filteredLocais = this.buscaLocalFormControl.valueChanges.pipe(
        startWith(''),
        map(value => {
          // ajuste pra evitar um bug especifico quando string de coordenada deixa de ser coordenada
          typedValue = value === '' ? this.currentSearchLocationInputValue.getValue() : value;
          return this._filter(typedValue || '')
        }),
      );
      this.buscaLocalFormControl.valueChanges.subscribe(value => {
        // precisa desse check pro caso de resetar o forms e nao dar erro NG0600 no effect
        if (value === null){
          return
        }

        if (value === ''){
          this.geo.removeSearchedLatLonMarkerFromMap()
        }

        if (this.buscaLocalFormControl.valid) {
          // ao clicar na coordenada form fica undefined, nao passar isso pro BS pro flyTo funfar
          if (value !== undefined){
            this.currentSearchLocationInputValue.next(value)
          }
          
          this.geo.setSearchLocationIsCoordinate(this.isCoordinate(value))

          this.onLocationSearchCompleted(value, typedValue);
        }
      });
    })
  }

  searchLocationDisplayName(talhao: LocalCliente | any): string {
    return talhao?.nome_geom || talhao || this.buscaLocalFormControl?.value || '';
  }

  getNomesLocais(){
    return this.geo.getNomeTalhao().then(nomesTalhao => {
      this.nomesLocais = nomesTalhao;
    });
  }
 
  private _filter(talhao: string | LocalCliente): LocalCliente[] {
    let filterValue: string;
    if (typeof(talhao) === 'string'){
      filterValue = talhao.toLowerCase();
    } else {
      filterValue = talhao.nome_geom.toLowerCase();
      this.geo.addSearchedGeomWfsLayer(talhao.id_geom, this.user.getIdPlanta(), 'ugm', 'search_talhao_wfs');
    }
    return this.nomesLocais.filter(talhao => talhao.nome_geom.toLowerCase().includes(filterValue));
  }

  isCoordinate(value: LocalCliente | string): boolean {
    if (!value || typeof(value) !== 'string'){
      return false
    }
    const coordinatePattern = /^\s*-?\d{1,2}(\.\d{1,6})?\s*,\s*-?\d{1,3}(\.\d{1,6})?\s*$/;
    return coordinatePattern.test(value.trim());
  }

  onSearchLocationKeydown(event: KeyboardEvent){
    if (event.key === 'Enter') {
      const value = this.currentSearchLocationInputValue.getValue();
      
      if (this.isCoordinate(value)) {
        this.searchLatLon();
      }
    }
  }

  searchLatLon() {
    const value = this.currentSearchLocationInputValue.getValue();
    if (this.isCoordinate(value)) {
      const [lat, lon] = value.split(',').map(coord => parseFloat(coord.trim()));
      this.geo.map.flyTo([lat, lon], this.geo.map.getZoom());
      this.geo.addSearchedLatLonMarker(lat, lon)
      this.geo.setSearchLocationIsCoordinate(false)
      this.amplitude.sendEvent('Pesquisou Local', {'Local': `${lat}, ${lon}`, 'Termo': value});
    }

    this.buscaLocalFormControl.patchValue(value)
  }

  toggleSearchLocation(event: any){
    event.stopPropagation()
    this.geo.setShowSearchBox(!this.geo.showSearchBox())
    if (this.geo.showSearchBox()){
      this.geo.removeSearchedLatLonMarkerFromMap()
    }
  }

  onLocationSearchCompleted(selectedValue: string, typedValue: string) {
    if (selectedValue && selectedValue["nome_geom"]) {
      let eventProperties =  {'Local': selectedValue["nome_geom"]}
      if (typedValue !== ''){
        eventProperties['Termo'] = typedValue
      }
      this.amplitude.sendEvent('Pesquisou Local', eventProperties);
    }
  }

  @HostListener('window:keydown', ['$event'])
  handleKeyDown(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      this.geo.removeSearchedLatLonMarkerFromMap();
    }
  }

  // ---------------------------------------------------------------------------------------


  async initMapReports(){
    await this.reports.updateReports();
    this.geo.drawFireReports(this.reports.reports_list);
    this.reports.setRecentReports(this.reports.reports_list.filter(r=> r.recent).length)
  }

  selectReport(state: boolean): void {
    if(state){
      this.panel = 'reports';
      this.user.sidePanelOpened = true;
    } else {
      this.panel = '';
      this.user.sidePanelOpened = false;
    }
  }

  openReportParam() {
    this.route.queryParams.subscribe(async params => {
      const id_report = params['idReport'];
      if (id_report) {
        await this.reports.updateReports();
        
        const id_reports_list = this.reports.reports_list.map(r => r.id_report);
    
        if (id_reports_list.includes(id_report)) {
          console.log('openReportParam', id_report);
          this.reports.selectReport(id_report);
          this.geo.openReportMarker(id_report);
          //O amplitude esta dentro do setTimeout para evitar enviar o evento duplicado de acessou planta e $indentify
          setTimeout(() => {
            this.amplitude.sendEvent('Abriu Relatório', {'Origem': 'URL', 'Número Relatório': this.reports.selectedR["n_relatorio_planta"], 'ID Relatório': id_report});
          }, 900)        
          this.router.navigateByUrl('/map');
        }
      } 
    });
  }

  openClusterParam() {
    this.route.queryParams.subscribe(async params => {
      const id_cluster = params['idCluster'];
      if (id_cluster) {
        await this.sat.createClusters(this.user.getIdPlanta());
        this.sat.selectCluster(id_cluster);
        effect(()=>{
          if(this.geo.panteraReady()){
            this.geo.openClusterPopUp(id_cluster, true);
            this.amplitude.sendEvent("Abriu Agrupamento", {"Origem": "URL", "unique_id": id_cluster});
          }
        }, {injector: this.injector})        
        this.router.navigateByUrl('/map');
      }
    });
  }
  
  selectCluster(state: boolean): void {
    if(state){
      this.panel = 'sat';
      this.user.sidePanelOpened = true;
    } else {
      this.panel = '';
      this.user.sidePanelOpened = false;
    }
  }


  toggleShow(panel){
    if(this.panel == panel){
      this.panel = '';
      this.user.sidePanelOpened = false;
      window.dispatchEvent(new Event('resize'));
    } else {
      this.panel = panel;
      this.user.sidePanelOpened = true;
      this.amplitude.sendEvent('Viu Painel', {'Grupo': `${this.amplitude.groupName[panel]}`});
    }
    this.geo.triggerPageReflow();
  }

  isReportTabSelected(selected: boolean){
    this.reportsTabSelected = selected;
  }

  setVhVw(){
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
    let vw = window.innerWidth * 0.01;
    document.documentElement.style.setProperty('--vw', `${vw}px`);
  }

  // potencialmente deprecated isso aqui
  @HostListener('window:resize', ['$event'])
  onResize(event) {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
    let vw = window.innerWidth * 0.01;
    document.documentElement.style.setProperty('--vw', `${vw}px`);
  }

  goToSupport(){
    this.amplitude.sendEvent("Clicou Ajuda", { "Tour": "Zendesk" })
    window.open("https://suporte.umgrauemeio.com/")
  }

  startMapTour() {
    this.amplitude.sendEvent("Clicou Ajuda", { "Tour": "Mapa" })
    this.onboarding.startMapTour()
  }

  copyClickedCoordinatesToClipboard(event: any){
    event.stopPropagation()
    const coords = `${this.geo.clickedLatLng.lat.toFixed(5)}, ${this.geo.clickedLatLng.lng.toFixed(5)}`
    this.clipboard.copy(coords);
  }
}