import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { UserDataService } from './user-data.service';
import { formatDate } from '@angular/common';
import { LoggingService } from './logging.service';

const ID_MONIT_TOWER = 2
interface MeteoData {
  nome_local: string;
  risk: number;
  temperature: number;
  rain: number;
  wind_speed: number;
  humidity: number;
  weatherIcon: string;
}

@Injectable({
  providedIn: 'root'
})
export class MeteoService {

constructor(
  public http: HttpService,
  private user: UserDataService,
  public logging: LoggingService
  ) { }
  
  public stations_data:any;
  public meteoPlacesCount: number;
  public meteoData : any;
  public tableData: MeteoData[] = [];
  public triplo30 = false;

  async getMeteoPlacesCount(){
    const locaisCountPlanta = await this.http.maestroGet(`get_locais_count_planta/${this.user.getIdPlanta()}`)
    let meteoPlacesCount = 0;
    try {
      meteoPlacesCount = locaisCountPlanta.filter(localCount => localCount.id_tipo_local == ID_MONIT_TOWER)[0].count
    } catch(error) {
      this.logging.logERROR(`getMeteoPlacesCount:locaisCountPlanta.filter ${error}`,error);
      console.log('Sem torres de monitoramento');
    }
    const stationsPlanta = await this.http.maestroGet(`get_stations_planta/${this.user.getIdPlanta()}`)
    if (stationsPlanta){
      meteoPlacesCount = stationsPlanta.length;
    } else {
      console.log('Sem estações meteorológicas')
    }
    console.log('meteoPlacesCount', meteoPlacesCount)
    return meteoPlacesCount
  }

  async getTowersData(search_date = null){
    if (!search_date){
      search_date = formatDate(new Date(), 'yyyy-MM-dd ', 'en-US')
    }
    console.log('get_towers_data', [search_date, `${this.user.getIdPlanta()}`]);
    let meteoData = await this.http.maestroGet(`get_towers_data/${search_date}/${this.user.getIdPlanta()}`)
    return meteoData;
  }

  async getStationsData(search_date = null){
    if (!search_date){
      search_date = formatDate(new Date(), 'yyyy-MM-dd ', 'en-US')
    }
    console.log('get_stations_data', [search_date, `${this.user.getIdPlanta()}`]);
    let stations_data =  await this.http.maestroGet(`get_stations_data/${search_date}/${this.user.getIdPlanta()}`)
    return stations_data;
  }

  async getValidMeteo(searchDate = null){
    let towersData = await this.getTowersData(searchDate);
    let stationsData = await this.getStationsData(searchDate);

    const combinedData = [...(towersData || []), ...(stationsData || [])];

    this.meteoData = combinedData;
    console.log('combined meteo_data', this.meteoData);
    this.pushTableData();
  }

  hasTriplo30 = (meteoRecord: MeteoData) => {
    if (meteoRecord.temperature >= 30 && meteoRecord.humidity < 30 && meteoRecord.wind_speed >= 30) return true;
    return false;
  };

  pushTableData(){
    this.tableData = [];
    if (this.meteoData?.length){
      for (let meteoLocal of this.meteoData){
        this.tableData.push({
          nome_local: meteoLocal.nome_local,
          risk: meteoLocal.fire_risk,
          temperature: meteoLocal.daily[0]?.temp.day,
          rain: meteoLocal.daily[0]?.rain || 0,
          wind_speed: meteoLocal.daily[0]?.wind_speed * 3.6,
          humidity: meteoLocal.daily[0]?.humidity,
          weatherIcon: meteoLocal.daily[0]?.weather[0].icon
        })
      };
      this.triplo30 = this.tableData.some(this.hasTriplo30);
    }
  }

}
