<mat-dialog-content>
  <div>
    <h2>Escolha um grupo</h2>
  </div>
  <div>
    <mat-selection-list #groups [multiple]="false" [(ngModel)]="reports.selectedGroup">
      @for (group of data.group_list; track group.nome_grupo) {
        <mat-list-option [value]="group">
          {{group.nome_grupo}}
        </mat-list-option>
      }
    </mat-selection-list>

    <br/>

    <p>
      Grupo selecionado: {{groups.selectedOptions.selected[0]?.value.nome_grupo}}
    </p>
  </div>
</mat-dialog-content>

<mat-dialog-actions align="center">
  <button mat-raised-button [mat-dialog-close]='false'>{{ 'MAP.CANCEL' | translate }}</button>
  <button mat-raised-button [mat-dialog-close]="true" [style]="{'background': '#dc3333', 'color': 'white'}">{{ 'MAP.CONFIRM' | translate }}</button>
</mat-dialog-actions>