<style>

mat-dialog-content {
  max-height: 80vh;
}

.outlineForm{
  margin: 0 3px;
  display: flex;
  justify-content: center;
}

.form {
  min-width: 150px;
  max-width: 500px;
  width: 100%;
}

.top-form-field {
  width: 100%;
}

.option-img {
  vertical-align: middle;
  margin-right: 8px;
}

[dir='rtl'] .option-img {
  margin-right: 0;
  margin-left: 8px;
}

.status-icon{
  width: 17px; 
  height: 17px; 
  padding-top: 1px;
  margin-right: 8px;
}

mat-slide-toggle {
  padding: 0 5px;
}

.combate, .rescaldo {
  margin-top: 1rem;
}

.hint-divider {
  width: 100%;
  height: 1rem;
}

.hint-futuro {
  margin-top: 8px;
  font-size: 12px;
}

mat-hint {
  color: #DC3233;
}

form > div ::ng-deep .mat-form-field-infix {
  padding-bottom: 8px;
}

.bottom-form-field ::ng-deep .mat-form-field-wrapper {
  padding: 12px 0;
}

.top-form-field ::ng-deep .mat-form-field-wrapper {
  padding: 8px 0;
}

.dts {
  align-content: center;
  justify-content: space-between;
}

input.mat-mdc-input-element::-webkit-calendar-picker-indicator {
  display: block;
}

@media (max-width: 600px){

  .dialog-responsive {
    width: 100%;
    max-width: 100vw;
  }

  .dts ::ng-deep .mat-form-field-wrapper {
    width: 40vw;
  }
}

</style>

<div class="report-dialog-wrapper">
  <mat-dialog-content>
    <form [formGroup]='brigadeForm' (ngSubmit)="onSubmit()">
  
      <h3>👨‍🚒 {{ 'MAP.REGISTER_FIREFIGHT' | translate }}</h3>
  
      <div class="form">
        <mat-form-field class="top-form-field" appearance="outline">
          <mat-label>{{ 'MAP.BRIGADE' | translate }}</mat-label>
          <input matInput
            aria-label="Brigada"
            [matAutocomplete]="auto"
            formControlName="nome_brigada">
            <mat-autocomplete #auto="matAutocomplete">
              @for (brigade of filteredBrigades | async; track brigade.idBrigada) {
                <mat-option [value]="brigade.name" class="brigades-info">
                  <img class="option-img" aria-hidden [src]="brigade.typeIcon" height="25">
                  @if (brigade.statusIcon) {
                    <img class ='status-icon '[src]="brigade.statusIcon" 
                    [matTooltip]=" brigade.statusTooltip | translate"
                    >
                  }
                  <span>{{brigade.name}}</span> |
                  @if (brigade.fireDrivingDistanceKm) {
                    <span>
                      {{brigade.fireDrivingDistanceKm}} km |
                    </span>
                  }
                  <small>{{brigade.category}}</small>
                  @if (brigade.plate) {
                    <span>
                      | <small>{{brigade.plate}}</small>
                    </span>
                  }
                </mat-option>
              }
            </mat-autocomplete>
            @if(brigadeNotFound()){
              <mat-error>
                {{ 'MAP.BRIGADE_NOT_FOUND' | translate }}
              </mat-error>
            }

          </mat-form-field>
          <br>
            <div class="fx-row-space-around-center">
              <mat-form-field appearance="outline" class="top-form-field">
                <mat-label>{{ 'MAP.PEOPLE' | translate }}</mat-label>
                <input matInput type="number" formControlName="contagem_equipe">
              </mat-form-field>
  
            </div>
          </div>
  
          <div class="dts flex">
  
            <mat-form-field class="bottom-form-field" appearance="outline" class="outlineForm">
              <mat-label>{{ 'MAP.BRIGADE_DRIVE' | translate }}</mat-label>
              <input matInput type="datetime-local" formControlName='dt_acionamento'>
            </mat-form-field>
            <mat-form-field appearance="outline" class="outlineForm">
              <mat-label>{{ 'MAP.ARRIVAL' | translate }}</mat-label>
              <input matInput type="datetime-local" formControlName='dt_chegada'>
              @if (brigadeForm.errors?.erroDtAcionamento) {
                <mat-hint>
                  {{ 'MAP.PREVIOUS_ARRIVAL_TIME' | translate }}
                </mat-hint>
              }
            </mat-form-field>
          </div>
  
          <div>
            <mat-slide-toggle formControlName="combate" (change)="onCombateChange($event)">{{ 'MAP.RECORD_FIREFIGHT' | translate }}</mat-slide-toggle>
            @if (combateChecked) {
              <div class="combate">
                <div class="dts flex">
                  <mat-form-field class="bottom-form-field" appearance="outline" class="outlineForm">
                    <mat-label>{{ 'MAP.FIREFIGHT_START' | translate }}</mat-label>
                    <input matInput type="datetime-local" formControlName='dt_inicio_combate'>
                    @if (brigadeForm.errors?.erroDtChegadaCombate) {
                      <mat-hint>{{ 'MAP.PREVIOUS_FIREFIGHT_START_TIME' | translate }}</mat-hint>
                    }
                  </mat-form-field>
                  <mat-form-field class="bottom-form-field" appearance="outline" class="outlineForm">
                    <mat-label>{{ 'MAP.FIREFIGHT_END' | translate }}</mat-label>
                    <input matInput type="datetime-local" formControlName='dt_fim_combate'>
                    @if (brigadeForm.errors?.erroDtCombate) {
                      <mat-hint>{{ 'MAP.END_TIME_BEFORE_START' | translate }}</mat-hint>
                    }
                  </mat-form-field>
                </div>
              </div>
            }
          </div>
          <div>
            @if (brigadeForm.errors?.erroDtChegadaCombate) {
              <div class="hint-divider"></div>
            }
            <mat-slide-toggle formControlName="rescaldo" (change)="onRescaldoChange($event)">{{ 'MAP.RECORD_AFTERMATH' | translate }}</mat-slide-toggle>
            @if (rescaldoChecked) {
              <div class="rescaldo">
                <div class="dts flex">
                  <mat-form-field class="bottom-form-field" appearance="outline" class="outlineForm">
                    <mat-label>{{ 'MAP.AFTERMATH_START' | translate }}</mat-label>
                    <input matInput type="datetime-local" formControlName='dt_inicio_rescaldo'>
                    @if (brigadeForm.errors?.erroDtChegadaRescaldo) {
                      <mat-hint>{{ 'MAP.PREVIOUS_AFTERMATH_START_TIME' | translate }}</mat-hint>
                    }
                  </mat-form-field>
                  <mat-form-field class="bottom-form-field" appearance="outline" class="outlineForm">
                    <mat-label>{{ 'MAP.AFTERMATH_END' | translate }}</mat-label>
                    <input matInput type="datetime-local" formControlName='dt_fim_rescaldo'>
                    @if (brigadeForm.errors?.erroDtRescaldo) {
                      <mat-hint>{{ 'MAP.END_TIME_BEFORE_START' | translate }}</mat-hint>
                    }
                  </mat-form-field>
                </div>
              </div>
            }
          </div>
  
          @if (brigadeForm.errors?.erroDtFuturo) {
            <div class="hint-futuro">
              <mat-hint>{{ 'MAP.FUTURE_DATES_NOT_ALLOWED' | translate }}</mat-hint>
            </div>
          }
          <mat-dialog-actions align="center">
            <button mat-raised-button [mat-dialog-close]='false'>{{ 'MAP.CANCEL' | translate }}</button>
            <button mat-raised-button color='primary'
              [disabled]='brigadeForm.status=="INVALID"'
              type="submit"
            [mat-dialog-close]="brigadeForm.value">{{ 'MAP.CONFIRM' | translate }}</button>
            @if(brigadeNotFound() && this.user.isManager()){
              <button mat-raised-button color="primary" (click)="createBrigadeOrCancel()">{{ addCancelBrigade ? ('MAP.CANCEL_REGISTRATION' | translate) :  'MAP.REGISTER_BRIGADE' | translate }}</button>
            }
          </mat-dialog-actions>
  
        </form>
        @if(createNewBrigade()){
          <app-brigades [selectedBrigadeViewOption]="2" [showResourceOptions]="false"></app-brigades>
        }
        
  </mat-dialog-content>
</div>