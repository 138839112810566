import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {LoginComponent} from './pages/login/login.component';
import {MapComponent} from './pages/map/map.component';
import {AdminComponent} from './pages/admin/admin.component';
import {RouteGuardsService} from './services/route-guards.service';
import { HeaderComponent } from './components/header/header.component';

const routes: Routes = [
  { path: '', redirectTo: 'map', pathMatch: 'full'},
  { path: 'login', component: LoginComponent},
  { path: 'login-sso', loadChildren: () => import('./sso-login/sso-login.module').then(m => m.SsoLoginModule) },
  { path: 'map', component: MapComponent, canActivate: [RouteGuardsService]},
  { path: 'interface', canActivate: [RouteGuardsService], loadChildren: () => import('./interface/interface.module').then(m => m.InterfaceModule) },
  { path: 'admin', component: AdminComponent, canActivate: [RouteGuardsService], data: {roles: [0, 3, 12]}},
  { path: 'labelling', canActivate: [RouteGuardsService], data: {roles: [0, 3]}, loadChildren: () => import('./labelling/labelling.module').then(m => m.LabellingModule) },
  { path: 'home', component: HeaderComponent, canActivate: [RouteGuardsService]},
  { path: 'propagation', canActivate: [RouteGuardsService], loadChildren: () => import('./propagation/propagation.module').then(m => m.PropagationModule) },
  { path: 'project', canActivate: [RouteGuardsService], loadChildren: () => import('./project/project.module').then(m => m.ProjectModule) },
  { path: 'mosaic', canActivate: [RouteGuardsService], loadChildren: () => import('./mosaic/mosaic.module').then(m => m.MosaicModule) },
  { path: '**', redirectTo: 'map'},
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true, onSameUrlNavigation: 'reload' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}