import { Injectable, Injector } from '@angular/core';

import { HttpService } from './http.service';
import { UserDataService } from './user-data.service';
import { TranslateService } from '@ngx-translate/core';
import { GeoLayersService } from './geo.layers';
import { GeoService } from './geo.service';

import * as L from 'leaflet';
import { LayerGroupObj } from './geo.store';
import { formatTalhaoPopUp } from "./geo.format";


@Injectable({
  providedIn: 'root'
})
export class TalhaoService {  
  constructor(
    private injector: Injector,
    private http: HttpService,
    private user: UserDataService,
    private translate: TranslateService,
    private layer: GeoLayersService,
  ) {}

  public talhaoLayer;
  public selectedTalhao;

  async createTalhaoGroup(talhaoPane: string):Promise<LayerGroupObj>{
    let limitsOverlays = [];

    if (this.user.getIdPlanta() === 119){
      // ficar abaixo da layer talhao
      // item_ids planet hardcoded, atualizar ao longo do tempo pra imgs mais recentes
      const planetPsScenes = '20240714_151459_34_2484,20240714_151501_14_2484,20240714_151502_94_2484,20240714_152656_21_247b,20240714_152658_00_247b,20240714_152659_78_247b,20240717_141504_14_24c4,20240717_141506_24_24c4,20241008_143001_07_24b4,20241008_143002_96_24b4,20241008_143004_85_24b4,20241008_143006_75_24b4,20241008_143008_64_24b4,20241008_151331_02_2514,20241008_151333_15_2514,20241008_151456_89_24cd,20241008_151458_94_24cd,20241008_151500_98_24cd,20241008_151503_02_24cd,20241008_151505_06_24cd,20241008_151507_10_24cd,20241008_151509_14_24cd,20241008_151647_16_24c6,20241008_151649_20_24c6,20241008_151651_24_24c6,20241008_151653_28_24c6,20241008_151655_32_24c6,20241008_151657_36_24c6'
      const planetLayerUrl = `https://tiles0.planet.com/data/v1/PSScene/${planetPsScenes}/{z}/{x}/{y}.png?api_key=PLAKe3b6ec29c2c9438bb27aed53a35a11ee`
      let planetLayer = L.tileLayer(planetLayerUrl);
      limitsOverlays.push({
        'legend': "Planet",
        'layer': planetLayer,
        'active': true
      });
    }

    var talhao = this.layer.createClientWmsLayer(this.user.getIdPlanta(), 'ugm', 'talhao', 1);

    if (this.user.isOperatorP2() || this.user.isSupportP2()) {      
      let talhaoGeojson = await this.createTalhaoLayer(talhaoPane);
      if (talhaoGeojson){
        talhao = talhaoGeojson;
      }
    };

    limitsOverlays.push({
      'legend': "MAP.PROPERTY",
      'layer': talhao,
      'active': true
    });

    if(this.user.getTalhaoZonaLegend()){
      let talhaoZona = this.layer.createClientWmsLayer(this.user.getIdPlanta(), 'ugm', 'talhao_zona', 1);
      limitsOverlays.push({
        'legend': "MAP.PROPERTY_ZONES",
        'layer': talhaoZona,
        'active': false
      });
    }

    // Camada "patrolling grid", planta Similipal Park
    if (this.user.getIdPlanta() === 64){
      let patrollingGrid = this.layer.createClientWmsLayer(this.user.getIdPlanta(), 'ugm', 'similipal_patrolling_grid', 1);
      limitsOverlays.push({
        'legend': "MAP.PATROLLING_GRID_LAYER",
        'layer': patrollingGrid,
        'active': false
      });
    }

    return {
      'groupId': 1,
      'groupTitle': "MAP.TALHAO_LAYERS",
      'icon': 'map',
      'layersList': limitsOverlays
    }
  }

  async createTalhaoLayer(talhaoPane: string){
    let rawTalhaoGeojson = await this.http.maestroGet(`geojson_talhao/${this.user.getIdPlanta()}`);
    if (!rawTalhaoGeojson) {
      return null
    }

    let layerMap = new Map();    
    let talhaoGeojson = L.geoJSON(rawTalhaoGeojson, {
      onEachFeature: (feature, layer) => {
        layer.bindPopup(formatTalhaoPopUp(feature.properties));

        let style = {
            weight: 0.7,
            color: '#000000',
            opacity: 1,
            fillColor: feature.properties.fill_color,
            fillOpacity: feature.properties.fill_opacity,
        };
        layer.setStyle(style);
        layer.initialStyle = { ...style };        
        
        layerMap.set(feature.properties.id_geom, layer);        
        
        layer.on('click', e => {
          this.injector.get(GeoService).mapOnClick(e);          
          if (this.selectedTalhao) {
            layerMap.get(this.selectedTalhao.properties.id_geom).setStyle({
              ...layerMap.get(this.selectedTalhao.properties.id_geom).initialStyle
            });
          };
          this.selectTalhao(feature.properties.id_geom);
        });
        
        layer.getPopup().on('remove', e => {
          if (this.selectedTalhao) {
            layerMap.get(this.selectedTalhao.properties.id_geom).setStyle({
              ...layerMap.get(this.selectedTalhao.properties.id_geom).initialStyle
            });
            this.selectedTalhao = null;
          };
        });        
      },
      pane: talhaoPane   
    });
    this.talhaoLayer = talhaoGeojson
    return talhaoGeojson;
  }

  async createTalhaoLineGroup():Promise<LayerGroupObj>{
    let limitsOverlays = [] 

    if (this.user.hasTalhaoLinhaEstrada()){
      let talhaoLinhaEstrada = this.layer.createClientWmsLayer(this.user.getIdPlanta(), 'ugm', 'talhao_linha_estrada', 1);
      limitsOverlays.push({
        'legend': 'MAP.ROAD',
        'layer': talhaoLinhaEstrada,
        'active': false
      });
    }

    if (this.user.hasTalhaoLinhaHidrografia()){
      let talhaoLinhaHidrografia = this.layer.createClientWmsLayer(this.user.getIdPlanta(), 'ugm', 'talhao_linha_hidrografia', 1);
      limitsOverlays.push({
        'legend': 'MAP.HYDROGRAPHY',
        'layer': talhaoLinhaHidrografia,
        'active': false
      });
    }

    if (this.user.hasTalhaoLinhaEnergia()){
      let talhaoLinhaEnergia = this.layer.createClientWmsLayer(this.user.getIdPlanta(), 'ugm', 'talhao_linha_energia', 1);
      limitsOverlays.push({
        'legend': 'MAP.POWER_LINE',
        'layer': talhaoLinhaEnergia,
        'active': false
      });
    }    

    return {
      'groupId': 6,
      'groupTitle': "MAP.LINE_LAYERS",
      'icon': 'remove',
      'layersList': limitsOverlays
    }
  }

  selectTalhao(id){
    this.selectedTalhao = null;
    this.talhaoLayer.eachLayer(layer => {   
      if (layer.feature.properties.id_geom === id) {
        this.selectedTalhao = layer.feature;
        console.log("selectedTalhao", this.selectedTalhao);
        layer.setStyle({ color: '#04d4d4', weight: 2 });
      }
    });
  }

}