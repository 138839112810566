<main>
  <div class="chart-rows">
    <div class="area-projeto">
    @if (layer.scarStats) {
      <div class="chart-row">
        <div>
          <canvas id="yearlyScarsChart"></canvas>
        </div>
        <div>
          <canvas id="totalScarsByLulcChart"></canvas>
        </div>
      </div>
      <div class="chart-row">
        <div>
          <canvas id="yearlyEmissionsChart"></canvas>
        </div>
        <div>
          <canvas id="totalEmissionsByLulcChart"></canvas>
        </div>
      </div>
    }
      <div class="chart-row">
        <div>
          <canvas id="yearlyHotspotsChart"></canvas>
        </div>
        <div>
          <canvas></canvas>
        </div>
      </div>
    </div>
  </div>
</main>