<div class="tab-content-container">
    <ng-template mat-tab-label>
      <mat-icon class="tab-icon">add_location_alt</mat-icon>
      Cadastro
    </ng-template>
    <mat-card appearance="outlined">
      <mat-card-title>{{ 'MAP.CREATE_NEW_STATION' | translate }}</mat-card-title>
  
      <div class="fx-column-space-around-center">
  
        <mat-form-field appearance="outline">
          <mat-label>{{ 'MAP.NAME' | translate }}</mat-label>
          <input type="text" matInput [formControl]="stationNameFc"
            [(ngModel)]="stationName">
            @if (stationNameFc.hasError('pattern')) {
              <mat-error>{{ 'MAP.ENTER_METEO_STATION_NAME' | translate }}</mat-error>
            }
          </mat-form-field>
  
          <mat-form-field appearance="outline" matTooltip="{{ 'MAP.POINT_OF_INTEREST_COORDS_TOOLTIP' | translate }}">
            <mat-label>Latitude</mat-label>
            <input required matInput [(ngModel)]="lat" type="number" min="-90" max="90" placeholder="Ex: -22.1823"/>
          </mat-form-field>
  
          <mat-form-field appearance="outline" color='primary' matTooltip="{{ 'MAP.POINT_OF_INTEREST_COORDS_TOOLTIP' | translate }}">
            <mat-label>Longitude</mat-label>
            <input required matInput [(ngModel)]="lon" type="number" min="-180" max=180 placeholder="Ex: -47.8911"/>
          </mat-form-field>
          </div>
  
          <div class="fx-row-space-around-center" >
            <button mat-raised-button color='primary'
              [disabled]="stationNameFc.hasError('pattern') || stationNameFc.hasError('required') || !lat || !lon || (lat < -90) || (lat > 90) || (lon < -180) || (lon > 180)"
              (click)='postStation()'>
              {{ 'MAP.CREATE_STATION' | translate }}
            </button>
          </div>
  
        </mat-card>
      </div>
  