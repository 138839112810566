<style>

  .outlineForm{
    margin: 0 3px;
    width: 300px;
}

  .form{
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 2rem;
  }

  .report-dialog-wrapper{
    width: 400px;
  }
  .unit-suffix{
    padding-right: 5px;
  }

</style>

<div class="report-dialog-wrapper">
  <mat-dialog-content>
    @if(!editarDanos){
      <h3>{{ 'MAP.REGISTER_DAMAGE' | translate }}</h3>
    }@else {
      <h3>{{ 'MAP.EDIT_DAMAGE' | translate }}</h3>
    }
  
    <form class='form' [formGroup]='lossForm'>
  
      <mat-form-field appearance="outline" class="outlineForm">
        <mat-label>{{ 'MAP.TYPE' | translate }}</mat-label>
        <mat-select [(ngModel)]="data.id_tipo_dano" formControlName='id_tipo_dano'>
          @for (p of idTipoDano; track p) {
            <mat-option [value] = "p">
              {{'DMG.' + p | translate}}
            </mat-option>
          }
        </mat-select>
      </mat-form-field>
  
      <mat-form-field appearance="outline" class="outlineForm">
        <mat-label>{{ 'MAP.UNIT' | translate }}</mat-label>
        <mat-select [(ngModel)]="data.unidade" formControlName='unit'>
          @for (u of unidades; track u.name) {
            <mat-option [value] = "u.unit">
              {{u.name}}
            </mat-option>
          }
        </mat-select>
      </mat-form-field>
  
      <mat-form-field appearance="outline" class="outlineForm">
        <mat-label>{{ 'MAP.VALUE' | translate }}</mat-label>
        <input matInput type="number" [(ngModel)]="data.valor" formControlName='value'>
        <span class="unit-suffix" matSuffix>{{data.unidade}}</span>
      </mat-form-field>

      <mat-form-field appearance="outline" class="outlineForm">
        <mat-label>{{ 'MAP.LOCATION' | translate }}</mat-label>
        <input type="text" matInput [matAutocomplete]="auto" formControlName="nome_local" [(ngModel)]="data.nome_local">
        <mat-autocomplete #auto="matAutocomplete">
          @for (local of filteredLocais | async; track local.nome_geom){
            <mat-option [value] = "local.nome_geom">
              {{ local.nome_geom }}
            </mat-option>
          }
        </mat-autocomplete>
        @if(lossForm.controls['nome_local'].hasError('invalidLocal')){
          <mat-error>
            {{ 'MAP.INVALID_LOCATION' | translate }}
          </mat-error>
        }
      </mat-form-field>

    </form>
  </mat-dialog-content>
  
  <mat-dialog-actions align="center">
    <button mat-raised-button [mat-dialog-close]='false'>{{ 'MAP.CANCEL' | translate }}</button>
    @if(!editarDanos){
      <button mat-raised-button [color]="'primary'"
      [disabled]='lossForm.status=="INVALID"'
      [mat-dialog-close]="data">{{ 'MAP.CONFIRM' | translate }}</button>
    }@else {
      <button mat-raised-button [color]="'primary'"
      [disabled]='lossForm.status=="INVALID"'
      [mat-dialog-close]="data">{{ 'MAP.UPDATE' | translate }}</button>
    }
   
  </mat-dialog-actions>
</div>