import { Component } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { BehaviorSubject } from 'rxjs';
import { TranslateService,TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-blocked-account',
  standalone: true,
  imports: [
    MatDialogModule,
    MatButtonModule,
    TranslateModule,
  ],
  templateUrl: './deactivate-dialog.component.html',
  styleUrl: './deactivate-dialog.component.scss'
})
export class DeactivatedAccountComponent {
  
  constructor(
    public translate: TranslateService,
  ) {}

}
