<div class="heading">
  <mat-icon>email</mat-icon>
  {{ 'MAP.RISK_RECIPIENTS' | translate }}
</div>

<mat-card appearance="outlined">
  <table mat-table [dataSource]="recipients" class="mat-elevation-z8" matSort (matSortChange)="sortData($event)" matSortDisableClear="true">

    <ng-container matColumnDef="email">
      <th mat-header-cell *matHeaderCellDef mat-sort-header="emails"> Emails </th>
      <td mat-cell *matCellDef="let element">

        <div class="fx-row-space-between-center">
          {{element}}
          @if (user.isManager()) {
            <button mat-icon-button color="primary" (click)='rmRecipient(element)'>
              <mat-icon class="cancel-button">close</mat-icon>
            </button>
          }
        </div>

      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="recipientsTableColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: recipientsTableColumns;"></tr>
  </table>

  @if (user.isManager()) {
    <div style="margin: 1rem;">
      <div class="fx-row-space-around-center email-form" style="margin-top:1rem">
        @if(emailAlreadyRegistered()){
          <p class="email-registered">{{ 'MAP.EMAIL_ALREADY_REGISTERED' | translate }}</p>
        }
        <div class="form-wrapper">
        <mat-form-field appearance="outline">
          <mat-label>{{ 'MAP.NEW_EMAIL' | translate }}</mat-label>
          <input type="email" matInput [formControl]="emailFormControl"
            [(ngModel)]="newRecipient">
            @if (emailFormControl.hasError('email')) {
              <mat-error>{{ 'MAP.INVALID_EMAIL' | translate }}</mat-error>
            }
          </mat-form-field>
          <button class="add-button" mat-mini-fab (click)="updateRecipients()" color="warn"
            matTooltip="{{ 'MAP.REGISTER_EMAIL' | translate }}"
            [disabled]="emailFormControl.hasError('email') || emailFormControl.hasError('required') || emailAlreadyRegistered()">
            <mat-icon>add</mat-icon>
          </button>
        </div>
        </div>
      </div>
    }

  </mat-card>