import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class BroadcastService {

    constructor() {
        this.broadcastChannel = new BroadcastChannel('interface-map-communication');
    }

    // --------------------------------- Service class attributes ---------------------------------
    public broadcastChannel: BroadcastChannel


    // --------------------------------- API calls methods ---------------------------------
    publishMessage(message: any) {
        this.broadcastChannel.postMessage(message)
    }
}