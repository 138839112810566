import { Injectable } from '@angular/core';
import { GeoService } from './geo.service';
import { HttpService } from '../services/http.service';
import { UserDataService } from './user-data.service';
import { AmplitudeService } from './amplitude.service';
import { environment } from 'src/environments/environment';
import { LoggingService } from './logging.service';

export interface PropagationParams {
  windSpeedKmh: number,
  windDeg: number,
  tSimulationMin: number,
  lat: number,
  lon: number,
}

const propUrl = environment.forefireUrl;
export const NUMBER_SIMULATION_STEPS = 5;
export const PROPAGATION_COLORS = [
  '#801100',
  '#B62203',
  '#FF5A00',
  '#FF9A00',
  '#FFCE00',
]

@Injectable({
  providedIn: 'root'
})
export class PropagationService {

  constructor(
    private geo: GeoService,
    private http: HttpService,
    private user: UserDataService,
    private amplitude: AmplitudeService,
    public logging: LoggingService
  ) { }

  public simulating = false
  public simulationFormSubmitted = false;
  public drawPropTimeoutId = undefined;
  
  async foreFire(n_relatorio_planta, id_report, wind_speed_km_h = 20, wind_deg = 45, t_sim_s = 1800, lat?, lon?){
    this.simulating = true
    wind_deg = wind_deg % 360

    let idPlanta = this.user.getIdPlanta() 

    if (idPlanta === 8) idPlanta = 999
    let decimalPrecision = 6

    if (!lat || !lon){
      lat = this.geo.clickedLatLng.lat.toFixed(decimalPrecision)
      lon = this.geo.clickedLatLng.lng.toFixed(decimalPrecision)
    }

    this.geo.centerOnObject({ lat: lat, lng: lon }, { setMapZoom: true, mapZoom: 13 })

    this.http.get(`${propUrl}/get_propagation_geojson/${idPlanta}/${lon}/${lat}/${wind_speed_km_h}/${wind_deg}/${t_sim_s}?num_steps=${NUMBER_SIMULATION_STEPS}`)
    .then(response => {
      this.simulating = false
      const keys = Object.keys(response)
      let i = 0;
      this.drawNextFirefront(i, keys, response, true);
    });

    this.amplitude.sendEvent('Simulou Propagação', {"Tempo_min": t_sim_s / 60, "Número Relatório": n_relatorio_planta, "ID Relatório": id_report });
  }

  drawNextFirefront(i: number, keys: any[], response: any, isFirstTime: boolean = false) {
    if (isFirstTime){
        clearTimeout(this.drawPropTimeoutId);
    };
    this.drawPropTimeoutId = setTimeout(() => {
      try {
        this.geo.drawFirefront(response[keys[i]], i);
      } catch (error) {
        this.logging.logERROR(`foreFire:drawFirefront ${error}`, error);
      }
      i++;
      if (i === keys.length + 1){
        i = 0;
      };  
      this.drawNextFirefront(i, keys, response);

    }, isFirstTime ? 0 : 1500);
  }
}
