import { Component, OnInit } from '@angular/core';
import { AmplitudeService } from '../../services/amplitude.service';
import { UserDataService } from 'src/app/services/user-data.service';

@Component({
  selector: 'app-land-tabs',
  templateUrl: './land-tabs.component.html',
  styleUrls: ['./land-tabs.component.scss']
})
export class LandTabsComponent implements OnInit {

  public cameras: any[];

  private tabs = {
    0: 'Ao Vivo',
    1: 'Presets',
    2: 'Alertas',
    3: 'Calibração'
  }

  constructor(
    private amplitude: AmplitudeService,
    public user: UserDataService,
  ) { }

  ngOnInit() {
    this.getCams();
  }

  amplitudeTabEvent(selectedTab){
    this.amplitude.sendTabViewEvent('cams', this.tabs, selectedTab);
  }

  getCams(){
    this.cameras = this.user.getDadosPantera('cameras');
    this.cameras.sort((c1, c2) => c1.id - c2.id)
  }
}