<main>

  <mat-tab-group id="group" (selectedTabChange)="amplitudeTabEvent($event['index'])">
    
    <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon class="tab-icon">wb_sunny</mat-icon>
        {{ 'MAP.WEATHER' | translate }}
      </ng-template>
      <div class="tab-content-container">
        <app-meteo></app-meteo>
      </div>
    </mat-tab>

    <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon class="tab-icon">flag_2</mat-icon>
        {{ 'MAP.VIRTUAL_STATIONS' | translate }}
      </ng-template>
      <div class="tab-content-container">
        <app-meteo-stations></app-meteo-stations>
      </div>
    </mat-tab>

    <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon class="tab-icon">email</mat-icon>
        {{ 'MAP.INFORMATIVE' | translate }}
      </ng-template>
      <div class="tab-content-container">
        <app-risk-alerts></app-risk-alerts>
      </div>
    </mat-tab>

  </mat-tab-group>

</main>
