import { Component, OnInit, Input } from '@angular/core';
import { ReportsService } from '../../services/reports.service';
import { HttpService } from '../../services/http.service';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialog } from 'src/app/shared/dialogs/confirmation-dialog/confirmation-dialog'
import { UserDataService } from 'src/app/services/user-data.service';
import { TranslateService } from '@ngx-translate/core';
import { LoggingService } from '../../services/logging.service';

@Component({
  selector: 'app-report-imgs',
  templateUrl: './report-imgs.component.html',
  styleUrls: ['./report-imgs.component.scss']
})
export class ReportImgsComponent implements OnInit {

  constructor(
    public rep: ReportsService,
    public http: HttpService,
    public dialog:MatDialog,
    public user: UserDataService,
    public translate: TranslateService,
    public logging: LoggingService,
    public reports: ReportsService
  ) { }

  // Salvar na outra classe
  @Input() public b64: boolean=false;
  @Input() public is_pdf: boolean=false;
    
  private canvas_el: any;
  private canvas_context: any;

  async ngOnInit() {
    if (this.rep.selectedReportImageIndex() > this.rep.imgs.length-1){
      this.rep.selectedReportImageIndex.set(this.rep.imgs.length-1)
    }
  }

  drawRect(){
    try{
      this.canvas_context.clearRect(0, 0, this.canvas_el.width, this.canvas_el.height);
    } catch(e) {
      this.logging.logERROR(`drawRect:clearRect ${e}`,e);
    }
    try {
      var resultado_modelo = this.rep.imgs[this.rep.selectedReportImageIndex()].model_result
      
      if (!resultado_modelo) {
        // apos ter o resultado modelo em todas as imagens, tem que acabar aqui a funcao
        // na transicao vames se eh a imagem de deteccao e se tem resultado modelo no report
        if (this.rep.imgs[this.rep.selectedReportImageIndex()].img_type !=1) return;
        var resultado_modelo = this.rep.selectedR.resultado_modelo
        if (!resultado_modelo) return;
      };
      

      this.canvas_el = document.getElementById('detectionCanvas');

      this.canvas_el.width = this.canvas_el.getBoundingClientRect().width;
      this.canvas_el.height = this.canvas_el.getBoundingClientRect().height;

      let width = this.canvas_el.width;
      let height = this.canvas_el.height;
      let bbox = resultado_modelo.bbox
  
      let x1 = bbox[0]*width
      let y1 = bbox[1]*height
      let rect_width = bbox[2]*width - x1
      let rect_height = bbox[3]*height - y1
      
      this.canvas_context = this.canvas_el.getContext('2d');
      
      this.canvas_context.lineWidth = '1';
      this.canvas_context.strokeStyle = 'red';
      
      this.canvas_context.font = "16px Arial";
      this.canvas_context.fillStyle = "red";
      // Daria pra colocar um BG
      
      if (this.user.isAdmin()) {
        let yText = y1>10? y1-5: y1+15
        this.canvas_context.fillText(`${(resultado_modelo.smoke_prob * 100).toFixed(0)} %`, x1, yText);
      }

      this.canvas_context.beginPath();
      this.canvas_context.rect(x1, y1, rect_width, rect_height);
      this.canvas_context.stroke();
      this.canvas_context.closePath();

    } catch (error) {
      this.logging.logERROR(`drawRect ${error}`,error);
      console.error('erro:', error);
    }
  }
  
  nextImage(){
    let index = this.rep.selectedReportImageIndex()
    this.rep.setSelectedReportImageIndex(index+1)
    if (this.rep.selectedReportImageIndex()==this.rep.imgs.length){
      this.rep.setSelectedReportImageIndex(0)
    }
    this.drawRect()
  }

  previousImage(){
    let index = this.rep.selectedReportImageIndex()
    this.rep.setSelectedReportImageIndex(index-1)
    if (this.rep.selectedReportImageIndex()==-1){
      this.rep.setSelectedReportImageIndex(this.rep.imgs.length-1)
    }
    this.drawRect()
  }
  
}