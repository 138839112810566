<main>

  <mat-tab-group id="group" (selectedTabChange)="amplitudeTabEvent($event['index'])">

    <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon class="tab-icon">gps_fixed</mat-icon>
        {{ 'SAT.CLUSTER_TAB' | translate }}
      </ng-template>

      <div class="tab-content-container">

        @if (!sat.selectedCluster) {
          <mat-toolbar class="custom-toolbar">
            <span>{{ 'SAT.CLUSTER_TABLE' | translate }}</span>
            <span class="update-time">
              {{ 'SAT.LAST_UPDATE' | translate }}: {{geo.satLastUpdated | date:'HH:mm' }}
            </span>
          </mat-toolbar>
        }

        @if (!sat.clusters.length) {
          <mat-card appearance="outlined" class="table">
            {{ 'SAT.NO_ACTIVE_FIRE_DATA' | translate }}
          </mat-card>
        }
        @else {
          <div class="table-container" [hidden]="sat.selectedCluster">
            <div class="table-sub-container">
              <table class="mat-elevation-z8" mat-table [dataSource]="this.sat.clusterTableData" matSort #clusterTableSort="matSort" matSortActive="detection_utc_offset" matSortDirection="desc" matSortDisableClear>

                <ng-container matColumnDef="detection_utc_offset">
                  <th class="detection_header" mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'SAT.CLUSTER_DETECTION' | translate }}</th>
                  <td class="detection_value" mat-cell *matCellDef="let element">
                    {{element.detection_utc_offset | date:'dd/MMM - HH:mm' : undefined : currentLocale }}
                  </td>
                </ng-container>                  
                
                <ng-container matColumnDef="cluster_confidence">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    <div>
                      <div>{{ 'SAT.CLUSTER_CONFIDENCE' | translate }}</div>
                      <div style="font-size:12px">(%)</div>
                    </div>
                  </th>  
                  <td mat-cell *matCellDef="let element">
                    {{element.cluster_confidence}}
                  </td>
                </ng-container>
                
                <ng-container matColumnDef="amount_of_hotspots">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'SAT.CLUSTER_AMOUNT_SHORT' | translate }}</th>
                  <td mat-cell *matCellDef="let element">
                    {{element.amount_of_hotspots}}
                  </td>
                </ng-container>

                <ng-container matColumnDef="cluster_frp_mw_km2">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>
                    <div>
                      <div>{{ 'SAT.CLUSTER_FRP' | translate }}</div>
                      <div style="font-size:11px">(MW/km²)</div>
                    </div>
                  </th>
                  <td mat-cell *matCellDef="let element">
                    {{element.cluster_frp_mw_km2}}
                  </td>
                </ng-container>                  

                <ng-container matColumnDef="talhao_nome">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'MAP.PROPERTY' | translate }}</th>
                  <td mat-cell *matCellDef="let element">
                    {{element.talhao_nome}}
                  </td>
                </ng-container>                  

                <ng-container matColumnDef="cluster_id">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="{{ 'SAT.ATTACHED_TO_REPORT' | translate }}">✔️</th>
                  <td mat-cell *matCellDef="let element">
                    @if (element.cluster_id) {
                      <span matTooltip="{{ 'SAT.ATTACHED_TO_REPORT' | translate }}">✔️</span>
                    }
                  </td>
                </ng-container>
                
                <ng-container matColumnDef="recent">
                  <th mat-header-cell *matHeaderCellDef mat-sort-header matTooltip="{{ 'MAP.NOT_VISUALIZED' | translate }}">🔴</th>
                  <td mat-cell *matCellDef="let element">
                    @if (element.recent) {
                      <span matTooltip="{{ 'MAP.NOT_VISUALIZED' | translate }}">🔴</span>
                    }
                  </td>
                </ng-container>                  
                
                <tr mat-header-row *matHeaderRowDef="clusterTableColumns; sticky: true;"></tr>
                <tr class='table-row' mat-row *matRowDef="let row; columns: clusterTableColumns;" (click)="selectCluster(row.unique_id)"></tr>
              </table>
              </div>
          </div>
        }

        @if (sat.selectedCluster) {
          <div>
            <app-sat-cluster></app-sat-cluster>
          </div>
        }

      </div>
    </mat-tab>

    <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon class="tab-icon">send_to_mobile</mat-icon>
        {{ 'MAP.ALERTS' | translate }}
      </ng-template>
      <div class="tab-content-container">
        <app-sat-alerts></app-sat-alerts>
      </div>
    </mat-tab>

    <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon class="tab-icon-historico">manage_search</mat-icon>
        {{ 'SAT.DETECTION_HISTORY_TAB' | translate }}
      </ng-template>
      <div class="tab-content-container">
        <app-hotspots></app-hotspots>
      </div>
    </mat-tab>

  </mat-tab-group>

</main>
