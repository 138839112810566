import { Component, OnInit, signal,ViewChild } from '@angular/core';

import { MatDialog } from '@angular/material/dialog';
import { InfoDialog } from "../../shared/dialogs/info-dialog/info-dialog";
import { ConfirmationDialog } from 'src/app/shared/dialogs/confirmation-dialog/confirmation-dialog';

import { HttpService } from '../../services/http.service';
import { UntypedFormControl, Validators } from '@angular/forms';
import { UserDataService } from '../../services/user-data.service';
import { TranslateService } from '@ngx-translate/core';
import { AmplitudeService } from '../../services/amplitude.service';
import { MatSort, Sort } from '@angular/material/sort';

@Component({
  selector: 'app-manager',
  templateUrl: './manager.component.html',
  styleUrls: ['./manager.component.scss']
})
export class ManagerComponent implements OnInit {
  
  constructor(
    private http:HttpService,
    private dialog: MatDialog,
    public user: UserDataService,
    private translate: TranslateService,
    public amplitude: AmplitudeService,
  ) { }
  
  public loaded:boolean=false;
  
  public destinatarios:string[];
  public newRecipient:string;
  public emailFormControl = new UntypedFormControl('', [
    Validators.required,
    Validators.email,
  ]);
 
  public recipientsTableColumns: string[] = [
    'email'
  ]
  public emailAlreadyRegistered = signal<boolean | undefined>(undefined)
  @ViewChild(MatSort) sort: MatSort;

  async ngOnInit(){    
    await this.getRecipients();
    // await this.getUserSessions();
    
    this.emailFormControl.valueChanges.subscribe((value: string) => {
      this.setEmailExists(value);
    });
    this.sortOnInit();
    this.loaded = true;
  }
  
  async getRecipients(){
    // TVZ ter uma rota com melhor nome
    let response = await this.http.maestroGet(`get_config_relatorios/${this.user.getIdPlanta()}`);
    this.destinatarios = response?.destinatarios || []
  }

  setEmailExists(email: string) {
    if (this.destinatarios.includes(email)) {
      this.emailAlreadyRegistered.set(true);
    } else {
      this.emailAlreadyRegistered.set(false);
    }
  }

  rmRecipient(r){
    const dialogRef = this.dialog.open(ConfirmationDialog, {
      data: {text: `${this.translate.instant("MAP.DELETE_RECIPIENT")}`}
    })
    
    dialogRef.afterClosed().subscribe((confirmation) => {
      if (!confirmation) return
      this.destinatarios = this.destinatarios.filter(d => d!=r)
      this.updateRecipients()
      this.amplitude.sendEvent('Removeu Destinatário', {"Grupo": this.amplitude.groupName['reports'], "Tipo": "E-mail", "Destinatário": r});
    })
    this.emailFormControl.reset();
    this.emailAlreadyRegistered.set(false);
  }

  async updateRecipients(){
    if (!this.destinatarios.includes(this.newRecipient) && !!this.newRecipient){
      this.destinatarios.push(this.newRecipient);
      this.destinatarios = [...this.destinatarios]
      this.amplitude.sendEvent('Cadastrou Destinatário', {"Grupo": this.amplitude.groupName['reports'], "Tipo": "E-mail", "Destinatário": this.newRecipient});
      this.emailFormControl.reset()
    }

    let obj = {
      id_planta : this.user.getIdPlanta(),
      destinatarios : this.destinatarios
    }
    console.log('update_destinatarios', obj)
    let response = await this.http.maestroPost('update_destinatarios', obj)

    if (response?.status){
      this.dialog.open(InfoDialog, {
        data: {text:`${this.translate.instant("MAP.RECIPIENTS_UPDATED_DIALOG")}`}
      });
    }
    else{
      this.dialog.open(InfoDialog, {
        data: {text:`${this.translate.instant("MAP.ERROR")} : ${response?.msg}`}
      });
    }
  }

  sortData(sort: Sort) {
    const data = this.destinatarios.slice();
    if (!sort.active || sort.direction === '') {
      this.destinatarios = data;
    } else {
      this.destinatarios = data.sort((a, b) => {
        return (a.toLowerCase() < b.toLowerCase() ? -1 : 1) * (sort.direction === 'asc' ? 1 : -1);
      });
    }
  }

  sortOnInit() {
    const defaultSort: Sort = { active: 'email', direction: 'asc' }; 
    this.sortData(defaultSort); 
  }

}