
  <mat-dialog-content>
    <div>
      <h3>Você acionou o {{ data.action }}, aguarde o status &#9989; no botão acionado!</h3>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="center">
    <button mat-raised-button [mat-dialog-close]="true" color="primary">
      Ok
    </button>
  </mat-dialog-actions>

  

