import { Component, OnInit, ViewChild, Output, EventEmitter } from '@angular/core';
import { CurrentSitComponent } from '../current-sit/current-sit.component';
import { DashboardComponent } from '../dashboard/dashboard.component';
import { AmplitudeService } from '../../services/amplitude.service';
import { LoggingService } from '../../services/logging.service';

@Component({
  selector: 'app-fire-data',
  templateUrl: './fire-data.component.html',
  styleUrls: ['./fire-data.component.scss']
})
export class FireDataComponent implements OnInit {

  // @ViewChild(CurrentSitComponent) currentSit: CurrentSitComponent;
  @ViewChild(DashboardComponent) dashboard: DashboardComponent;

  private tabs = {
    0: 'Relatórios',
    1: 'Dashboard',
    2: 'Informativos'
  };

  @Output() reportTabSelected = new EventEmitter<boolean>();

  constructor( 
    private amplitude: AmplitudeService,
    public logging: LoggingService
   ) { }


  ngOnInit() {}

  tabChangeEvent(selectedTab: number){
    selectedTab === 0 ? this.reportTabSelected.emit(true) : this.reportTabSelected.emit(false);
    // this.currentSit.getCurrentStats();
    this.amplitude.sendTabViewEvent('reports', this.tabs, selectedTab);
    if (this.tabs[selectedTab] === 'Dashboard'){
      const dashReadyValue = this.dashboard.dashReady.value;
      this.dashboard.dashReady.next({ ...dashReadyValue, dashTabSelected: true });
      return
    }
    try {
      const dashReadyValue = this.dashboard.dashReady.value;
      this.dashboard.dashReady.next({ ...dashReadyValue, dashTabSelected: false });
      this.dashboard.destroyCharts();
    }
    catch(error) {
      this.logging.logERROR(`tabChangeEvent:destroySecondChart ${error}`,error);
    }
  }

}
