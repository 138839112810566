import { Injectable, Injector, EventEmitter, Inject, LOCALE_ID, signal, effect } from '@angular/core';
import { formatDate } from '@angular/common';
import { MatTableDataSource } from '@angular/material/table';
import { Subject } from 'rxjs';

import { HttpService } from './http.service';
import { UserDataService } from './user-data.service';
import { TranslateService } from '@ngx-translate/core';
import { AmplitudeService } from '../services/amplitude.service';
import { GeoService } from '../services/geo.service';
import { GeoLayersService } from '../services/geo.layers';
import { LoggingService } from './logging.service';
import { ReportsService } from './reports.service';

import * as L from 'leaflet';
import { formatHotspotPopUp, formatClusterPopUp, formatClusterTimelinePopUp, formatHotspotTalhaoPopUp, formatHotspotRoiPopUp, formatReportSatImagePopUp } from "./geo.format";
import * as gl from "./geo.legends";
import { LayerObj, LayerGroupObj } from "./geo.store";
import { environment } from '../../environments/environment';
import { addActivatedPopup }  from "./geo.format";


const geoServerBaseUrl = environment.geoserverUrl;

//Cluster layer symbology parameters
const CLUSTER_STROKE_WEIGHT = 2;
const CLUSTER_DEFAULT_STROKE_DASH_ARROW = '5'; //cluster temporário
const CLUSTER_CONFIRMED_STROKE_DASH_ARROW = '0'; //cluster permanente
const CLUSTER_STROKE_COLOR = {
  1:'red',
  2:'orange',
  3:'yellow',
  4:'black'
}
const CLUSTER_STROKE_OPACITY = 1;
const CLUSTER_FILL_COLOR = {
  1:'red',
  2:'orange',
  3:'yellow',
  4:'gray'
}
const CLUSTER_FILL_OPACITY = 0.4;
const CLUSTER_SELECTED_STROKE_COLOR = '#04d4d4';

//Cluster timeline layer symbology parameters
const CLUSTER_TIMELINE_COLOR = {
  1:'#efedf5',
  2:'#756bb1',
  3:'#7a0177'
}

const detectionHistoryInitDeltaDays = 3

@Injectable({
  providedIn: 'root'
})
export class SatelliteService {

  constructor(
    private injector: Injector,
    private http: HttpService,
    private user: UserDataService,
    public translate: TranslateService,    
    private amplitude: AmplitudeService,
    private layer: GeoLayersService,
    public logging: LoggingService,
    private reports: ReportsService,
    public geo: GeoService,
    @Inject(LOCALE_ID) public locale: string
  ) { 
    this.pt = this.translate.currentLang == 'pt-br';
    this.selectClusterEmmitter = new EventEmitter<boolean>();

    effect(()=> {
      if (this.newClusters()) { 
        this.getAlerts();
      }
    }, {allowSignalWrites: true})

    effect(()=>{
      if (this.clusterCount() > this.previousClusterCount) {
        this.playAudio();
      }
      this.previousClusterCount = this.clusterCount(); 
      document.title = this.clusterCount() > 0 ? `Pantera Web - (${this.clusterCount()}🛰️)` : 'Pantera Web';
    })
  }

  public pt:boolean;

  public selectClusterEmmitter: EventEmitter<boolean>; 
  public clusters;
  public clusterTableData;
  public selectedCluster;
  public clusterLayer: any;
  public clusterHotspotTableData;
  public updateSelectedClusterSubject = new Subject<void>();
  updateSelectedCluster$ = this.updateSelectedClusterSubject.asObservable();
  public clusterCount = signal<number>(undefined)
  private previousClusterCount = 0;
  private newClusters = signal<boolean| undefined>(undefined)

  public hotspotMarkers:any[] = [];

  public startDate = new Date(new Date().getTime() - detectionHistoryInitDeltaDays *  24 * 60 * 60 * 1000);
  public endDate = new Date();
  public hotspotTalhao: Array<any> = [];
  public selectedHotspotTalhao;
  public hotspotTalhaoMarkers:any[] = [];
  public maxHotspotTalhao:number = 1000;

  public alertRecipients;

  public imageSwiperControl;

  async createActiveFireGroup(id_planta){

    let clusterLayer = await this.createClusters(id_planta);
    let hotspotLayer = await this.createHotspots(id_planta);    
    let clusterTimelineLayer = await this.createClustersTimeline(id_planta);
    
    let satelliteOverlays:LayerObj[] = []

    if (clusterLayer){
      satelliteOverlays.push({
        'legend':'SAT.CLUSTER_LAYER',
        'layer': clusterLayer,
        'active': true
      })
    }

    if (hotspotLayer){
      satelliteOverlays.push({
        'legend':'SAT.HOTSPOT_ACTIVE_FIRE_LAYER',
        'layer': hotspotLayer,
        'active': false
      })
    }  

    if (clusterTimelineLayer){
      satelliteOverlays.push({
        'legend':'SAT.TIMELINE_LAYER',
        'layer': clusterTimelineLayer,
        'active': false
      })
    }
    
    let overlayGroup:LayerGroupObj = {
      'groupId': 4,
      'groupTitle': "SAT.ACTIVE_FIRE_GROUP",
      'icon':'satellite_alt',
      'layersList': satelliteOverlays,
      'info': true,
      'infoHTML': 'SAT.ACTIVE_FIRE_GROUP_INFO',
      'showInfo': false,
    }

    return {
      'overlayGroup': overlayGroup
    }
  }

  async createClusters(id_planta) {
    try {
      let rawClusters = await this.http.maestroGet(`get_clusters_geojson/${id_planta}`);
      this.initClusters(rawClusters);   

      let layerMap = new Map(); // Map to store layer references

      let clustersGeoJSON = L.geoJSON(rawClusters, {
        onEachFeature: (feature, layer) => {
          layer.bindPopup(formatClusterPopUp(feature.properties, this.translate));
          addActivatedPopup({
            tipo: 'Cluster',
            marker: layer,
            properties: feature.properties,
          });

          let clusterStyle = {
            weight: CLUSTER_STROKE_WEIGHT,
            dashArray: CLUSTER_DEFAULT_STROKE_DASH_ARROW,
            color: CLUSTER_STROKE_COLOR[layer.feature.properties.symbology_class],
            opacity: CLUSTER_STROKE_OPACITY,
            fillColor: CLUSTER_FILL_COLOR[layer.feature.properties.symbology_class],
            fillOpacity: CLUSTER_FILL_OPACITY,
          };
          if (feature.properties.cluster_id) {
            clusterStyle.dashArray = CLUSTER_CONFIRMED_STROKE_DASH_ARROW;
          }
          layer.setStyle(clusterStyle);
          layer.initialStyle = { ...clusterStyle }; //keep initial style

          // Store the layer reference using a unique identifier
          layerMap.set(feature.properties.unique_id, layer);          
          
          layer.on('click', e => {
            this.injector.get(GeoService).mapOnClick(e);
            
            //Reset symbology for previously selected cluster            
            if (this.selectedCluster) {
              layerMap.get(this.selectedCluster.unique_id).setStyle({
                ...layerMap.get(this.selectedCluster.unique_id).initialStyle
              }); 
            };

            if (!this.selectedCluster || this.selectedCluster["unique_id"] !== feature.properties.unique_id) {
              this.amplitude.sendEvent("Abriu Agrupamento", {"Origem": "Mapa", "unique_id": feature.properties.unique_id});
            };

            this.selectCluster(feature.properties.unique_id);
          });

          layer.unique_id = feature.properties.unique_id;
        },
      });      
      this.clusterLayer = clustersGeoJSON; 
      this.newClusters.set(true)     
      return clustersGeoJSON;

    } catch (error) {
      this.logging.logERROR(`createClusters ${error}`,error);
      console.log('❌createClusters');
      return null;
    }
  }

  initClusters(clusters_geojson){
    if (!clusters_geojson.features){
      this.clusters = []
      return
    }

    this.clusters = clusters_geojson.features.map(
      feature => Object.assign(
        {
          'recent': +this.user.last_access < +new Date(feature.properties.detection)
        },
        feature.properties
      )
    );
    this.clusters.sort((c1, c2) => +new Date(c2.newest_hotspot) - +new Date(c1.newest_hotspot));
    this.clusterTableData = new MatTableDataSource(this.clusters);
  }

  selectCluster(id){
    //Rollback the logic when the same cluster is selected again
    if (this.selectedCluster) {
      if (this.selectedCluster.unique_id == id) {
        this.selectedCluster = null;
        this.selectClusterEmmitter.emit(false);
        this.injector.get(GeoService).map.closePopup();
        return
      }
    }
    this.selectedCluster = this.getCluster(id);
  }

  getCluster(id) {
    this.selectedCluster = this.clusters.filter(c => c.unique_id == id)[0];

    if(this.selectedCluster){
      this.selectedCluster.recent = false;
      const currentClusterCount = this.clusters.filter(c => c.recent).length;
      this.clusterCount.set(currentClusterCount);
    
      this.selectClusterEmmitter.emit(true);
      console.log("selectedCluster", this.selectedCluster);

      this.clusterHotspotTableData = new MatTableDataSource(this.selectedCluster.geojson_hotspots.features.map(f=>f.properties));
      this.updateSelectedClusterSubject.next();

      //Apply modified symbology for selected cluster
      if (this.clusterLayer) {
        this.clusterLayer.eachLayer(layer => {   
          if (this.selectedCluster.unique_id === layer.feature.properties.unique_id) {
            layer.setStyle({ color: CLUSTER_SELECTED_STROKE_COLOR, dashArray: this.selectedCluster.cluster_id ? CLUSTER_CONFIRMED_STROKE_DASH_ARROW : CLUSTER_DEFAULT_STROKE_DASH_ARROW });
            if (!layer.isPopupOpen()) {
              layer.openPopup(); // abre o popup do agrupamento selecionado após o refresh dos dados (não quero usar this.geo.openClusterPopUp(), a qual centraliza o agrupamento; e nem abrir novamente o popup quando o polígono é clicado, pois já é feito nativamente pelo método bindPopup do Leaflet)
            }
          }
        });
      }

      return this.selectedCluster;
    }
  }

  unselectCluster(){
    this.selectedCluster = null;

    //Reset to initial symbology - all clusters
    if (this.clusterLayer) {
      this.clusterLayer.eachLayer(layer => {
        let clusterStyle = {
            weight: CLUSTER_STROKE_WEIGHT,
            dashArray: CLUSTER_DEFAULT_STROKE_DASH_ARROW,
            color: CLUSTER_STROKE_COLOR[layer.feature.properties.symbology_class],
            opacity: CLUSTER_STROKE_OPACITY,
            fillColor: CLUSTER_FILL_COLOR[layer.feature.properties.symbology_class],
            fillOpacity: CLUSTER_FILL_OPACITY,
        };
        if (layer.feature.properties.cluster_id) {
            clusterStyle.dashArray = CLUSTER_CONFIRMED_STROKE_DASH_ARROW;
        };
          layer.setStyle(clusterStyle);
          layer.closePopup();
      });
    };
  }

  async createHotspots(id_planta){
    try{
      this.hotspotMarkers = [];
      let rawHotspots = await this.http.maestroGet(`get_geojson_hotspots/${id_planta}`);

      let hotspotsGeoJSON = L.geoJSON(rawHotspots, {
        pointToLayer: (feature, latlng) => {
          var hostpostMarkerOptions = {
            radius: 5,
            weight: 1,            
            color: "#000000",
            opacity: 1,
            fillColor: CLUSTER_FILL_COLOR[feature.properties['symbology_class']],
            fillOpacity: 1         
          };

          let marker = L.circleMarker(latlng, hostpostMarkerOptions);
          marker.bindPopup(formatHotspotPopUp(feature.properties, this.translate));
          addActivatedPopup({
            tipo: 'Cluster Hotspot',
            marker: marker,
            properties: feature.properties
          });
          this.hotspotMarkers.push(marker);
          return marker;
        },
        onEachFeature: (feature, layer) => {
          layer.on('click', e => {
            this.amplitude.sendEvent("Clicou Foco De Calor", {"Origem": "Mapa", "ID Foco Calor": feature.properties.id_hotspot})
          });
        }
      });
      return hotspotsGeoJSON;

    } catch (error) {
      this.logging.logERROR(`createHotspots ${error}`,error);
      console.log('❌createHotspots');
      return null
    }
  }

  async createClustersTimeline(id_planta){
    try {
      let rawClustersTimeline = await this.http.maestroGet(`get_clusters_timeline/${id_planta}`);
      let clustersTimelineGeoJSON = L.geoJSON(rawClustersTimeline, {
        onEachFeature: (feature, layer) => {
          layer.bindPopup(formatClusterTimelinePopUp(feature.properties, this.translate));
          addActivatedPopup({
            tipo: 'Cluster Timeline',
            marker:layer,
            properties: feature.properties,
          });

          let style = {
            "weight": 2,
            "color": CLUSTER_TIMELINE_COLOR[feature.properties.timeline_class],
            "opacity": 1, //opaque stroke
            "fillColor": CLUSTER_TIMELINE_COLOR[feature.properties.timeline_class],
            "fillOpacity": 0.8  
          }
          layer.setStyle(style)
        }
      });
      return clustersTimelineGeoJSON;
      
    } catch (error){
      this.logging.logERROR(`createClustersTimeline ${error}`,error);
      console.log('❌createClustersTimeline');
      return null
    }
  }  
  
  async createDetectionHistoryGroup() {
    let satelliteOverlays:LayerObj[] = []

    let hotspotTalhaoLayer = await this.createDetectionHistoryLayer();
    if (this.hotspotTalhao.length){
      satelliteOverlays.push({
        'legend':'SAT.HOTSPOT_DETECTION_HISTORY_LAYER',
        'layer': hotspotTalhaoLayer,
        'active': false
      })
    }

    let overlayGroup:LayerGroupObj = {
      'groupId': 10,
      'groupTitle': "SAT.DETECTION_HISTORY_GROUP",
      'icon':'search',
      'layersList': satelliteOverlays,
      'info': true,
      'infoHTML': "SAT.DETECTION_HISTORY_GROUP_INFO",
      'showInfo': false,      
    }

    return {
      'overlayGroup': overlayGroup
    }
  }
  
  async createDetectionHistoryLayer() {
    await this.getHotspotTalhao();
    let rawHotspotTalhaoGeoJSON = this.convertHotspotTalhaoToGeoJSON(this.hotspotTalhao);
    //Reverse order so newer hotspots stay on top of the layer
    let reversedFeatures = rawHotspotTalhaoGeoJSON.features.slice().reverse();
    let reversedHotspotTalhaoGeoJSON = { ...rawHotspotTalhaoGeoJSON, features: reversedFeatures };

    this.hotspotTalhaoMarkers = []

    let hotspotTalhaoGeoJSON = L.geoJSON(reversedHotspotTalhaoGeoJSON, {
      pointToLayer: (feature, latlng) => {
        var hostpostMarkerOptions = {
          radius: 5,
          weight: 1,
          color: '#000000',
          opacity: 1,
          fillColor: '#1da0ab',
          fillOpacity: 1         
        };

        let marker = L.circleMarker(latlng, hostpostMarkerOptions);
        marker.bindPopup(formatHotspotTalhaoPopUp(feature.properties, this.translate));
        addActivatedPopup({
          tipo: 'Hotspot Talhao',
          marker: marker,
          properties: feature.properties
        });
        
        this.hotspotTalhaoMarkers.push(marker)

        marker.getPopup().on('remove', e => {
          this.selectedHotspotTalhao = null;
        });

        return marker
      },
      onEachFeature: (feature, layer) => {
        layer.on('click', e => {
          this.selectedHotspotTalhao = feature.properties.id_hotspot;
          console.log('selectedHotspotTalhao', this.selectedHotspotTalhao)
        });
      }
    });

    return hotspotTalhaoGeoJSON    
  }

  async getHotspotTalhao(){
    if (!this.startDate || !this.endDate) return;
    let startDate = formatDate(this.startDate, 'yyyy-MM-dd', this.locale)
    let endDate = formatDate(this.endDate, 'yyyy-MM-dd', this.locale)
    this.hotspotTalhao = await this.http.maestroGet(`get_hotspot_talhao/${this.user.getIdPlanta()}/${startDate}/${endDate}`) || [];
  }

  convertHotspotTalhaoToGeoJSON(jsonData) {
    return {
      type: 'FeatureCollection',
      features: jsonData.map(hotspot => {
        const properties = {};
        for (const [key, value] of Object.entries(hotspot)) {
          properties[key] = value;
        }  
        return {
          type: 'Feature',
          properties: properties,
          geometry: {
            type: 'Point',
            coordinates: [hotspot.longitude, hotspot.latitude],
          },
        };
      }),
    };
  }

  async createOrbitTrackingGroup() { 

    let satelliteOverlays:LayerObj[] = []

    let goes_16 = this.createGoesFovWmsLayer(14, 'goes_fov', 0.7);
    satelliteOverlays.push({
      'legend':'GOES-16',
      'layer': goes_16,
      'active': false
    });

    let goes_18 = this.createGoesFovWmsLayer(23, 'goes_fov', 0.7);
    satelliteOverlays.push({
      'legend':'GOES-18',
      'layer': goes_18,
      'active': false
    });

    let aqua_ascending = this.createOrbitTrackLayer('OrbitTracks_Aqua_Ascending');
    let aqua_descending = this.createOrbitTrackLayer('OrbitTracks_Aqua_Descending');
    let aqua_group = L.layerGroup([aqua_ascending, aqua_descending]);
    satelliteOverlays.push({
      'legend':'AQUA',
      'layer': aqua_group,
      'active': false
    });
    
    let terra_ascending = this.createOrbitTrackLayer('OrbitTracks_Terra_Ascending');
    let terra_descending = this.createOrbitTrackLayer('OrbitTracks_Terra_Descending');
    let terra_group = L.layerGroup([terra_ascending, terra_descending]);
    satelliteOverlays.push({
      'legend':'TERRA',
      'layer': terra_group,
      'active': false
    });

    let noaa_20_ascending = this.createOrbitTrackLayer('OrbitTracks_NOAA-20_Ascending');
    let noaa_20_descending = this.createOrbitTrackLayer('OrbitTracks_NOAA-20_Descending');
    let noaa_20_group = L.layerGroup([noaa_20_ascending, noaa_20_descending]);
    satelliteOverlays.push({
      'legend':'NOAA-20',
      'layer': noaa_20_group,
      'active': false
    });

    let noaa_21_ascending = this.createOrbitTrackLayer('OrbitTracks_NOAA-21_Ascending');
    let noaa_21_descending = this.createOrbitTrackLayer('OrbitTracks_NOAA-21_Descending');
    let noaa_21_group = L.layerGroup([noaa_21_ascending, noaa_21_descending]);
    satelliteOverlays.push({
      'legend':'NOAA-21',
      'layer': noaa_21_group,
      'active': false
    });

    let suomi_npp_ascending = this.createOrbitTrackLayer('OrbitTracks_Suomi_NPP_Ascending');
    let suomi_npp_descending = this.createOrbitTrackLayer('OrbitTracks_Suomi_NPP_Descending');
    let suomi_npp_group = L.layerGroup([suomi_npp_ascending, suomi_npp_descending]);
    satelliteOverlays.push({
      'legend':'SUOMI-NPP',
      'layer': suomi_npp_group,
      'active': false
    });

    let landsat_8_ascending = this.createOrbitTrackLayer('OrbitTracks_Landsat-8_Ascending');
    let landsat_8_descending = this.createOrbitTrackLayer('OrbitTracks_Landsat-8_Descending');
    let landsat_8_group = L.layerGroup([landsat_8_ascending, landsat_8_descending]);
    satelliteOverlays.push({
      'legend':'Landsat-8',
      'layer': landsat_8_group,
      'active': false
    });
    
    let landsat_9_ascending = this.createOrbitTrackLayer('OrbitTracks_Landsat-9_Ascending');
    let landsat_9_descending = this.createOrbitTrackLayer('OrbitTracks_Landsat-9_Descending');
    let landsat_9_group = L.layerGroup([landsat_9_ascending, landsat_9_descending]);
    satelliteOverlays.push({
      'legend':'Landsat-9',
      'layer': landsat_9_group,
      'active': false
    });

    let sentinel_3a_ascending = this.createOrbitTrackLayer('OrbitTracks_Sentinel-3A_Ascending');
    let sentinel_3a_descending = this.createOrbitTrackLayer('OrbitTracks_Sentinel-3A_Descending');
    let sentinel_3a = L.layerGroup([sentinel_3a_ascending, sentinel_3a_descending]);
    satelliteOverlays.push({
      'legend':'Sentinel-3A',
      'layer': sentinel_3a,
      'active': false
    });

    let sentinel_3b_ascending = this.createOrbitTrackLayer('OrbitTracks_Sentinel-3B_Ascending');
    let sentinel_3b_descending = this.createOrbitTrackLayer('OrbitTracks_Sentinel-3B_Descending');
    let sentinel_3b = L.layerGroup([sentinel_3b_ascending, sentinel_3b_descending]);
    satelliteOverlays.push({
      'legend':'Sentinel-3B',
      'layer': sentinel_3b,
      'active': false
    });

    if (this.user.isUgm()){

      let metop_b_ascending = this.createOrbitTrackLayer('OrbitTracks_METOP-B_Ascending');
      let metop_b_descending = this.createOrbitTrackLayer('OrbitTracks_METOP-B_Descending');
      let metop_b_group = L.layerGroup([metop_b_ascending, metop_b_descending]);
      satelliteOverlays.push({
        'legend':'MetOp-B',
        'layer': metop_b_group,
        'active': false
      });

      let metop_c_ascending = this.createOrbitTrackLayer('OrbitTracks_METOP-C_Ascending');
      let metop_c_descending = this.createOrbitTrackLayer('OrbitTracks_METOP-C_Descending');
      let metop_c_group = L.layerGroup([metop_c_ascending, metop_c_descending]);
      satelliteOverlays.push({
        'legend':'MetOp-C',
        'layer': metop_c_group,
        'active': false
      });

    }

    return {
      'groupId': 14,
      'groupTitle': "SAT.ORBIT_TRACK_GROUP",
      'icon':'public_off',
      'layersList': satelliteOverlays,
      'info': true,
      'infoHTML': "SAT.ORBIT_TRACK_GROUP_INFO",
      'showInfo': false,
    }
  }

  //https://nasa-gibs.github.io/gibs-api-docs/available-visualizations/#utility
  createOrbitTrackLayer(layer) {
    let today_utc = new Date().toISOString().split('T')[0]; //Date (UTC 0) yyyy-mm-dd  
    let gibs_wms_kvp = '//gibs-{s}.earthdata.nasa.gov/wms/epsg4326/best/wms.cgi';
  
    let gibs_wms_kvp_layer = L.tileLayer.wms(gibs_wms_kvp, {
      layers: layer,
      time: today_utc,
      format: 'image/png',
      transparent: true,
      version: '1.3.0',  
      subdomains: 'abc',
      bounds: [
        [-89.9999, -179.9999],
        [89.9999, 179.9999]
      ],  
      attribution: "<a href='https://wiki.earthdata.nasa.gov/display/GIBS' target='_blank'>NASA EOSDIS GIBS</a>&nbsp;&nbsp;&nbsp;</a>"     
    });    
    return gibs_wms_kvp_layer
  };
 
  async createHotspotRoiGroup() {
    
    let satelliteOverlays:LayerObj[] = []

    if (this.user.hasTalhaoRoi() === true){
      let talhao_roi = this.layer.createClientWmsLayer(this.user.getIdPlanta(), 'ugm', 'talhao_roi', 0.4);
      satelliteOverlays.push({
        'legend': "SAT.ROI_POLY_LAYER",
        'layer': talhao_roi,
        'active': false
      })
    }    
    //Hotspot ROI
    if (this.user.hasHotspotRoi() === true){      
      //WFS
      const hotspotRoiGeoJSON = L.geoJSON(null, {
        pointToLayer: function (feature, latlng) {
          return L.circleMarker(latlng, {
            radius: 5,
            weight: 1,
            color: "#000000",
            opacity: 1,
            fillColor: gl.HOTSPOT_ROI_FILL_COLOR_MAP[feature.properties.satellite],
            fillOpacity: 1  
          });
        },
        onEachFeature: (feature, layer) => { 
          layer.bindPopup(formatHotspotRoiPopUp(feature.properties, this.translate));
          addActivatedPopup({
            tipo: 'Hotspot Roi',
            marker: layer,
            properties: feature.properties
          });
        }
      });
      let hotspot_roi_wfs = await this.layer.createClientWfsLayer(this.user.getIdPlanta(), 'sat', 'hotspot_roi', hotspotRoiGeoJSON, null);
      satelliteOverlays.push({
        'legend': "SAT.HOTSPOT_ROI_LAYER",
        'layer': hotspot_roi_wfs,
        'active': false
      })    
    }

    let overlayGroup:LayerGroupObj = {
      'groupId': 18,
      'groupTitle': "SAT.ROI_GROUP",
      'icon':'open_in_full',
      'layersList': satelliteOverlays,
      'info': true,
      'infoHTML': "SAT.ROI_GROUP_INFO",
      'showInfo': false,      
    }

    return {
      'overlayGroup': overlayGroup
    }
  }

  async createHotspotWorldGroup() {
    
    let satelliteOverlays:LayerObj[] = []

    if (this.user.isUgm()){

      let goes = this.createSatWmsLayer('v_goes','1.1.0');
      satelliteOverlays.push({
        'legend':"SAT.GOES_LAYER",
        'layer': goes,
        'active': false
      });

      let firms = this.createSatWmsLayer('v_firms','1.1.0');
      satelliteOverlays.push({
        'legend':'SAT.FIRMS_LAYER',
        'layer': firms,
        'active': false
      });

      let eumetsat = this.createSatWmsLayer('v_eumetsat','1.1.0');
      satelliteOverlays.push({
        'legend':'SAT.EUMETSAT_LAYER',
        'layer': eumetsat,
        'active': false
      });

    }

    let overlayGroup:LayerGroupObj = {
      'groupId': 21,
      'groupTitle': "SAT.HOTSPOT_WORLD_GROUP",
      'icon':'south_america',
      'layersList': satelliteOverlays,
    }

    return {
      'overlayGroup': overlayGroup
    }

  }

async createReportImageGroup(id_report) {

  let satelliteOverlays: LayerObj[] = [];

  let hasSatImage = this.reports.selectedR['has_sat_image'][0];
  if (hasSatImage) {

    let reportSatImages = await this.http.maestroGet(`get_report_sat_images/${id_report}`);

    // True Color layer
    let reportSatTrueColorImages = reportSatImages.filter(item => item.id_img_theme === 1);
    if (reportSatTrueColorImages.length) {
      let bbox = [
        [reportSatImages[0]['bbox'][1], reportSatImages[0]['bbox'][0]], // min_y, min_x
        [reportSatImages[0]['bbox'][3], reportSatImages[0]['bbox'][2]]  // max_y, max_x
      ];

      let trueColorImage: L.Layer | L.LayerGroup | null = null;
      let trueColorImageBeforeLayer: L.Layer | null = null;
      let trueColorImageAfterLayer: L.Layer | null = null;

      // Before
      let trueColorImageBefore = reportSatTrueColorImages.find(item => item.img_path.includes('before'));
      if (trueColorImageBefore) {
        trueColorImageBefore['step'] = 'before';
        trueColorImageBefore['acquisition_interval'] = this.getReportSatImageAcquisitionInterval(trueColorImageBefore['metadata']);

        trueColorImageBeforeLayer = this.createImageOverlayLayer(trueColorImageBefore['img_url'], bbox, 'left-report-sat-image');
        trueColorImageBeforeLayer.bindPopup(formatReportSatImagePopUp(trueColorImageBefore, this.translate));
        addActivatedPopup({
          tipo: 'True color Image - Before',
          marker: trueColorImageBeforeLayer,
          properties: trueColorImageBefore
        });
      }

      // After
      let trueColorImageAfter = reportSatTrueColorImages.find(item => item.img_path.includes('after'));
      if (trueColorImageAfter) {
        trueColorImageAfter['step'] = 'after';
        trueColorImageAfter['acquisition_interval'] = this.getReportSatImageAcquisitionInterval(trueColorImageAfter['metadata']);

        trueColorImageAfterLayer = this.createImageOverlayLayer(trueColorImageAfter['img_url'], bbox, 'right-report-sat-image');
        trueColorImageAfterLayer.bindPopup(formatReportSatImagePopUp(trueColorImageAfter, this.translate));
        addActivatedPopup({
          tipo: 'True Color Image - After',
          marker: trueColorImageAfterLayer,
          properties: trueColorImageAfter
        });
      }

      if (trueColorImageBeforeLayer && trueColorImageAfterLayer) {
        trueColorImage = L.layerGroup([trueColorImageBeforeLayer, trueColorImageAfterLayer]);
        this.imageSwiperControl = L.control.sideBySide(trueColorImageBeforeLayer, trueColorImageAfterLayer);
      }
      else {
        if (this.imageSwiperControl) {
          this.imageSwiperControl = null;
        }
        trueColorImage = trueColorImageBeforeLayer || trueColorImageAfterLayer;
      }

      // Add the layer to satelliteOverlays
      satelliteOverlays.push({
        'legend': "SAT.REPORT_IMAGE_TRUE_COLOR_LAYER",
        'layer': trueColorImage,
        'active': false
      });
    }    
  }

  // Build the overlay group
  let overlayGroup: LayerGroupObj = {
    'groupId': 24,
    'groupTitle': "SAT.REPORT_IMAGE_GROUP",
    'icon': 'image',
    'layersList': satelliteOverlays,
    'info': true,
    'infoHTML': 'SAT.REPORT_IMAGE_GROUP_INFO',
    'showInfo': false,
  };

  // Return the overlay group
  return {
    'overlayGroup': overlayGroup
  };
}


getReportSatImageAcquisitionInterval(metadata) {

  let dates = Array.from(new Set(
    metadata.scenes.map((scene: any) => {
      // Create a Date object, but ensure it's in UTC
      let date = new Date(scene.date);
      // Extract only the date part (ignoring time) by converting to YYYY-MM-DD format
      return `${date.getUTCFullYear()}-${(date.getUTCMonth() + 1).toString().padStart(2, '0')}-${date.getUTCDate().toString().padStart(2, '0')}`;
    })
  ))
  .sort((a: string, b: string) => new Date(a).getTime() - new Date(b).getTime()) // Sort in chronological order
  .map((dateStr: string) => {
    let date = new Date(dateStr);
    let day = date.getUTCDate();
    let month = date.toLocaleString('en', { month: 'short', timeZone: 'UTC' }).toLowerCase();
    let year = date.getUTCFullYear();
    return `${day}-${month}-${year}`;
  });

  let acquisitionInterval = dates.length > 1 ? `${dates[0]} - ${dates[dates.length - 1]}` : `${dates[0]}`;
  return acquisitionInterval;
}

  createSatWmsLayer(layerName, version){
    return L.tileLayer.wms(`${geoServerBaseUrl}/geoserver/sat/wms`,
      {
        format: 'image/png',
        version: version,
        layers: `sat:${layerName}`,
        transparent: true,
      }
    )
  }

  createImageOverlayLayer(imageUrl, imageBounds, pane){
    return L.imageOverlay(imageUrl, imageBounds, {pane: pane, interactive: true})
  }

  createGoesFovWmsLayer(satellite_id: number, layerName: string, opacity: number){
    return L.tileLayer.wms(`${geoServerBaseUrl}/geoserver/sat/wms`,
      {
        layers: `sat:${layerName}`,
        format: 'image/png',
        version: '1.1.0',
        transparent: true,
        opacity: `${opacity}`,
        viewparams: `_satellite_id:${satellite_id}`,
      }
    )
  }

  getAlerts() {
    try {
      const currentClusterCount = this.clusters.filter(c => c.recent).length;
      this.clusterCount.set(currentClusterCount)
      this.newClusters.set(false)
    } catch(e) {
      // this.logging.logERROR(`getAlerts ${e}`);
      this.clusterCount.set(0)
    }
  }

  playAudio(){
    if(this.user.audioState()){
      let audio = new Audio();
      audio.src = 'assets/sound/sat-alert.wav';
      audio.load();
      audio.play();
      console.log("Play alert!")
    }
  }

  async getAlertRecipients(){
    this.alertRecipients = await this.http.maestroGet(`get_sat_alert_recipients/${this.user.getIdPlanta()}`);
  }
}