<mat-tab-group id="group" (selectedTabChange)="tabChangeEvent($event['index'])">
  <mat-tab>
    <ng-template mat-tab-label>
      <mat-icon class="tab-icon">search</mat-icon>
      {{ 'MAP.SEARCH' | translate }}
    </ng-template>
    <main>
      <div class="header-container fx-row-space-around-center">
        <div>
          <mat-form-field class="interval-form" appearance="outline" color='accent'>
            <mat-label>{{ "MAP.PICK_INTERVAL" | translate}}</mat-label>
            <mat-date-range-input [rangePicker]="picker">
              <input matStartDate placeholder="{{ 'MAP.STARTS_AT' | translate }}" [formControl]="startDateControl">
              <input matEndDate #endDateInput placeholder="{{ 'MAP.ENDS_AT' | translate }}" [formControl]="endDateControl" (dateChange)='onEndDateChange(endDateInput.value)'>
            </mat-date-range-input>
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-date-range-picker #picker></mat-date-range-picker>
          </mat-form-field>
        </div>
      </div>
      <div class="deforestation-query">
        @if (!selectedDeforestationAlert) {
          <div>
            <table mat-table [dataSource]="sortedTableDataSource" matSort matSortDisableClear = "true" class="mat-elevation-z8">
              <ng-container matColumnDef="idGeom">
                <th mat-header-cell *matHeaderCellDef mat-sort-header="id_geom"> ID </th>
                <td mat-cell *matCellDef="let element">
                  {{ element.id_geom }}
                </td>
              </ng-container>
              <ng-container matColumnDef="nomeGeom">
                <th mat-header-cell *matHeaderCellDef mat-sort-header="nome_geom"> {{ 'MAP.PROPERTY' | translate }} </th>
                <td mat-cell *matCellDef="let element">
                  {{element.nome_geom}}
                </td>
              </ng-container>
              <ng-container matColumnDef="detectedAt">
                <th mat-header-cell *matHeaderCellDef mat-sort-header="detected_at">{{ 'MAP.DETECTION' | translate }}</th>
                <td mat-cell *matCellDef="let element" class="td-date">
                  {{ element.detected_at | date: "dd/MM/YYYY" }}
                </td>
              </ng-container>
              <ng-container matColumnDef="publishedAt">
                <th mat-header-cell *matHeaderCellDef mat-sort-header="published_at">{{ 'MAP.PUBLICATION' | translate }}</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.published_at | date: "dd/MM/YYYY" }}
                </td>
              </ng-container>
              <ng-container matColumnDef="confidence">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'DEF.CONFIDENCE' | translate }}</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.confidence }}
                </td>
              </ng-container>
              <ng-container matColumnDef="sources">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'MAP.SOURCE' | translate }} </th>
                <td mat-cell *matCellDef="let element" style="text-align: left;">
                  {{ element.sources}}
                </td>
              </ng-container>
              <ng-container matColumnDef="areaHa">
                <th mat-header-cell *matHeaderCellDef mat-sort-header="area_ha">{{ 'MAP.AREA_HA' | translate }}</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.area_ha }}
                </td>
              </ng-container>
              <ng-container matColumnDef="confirmed">
                <th mat-header-cell *matHeaderCellDef mat-sort-header="confirmed">{{ 'MAP.CONFIRMED' | translate }}</th>
                <td mat-cell *matCellDef="let element">
                  {{ element.confirmed === true ? '✅' : (element.confirmed === false ? '❌' : '❓') }}
                </td>
              </ng-container>
              <tr mat-header-row *matHeaderRowDef="deforestationTableColumns; sticky:true"></tr>
              <tr mat-row *matRowDef="let row; columns: deforestationTableColumns;" (click)="selectDeforestationAlert(row.id_geom)"></tr>
            </table>
          </div>
        }
        @else {
          <div>
            <div class="alert-container flex">
              <div class="alert-data-container fx-column">
                <div>
                  <div> ID </div>
                  <div> {{ selectedDeforestationAlert.alert_data.idGeom}} </div>
                </div>
                <div>
                  <div> {{ 'MAP.PROPERTY' | translate }} </div>
                  <div> {{ selectedDeforestationAlert.alert_data.nomeGeom}} </div>
                </div>
                <div>
                  <div> {{ 'MAP.AREA_HA' | translate }} </div>
                  <div> {{ selectedDeforestationAlert.alert_data.areaHa}} </div>
                </div>
                <div>
                  <div> {{ 'MAP.DETECTION' | translate }} </div>
                  <div> {{ selectedDeforestationAlert.alert_data.detectedAt | date:'dd/MMM' }} </div>
                </div>
                <div>
                  <div> {{ 'MAP.PUBLICATION' | translate }} </div>
                  <div> {{ selectedDeforestationAlert.alert_data.publishedAt | date:'dd/MMM'}} </div>
                </div>
                <div>
                  <div> {{ 'MAP.CONFIRMED' | translate }} </div>
                  <div> {{ selectedDeforestationAlert.alert_data?.confirmed === true ? '✅' : (selectedDeforestationAlert.alert_data?.confirmed === false ? '❌' : '❓')}} </div>
                </div>
                <div>
                  <div> {{ 'DEF.CONFIDENCE' | translate }} </div>
                  <div> {{ selectedDeforestationAlert.alert_data?.confidence}} </div>
                </div>
                <div>
                  <div> {{ 'MAP.BIOME' | translate }} </div>
                  <div> {{ selectedDeforestationAlert.alert_data?.crossedBiomes}} </div>
                </div>
                <div>
                  <div> {{ 'MAP.STATE' | translate }} </div>
                  <div> {{ selectedDeforestationAlert.alert_data?.crossedStates}} </div>
                </div>
                <div>
                  <div> {{ 'MAP.CITIES' | translate }} </div>
                  <div> {{ selectedDeforestationAlert.alert_data?.crossedCities}} </div>
                </div>
                <div>
                  <div> {{ 'MAP.CATEGORY' | translate }} </div>
                  <div> {{ selectedDeforestationAlert.alert_data?.deforestationClasses}} </div>
                </div>
                <div>
                  <div> {{ 'MAP.SOURCE' | translate }} </div>
                  <div> {{ selectedDeforestationAlert.alert_data?.sources}} </div>
                </div>
                <div>
                  <div> CAR </div>
                  @for (property of selectedDeforestationAlert.alert_data?.ruralPropertiesList; track property.code) {
                    <div>
                      <div [matTooltip]="property.code">{{ property.code | slice:0:5 }}...</div>
                    </div>
                  }
                </div>
                <div>
                  <div> {{ 'MAP.BASEMAP_SAT_RGB' | translate }} </div>
                  @if (selectedDeforestationAlert.alert_data?.publishedImages) {
                    <div>
                      {{ selectedDeforestationAlert.alert_data?.publishedImages[imgUrlNum].constellation }}
                    </div>
                  }
                  @else {
                    <div>
                      Sentinel, Landsat
                    </div>
                  }
                </div>
                <div class="end-btns">
                  <button matTooltip="Voltar" mat-mini-fab color='primary' (click)="unselectAlert()">
                    <mat-icon>reply</mat-icon>
                  </button>
                  <button mat-raised-button color='primary' (click)="confirmAlert(selectedDeforestationAlert.alert_data.idGeom, !selectedDeforestationAlert.alert_data?.confirmed)">
                    @if (selectedDeforestationAlert.alert_data?.confirmed){
                      {{ 'DEF.UNCONFIRM_ALERT' | translate }}
                    } @else {
                      {{ 'DEF.CONFIRM_ALERT' | translate }}
                    }
                  </button>
                </div>
              </div>
              @if (selectedDeforestationAlert.alert_data.publishedImages) {
                <div class="img-container">
                  <img [src]="selectedDeforestationAlert.alert_data.publishedImages[imgUrlNum].url" alt="desmatamento">
                  <div class="before-after">
                    @if (selectedDeforestationAlert.alert_data.publishedImages[imgUrlNum].reference == 'before') {
                      <div>
                        <span>{{ selectedDeforestationAlert.alert_data.publishedImages[imgUrlNum].acquiredAt | date:'dd/MM/YYYY' }}</span>
                      </div>
                    }
                    @if (selectedDeforestationAlert.alert_data.publishedImages[imgUrlNum].reference == 'after') {
                      <div>
                        <span>{{ selectedDeforestationAlert.alert_data.publishedImages[imgUrlNum].acquiredAt | date:'dd/MM/YYYY' }}</span>
                      </div>
                    }
                  </div>
                  <button class='previous-image' mat-mini-fab color='primary' (click)="changeImage()">
                    <mat-icon>navigate_before</mat-icon>
                  </button>
                  <button class='next-image' mat-mini-fab color='primary' (click)="changeImage()">
                    <mat-icon>navigate_next</mat-icon>
                  </button>
                </div>
              }
              @if (selectedDeforestationAlert.alert_data?.planetTilesUrls){
                <div class="img-container">
                  <img [src]="selectedDeforestationAlert.alert_data.planetTilesUrls[planetUrlsIndex]?.url" alt="desmatamento">
                  <div class="before-after">
                    <div>
                      <span>{{ selectedDeforestationAlert.alert_data.planetTilesUrls[planetUrlsIndex]?.date | date: 'dd/MM/YYYY' }}</span>
                    </div>
                  </div>
                  <button class='previous-image' mat-mini-fab color='primary' (click)="goToImage(-1)">
                    <mat-icon>navigate_before</mat-icon>
                  </button>
                  <button class='next-image' mat-mini-fab color='primary' (click)="goToImage(1)">
                    <mat-icon>navigate_next</mat-icon>
                  </button>
                </div>
              }
              @if (!selectedDeforestationAlert.alert_data.publishedImages && !selectedDeforestationAlert.alert_data?.planetTilesUrls) {
                <div class="img-container fx-row-center">
                  <div>{{ 'MAP.NO_IMAGE_AVAILABLE' | translate }}</div>
                </div>
              }
            </div>
          </div>
        }
      </div>
    </main>
  </mat-tab>
  <mat-tab>
    <ng-template mat-tab-label>
      <mat-icon class="tab-icon">bar_chart</mat-icon>
      Dashboard
    </ng-template>
    <div class="tab-content-container">
      <div class="chart-rows">
        <div class="chart-row first-row">
          <div>
            <canvas id="monthlyDeforestation"></canvas>
          </div>
          <div>
            <canvas id="yearlyDeforestation"></canvas>
          </div>
        </div>
        <div class="chart-row">
          <div>
            <canvas id="sources"></canvas>
          </div>
          <div>
            <canvas id="cities"></canvas>
          </div>
        </div>
        <!-- chart-row vazia = gambi pra responsividade -->
        <div class="chart-row">
          <div><canvas></canvas></div>
        </div>
      </div>
    </div>
  </mat-tab>
</mat-tab-group>
