<style>

main {
  display: flex;
  flex-direction: column;
}

.button-cluster-and-report {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  font-size: 14px;
}

.cluster-and-report-subheader {
  margin-top: 6px;
}

.form-and-button-container {
  display: flex;
  align-items: baseline;
  gap: 10px;
}

.report-cluster-dialog {
padding: 10px;
}

.dropdown-report { 
  mat-label {
    font-size: 13px;
  }  
  mat-select {
    font-size: 14px;
  }
}

@media (max-width: 600px) {
  .form-and-button-container, .cluster_to_report {
    flex-direction: column;
    align-items: center; 
    display:flex
  }

  .dropdown-report {
    width: 50vw !important; 
  }

  .button-cluster-and-report {
    margin-top: 20px; 
  }
}
</style>

<div class="report-cluster-dialog">
  <div class="cluster_to_report">
    <div [ngClass]="{'fx-row-space-around-center': rep.reports_list.length < 1}">
      <button mat-raised-button color='primary' class="button-cluster-and-report" 
        (click)='createSatReport(sat.selectedCluster.latitude, sat.selectedCluster.longitude, sat.selectedCluster.detection, rData, sat.selectedCluster.pk_mv)'
        matTooltip="{{ 'SAT.CONFIRM_CLUSTER_TOOLTIP' | translate }}"
        
        [disabled]="selectedRepId !== null ? true : null || creatingReport ? true : null" >
        {{ 'REP.CREATE_REPORT' | translate }}
        <mat-icon>local_fire_department</mat-icon>
      </button>
    </div>

    @if(rep.reports_list.length > 0){
    <div class="cluster-and-report-subheader">
      <p style="font-size:14px">{{ 'SAT.BIND_CLUSTER_SUBHEADER' | translate }}:</p>
    </div>
    <div class="form-and-button-container">
      <mat-form-field class="dropdown-report" appearance='outline' subscriptSizing="dynamic" style="width:14vw">
        <mat-label matTooltip="{{ 'REP.SELECT_REPORT_TOOLTIP' | translate }}">
          {{ 'REP.SELECT_REPORT' | translate }}
        </mat-label>
        <mat-select [(value)]="selectedRepId">
          <mat-option [value]="null">{{ 'MAP.CLEAR_SELECTION' | translate }}</mat-option>
          @for (r of rep.reports_list; track r.id_report) {
            <mat-option [value]="r.id_report">
              {{r.n_relatorio_planta}}
            </mat-option>
          }
        </mat-select>
        
      </mat-form-field>
      <button mat-raised-button color='primary' class="button-cluster-and-report" 
        (click)='confirmCluster(sat.selectedCluster.pk_mv, selectedRepId)'
        matTooltip="{{ 'SAT.BIND_CLUSTER_TOOLTIP' | translate }}"
        
        [disabled]="selectedRepId === null ? true : null">
        {{ 'SAT.BIND_CLUSTER' | translate }}
        <mat-icon>local_fire_department</mat-icon>
      </button>
    </div>
  }
  @if(rep.reports_list.length < 1){
    <div class="cluster-and-report-subheader">
      <p style="font-size:14px; padding-top: 8px;">{{ 'SAT.CONFIRM_CLUSTER_TOOLTIP' | translate }}</p>
    </div>
   }
  </div>
</div>