import { TranslateService } from '@ngx-translate/core';
import {control, DomUtil} from 'leaflet'

interface LegendObj {
  legend: string;
  color: string;
}


export function createRiskInpeLegend(){

  var legend = control({position: 'bottomright'});
  legend.onAdd = map => {
    
    var div = DomUtil.create('div', 'map-legend')
    div.innerHTML = "<img src=https://queimadas.dgi.inpe.br/queimadas/portal/imagens/legendas/legenda_risco_de_fogo.png>"
    return div;
  };
  return legend  
}

export function createDeforestationLegend(layerUrl){
  var legend = control({position: 'bottomright'});
  legend.onAdd = map => {
    
    var div = DomUtil.create('div', 'map-legend')
    div.innerHTML = `<img src=${layerUrl}>`
    return div;
  };
  return legend
} 


export const HOTSPOT_ROI_FILL_COLOR_MAP = {
  'GOES-16': "#6a3d9a", //purple
  'GOES-18': "#b15928", //brown
  'NOAA-21': "#c9e6c8", //light green
  'NOAA-20': "#6ead6c", //green
  'SUOMI-NPP': "#20751d", //dark green
  'AQUA': '#a6cee3', //light blue
  'TERRA': "#1f78b4", //dark blue
  'Sentinel-3A': "#ff6ec7", // light pink
  'Sentinel-3B': "#f73772", // dark pink
  'Landsat-8': "#fdbf6f", //light orange
  'Landsat-9': "#ff5722", //vivid orange
}

export function createHotspotRoiLegend(){
  const hotspot_roi_legend = control({position: 'bottomleft'});
  hotspot_roi_legend.onAdd = map => {      
    let div = DomUtil.create('div', 'circle-map-legend'),
    labels = ['<strong>Satélite</strong>'],
    categories = Object.keys(HOTSPOT_ROI_FILL_COLOR_MAP).sort((a: any, b: any) => b - a)
    for (let c of categories) {
      div.innerHTML += 
      labels.push(
        '<i style="background:' + HOTSPOT_ROI_FILL_COLOR_MAP[c] + '"></i> ' + c
      );
    }  
    div.innerHTML = labels.join('<br>');
    return div;
  };
  return hotspot_roi_legend;
}


const ACTIVE_FIRE_COLOR_MAP = {
  '\< 30 min': 'red',
  '\< 1 hora': 'orange',
  '\< 3 horas': 'yellow',
  '\< 12 horas': 'gray'
}

export function createActiveFireLegend(){
  const activeFireLegend = control({position: 'bottomleft'});
  activeFireLegend.onAdd = map => {      
    let div = DomUtil.create('div', 'circle-map-legend'),
    labels = ['<strong>Aquisição</strong>'],
    acquisition_classes = Object.keys(ACTIVE_FIRE_COLOR_MAP).sort((a: any, b: any) => b - a)
    for (let acquisition_class of acquisition_classes) {
      div.innerHTML += 
      labels.push(
        '<i style="background:' + ACTIVE_FIRE_COLOR_MAP[acquisition_class] + '"></i> ' + acquisition_class
      );
    }  
    div.innerHTML = labels.join('<br>');
    return div;
  };
  return activeFireLegend;
}

export function createClusterTimelineLegend(translate: TranslateService){

  let clusterTimelineColorMap = {
    [translate.instant("SAT.TIMELINE_LEGEND_CLASS_START")]: "#efedf5",
    [translate.instant("SAT.TIMELINE_LEGEND_CLASS_MIDDLE")]: "#756bb1",
    [translate.instant("SAT.TIMELINE_LEGEND_CLASS_END")]: "#7a0177"
  };

  let clusterTimelineLegend = control({position: 'bottomleft'});
  clusterTimelineLegend.onAdd = map => {      
    let div = DomUtil.create('div', 'square-map-legend'),
    labels = [`<strong>${translate.instant("SAT.TIMELINE_LEGEND_HEADER")}</strong>`],
    categories = Object.keys(clusterTimelineColorMap).sort((a: any, b: any) => b - a);
    for (let c of categories) {
      div.innerHTML += 
      labels.push(
        '<i style="background:' + clusterTimelineColorMap[c] + '"></i> ' + c
      );
    }  
    div.innerHTML = labels.join('<br>');
    return div;
  };
  return clusterTimelineLegend;
}

export function createTalhaoZonaLegend(data, translate: TranslateService){
  if (data) {
    let zoneName = data.map(function(item) {return item.nome_zona});
    let fillColor = data.map(function(item) {return item.fill_color});

    let talhaoZonaLegend = control({position: 'bottomright'});
    talhaoZonaLegend.onAdd = function (map) {
    
      let div = DomUtil.create('div', 'square-map-legend'),
      labels = [`<strong>${translate.instant("MAP.PROPERTY_ZONES_LEGEND_HEADER")}</strong>`],
      categories = zoneName,
      fill_color = fillColor; 
    
      for (var i = 0; i < categories.length; i++) {
        div.innerHTML += 
        labels.push(
          '<i style="background:' + fill_color[i] + '"></i> ' + categories[i]);
      }
      
      div.innerHTML = labels.join('<br>');
      return div;
    };
    return talhaoZonaLegend;
  } else {
    return null;
  }
}

export const DADOS_HISTORICOS_COLOR_MAP = {
  2024: '#FAC0FA',
  2023: '#AC0617',
  2022: '#FF0000',
  2021: '#FF8000',
  2020: '#FFFF00',
  2019: '#00FF00',
  2018: '#00FFFF',
  2017: '#0000FF',
  2016: '#8000FF',
  2015: '#FF00FF',
}

export function createHistoricalDataLegend(){
  const historicalDataLegend = control({position: 'bottomleft'});
  historicalDataLegend.onAdd = map => {
          let div = DomUtil.create('div', 'circle-map-legend'),
    labels = [],
    years = Object.keys(DADOS_HISTORICOS_COLOR_MAP).sort((a: any, b: any) => b - a)
    for (let year of years) {
      div.innerHTML += 
      labels.push(
        '<i style="background:' + DADOS_HISTORICOS_COLOR_MAP[year] + '"></i> ' + year
      );
    }  
    div.innerHTML = labels.join('<br>');
    return div;
  };
  return historicalDataLegend;
}



const plantasContratoColorMap:LegendObj[] = [
  {legend:'Cliente' , color: 'green'},
  {legend:'POC' , color: 'orange'},
  {legend:'Trial' , color: 'red'},
  {legend:'Desativado' , color: 'black'},
]


const plantasColorMap:LegendObj[] = [
  {legend:'Preservação' , color: '#1CA3F5'},
  {legend:'Florestal' , color: '#008337'},
  {legend:'Cana' , color: '#75C32C'},
  {legend:'Mineração' , color: 'black'},
  {legend:'Energia' , color: 'orange'},
]

export function createPpTypeLegend(){
  return createSimpleLegend(plantasContratoColorMap, 'Tipo de contrato', 'circle')
}

export function createPpSectorLegend(){
  return createSimpleLegend(plantasColorMap, 'Setor de atividade', 'circle')
}


function createSimpleLegend(colorMap:LegendObj[], title, shape){
  let leafletlegend = control({position: 'bottomleft'});

  leafletlegend.onAdd = map => {
      
     let div = DomUtil.create('div', `${shape}-map-legend`),
    labels = [`<strong>${title}</strong>`];

    for (let c of colorMap) {
      div.innerHTML += 
      labels.push(
        '<i style="background:' + c.color + '"></i> ' + c.legend
      );
      labels
    }
  
    div.innerHTML = labels.join('<br>');
    return div;
  };
  return leafletlegend;
}


export function createImgLegend(source_url: string, position='bottomleft', cssClass=null){
  let leafletlegend = control({position: position});

  leafletlegend.onAdd = map => {
      
    let div = DomUtil.create('div', 'img-map-legend')
    if (cssClass){
      div.classList.add(cssClass)
    }
    div.innerHTML =  `<img src='${source_url}'>`;
    return div;
  };
  return leafletlegend;
}

export function createTalhaoLinhaEstradaLegend(){
  return createImgLegend('https://geo2.umgrauemeio.com/geoserver/wms?REQUEST=GetLegendGraphic&VERSION=1.0.0&FORMAT=image/png&width=10&height=10&LAYER=ugm:talhao_linha_estrada&LEGEND_OPTIONS=fontAntiAliasing:true');
}

export function createGoesLegend(){
  return createImgLegend('https://geo2.umgrauemeio.com/geoserver/wms?REQUEST=GetLegendGraphic&VERSION=1.0.0&FORMAT=image/png&LAYER=sat:v_goes&LEGEND_OPTIONS=fontAntiAliasing:true');
}

export function createFirmsLegend(){
  return createImgLegend('https://geo2.umgrauemeio.com/geoserver/wms?REQUEST=GetLegendGraphic&VERSION=1.0.0&FORMAT=image/png&LAYER=sat:v_firms&LEGEND_OPTIONS=fontAntiAliasing:true');
}

export function createEumetsatLegend(){
  return createImgLegend('https://geo2.umgrauemeio.com/geoserver/wms?REQUEST=GetLegendGraphic&VERSION=1.0.0&FORMAT=image/png&LAYER=sat:v_eumetsat&LEGEND_OPTIONS=fontAntiAliasing:true');
}

export function createUCLegend(){
  return createImgLegend("https://geo2.umgrauemeio.com/geoserver/wms?REQUEST=GetLegendGraphic&VERSION=1.0.0&FORMAT=image/png&LAYER=ugm:unidade_conservacao&LEGEND_OPTIONS=fontAntiAliasing:true");
}

export function createTILegend(){
  return createImgLegend("https://geo2.umgrauemeio.com/geoserver/wms?REQUEST=GetLegendGraphic&VERSION=1.0.0&FORMAT=image/png&LAYER=ugm:terra_indigena&LEGEND_OPTIONS=fontAntiAliasing:true");
}

export function createRiskLegend(){
  return createImgLegend("https://geo2.umgrauemeio.com/geoserver/wms?REQUEST=GetLegendGraphic&VERSION=1.0.0&FORMAT=image/png&TRANSPARENT=true&WIDTH=15&HEIGHT=15&LAYER=risk:999_danger_d0");
}

export function createRiskLegendEnglish(){
  return createImgLegend("https://geo2.umgrauemeio.com/geoserver/wms?REQUEST=GetLegendGraphic&VERSION=1.0.0&FORMAT=image/png&TRANSPARENT=true&WIDTH=15&HEIGHT=15&LAYER=risk:999_danger_d1");
}

export function createHistoricalRiskLegend(){
  return createImgLegend("https://geo2.umgrauemeio.com/geoserver/wms?REQUEST=GetLegendGraphic&VERSION=1.0.0&FORMAT=image/png&TRANSPARENT=true&WIDTH=15&HEIGHT=15&LAYER=risk:999_heat_mock");
}

export function createHistoricalRiskLegendEnglish(){
  return createImgLegend("https://geo2.umgrauemeio.com/geoserver/wms?REQUEST=GetLegendGraphic&VERSION=1.0.0&FORMAT=image/png&TRANSPARENT=true&WIDTH=15&HEIGHT=15&LAYER=risk:999_heat_mock_eng");
}

export function createPrecipitationLegend(){
  return createImgLegend("https://geo2.umgrauemeio.com/geoserver/wms?REQUEST=GetLegendGraphic&VERSION=1.0.0&FORMAT=image/png&LAYER=ugm:precipitation_mock&LEGEND_OPTIONS=fontAntiAliasing:true");
}

export function createScarRecurrenceBpBioenergyLegend(){
  return createImgLegend("https://geo2.umgrauemeio.com/geoserver/wms?REQUEST=GetLegendGraphic&VERSION=1.0.0&FORMAT=image/png&LAYER=geo:cicatriz_bp_bioenergy&LEGEND_OPTIONS=fontAntiAliasing:true");
}

export function createDeterLegend(){
  return createImgLegend('https://terrabrasilis.dpi.inpe.br/geoserver/deter-amz/ows?request=GetLegendGraphic&format=image/png&width=20&height=20&layer=deter-amz:deter_amz&service=WMS&bbox=-64.37988281250001,-24.806681353851978,-39.46289062500001,-4.34641127533318&srs=EPSG:4674&style=deter-amz:deter_amz_pt-br', 'bottomright');
}

export function createProdesLegend(){
  return createImgLegend('https://terrabrasilis.dpi.inpe.br/geoserver/prodes-brasil-nb/ows?request=GetLegendGraphic&format=image/png&width=20&height=20&layer=prodes-brasil-nb:prodes_brasil&service=WMS&bbox=-63.28125000000001,-24.806681353851978,-40.60546875000001,-4.34641127533318&srs=EPSG:4674&style=prodes-brasil-nb:prodes_brasil_pt-br', 'bottomright', 'prodes-legend');
}

export function createDeforestationGfwLegend(){
  return createImgLegend("https://geo2.umgrauemeio.com/geoserver/wms?REQUEST=GetLegendGraphic&VERSION=1.0.0&FORMAT=image/png&TRANSPARENT=true&WIDTH=15&HEIGHT=15&LAYER=ugm:119_gfw");
}

export function createFuelClassLegend(){
  return createImgLegend("https://geo2.umgrauemeio.com/geoserver/wms?REQUEST=GetLegendGraphic&VERSION=1.0.0&FORMAT=image/png&TRANSPARENT=true&WIDTH=15&HEIGHT=15&LAYER=risk:999_fuelclass");
}

export function createfuelClassLegendEnglish(){
  return createImgLegend("https://geo2.umgrauemeio.com/geoserver/wms?REQUEST=GetLegendGraphic&VERSION=1.0.0&FORMAT=image/png&TRANSPARENT=true&WIDTH=15&HEIGHT=15&LAYER=risk:111_fuelclass");
}

export function getRiskColor(risk){
  console.log(risk)
  if (risk<0.2){
    return '#2b83ba'
  }
  if (risk<0.4){
    return '#abdda4'
  }
  if (risk<0.6){
    return '#ffffbf'
  }
  if (risk<0.8){
    return '#fdae61'
  }
  else {
    return '#d7191c'
  }
}