<main>
  <div class="content" id="content-wrapper">
    <div class="header">
      <div class="report-data-and-actions">
        <div class="report-main-header">
          <mat-icon>local_fire_department</mat-icon>
          @if (reports.selectedR.n_relatorio_planta) {
            <h1>
              {{ 'REP.REPORT' | translate }} {{ reports.selectedR.n_relatorio_planta }} | <span>{{reports.selectedR.datetime_deteccao}}</span>
            </h1>
          }

          @else {
            <h1>
              {{ 'REP.NO_NUMBER_REPORT' | translate }} | <span>{{reports.selectedR.datetime_deteccao}}</span>
            </h1>
          }
        </div>
        <div class="actions-row" id="to-hide">
          <button [mat-dialog-close]="false">{{ 'MAP.CANCEL' | translate }}</button>
          <button mat-button [matMenuTriggerFor]="downloadMenu">{{ 'MAP.DOWNLOAD_REPORT' | translate }}</button>
          <mat-menu class="download-menu" #downloadMenu="matMenu">
            <button [ngClass]="user.darkMode() ? 'download-options-dark' : 'download-options'" mat-menu-item (click)="downloadReportPDF()">Download PDF</button>
            <button [ngClass]="user.darkMode() ? 'download-options-dark' : 'download-options'" mat-menu-item (click)="downloadReportPNG()">Download PNG</button>
          </mat-menu>
          <button mat-button (click)="sendReportViaEmail()">{{ 'MAP.SEND_VIA_EMAIL' | translate }}</button>
        </div>


      </div>

      <div class="logos">
        <img src="assets/logos/logo_ugm.svg"/>
        <img src="assets/logos/pantera_head_negative.svg"/>
        <img [src]="this.b64_logo" />
      </div>
    </div>

    <div class="main-container">
      <div class="data-section-row">
        <div class="white-box">
          <div class="white-box-header">
            <mat-icon>location_on</mat-icon>
            <h2>{{ 'REP.LOCAL_INFO' | translate }}</h2>
          </div>

          <div class="data-box-content">
            <div class="data-box-column">
              @for (i of reports.selectedR.dados_localizacao[0]?.dados_geo.atributos?.Local; track i.key) {
                <div class="data-box-attribute-info">
                  <span>{{i.key}}: {{i.value}}</span>
                </div>
              }
              <div class="data-box-attribute-info">
                <span>Lat: {{reports.selectedR.dados_localizacao[0]?.lat | number:'1.4-4'}}</span>
                <span>Long: {{reports.selectedR.dados_localizacao[0]?.lon | number:'1.4-4'}}</span>
              </div>
            </div>

            @if (reports.selectedR?.dados_meteo[0]) {
              <div class="data-box-column">
                @if (reports.selectedR.dados_meteo[0]['temp']) {
                  <div class="data-box-attribute-info">
                    <mat-icon>
                      thermostat
                    </mat-icon>
                    <span>
                      {{ 'MAP.TEMPERATURE' | translate }}: {{reports.selectedR.dados_meteo[0]['temp' ]|number:'.1-1'}}°C
                    </span>
                  </div>
                }
                @if (reports.selectedR.dados_meteo[0]['humidity']) {
                  <div class="data-box-attribute-info">
                    <mat-icon>
                      water_drop
                    </mat-icon>
                    <span>
                      {{ 'MAP.HUMIDITY' | translate }}: {{reports.selectedR.dados_meteo[0]['humidity']}}%
                    </span>
                  </div>
                }
                @if (reports.selectedR.dados_meteo[0]['wind_speed']) {
                  <div class="data-box-attribute-info">
                    <mat-icon>
                      air
                    </mat-icon>
                    <span>
                      {{ 'MAP.WIND_SPEED' | translate }}: {{reports.selectedR.dados_meteo[0]['wind_speed']*3.6|number:'.0-0'}} km/h
                    </span>
                  </div>
                }
                @if (reports.selectedR.dados_meteo[0]['wind_deg']) {
                  <div class="data-box-attribute-info">
                    <mat-icon>
                      explore
                    </mat-icon>
                    <span>
                      {{ 'MAP.WIND_DIRECTION' | translate }}: {{reports.selectedR.dados_meteo?.[0]?.['wind_deg']}}° {{reports.getWindOriginIcon(reports.selectedR.dados_meteo?.[0]?.['wind_deg']).emoji}}
                    </span>
                  </div>
                }
                @if (reports.selectedR.dados_meteo[0]['rain']) {
                  <div class="data-box-attribute-info">
                    <mat-icon>
                      umbrella
                    </mat-icon>
                    <span>
                      {{ 'MAP.PRECIPITATION' | translate }}: {{reports.selectedR.dados_meteo?.[0]?.['rain']}}mm
                    </span>
                  </div>
                }
              </div>
            }
          </div>
        </div>

        <div class="white-box">
          <div class="white-box-header">
            <mat-icon>image</mat-icon>
            <h2>{{ 'REP.FIRE_IMAGES' | translate }}</h2>
          </div>

          @if (this.reports.imgs.length <= 0) {
            <div>
              <h3 style="color: white;">{{ 'REP.NO_IMAGES' | translate }}</h3>
            </div>
          }

          @else {
            <div class="images-wrapper">
              @if (this.reports.imgs[0].base64) {
                <div class="image-container">
                  <img [src]="this.reports.imgs[0].base64 | safe: 'resourceUrl'"/>
                  <div class="metadata-image">
                    @if (this.reports.imgs[0].img_timestamp) {
                      <span>
                        {{this.reports.imgs[0].img_timestamp | date: "dd/MM/yy HH:mm:ss" }}
                      </span>
                    }
                    @if (this.reports.imgs[0].img_details.pan) {
                      <span>
                        {{this.reports.imgs[0].img_details.pan}}° | {{this.reports.imgs[0].img_details.zoom}}x | ~{{this.reports.imgs[0].img_details?.dist | number: '1.2-2'}}km - {{this.reports.imgs[0].img_details.nome}}
                      </span>
                    }
                  </div>
                </div>
              }
              @if (this.reports.imgs.length > 1) {
                <div class="image-container">
                  @if (this.reports.imgs[1].base64) {
                    <img [src]="this.reports.imgs[1].base64 | safe: 'resourceUrl'"/>
                  }
                  <div class="metadata-image">
                    @if (this.reports.imgs[1].img_timestamp) {
                      <span>
                        {{this.reports.imgs[1].img_timestamp | date: "dd/MM/yy HH:mm:ss" }}
                      </span>
                    }
                    @if (this.reports.imgs[1].img_details.pan) {
                      <span>
                        {{this.reports.imgs[1].img_details.pan}}° | {{this.reports.imgs[1].img_details.zoom}}x | ~{{this.reports.imgs[1].img_details?.dist | number: '1.2-2'}}km - {{this.reports.imgs[1].img_details.nome}}
                      </span>
                    }
                  </div>
                </div>
              }
            </div>
          }
        </div>
      </div>

      <div class="data-section-row">
        <div class="white-box" id="third-white-box">
          <div class="white-box-header">
            <mat-icon>fire_truck</mat-icon>
            <h2>{{ 'MAP.TRIGGERINGS' | translate }}</h2>
          </div>

          @if (reports.selectedR.acionamentos.length <= 0) {
            <div>
              <h3 style="color: white;">{{ 'REP.NO_TRIGGERINGS' | translate }}</h3>
            </div>
          }

          @else {
            <div class="table-wrapper">
              <table>
                <tr>
                  <th>{{ 'MAP.BRIGADE' | translate }}</th>
                  <th>{{ 'MAP.TRIGGERING' | translate }}</th>
                  <th>{{ 'MAP.FIRE_FIGHT' | translate }}</th>
                  <th>{{ 'MAP.AFTERMATH' | translate }}</th>
                </tr>
                @for (acionamento of reports.selectedR.acionamentos; track acionamento.id_acionamento) {
                  <tr>
                    <td>{{acionamento.nome_brigada}}</td>
                    <td>{{acionamento.dt_acionamento | date:'HH:mm'}} - {{acionamento.dt_chegada | date:'HH:mm'}}</td>
                    <td>{{acionamento.dt_inicio_combate | date:'HH:mm'}} - {{acionamento.dt_fim_combate | date:'HH:mm'}}</td>
                    <td>{{acionamento.dt_inicio_rescaldo | date:'HH:mm'}} - {{acionamento.dt_fim_rescaldo | date:'HH:mm'}}</td>
                  </tr>
                }
              </table>
            </div>
          }
        </div>

        <div class="white-box" id="forth-white-box">
          <div class="white-box-header">
            <mat-icon>attachment</mat-icon>
            <h2>{{ 'REP.ADDITIONAL_DATA' | translate }}</h2>
          </div>

          <div class="row">
            <div class="sub-white-box">
              <div class="sub-white-box-header">
                <mat-icon>comments</mat-icon>
                <h3>{{ 'MAP.COMMENTS' | translate }}</h3>
              </div>
              @if (reports.selectedR.comentarios) {
                <span id="comments-data">
                  {{reports.selectedR.comentarios}}
                </span>
              }
            </div>
          </div>

          <div class="row" id="second-row">
            <div class="sub-white-box">
              <div class="sub-white-box-header">
                <mat-icon>info</mat-icon>
                <h3>{{ 'REP.INFORMATIONS' | translate }}</h3>
              </div>
              @if (this.data.origin) {
                <span>
                {{ 'REP.ORIGIN' | translate }}: {{ ('OD.' + reports.selectedR.id_origem_deteccao) | translate }}
                </span>
              }
              @if (this.data.cause) {
                <span>
                  {{ 'REP.CAUSE' | translate }}: {{ ('FC.' + reports.selectedR.id_causa_incendio) | translate }}
                </span>
              }
            </div>

            <div class="sub-white-box">
              <div class="sub-white-box-header">
                <mat-icon>fire_truck</mat-icon>
                <h3> {{ 'MAP.DAMAGE' | translate }}</h3>
              </div>
              @for (damage of reports.selectedR.dados_perdas; track damage.tipo) {
                <span>
                  {{ damage.tipo }}: {{ damage.valor }}{{ damage.unidade }}
                </span>
              }
            </div>
          </div>
        </div>
      </div>

      @if(reports.selectedR?.cluster_geojson){
        <div class="data-section-row">
          <div class="white-box" id="fifth-white-box">
            <div class="white-box-header">
              <mat-icon>satellite_alt</mat-icon>
              <h2>{{'SAT.MENU_TOOLTIP' | translate}}</h2>
            </div>
  
            <div class="data-box-content">
              <div class="data-box-column">
                <div class="data-box-attribute-info">
                  <mat-icon>satellite_alt</mat-icon>
                  <span>{{ 'SAT.CLUSTER_ACQUISITION' | translate }}: {{reports.selectedR?.cluster_geojson?.features[0]?.properties?.acquisition_utc_offset}}</span>
                </div>
                <div class="data-box-attribute-info">
                  <mat-icon>notifications</mat-icon>
                  <span>{{ 'SAT.CLUSTER_DETECTION' | translate }}: {{reports.selectedR?.cluster_geojson?.features[0]?.properties?.detection_utc_offset}}</span>
                </div>
              </div>
              <div class="data-box-column">
                <div class="data-box-attribute-info">
                  <mat-icon>grain</mat-icon>
                  <span>{{ 'SAT.CLUSTER_AMOUNT' | translate }}: {{reports.selectedR?.cluster_geojson?.features[0]?.properties?.amount_of_hotspots}}</span>
                </div>
                <div class="data-box-attribute-info">
                  <mat-icon>whatshot</mat-icon>
                  <span>{{ 'SAT.CLUSTER_FRP' | translate }}: {{reports.selectedR?.cluster_geojson?.features[0]?.properties?.cluster_frp_mw_km2}} MW/km²</span>
                </div>
              </div>         
            </div>
          </div>
        </div>
      }
    </div>
  </div>
</main>
