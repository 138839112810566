<div class="container">

  <div class="form-and-button-container">
    <mat-form-field appearance="outline" class="pick-interval" subscriptSizing="dynamic">
      <mat-label>{{ 'MAP.PICK_INTERVAL' | translate }}</mat-label>
      <mat-date-range-input [rangePicker]="picker">
        <input matStartDate placeholder="{{ 'MAP.STARTS_AT' | translate }}" [(ngModel)]='this.sat.startDate'>
        <input matEndDate #endDateInput placeholder="{{ 'MAP.ENDS_AT' | translate }}" [(ngModel)]='this.sat.endDate' (dateChange)='onEndDateChange(endDateInput.value)'>
      </mat-date-range-input>
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-date-range-picker #picker color="warn"></mat-date-range-picker>
    </mat-form-field>

    <button mat-mini-fab color='accent' (click)='downloadXls()' [disabled]='!this.sat.hotspotTalhao.length' matTooltip="{{ 'MAP.EXPORT_EXCEL' | translate }}" style="margin-left: 1rem;">
      <span>
        <mat-icon>download</mat-icon>
      </span>
    </button>

  </div>

  @if (this.sat.hotspotTalhao.length == this.sat.maxHotspotTalhao) {
    <mat-card class="data-truncated-card" appearance="outlined">
      {{ 'SAT.HOTSPOT_DETECTION_HISTORY_DATA_TRUNCATED' | translate }}
    </mat-card>
  }

  @if (this.sat.hotspotTalhao.length) {
    <div class="table">
      <div class="table-container">
        <table class="mat-elevation-z8" mat-table [dataSource]="this.sat.hotspotTalhao">

          <ng-container matColumnDef="satellite">
            <th mat-header-cell *matHeaderCellDef>{{ 'SAT.SATELLITE' | translate }}</th>
            <td class="satellite_value" mat-cell *matCellDef="let element">
              {{element.satellite.replace('-', '-\u2060')}}
            </td>
          </ng-container>

          <ng-container matColumnDef="acquisition_utc_offset">
            <th mat-header-cell *matHeaderCellDef>{{ 'SAT.HOTSPOT_ACQUISITION' | translate }}</th>
            <td class="acquisition_value" mat-cell *matCellDef="let element">
              {{element.acquisition_utc_offset | date:'dd/MMM - HH:mm'}}
            </td>
          </ng-container>

          <ng-container matColumnDef="detection_utc_offset">
            <th mat-header-cell *matHeaderCellDef>{{ 'SAT.HOTSPOT_DETECTION' | translate }}</th>
            <td class="detection_value" mat-cell *matCellDef="let element">
              {{element.detection_utc_offset | date:'dd/MMM - HH:mm' : undefined : currentLocale }}
            </td>
          </ng-container>

          <ng-container matColumnDef="talhao_nome">
            <th mat-header-cell *matHeaderCellDef>{{ 'MAP.PROPERTY' | translate }}</th>
            <td mat-cell *matCellDef="let element">
              {{element.talhao_nome}}
            </td>
          </ng-container>

          <ng-container matColumnDef="alerted">
            <th class="alerted" mat-header-cell *matHeaderCellDef>{{ 'SAT.CLUSTER_ALERT_2' | translate }}</th>
            <td class="alerted" mat-cell *matCellDef="let element">
              @if (element.alerted) {
                <div>
                  {{ 'MAP.YES' | translate }}
                </div>
              }
              @else {
                <div>
                  {{ 'MAP.NO' | translate }}
                </div>
              }
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="hotspotTalhaoDfColumns; sticky: true;"></tr>
          <tr mat-row *matRowDef="let row; columns: hotspotTalhaoDfColumns;" class='table-row' [ngClass]="{'table-row-color': row.id_hotspot == this.sat.selectedHotspotTalhao}" (click)="selectHotspotTalhao(row.id_hotspot)"></tr>
        </table>
      </div>
    </div>
  }
  @else {
    <mat-card class="no-data-card" appearance="outlined">
      {{ 'SAT.NO_DETECTION_HISTORY_DATA' | translate }}
    </mat-card>
  }

</div>